import React, { useState } from "react";

import styles from "./LegalPages.module.scss";
import { Link } from "react-router-dom";

const Privacy = () => {

  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionId) => {
    if (openSection === sectionId) {
      setOpenSection(null);
    } else {
      setOpenSection(sectionId);
    }
  };

  return (
    <div className={styles["super-container"]}>
      <div className={styles.container}>
        <h3 className={styles.heading}>Politique de confidentialité</h3>

        <h5 className="pt-3">
          Qu’est-ce que la Politique de confidentialité ?
        </h5>

        <p className={styles.paragraph}>
          PsyUP s’engage à ce que le traitement de vos données personnelles soit
          conforme à la loi « Informatique et Libertés » du 6 janvier 1978
          modifié, et au règlement général sur la protection des données (RGPD)
          dit règlement UE 2016/679 du Parlement européen et du Conseil du 27
          avril 2016 relatif à la protection des personnes physiques à légard du
          traitement des données à caractère personnel et à la libre circulation
          de ces données.
        </p>
        <p>
          Dans cette Politique de confidentialité, nous expliquons comment nous
          recueillons, utilisons, partageons, stockons et transférons vos
          informations personnelles. Nous vous faisons également savoir quels
          sont vos droits.
        </p>

        <h5 className="pt-2">Quelles informations recueillons-nous ?</h5>

        <div>
          <p className="pt-2">Voici les informations que nous recueillons :</p>
          <ul className={styles.cookiesCollapse}>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(1)}
              >
                <h6>Votre activité et les informations que vous fournissez</h6>
                <h5>{openSection === 1 ? "-" : "+"}</h5>
              </div>
              {openSection === 1 && (
                <>
                  <p className="pb-2">
                    Nous recueillons les informations concernant votre activité
                    et les informations que vous fournissez, notamment :
                  </p>
                  <ul className={styles.dashaedUL}>
                    <li>
                      Les informations relatives à la création de votre compte
                      PsyUP ou au formulaire de contact (nom, prénom, pseudo,
                      mot de passe, adresse e-mail, numéro de téléphone, âge,
                      genre, photo de profil, informations de paiement, etc.).
                      Vous pouvez choisir de fournir des informations sur vos
                      croyances religieuses, votre orientation sexuelle, vos
                      opinions politiques, votre santé, votre origine ethnique
                      ou raciale, vos croyances philosophiques ou votre adhésion
                      à un syndicat. Ces types d’informations font l’objet de
                      protections spéciales.
                    </li>
                    <li>
                      Le contenu que vous créez, comme les publications, les
                      commentaires ou les fichiers audio.
                    </li>
                    <li>
                      Les messages que vous envoyez et recevez, en particulier
                      lorsque les utilisateurs nous les signalent pour examen.
                    </li>
                    <li>
                      Les métadonnées à propos du contenu et des messages (ex.
                      la date et l’heure de son envoi).
                    </li>
                    <li>
                      Les types de contenus, y compris les publicités, que vous
                      visualisez ou avec lesquels vous interagissez, et la
                      manière dont vous interagissez avec eux.
                    </li>
                    <li>
                      Les applications et les fonctionnalités que vous utilisez
                      et les actions que vous faites avec celles-ci. Il s’agit
                      par exemple de l’option de saisie automatique, des plugins
                      sociaux (réactions avec émoticônes, actions de partages et
                      de commentaires, etc.).
                    </li>
                    <li>
                      Les achats de services et autres transactions que vous
                      faites, y compris vos informations de carte de crédit.
                    </li>
                    <li>Les hashtags que vous utilisez.</li>
                    <li>
                      L’heure, la fréquence et la durée de vos activités sur
                      PsyUP.
                    </li>
                    <li>
                      Les consultations d’une Page PsyUP et de son contenu,
                      ainsi que les interactions avec celle-ci, afin de fournir
                      à l’administrateur de la Page des informations agrégées
                      sur la façon dont les internautes utilisent leur Page et
                      son contenu. PsyUP est responsable conjointement avec les
                      administrateurs de Pages.
                    </li>
                  </ul>
                </>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(2)}
              >
                <h6>
                  Les informations sur vos Abonnements, Abonnés et autres
                  relations
                </h6>
                <h5>{openSection === 2 ? "-" : "+"}</h5>
              </div>
              {openSection === 2 && (
                <>
                  <p className="pb-2">
                    Nous recueillons les informations concernant vos
                    Abonnements, Abonnés et autres relations, notamment :
                  </p>
                  <ul className={styles.dashaedUL}>
                    <li>
                      Les informations de vos contacts tels que leur nom et leur
                      adresse e-mail ou leur numéro de téléphone.
                    </li>
                    <li>
                      Les informations que nous recueillons ou déduisons à votre
                      sujet à partir des activités d’autres personnes. Par
                      exemple, nous pouvons vous suggérer un abonnement via la
                      fonctionnalité Suggestions d’abonnements.
                    </li>
                  </ul>
                </>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(3)}
              >
                <h6>
                  Les informations de l’application, du navigateur et de
                  l’appareil que vous utilisez
                </h6>
                <h5>{openSection === 3 ? "-" : "+"}</h5>
              </div>
              {openSection === 3 && (
                <>
                  <p className="pb-2">
                    Nous recueillons les informations de l’application, du
                    navigateur et de l’appareil que vous utilisez, notamment :
                  </p>
                  <ul className={styles.dashaedUL}>
                    <li>
                      L’appareil et le logiciel que vous utilisez, ainsi que
                      d’autres caractéristiques de l’appareil (tels que le type
                      d’appareil, les détails sur le système d’exploitation, les
                      détails sur le matériel et le logiciel, le niveau de
                      batterie, la force du signal, l’espace de stockage
                      disponible, le type de navigateur, les types et noms de
                      l’application et du fichier, les plugins)
                    </li>
                    <li>
                      Ce que vous faites sur votre appareil, par exemple si
                      notre application est au premier plan ou si votre souris
                      bouge (ce qui peut aider à distinguer les humains des
                      robots).
                    </li>
                    <li>
                      Les identifiants qui nous permettent de différencier votre
                      appareil de celui d’autres utilisateurs.
                    </li>
                    <li>
                      Les signaux de votre appareil (tels que le GPS, les
                      signaux Bluetooth, les points d’accès Wi-Fi, balises et
                      antennes-relais de téléphonie mobile à proximité).
                    </li>
                    <li>
                      Les informations sur le réseau auquel vous connectez votre
                      appareil, y compris votre adresse IP.
                    </li>
                    <li>
                      Les informations sur les performances de PsyUP sur votre
                      appareil.
                    </li>
                    <li>
                      Les informations provenant de cookies et de technologies
                      similaires. <Link to="/cookies">En savoir plus</Link>
                    </li>
                  </ul>
                </>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(4)}
              >
                <h6>
                  Les informations provenant de Partenaires, de fournisseurs et
                  de tiers
                </h6>
                <h5>{openSection === 4 ? "-" : "+"}</h5>
              </div>
              {openSection === 4 && (
                <>
                  <ul className={styles.dashaedUL}>
                    Nous utilisons votre activité sur PsyUP et en dehors de
                    PsyUP pour :
                    <li>Vous montrer des publicités plus pertinentes.</li>
                    <li>
                      Vous suggérer des évènements ou des articles Marketplace
                      susceptibles de vous intéresser.
                    </li>
                    <li>
                      Vous aider à découvrir de nouvelles entreprises et de
                      nouvelles marques.
                    </li>
                    <li>
                      Permettre aux entreprises et aux organisations d’analyser
                      les performances de leur site web, de leur application ou
                      de leurs publicités, ainsi que de déterminer si elles
                      touchent les bonnes personnes.
                    </li>
                  </ul>
                  <p className={styles.paragraph}>
                    Nous recevons ces informations, que vous soyez connecté ou
                    non et que vous ayez un compte ou non sur PsyUP.
                  </p>
                  <p>
                    Ces Partenaires recueillent vos informations lorsque vous
                    consultez leur site ou leur application, ou lorsque vous
                    utilisez leurs services, ou via d’autres entreprises ou
                    organisations avec lesquelles ils collaborent. Nous exigeons
                    des Partenaires qu’ils aient le droit de recueillir,
                    d’utiliser et de partager vos informations avant de nous les
                    fournir.
                  </p>
                  <p className="pt-2">
                    Nous traitons certaines des informations que nous recevons
                    de nos Partenaires avec eux, en tant que responsables de
                    traitement conjoints. Pour apprendre comment une entreprise
                    traite ou partage vos informations, lisez sa politique de
                    confidentialité ou contactez-la directement.
                  </p>
                </>
              )}
            </li>
          </ul>
        </div>

        <div>
          <div>
            <h5>Comment utilisons-nous vos informations ?</h5>
          </div>
          <div>
            <p className={styles.paragraph}>
              Nous utilisons les informations que nous recueillons pour vous
              fournir une expérience personnalisée, par le biais de publicités
              notamment, ainsi qu’à d’autres fins expliquées en détail
              ci-dessous.
            </p>
            <p className="pb-2">
              Dans certains cas, pour utiliser moins d’informations liées à des
              utilisateurs individuels, nous désidentifions ou agrégeons les
              informations. Nous pouvons également les rendre anonymes afin
              qu’elles ne permettent plus de vous identifier. Nous utilisons ces
              informations de la même manière que les informations vous
              concernant décrites dans cette section.
            </p>
            <p>Voici les manières dont nous utilisons vos informations :</p>
            <ul className={styles.cookiesCollapse}>
              <li>
                <div
                  className={styles.sectionHead}
                  onClick={() => handleSectionClick(6)}
                >
                  <h6>Pour offrir, personnaliser et améliorer notre site</h6>
                  <h5>{openSection === 6 ? "-" : "+"}</h5>
                </div>
                {openSection === 6 && (
                  <ul className={styles.dashaedUL}>
                    <p>
                      Cela inclut des fonctionnalités de personnalisation du
                      contenu et des recommandations.
                      <p>
                        Quand vous utilisez PsyUP, vous voyez des publicités et
                        du contenu sponsorisé ou commercial. Nous voulons que
                        tout ce que vous consultez soit intéressant et utile
                        pour vous.
                      </p>
                      Pour décider ce que nousvous montrons, nous utilisons :
                    </p>
                    <li>
                      Vos informations générales (tels que votre âge, votre
                      genre, votre localisation, l’appareil que vous utilisez et
                      la langue que vous avez sélectionnée sur celui-ci)
                    </li>
                    <li>
                      Et les autres informations dont nous disposons à votre
                      sujet (y compris les publicités que nous vous avons
                      montrées et vos interactions avec elles, vos informations
                      de profil, les informations relatives à votre activité sur
                      et en dehors de PsyUP, les choses que nous déduisons à
                      votre sujet comme les sujets qui pourraient vous
                      intéresser, et des informations à propos de vos
                      abonnements, de vos abonnés et de vos autres relations).
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className={styles.sectionHead}
                  onClick={() => handleSectionClick(7)}
                >
                  <h6>Pour promouvoir la sûreté, l'intégrité et la sécurité</h6>
                  <h5>{openSection === 7 ? "-" : "+"}</h5>
                </div>
                {openSection === 7 && (
                  <ul className={styles.dashaedUL}>
                    <p>
                      Nous utilisons les informations que nous recueillons pour
                      fournir un service sûr à nos utilisateurs. Par exemple
                      pour :
                    </p>
                    <li>Vérifier les comptes et l’activité.</li>
                    <li>
                      Localiser et traiter les infractions à nos conditions
                      générales ou à nos politiques.
                    </li>
                    <li>Enquêter sur les activités suspectes.</li>
                    <li>Maintenir l’intégrité de nos Produits.</li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className={styles.sectionHead}
                  onClick={() => handleSectionClick(8)}
                >
                  <h6>
                    Pour fournir des mesures, des analyses et des services
                    professionnels
                  </h6>
                  <h5>{openSection === 8 ? "-" : "+"}</h5>
                </div>
                {openSection === 8 && (
                  <p className="pb-3">
                    Nous aidons les entreprises à mesurer à quel point leurs
                    publicités et leurs autres contenus fonctionnent.
                  </p>
                )}
              </li>
              <li>
                <div
                  className={styles.sectionHead}
                  onClick={() => handleSectionClick(9)}
                >
                  <h6>Pour communiquer avec vous</h6>
                  <h5>{openSection === 9 ? "-" : "+"}</h5>
                </div>
                {openSection === 9 && (
                  <p className="pb-3">
                    Nous communiquons avec vous en utilisant les informations
                    que vous nous avez fournies, comme vos coordonnées que vous
                    avez insérées sur votre profil.
                  </p>
                )}
              </li>
              <li>
                <div
                  className={styles.sectionHead}
                  onClick={() => handleSectionClick(10)}
                >
                  <h6>
                    Pour faire de la recherche et innover pour le bien social
                  </h6>
                  <h5>{openSection === 10 ? "-" : "+"}</h5>
                </div>
                {openSection === 10 && (
                  <div>
                    Nous utilisons les informations à notre disposition, les
                    informations fournies par des chercheurs ainsi que les
                    ensembles de données publiques, par les groupes
                    professionnels et les groupes à but non lucratif pour faire
                    de la recherche et soutenir nos efforts en la matière.
                    <p className="pt-2">
                      Si nous vendons ou transférons une partie de notre
                      activité à quelqu’un d’autre, nous pouvons donner au
                      nouveau propriétaire des informations dans le cadre de
                      cette transaction, conformément à la loi applicable.
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        <h5>Quels sont vos droits concernant vos données personnelles ?</h5>

        <div>
          <p className="pt-2">
            Nous traitons vos données personnelles lorsque vous nous donnez
            votre consentement.
          </p>
          <p className={styles.paragraph}>
            Certaines informations sont requises pour que notre site fonctionne.
            D’autres informations sont facultatives, mais la qualité de votre
            expérience pourrait être affectée sans celles-ci.
          </p>
          <p className="pb-2">
            Vous bénéficiez d’un droit d’accès, de rectification, de portabilité
            et d’effacement de vos données ou encore de limitation du
            traitement. Vous pouvez également, pour des motifs légitimes, vous
            opposer au traitement des données vous concernant. Vous pouvez, sous
            réserve de la production d’un justificatif d’identité valide,
            exercer vos droits en contactant{" "}
            <Link to="/contact">contact@psyup.fr</Link>.
          </p>
          <ul className={styles.dashaedUL}>
            Vos informations personnelles seront conservées aussi longtemps que
            nécessaire pendant toute la durée de votre adhésion à PsyUP, sauf si
            :<li>Vous exercez votre droit de suppression de vos données;</li>
            <li>
              Une durée de conservation plus longue est autorisée ou imposée en
              vertu d’une obligation légale ou règlementaire.
            </li>
          </ul>
          <p>
            Pendant cette période, nous mettons en place tous moyens aptes à
            assurer la confidentialité et la sécurité de vos données
            personnelles, de manière à empêcher leur endommagement, effacement
            ou accès par des tiers non autorisés.
          </p>
          <p className={styles.paragraph}>
            Les informations que nous utilisons sont traitées automatiquement
            par nos systèmes. Dans certains cas, nous effectuons également un
            examen manuel pour accéder à vos informations et les examiner (ex.
            nos examinateurs peuvent rechercher et supprimer du contenu
            contraire à nos conditions générales et politiques…). Nous exigeons
            que tous les examinateurs autorisés à accéder à vos informations
            respectent les standards de sécurité et de confidentialité.
          </p>
          <p>
            Laccès à vos données personnelles est strictement limité à nos
            employés et préposés et, le cas échéant, à nos sous-traitants. Les
            sous-traitants en question sont soumis à une obligation de
            confidentialité et ne peuvent utiliser vos données qu’en conformité
            avec nos dispositions contractuelles et la législation applicable.
          </p>
          <p className={styles.paragraph}>
            En dehors des cas énoncés ci-dessus, nous nous engageons à ne pas
            vendre, louer, céder ni donner accès à des tiers à vos données sans
            votre consentement préalable, à moins d’y être contraints en raison
            d’un motif légitime (obligation légale, lutte contre la fraude ou
            l’abus, exercice des droits de la défense, etc.).
          </p>
          <p>
            Pour toute information complémentaire ou réclamation, vous pouvez
            contacter la Commission Nationale de l’Informatique et des Libertés
            (plus d’informations sur{" "}
            <Link to="https://www.cnil.fr/">www.cnil.fr</Link>
            ).
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
