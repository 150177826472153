import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/subscriptions/Subscriptions.module.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  arrowDownBlack,
  arrowUpBlack,
  backArrowWhite,
  heartBlack,
  heartRed,
  nextArrowWhite,
  userAvatar,
} from "../../assets";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";
import { Spin } from "antd";
import ButtonFilled from "../../components/buttonFilled";
import DisplayStories from "../../components/stories/DisplayStories";
import MySubscriptionsStoriesModal from "./MySubscriptionsStoriesModal";

const ITEMS_PER_PAGE = 23;

const Subscriptions = () => {
  const {
    token,
    getSearchedUserProfile,
    getUserStories,
    singleUserStoryCount,
    loginModal,
    setLoginModal,
    allUsersStories,
  } = useAuth();
  const navigate = useNavigate();
  const [storyCount, setStoryCount] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [allSubscribers, setAllSubscribers] = useState(false);
  const [mySubscriptions, setMySubscriptions] = useState(null);
  const [suggestedSubscriptions, setSuggestedSubscriptions] = useState(null);
  const [mySubscriptionStories, setMySubscriptionStories] = useState(null);
  const [mySubscriptionStatus, setMySubscriptionStatus] = useState(null);
  const [selectedSubscriptionIndex, setselectedSubscriptionIndex] =
    useState(null);
  const [selectedSubscriptionCircle, setselectedSubscriptionCircle] =
    useState(null);
  const [subscriptionsLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionsStatusLoading, setSubscriptionsStatusLoading] =
    useState(false);

  const [subscriptionsStoriesModalOpen, setSubscriptionsStoriesModalOpen] =
    useState(false);

  const [suggestedSubscriptionsLoading, setSuggestedSubscriptionsLoading] =
    useState(false);
  const [subscriptionsStoriesLoading, setSubscriptionsStoriesLoading] =
    useState(false);

  const [isSuggestionsOpenVisible, setIsSuggestionsOpenVisible] =
    useState(false);

  const toggleContentVisibility = () => {
    setIsSuggestionsOpenVisible(!isSuggestionsOpenVisible);
  };

  const toggleAllSubscribers = () => {
    setAllSubscribers(!allSubscribers);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  useEffect(() => {
    if (token) {
      getSubscriptionsStatus();
      getSubscriptionsData();
      getSubscriptionsStories();
      getSuggestedSubscriptionsData();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getSubscriptionsStories();
    }
  }, [token, storyCount]);

  // const handleSingleUserClick = async (index, id) => {
  //   setSubscriptionsStoriesLoading(true);
  //   try {
  //     const response = await ApiCall({
  //       params: {},
  //       route: "story/story-status/" + id + "&" + storyCount,
  //       verb: "get",
  //       baseurl: true,
  //       token: token,
  //     });

  //     if (response.status === 200) {
  //       setMySubscriptionStories(response.response);
  //     }
  //     console.log("SubscriptionUserStories", response);
  //   } catch (error) {
  //     setSubscriptionsStoriesLoading(false);
  //   } finally {
  //     setSubscriptionsStoriesLoading(false);
  //   }
  //   setSelectedIndex(index);
  // };

  const getSubscriptionsStatus = async () => {
    try {
      setSubscriptionsStatusLoading(true);
      const response = await ApiCall({
        params: {},
        route: `user/subscribedUserStatusList/${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setMySubscriptionStatus(response.response);
      }
      console.log("setMySubscriptionStatus", response);
    } catch (error) {
      setSubscriptionsStatusLoading(false);
      toast.error("Connection Error");
    } finally {
      setSubscriptionsStatusLoading(false);
    }
  };

  const getSubscriptionsData = async () => {
    try {
      setSubscriptionLoading(true);
      const response = await ApiCall({
        params: {},
        route: `user/subscribedUserList/${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setMySubscriptions(response.response);
      }
      console.log("mySubscriptionsResp", response);
    } catch (error) {
      setSubscriptionLoading(false);
      toast.error("Connection Error");
    } finally {
      setSubscriptionLoading(false);
    }
  };

  const getSuggestedSubscriptionsData = async () => {
    try {
      setSuggestedSubscriptionsLoading(true);
      const response = await ApiCall({
        params: {},
        route: `user/suggestedSubscribers/${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setSuggestedSubscriptions(response.response);
      }
      console.log("mySuggestedSubscriptionsResp", response);
    } catch (error) {
      setSuggestedSubscriptionsLoading(false);
      toast.error("Connection Error");
    } finally {
      setSuggestedSubscriptionsLoading(false);
    }
  };

  const getSubscriptionsStories = async () => {
    try {
      setSubscriptionsStoriesLoading(true);
      const response = await ApiCall({
        params: {},
        route: `story/subscribedUserStories/${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setMySubscriptionStories(response.response);
      }
      console.log("setMySubscriptionStoriesResp", response.response);
    } catch (error) {
      setSubscriptionsStoriesLoading(false);
      toast.error("Connection Error");
    } finally {
      setSubscriptionsStoriesLoading(false);
    }
  };

  const handleUserClick = (id) => {
    getSearchedUserProfile(id);
    getUserStories(id, singleUserStoryCount);
    navigate("/userProfile");
  };

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const handlesubscriptionsStoriesModal = (item) => {
    setselectedSubscriptionIndex(item?._id);
    setselectedSubscriptionCircle(item?.circle);
    setSubscriptionsStoriesModalOpen(!subscriptionsStoriesModalOpen);
  };

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  // load more stories
  const loadMore = () => {
    if (token) {
      setStoryCount((prevCount) => prevCount + 1);
    } else {
      setLoginModal(!loginModal);
    }
  };

  return (
    getToken && (
      <div className={styles.subscriptionsContainer}>
        {/* stories status */}
        <Spin spinning={subscriptionsStatusLoading}>
          <div className={styles.mySubscriptions}>
            <div className={styles.mySubscriptionsStoriesListWrapper}>
              <div className={styles.mySubscriptionsStoriesList}>
                <div
                  className={`${styles.subscriber} ${styles.allSubscriber} ${
                    allSubscribers ? styles.selectedAllSubscriber : ""
                  }`}
                  onClick={() => {
                    toggleAllSubscribers();
                  }}
                >
                  <div className={styles.imgContainer}>
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={allSubscribers ? heartRed : heartBlack}
                      alt="all"
                    />
                  </div>
                  {/* <div
                    className={styles.picWrapper}
                    // onClick={() => {
                    //   if (item?.statusExist) {
                    //     handlesubscriptionsStoriesModal(item);
                    //     setTimeout(() => {
                    //       getSubscriptionsData();
                    //     }, 3000);
                    //   }
                    // }}
                  >
                    <div>
                      <img
                        onContextMenu={(e) => e.preventDefault()}
                        src={selectedIndex == -1 ? heartRed : heartBlack}
                        alt="all"
                      />
                    </div>
                  </div> */}
                  <h4>TOUS</h4>
                </div>
                {mySubscriptionStatus
                  ?.slice(startIndex, endIndex)
                  .map((item, index) => {
                    return (
                      <div
                        className={`${styles.subscriber} ${
                          item?.circle ? styles.userWithNewStory : ""
                        }`}
                        key={startIndex + index}
                      >
                        <div
                          className={styles.picWrapper}
                          onClick={() => {
                            if (item?.statusExist) {
                              handlesubscriptionsStoriesModal(item);
                            }
                          }}
                        >
                          <div>
                            <img
                              onContextMenu={(e) => e.preventDefault()}
                              src={
                                item?.profilePicture?.url
                                  ? item?.profilePicture?.url
                                  : userAvatar
                              }
                              alt="userAvatar"
                            />
                          </div>
                        </div>

                        <h4
                          onClick={() => handleUserClick(item._id)}
                          className={styles.userName}
                        >
                          {item?.username}
                        </h4>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* MySubscriptions pagination */}
            {/* <>
              {currentPage > 1 ? (
                <div
                  className={styles.backArrowWhiteActive}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={backArrowWhite}
                    alt="back"
                  />
                </div>
              ) : (
                <div className={styles.backArrowWhite}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={backArrowWhite}
                    alt="back"
                  />
                </div>
              )}

              {mySubscriptions?.length > endIndex ? (
                <div
                  className={styles.nextArrowWhiteActive}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={nextArrowWhite}
                    alt="next"
                  />
                </div>
              ) : (
                <div className={styles.nextArrowWhite}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={nextArrowWhite}
                    alt="next"
                  />
                </div>
              )}
            </> */}
          </div>
        </Spin>

        {/* Subscriptions */}

        <br />
        {allSubscribers && (
          <Spin spinning={subscriptionsLoading}>
            <div className={styles.mySubscriptions}>
              <h2>Mes abonnements</h2>

              <div className={styles.mySubscriptionsListWrapper}>
                <div className={styles.mySubscriptionsList}>
                  {mySubscriptions
                    ?.slice(startIndex, endIndex)
                    .map((item, index) => {
                      return (
                        <div
                          className={`${styles.subscriber}`}
                          key={startIndex + index}
                        >
                          <div className={styles.picWrapper}>
                            <div>
                              <img
                                onContextMenu={(e) => e.preventDefault()}
                                src={
                                  item?.profilePicture?.url
                                    ? item?.profilePicture?.url
                                    : userAvatar
                                }
                                alt="userAvatar"
                              />
                            </div>
                          </div>

                          <h4
                            onClick={() => handleUserClick(item._id)}
                            className={styles.userName}
                          >
                            {item?.username}
                          </h4>
                          <p>
                            {item?.subscriber_count}{" "}
                            {item?.subscriber_count > "1"
                              ? "abonnés"
                              : "abonné"}{" "}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* MySubscriptions pagination */}
              <>
                {currentPage > 1 ? (
                  <div
                    className={styles.backArrowWhiteActive}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={backArrowWhite}
                      alt="back"
                    />
                  </div>
                ) : (
                  <div className={styles.backArrowWhite}>
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={backArrowWhite}
                      alt="back"
                    />
                  </div>
                )}

                {mySubscriptions?.length > endIndex ? (
                  <div
                    className={styles.nextArrowWhiteActive}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={nextArrowWhite}
                      alt="next"
                    />
                  </div>
                ) : (
                  <div className={styles.nextArrowWhite}>
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={nextArrowWhite}
                      alt="next"
                    />
                  </div>
                )}
              </>
            </div>
          </Spin>
        )}

        <div className={styles.suggestedSubscriptions}>
          <div className={styles.header} onClick={toggleContentVisibility}>
            <h2>Suggestion d’abonnements</h2>
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={isSuggestionsOpenVisible ? arrowUpBlack : arrowDownBlack}
              alt="arrow-up"
            />
          </div>

          {isSuggestionsOpenVisible && (
            <div className={styles.content}>
              {suggestedSubscriptions?.map((suggestion) => {
                return (
                  <div className={styles.themeWrapper}>
                    <p className={styles.title}>{suggestion?.themeId?.name}</p>
                    <div className={styles.theme}>
                      {suggestion?.users?.map((item, index) => {
                        return (
                          <div
                            className={`${styles.subscriber} `}
                            key={index}
                            onClick={() => handleUserClick(item._id)}
                          >
                            <img
                              onContextMenu={(e) => e.preventDefault()}
                              height={70}
                              width={70}
                              src={
                                item?.profilePicture?.url
                                  ? item?.profilePicture?.url
                                  : userAvatar
                              }
                              alt="user"
                            />
                            <h4>{item?.fullname}</h4>
                            <p>
                              {item?.subscriber_count}{" "}
                              {item?.subscriber_count > "1"
                                ? "abonnés"
                                : "abonné"}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <Spin spinning={subscriptionsStoriesLoading}>
          <div className="pt-5">
            <DisplayStories
              stories={mySubscriptionStories}
              setStories={setMySubscriptionStories}
              // selectedTheme={sidebarSelectedTheme?.themeid}
              setStoryCount={setStoryCount}
              storyCount={storyCount}
            />
          </div>
        </Spin>

        {/* {mySubscriptionStories?.length !== 0 && (
          <>
            {subscriptionsLoading ? (
              <div className="pt-5">
                <ButtonFilled title="Loading..." textColr="white" />
              </div>
            ) : (
              <div className="pt-5">
                <ButtonFilled
                  title="VOIR PLUS"
                  textColr="white"
                  handleClick={loadMore}
                />
              </div>
            )}
          </>
        )} */}

        <MySubscriptionsStoriesModal
          toggleState={subscriptionsStoriesModalOpen}
          handleToggle={handlesubscriptionsStoriesModal}
          selectedSubscriptionIndex={selectedSubscriptionIndex}
          selectedSubscriptionCircle={selectedSubscriptionCircle}
          getSubscriptionsStatus={getSubscriptionsStatus}
        />
      </div>
    )
  );
};

export default Subscriptions;
