import React, { useEffect } from "react";
import styles from "./ProfessionalProfile.module.scss";
import { useAuth } from "../../../context/AuthContext";
import { location, userAvatar } from "../../../assets";
import { Collapse } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faCreditCard,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faClockFour,
  faCreditCardAlt,
  faEuro,
  faEuroSign,
  faFileEdit,
  faInfoCircle,
  faLocation,
  faLocationPin,
  faUserAlt,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import SkeltonList from "../../../components/UI/SkeltonList";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const address = [
  {
    key: "1",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
          <FontAwesomeIcon className={styles.icon} icon={faLocationPin} />
          </span>
          Adresse
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: (
      <div className={styles.detailBottom}>
        <p>10 Boulevard Totin</p>
        <p>34000 Singapur</p>
      </div>
    ),
    showArrow: false,
  },
];

const presentation = [
  {
    key: "1",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faUserAlt} />
          </span>
          Présentation
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: (
      <div className={styles.detailBottom}>
        <p>10 Boulevard Totin 10 Boulevard Totin 10 Boulevard Totin</p>
        <p>34000 Singapur</p>

        <div className={styles.expertiesWrapp}>
          <p>Psychologie clinique</p>
          <p>Psychologie de I'adloscent</p>
          <p>Psychologie de I'enfant</p>
          <p>+1</p>
        </div>
      </div>
    ),
    showArrow: false,
  },
];

const pricesAndFunds = [
  {
    key: "1",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faEuroSign} />
          </span>
          Tarifs et remboursements
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: <p>Cunsultation non remboursements</p>,
    showArrow: false,
  },
  {
    key: "2",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faCreditCardAlt} />
          </span>
          Moyens de paiement
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: (
      <div className={styles.detailBottom}>
        <p>Cheques et especes</p>
        <p className="text-danger">Cartes bancarres non acceptees</p>
      </div>
    ),
    showArrow: false,
  },
];

const otherDetails = [
  {
    key: "1",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faClockFour} />
          </span>
          Horaires et contacts
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: <p>{text}</p>,
    showArrow: false,
  },
  {
    key: "2",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faUserGraduate} />
          </span>
          Formations
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: <p>{text}</p>,
    showArrow: false,
  },
  {
    key: "3",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faFileEdit} />
          </span>
          Expériences
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: <p>{text}</p>,
    showArrow: false,
  },
  {
    key: "4",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
          </span>
          Informations légales
        </h6>
        <a>Voir plus</a>
      </div>
    ),
    children: <p>{text}</p>,
    showArrow: false,
  },
];

const ProfessionalProfile = () => {
  const { getSearchedUserProfile, searchedUserLoading, searchedUserInfo } =
    useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const getProfessionalId = searchParams.get("professionalId");

  useEffect(() => {
    if (getProfessionalId) {
      getSearchedUserProfile(getProfessionalId);
    }
  }, [getProfessionalId]);

  // console.log("searchedUserInfo", searchedUserInfo);

  return (
    <>
      {searchedUserLoading ? (
        <SkeltonList />
      ) : (
        <>
          <div className={styles.profileDetailswrapper}>
            <div className={styles.bio}>
              <div className={styles.profilepicWrapper}>
                <div className={`${styles.profilepic} `}>
                  <img
                    src={
                      searchedUserInfo?.profilePicture?.url
                        ? searchedUserInfo?.profilePicture?.url
                        : userAvatar
                    }
                  />
                </div>
              </div>
              <div className={styles.userInfo}>
                <h3 className={`${styles.name} `}>
                  {searchedUserInfo?.professional?.fullName}
                </h3>
                <p className={`${styles.professionaltype} `}>
                  {searchedUserInfo?.professional?.professionaltype}
                </p>

                <div className={styles.details}>
                  {/* <p>{searchedUserInfo?.professional?.professionaltype}</p> */}
                </div>
              </div>
            </div>

            <div
              className={styles.cunsultationButton}
              onClick={() => {
                navigate(
                  `/professional-profile/appointment?professionalId=${searchedUserInfo?._id}`
                );
              }}
            >
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCalendar}
                style={{ color: "white", cursor: "pointer" }}
              />
              <h6>Prendre rendez-vous</h6>
            </div>
          </div>

          {/* address */}
          <Collapse defaultActiveKey={["1"]} items={address} />

          {/* Presentation */}
          <Collapse defaultActiveKey={["1"]} items={presentation} />

          {/* Prices and funds */}
          <Collapse items={pricesAndFunds} />

          {/* other details */}
          <Collapse items={otherDetails} />
        </>
      )}
    </>
  );
};

export default ProfessionalProfile;
