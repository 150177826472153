import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { check, goodWriterGold } from "../../assets/index";
import styles from "../../assets/styles/profile/profileDetails.module.scss";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userAvatar, block, storyAdmin, storyEdit } from "../../assets/index";
import StoryLoader from "../../components/UI/StoryLoader";
import { ApiCall } from "../../config/apiCall";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import BlockUserModal from "./BlockUserModal";
import ReportUserModal from "./ReportUserModal";
import { Spinner } from "reactstrap";
import MySubscriptionsStoriesModal from "../subscriptions/MySubscriptionsStoriesModal";

const UserProfileDetails = ({
  userDetails,
  setUserDetails,
  selectedTheme,
  setSelectedTheme,
}) => {
  const {
    token,
    userInfo,
    setSearchedUserId,
    searchedUserLoading,
    singleUserStoryCount,
    getSearchedUserProfile,
    getUserStories,
  } = useAuth();
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [userReportModal, setUserReportModal] = useState(false);
  const [userBlockModal, setUserBlockModal] = useState(false);
  const [userId, setUserId] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const getUserId = searchParams.get("userId");

  const [selectedSubscriptionIndex, setselectedSubscriptionIndex] =
    useState(null);
  const [selectedSubscriptionCircle, setselectedSubscriptionCircle] =
    useState(null);
  const [subscriptionsStoriesModalOpen, setSubscriptionsStoriesModalOpen] =
    useState(false);

  useEffect(() => {
    // Clear userDetails when component unmounts or when navigating to another page
    return () => {
      setUserDetails(null);
    };
  }, []);

  useEffect(() => {
    getSearchedUserProfile(getUserId);
    getUserStories(getUserId, singleUserStoryCount);
  }, [getUserId]);

  const toggleBlockUserModal = () => {
    setUserBlockModal(!userBlockModal);
  };

  const toggleReportUserModal = () => {
    setUserReportModal(!userReportModal);
  };

  const handlesubscriptionsStoriesModal = (item) => {
    setselectedSubscriptionIndex(item?._id);
    setselectedSubscriptionCircle(item?.circle);
    setSubscriptionsStoriesModalOpen(!subscriptionsStoriesModalOpen);
  };

  useEffect(() => {
    // This effect will be triggered whenever 'subscribed' changes
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      isSubscribed: subscribed,
    }));
  }, [subscribed]);

  const handleSubscribeClick = async (id) => {
    setSubscribeLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `user/subscribeUser/${id}/${
          userDetails?.isSubscribed ? "unsub" : "sub"
        }`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setSubscribed(!subscribed);
        // message.success(response.response.message);
        setSubscribeLoading(false);
      }
    } catch (error) {
      setSubscribeLoading(false);
    } finally {
      setSubscribeLoading(false);
    }
  };

  const handleThemeChange = (themeid) => {
    setSelectedTheme(themeid);
  };

  // Delete User profile

  const deleteUserProfile = async (id) => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "user/deleteUser/" + id,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // message.success(response.response);
        navigate("/");
        // setUserProfiles(
        // setallUsersStories(allUsersStories.filter((story) => story._id !== id));
        message.success(response.response.message);
      }
      console.log("deleteUserResp", response);
    } catch (error) {
      // setStoriesLoading(false);
      // Handle error if necessary
    } finally {
      // setStoriesLoading(false);
    }
  };

  // create a chatRoom
  const createChat = async () => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: { user_id1: userInfo?._id, user_id2: userDetails?._id },
        route: "chat/create-chatRoom",
        verb: "post",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        navigate("/messaging");
        setSearchedUserId(userDetails?._id);
      }
      console.log("create-chatRoomResp", response);
    } catch (error) {
      // setStoriesLoading(false);
    } finally {
      // setStoriesLoading(false);
    }
  };

  return (
    <>
      {searchedUserLoading ? (
        <div>
          <StoryLoader />
        </div>
      ) : (
        <>
          <div className={styles.profileDetailswrapper}>
            <div className={styles.bio}>
              <div className={styles.profilepicWrapper}>
                <div
                  className={`${styles.profilepic} ${
                    userDetails?.circle ? styles.userWithNewStory : ""
                  }`}
                  onClick={() => {
                    if (userDetails?.statusExist) {
                      handlesubscriptionsStoriesModal(userDetails);
                    }
                    // setTimeout(() => {
                    //   getProfileData(userDetails._id);
                    // }, 1000);
                  }}
                >
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={
                      userDetails?.profilePicture?.url
                        ? userDetails?.profilePicture?.url
                        : userAvatar
                    }
                  />
                </div>
                <div className={styles.actions}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={storyEdit}
                    alt="message"
                    onClick={() => createChat()}
                  />
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={storyAdmin}
                    alt="report"
                    onClick={() => {
                      setUserId(userDetails._id);
                      setUserReportModal(!userReportModal);
                    }}
                  />

                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={block}
                    alt="block"
                    onClick={() => {
                      setUserId(userDetails._id);
                      setUserBlockModal(!userBlockModal);
                    }}
                  />
                </div>
              </div>
              <div className={styles.userInfo}>
                <h3 className={styles.name}>
                  {userDetails?.fullname}
                  {userDetails?.isRated && (
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={goodWriterGold}
                      alt="feather"
                      height={25}
                      width={25}
                    />
                  )}
                </h3>
                <p>@{userDetails?.username} </p>
                {/* <div className={styles.badges}>
                  <div className={styles.badge}>
                    <img
                     onContextMenu={e => e.preventDefault()}
                      src={beStrongHug}
                      alt="profilepic"
                      height={15}
                      width={15}
                    />
                    <p>Modérateur</p>
                  </div>
                  <div className={styles.badge}>
                    <img
                     onContextMenu={e => e.preventDefault()}
                      src={beStrongHug}
                      alt="profilepic"
                      height={15}
                      width={15}
                    />
                    <p>Plume d’or</p>
                  </div>
                </div> */}

                <div className={styles.details}>
                  <p>{userDetails?.biography}</p>
                </div>
                <div className={styles.actionsMobileView}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={storyEdit}
                    alt="message"
                    onClick={() => createChat()}
                  />

                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={storyAdmin}
                    alt="report"
                    onClick={() => {
                      setUserId(userDetails._id);
                      setUserReportModal(!userReportModal);
                    }}
                  />

                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={block}
                    alt="block"
                    onClick={() => {
                      setUserId(userDetails._id);
                      setUserBlockModal(!userBlockModal);
                    }}
                  />
                </div>
              </div>

              {subscribeLoading ? (
                <div
                  className={styles.subscribeBtn}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "180px",
                    height: "43px",

                    backgroundColor: userDetails?.isSubscribed
                      ? "#01989f"
                      : "#00565b",
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                <div
                  className={styles.subscribeBtn}
                  onClick={() => handleSubscribeClick(userDetails._id)}
                  style={{
                    backgroundColor: userDetails?.isSubscribed
                      ? "#01989f"
                      : "#00565b",
                  }}
                >
                  {userDetails?.isSubscribed ? "Abonné" : "S'abonner"}
                  {userDetails?.isSubscribed ? (
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={check}
                      alt="check"
                    />
                  ) : null}
                </div>
              )}
            </div>

            <div className={styles.socailCounts}>
              <div className={styles.countsWrapper}>
                <div className={styles.counts}>
                  <h3>{userDetails?.storyCount}</h3>
                  <p>Histoires postées</p>
                </div>
                <div className={styles.Subscribers}>
                  <div className={styles.counts}>
                    <h3>{userDetails?.subscriber_count}</h3>
                    <p>Abonnés</p>
                  </div>
                  <div className={styles.counts}>
                    <h3>{userDetails?.subscribed_count}</h3>
                    <p>Abonnements</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.deleteStoryIcon}>
              {userInfo?.isAdmin && !userDetails?.isAdmin && (
                <div onClick={() => deleteUserProfile(userDetails._id)}>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faTrashCan}
                    size="lg"
                  />
                </div>
              )}
            </div>
          </div>

          {!userDetails?.isPrivate && userDetails?.theme_visibility_status && (
            <>
              <p className={styles.themestitle}>Thèmes favoris :</p>
              <div className={styles.themes}>
                {/* {console.log(userDetails?.selectedThemes)} */}
                {userDetails?.selectedThemes?.map((theme) => (
                  <>
                    <div className={styles.themeFiltratin} key={theme._id}>
                      <div className={styles.selector}>
                        <div className={styles.selectorItem}>
                          <input
                            type="radio"
                            id={theme._id}
                            name="resource"
                            className={styles.selectorItemRadio}
                            value={theme._id}
                            checked={theme._id === selectedTheme}
                            onChange={(e) => handleThemeChange(e.target.value)}
                          />
                          <label
                            htmlFor={theme._id}
                            className={styles.selectorItemLabel}
                          >
                            {theme.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}

          <ReportUserModal
            toggleState={userReportModal}
            handleToggle={toggleReportUserModal}
            userId={userId}
          />

          <BlockUserModal
            toggleState={userBlockModal}
            handleToggle={toggleBlockUserModal}
            userId={userId}
            userDetails={userDetails}
          />

          <MySubscriptionsStoriesModal
            toggleState={subscriptionsStoriesModalOpen}
            handleToggle={handlesubscriptionsStoriesModal}
            selectedSubscriptionIndex={selectedSubscriptionIndex}
            selectedSubscriptionCircle={selectedSubscriptionCircle}
          />
        </>
      )}
    </>
  );
};

export default UserProfileDetails;
