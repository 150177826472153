const siteUrl = "https://psyup.fr";
const baseUrl = "https://psyup.fr/api";

// const siteUrl = "http://192.168.1.21:5005";
// const baseUrl = "http://192.168.1.21:5005/api";

// const siteUrl = "http://127.0.0.1:5005";
// const baseUrl = "http://127.0.0.1:5005/api";

// const siteUrl = "http://44.203.197.155";
// const baseUrl = "http://44.203.197.155/api";

module.exports = { baseUrl, siteUrl };
