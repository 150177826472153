import React, { useState, useEffect, useRef } from "react";
import styles from "../assets/styles/CustomInput.module.scss";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { smilyIcon } from "../assets";

const CustomTextArea = ({
  label,
  placeHolder,
  mendatory,
  handleOnChange,
  name,
  value,
  characterCount,
  emogie,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const handleEmojiSelect = (emoji) => {
    handleOnChange({
      target: {
        name: name,
        value: value ? value + emoji.native : emoji.native,
      },
    });
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
// click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className={styles.CustomTextArea}>
      <label>
        {label} <span className="text-danger">{mendatory}</span>{" "}
      </label>
      <div className={styles.inputWrapper}>
        <textarea
          id={name}
          name={name}
          value={value}
          rows="4"
          cols="50"
          placeholder={placeHolder}
          onChange={handleOnChange}
        />
        {emogie && (
          <span className={styles.emogie} onClick={toggleEmojiPicker}>
            <img src={smilyIcon} alt="smily"  onContextMenu={e => e.preventDefault()}/>
          </span>
        )}

        {characterCount && (
          <p className={styles.charCount}>
            {characterCount} caractères restants.
          </p>
        )}
      </div>
      {showEmojiPicker && (
        <div className={styles.emogiePicker} ref={emojiPickerRef}>
          <Picker
            data={data}
            previewPosition="none"
            theme="light"
            onEmojiSelect={handleEmojiSelect}
          />
        </div>
      )}
    </div>
  );
};

export default CustomTextArea;
