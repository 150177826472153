// Function to get the current location and fetch the exact address from Mapbox Geocoding API
export async function getCurrentLocationAndFetchAddress() {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const access_token =
            "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";
          const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${access_token}`;

          try {
            const response = await fetch(url);
            const data = await response.json();
            // console.log(data);

            if (data.features.length > 0) {
              const location = data.features[0].place_name;
              resolve(location);
            } else {
              reject("Location not found.");
            }
          } catch (error) {
            reject("Error fetching location data.");
          }
        },
        (error) => {
          // console.log(error);
          reject("Error getting geolocation.");
        }
      );
    } else {
      reject("Geolocation is not available in this browser.");
    }
  });
}

// Call the function to get the current location and fetch the address
