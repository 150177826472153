import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/home.module.scss";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Collapse, Modal, ModalBody, ModalFooter } from "reactstrap";
import ToggleButton from "../ToggleButton";
const acceptCookiesDescription =
  " Ci-dessous la liste des partenaires qui requièrent votre consentement et/ou se basent sur leur intérêt légitime pour exercer les activités décrites dans la page Activités. Vous pouvez définir vos préférences de consentement pour chaque partenaire individuellement. Cliquez sur le nom d'un partenaire pour obtenir plus d'informations sur ce qu'il fait, les données qu'il récolte et comment il les utilise.";

const CookiesPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [open, setOpen] = useState(null);
  const toggle = (id) => {
    setOpen((prevState) => (prevState === id ? null : id));
  };

  // cookies settings
  const acceptCookies = () => {
    setModalOpen(!modalOpen);
    localStorage.setItem("cookies", "Cookies");
    Cookies.set("this.sid", "this.sid");
    Cookies.set("_ga _gat _gid", "_ga _gat _gid");
    Cookies.set("renderCtx", "renderCtx");
    Cookies.set("force-stream", "force-stream");
    Cookies.set("BrowserId", "BrowserId");
  };
  const acceptCookies1 = () => {
    setModalOpen1(!modalOpen1);
    localStorage.setItem("cookies", "Cookies");
    Cookies.set("this.sid", "this.sid");
    Cookies.set("_ga _gat _gid", "_ga _gat _gid");
    Cookies.set("renderCtx", "renderCtx");
    Cookies.set("force-stream", "force-stream");
    Cookies.set("BrowserId", "BrowserId");
  };
  const acceptCookies2 = () => {
    setModalOpen2(!modalOpen2);
    localStorage.setItem("cookies", "Cookies");
    Cookies.set("this.sid", "this.sid");
    Cookies.set("_ga _gat _gid", "_ga _gat _gid");
    Cookies.set("renderCtx", "renderCtx");
    Cookies.set("force-stream", "force-stream");
    Cookies.set("BrowserId", "BrowserId");
  };
  const refuseCookies = () => {
    setModalOpen2(!modalOpen2);
    localStorage.setItem("cookies", "Cookies");
  };
  // Modals
  const toggleSecondModal = () => {
    setModalOpen(!modalOpen);
    setModalOpen1(!modalOpen1);
  };
  const toggleSecondModalFromThird = () => {
    setModalOpen2(!modalOpen2);
    setModalOpen1(!modalOpen1);
  };
  const toggleThirdModal = () => {
    setModalOpen(!modalOpen);
    setModalOpen2(!modalOpen2);
  };
  const toggleFourthModal = () => {
    //This is not used
    setModalOpen(!modalOpen);
    setModalOpen3(!modalOpen3);
  };
  const toggleFifthModal = () => {
    setModalOpen3(!modalOpen3);
  };
  useEffect(() => {
    let cookies = localStorage.getItem("cookies");
    if (cookies == null) {
      setModalOpen(!modalOpen);
    }
    // getAllUserStories(storyCount)
  }, []);

  return (
    <div>
      {/* Modals */}

      <Modal
        centered
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Accepter les cookies de PsyUP dans ce navigateur ?
          </h5>
        </div>
        <ModalBody className="Dashboard-policies-popup-body">
          Avec votre accord,{" "}
          <span className="modal-links" onClick={toggleThirdModal}>
            nos partenenaires
          </span>{" "}
          et nous utilisons des cookies ou technologies similaires afin de
          personnaliser notre contenu, diffuser des publicités pertinentes et
          offrir une expérience plus sûre. Vous pouvez vérifier vos{" "}
          <span className="modal-links" onClick={toggleSecondModal}>
            paramètres de cookies
          </span>{" "}
          à tout moment. Pour en savoir plus sur l’utilisation des cookies et
          leurs paramètres, consultez notre
          <Link to="/cookies"> politique d’utilisation des cookies.</Link>
          {/* <span className="modal-links" onClick={toggleFourthModal}>
            {" "}
            notre politique d’utilisation des cookies.
          </span> */}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="modal-button-cancel"
            onClick={toggleSecondModal}
          >
            Paramétrer
          </button>
          <button
            className="modal-button-accept"
            type="button"
            onClick={acceptCookies}
          >
            Tout accepter
          </button>
        </ModalFooter>
      </Modal>

      {/* Modal for Cookies Settings */}

      <Modal
        centered
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        toggle={() => setModalOpen1(!modalOpen1)}
        isOpen={modalOpen1}
        backdrop="static"
      >
        <div className="modal-header">
          <button
            type="button"
            className="modal-button-cancel"
            style={{
              background: "none",
              color: "#01989f",
            }}
            onClick={toggleSecondModalFromThird}
          >
            <h5 className="modal-title" id="exampleModalLabel2">
              Voir les partenaires
            </h5>
          </button>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen1(!modalOpen1);
              setModalOpen(true);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody className="Dashboard-policies-popup-body">
          <h6>Accepter les cookies ? </h6>
          <p className="pt-2">
            Nos partenaires et nous utilisons des cookies dans les cadres des
            activités suivantes :
          </p>
          <ul className="modal-ul ps-4">
            <li>Faciliter votre accès à PsyUP et votre navigation</li>
            <li>Assurer la sécurité, prévenir la fraude </li>
            <li>Assurer la performance et l’amélioration du site </li>
            <li>Personnaliser les contenus et les publicités</li>
            <li>Mesurer l’audience</li>
          </ul>
          <p>
            Certains cookies sont nécessaires au fonctionnement du site PsyUP.
          </p>
          <p>
            Vous pouvez en savoir plus sur notre utilisation des cookies en
            lisant{" "}
            <span className="modal-links" onClick={toggleFifthModal}>
              {" "}
              notre politique d’utilisation des cookies.
            </span>
          </p>
          <div style={{ marginTop: "10px" }}>
            <p>
              Vous pouvez contrôler la façon dont nous exploitons vos données
              grâce aux outils décrits cidessous.
            </p>
          </div>
          <h6 style={{ marginTop: "20px" }}>
            Contrôle du principal cookie publicitaire de PsyUP{" "}
          </h6>

          <p className={styles.paragraph}>
            Vous pouvez accepter ou refuser l’utilisation du cookie publicitaire
            principal.{" "}
          </p>

          <div className={styles.cookiesBorderSection}>
            <p>
              Accepter le cookie publicitaire principal de PsyUP, qui est
              principalement utilisé pour diffuser, mesurer et améliorer la
              pertinence des publicités proposées ? Si vous n’acceptez pas de ce
              cookie, vous verrez toujours des annonces sur notre site, mais
              cellesci ne seront pas personnalisées.{" "}
            </p>
            <div className={styles.cookiesToggle}>
              <ToggleButton checked="checked" />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h6>Contrôle des cookies de votre navigateur</h6>
            <p className="pt-2">
              Vous pouvez choisir vos cookies de navigation directement dans les
              paramètres de votre navigateur Internet. Nous vous alertons sur le
              fait que certaines parties du site PsyUP peuvent ne pas
              fonctionner correctement si vous désactivez l’utilisation des
              cookies dans votre navigateur. Ci-dessous les liens de gestion des
              cookies des principaux navigateurs.
            </p>
            <ul style={{ marginTop: "10px" }} className={styles.links}>
              <li>
                <a href="https://support.google.com/chrome/answer/9564 ">
                  Google Chrome
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en‐us/windows/delete‐and‐ 
                        manage‐cookies‐168dab11‐0753‐043d‐7c16‐ede5947fc64d "
                >
                  Internet Exporer
                </a>
              </li>
              <li>
                <a href="https://support.mozilla.org/en‐US/kb/enhanced‐tracking‐protection‐firefox‐desktop">
                  Firefox
                </a>
              </li>
              <li>
                <a href="https://support.apple.com/en‐ie/guide/safari/sfri11471/mac ">
                  Safari
                </a>
              </li>
              <li>
                <a href="https://support.apple.com/en‐us/HT201265">
                  Safari Mobile
                </a>
              </li>
              <li>
                <a href="https://blogs.opera.com/news/2015/08/how‐to‐manage‐cookies‐in‐opera">
                  Opera
                </a>
              </li>
            </ul>
          </div>

          <h6>Informations globales sur la publicité en ligne</h6>
          <p>
            Pour en savoir plus sur la manière dont les annonceurs publicitaires
            utilisent généralement les cookies et sur les choix dont vous
            disposez, vous pouvez consultez les liens suivants :
          </p>
          <ul style={{ marginTop: "10px" }}>
            <li className={styles.pMarginBottom}>
              <a href="https://optout.aboutads.info/?c=2&lang=EN">
                Alliance de la publicité numérique
              </a>
            </li>
            <li className={styles.pMarginBottom}>
              <a href="https://youradchoices.ca/ ">
                Alliance canadienne de la publicité numérique
              </a>
            </li>
            <li className={styles.pMarginBottom}>
              <a href="http://www.youronlinechoices.eu/ ">
                Alliance européenne pour l’éthique en matière de publicité
                numérique
              </a>
            </li>
          </ul>
          <p className="py-3">
            Vous pouvez vous désinscrire de toutes publicités en ligne par le
            biais de la{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://optout.aboutads.info/?c=2&lang=EN"
            >
              Digital Advertising Alliance
            </a>{" "}
            aux États-Unis, la{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youradchoices.ca/"
            >
              Digital Advertising Alliance of Canada
            </a>{" "}
            au Canada ou la{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youronlinechoices.eu/"
            >
              European Interactive Digital Advertising Alliance
            </a>{" "}
            en Europe, ou par le biais des paramètres de votre appareil mobile
            (Android ou iOS).
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="modal-button-accept" onClick={acceptCookies1}>
            Tout accepter
          </button>
        </ModalFooter>
      </Modal>

      {/* Modal for Our Partner */}

      <Modal
        centered
        size="lg"
        style={{ maxWidth: "600px", width: "100%" }}
        toggle={() => setModalOpen2(!modalOpen2)}
        isOpen={modalOpen2}
        backdrop="static"
      >
        <div className="modal-header">
          <button
            type="button"
            className="modal-button-cancel"
            style={{
              background: "none",
              color: "#01989f",
            }}
            onClick={toggleSecondModalFromThird}
          >
            <h5 className="modal-title" id="exampleModalLabel2">
              Voir les activités
            </h5>
          </button>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen2(!modalOpen2);
              setModalOpen(true);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody className="Dashboard-policies-popup-body">
          <h6 onClick={toggle}>Accepter les cookies ?</h6>
          <p>{acceptCookiesDescription}</p>

          <h6 style={{ marginTop: "1rem" }}>NOS PARTENAIRES</h6>
          <hr style={{ marginTop: "0px" }} />
          <div className="cookies-borderless-section">
            <div className="headings">
              <div style={{ display: "flex" }} onClick={() => toggle(1)}>
                <span>{open ? "▼" : "►"}</span>
                <p>_ga _gat _gid</p>
              </div>
              <ToggleButton checked="checked" />
            </div>
            <Collapse isOpen={open === 1}>
              <p>
                Cookies analytiques de Google Analytics, veuillez trouver plus
                d'informations{" "}
                <Link
                  target="_blank"
                  to="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                >
                  ici
                </Link>
              </p>
            </Collapse>

            <div className="headings">
              <div style={{ display: "flex" }} onClick={() => toggle(4)}>
                <span>{open ? "▼" : "►"}</span>
                <p>this.sid</p>
              </div>
              <ToggleButton checked="" />
            </div>
            <Collapse isOpen={open === 4}>
              <p>
                Identifie la même session stockant un ID. Le cookie expire à la
                fin de la session lorsque vous fermez votre navigateur.
              </p>
            </Collapse>

            <div className="headings">
              <div style={{ display: "flex" }} onClick={() => toggle(5)}>
                <span>{open ? "▼" : "►"}</span>
                <p>renderCtx</p>
              </div>
              <ToggleButton checked="checked" />
            </div>
            <Collapse isOpen={open === 5}>
              <p>
                Utilisé pour fournir les pages et le contenu demandés en
                fonction de la navigation d'un utilisateur. Basé sur la session.
              </p>
            </Collapse>

            <div className="headings">
              <div style={{ display: "flex" }} onClick={() => toggle(8)}>
                <span>{open ? "▼" : "►"}</span>
                <p>force-stream</p>
              </div>
              <ToggleButton checked="" />
            </div>
            <Collapse isOpen={open === 8}>
              <p>
                Utilisé pour acheminer correctement les demandes de serveur au
                sein de l'infrastructure Salesforce pour les sessions
                permanentes. Durée 3 heures.
              </p>
            </Collapse>

            <div className="headings">
              <div style={{ display: "flex" }} onClick={() => toggle(9)}>
                <span>{open ? "▼" : "►"}</span>
                <p>BrowserId</p>
              </div>
              <ToggleButton checked="checked" />
            </div>
            <Collapse isOpen={open === 9}>
              <p>
                Utilisé pour consigner les sessions/visites du navigateur pour
                l'analyse des produits en interne uniquement. 1 an.
              </p>
            </Collapse>
            {/* <Collapse accordion>
              <Panel header="This is panel header 1" key="1" extra={genExtra()}>
                <p>{text}</p>
              </Panel>
              <Panel header="This is panel header 2" key="2">
                <p>{text}</p>
              </Panel>
              <Panel header="This is panel header 3" key="3">
                <p>{text}</p>
              </Panel>
            </Collapse> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="modal-button-cancel" onClick={refuseCookies}>
            Tout refuser
          </button>
          <button className="modal-button-accept" onClick={acceptCookies2}>
            Tout accepter
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        toggle={() => setModalOpen3(!modalOpen3)}
        isOpen={modalOpen3}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel1">
            Politique d’utilisation des cookies{" "}
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen3(!modalOpen3);
              setModalOpen(true);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody className="Dashboard-policies-popup-body">
          <p>
            Nos partenaires et nous-mêmes utilisons différentes technologies,
            telles que les cookies, afin de stocker des informations (comme vos
            identifiants) sur votre navigateur web ou sur votre appareil.{" "}
          </p>
          <h6 style={{ marginTop: "20px" }}>
            Quand utilisons-nous des cookies ?
          </h6>

          <p>
            Nous utilisons des cookies lors de votre navigation sur psyup.fr
          </p>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default CookiesPage;
