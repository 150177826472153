import React, { useState } from "react";
import styles from "../../../assets/styles/SearchUser.module.scss";
import { faTrashCan, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { report } from "../../../assets";
import { useAuth } from "../../../context/AuthContext";
import { ApiCall } from "../../../config/apiCall";

import { message } from "antd";
import { toast } from "react-toastify";
import StoryLoader from "../../../components/UI/StoryLoader";

const ReportedProfiles = ({
  reportedProfiles,
  setReportedProfiles,
  isProfileloading,
}) => {
  const { token } = useAuth();
  const [deleteUserLoading, setdeleteUserLoading] = useState(false);
  // Delete User profile

  const deleteUserProfile = async (id) => {
    setdeleteUserLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "user/deleteUser/" + id,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setReportedProfiles(reportedProfiles.filter((user) => user._id !== id));
        message.success(response.response.message);
      }
      console.log("deleteUserResp", response);
    } catch (error) {
      setdeleteUserLoading(false);
    } finally {
      setdeleteUserLoading(false);
    }
  };
  return (
    <div>
      {isProfileloading ? (
        <StoryLoader oneLine={true} />
      ) : (
        <>
          {reportedProfiles.length == 0 && <p>Aucune utilisateur trouvé.</p>}
          {reportedProfiles?.map((user) => (
            <div className={styles.usersWrapper}>
              <h5>
                {user.fullname}
              </h5>
              <div
                style={{ cursor: "pointer" }}
                className={styles.commentsWrapper}
              >
                <img src={report} alt="logo" width={20} height={20}  onContextMenu={e => e.preventDefault()}/>
                <p>({user.reports_count})</p>
              </div>
              <div
                onClick={() =>
                  !deleteUserLoading && deleteUserProfile(user._id)
                }
              >
                <FontAwesomeIcon className="ps-2" icon={faTrashCan} />
              </div>
              <div
                onClick={() =>
                  !deleteUserLoading && deleteUserProfile(user._id)
                }
              >
                <FontAwesomeIcon className="ps-2" icon={faSquareCheck} />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ReportedProfiles;
