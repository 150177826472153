import { useState, useEffect } from "react";
import { message } from "antd";
import styles from "../../../assets/styles/signUp/step2.module.scss";

import { ApiCall } from "../../../config/apiCall";
import Themes from "../../themes";
import { Spinner } from "reactstrap";
import DotsLoader from "../../UI/DotsLoader";

function Step2({ userDataFunc, userData, setUserData, goBack }) {
  const [themes, setThemes] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedThemes, setselectedThemes] = useState([]);

  console.log("userDataStep2", userData);

  useEffect(() => {
    const getThemes = async () => {
      const response = await ApiCall({
        params: {},
        route: "theme/all",
        verb: "get",
        baseurl: true,
      });

      if (response.status === 200) {
        const themesWithSelectedFlag = response.response.map((theme) => ({
          ...theme,
          isSelected: false,
        }));
        setThemes(themesWithSelectedFlag);
      }
      console.log("responsetheme", response);
    };
    getThemes();
  }, []);

  function handleCardClick(id) {
    const selectedThemesFilter = themes.filter((theme) => theme.isSelected);
    const clickedTheme = themes.find((theme) => theme._id === id);
    if (!clickedTheme.isSelected && selectedThemesFilter.length < 3) {
      setThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: true } : theme
        )
      );
    } else {
      setThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: false } : theme
        )
      );
    }
  }

  useEffect(() => {
    const selectedThemesFilter = themes.filter((theme) => theme.isSelected);
    const selectedThemes = selectedThemesFilter.map((theme) => theme._id);
    setUserData((prevState) => ({ ...prevState, selectedThemes }));
  }, [themes]);

  const handleSubmitStep2 = async () => {
    try {
      setloading(true);
      // Submit sign up details to backend
      const response = await ApiCall({
        params: userData,
        route: "auth/signup",
        verb: "post",
        token: "jwt_token",
        baseurl: true,
      });
      if (response.status === 200) {
        // message.success(`${response.response.message}`);
        userDataFunc();
      } else {
        message.error(response.response.message);
        // message.error(response.response.error?.message);
      }
      console.log("responcs", response);
    } catch (error) {
      setloading(false);
      message.error(error);
    } finally {
      setloading(false);
    }
  };
  return (
    <div>
      <Themes themes={themes} handleCardClick={handleCardClick} />
      <div>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <DotsLoader color="white" />
          </div>
        ) : (
          <div className={styles.NextButtonWrapper}>
            <button onClick={handleSubmitStep2} className={styles.NextButton}>
              Étape suivante
            </button>
            <button className={styles.backBtn} onClick={goBack}>
              Retour
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Step2;
