import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Tabs } from "antd";

import ProfileDetails from "./profileDetails";
import DisplayStories from "../../components/stories/DisplayStories";

const MainProfile = () => {
  const {
    token,
    userId,
    getProfileData,
    userInfo,
    getUserStories,
    userStories,
    setUserStories,
    favStories,
    setFavStories,
    getUserFavouriteStories,
    loginModal,
    setLoginModal,
  } = useAuth();
  const [userDetails, setUserDetails] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [storyCount, setStoryCount] = useState(1);

  const onChange = (key) => {
    console.log(key);
  };
  const storiesContent = [
    {
      key: "1",
      label: `Book`,
      children: (
        <DisplayStories
          stories={userStories}
          setStories={setUserStories}
          selectedTheme={selectedTheme}
          setStoryCount={setStoryCount}
        />
      ),
    },
    {
      key: "2",
      label: `Histoires favorites`,
      children: (
        <DisplayStories
          stories={favStories}
          setStories={setFavStories}
          selectedTheme={selectedTheme}
          setStoryCount={setStoryCount}
        />
      ),
    },
  ];

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  useEffect(() => {
    if (token) {
      getProfileData(userId);
      getUserStories(userId, storyCount);
      getUserFavouriteStories(storyCount);
    }
  }, [token, storyCount]);

  useEffect(() => {
    setUserDetails(userInfo);
  }, [userInfo]);

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  return (
    <>
      {token && (
        <div>
          <ProfileDetails
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            userId={userId}
          />
          <div>
            <Tabs
              defaultActiveKey="1"
              items={storiesContent}
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MainProfile;
