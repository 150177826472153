import React, { useState } from "react";
import styles from "../assets/styles/ContactUs.module.scss";

import { toast } from "react-toastify";
import { baseUrl } from "../environment";
import { Spinner } from "reactstrap";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    if (!isValid) {
      toast.error("Veuillez remplir tous les champs");
    } else {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/contact/contactUs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();

        if (response.status == 200) {
          toast.success(result.message);
        } else {
          toast.error();
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: "",
          email: "",
          subject: "",
          message: "",
        }));
        console.log("contact", response);
        console.log("result", result);
      } catch (error) {
        console.error(error);
        toast.error("Quelque chose s'est mal passé !");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.contactUsBox}>
      <h2 className={styles.contactUsTitle}>Nous contacter</h2>
      <form onSubmit={handleSubmit} className={styles.contactUsForm}>
        <div className={styles.contactDetails}>
          <label>Nom</label>
          <input
            className={styles.contactUsInput}
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className={styles.contactDetails}>
          <label>Email</label>
          <input
            className={styles.contactUsInput}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className={styles.contactDetails}>
          <label>Objet </label>
          <input
            className={styles.contactUsInput}
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <div className={styles.contactDetails}>
          <label>Message</label>
          <textarea
            className={styles.contactUsTextarea}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "7px",
            }}
          >
            <Spinner color="info" />
          </div>
        ) : (
          <input
            type="submit"
            className={styles.contactUsFormButton}
            value="Envoyer"
          />
        )}
      </form>
    </div>
  );
};

export default ContactUs;
