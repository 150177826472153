import React, { useState } from "react";
import styles from "../../assets/styles/resources/ResourceSuccessModal.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { burgerCrossBlack, validate } from "../../assets";
const ResourceSuccessModal = ({ handleToggle, toggleState, name }) => {
  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "450px", width: "100%" }}
        centered
        modalClassName="loginModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.ModalBodyHeader}>
            <button aria-label="Close" type="button" onClick={handleToggle}>
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.resourceSuccessWrapper}>
            <img
              src={validate}
              alt="logo"
              width={80}
              height={80}
              onContextMenu={(e) => e.preventDefault()}
            />
            <h3>Recommandation de :</h3>
            <h3>{name}</h3>
            <p>Merci !</p>
            <button
              onClick={() => {
                handleToggle();
              }}
              className={styles.doneButton}
            >
              retour à la page des ressources
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ResourceSuccessModal;
