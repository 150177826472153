import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/signUp/userSignup.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { Steps } from "antd";

import { burgerCrossBlack } from "../../../assets";
import { useNavigate } from "react-router-dom";
import ProfessionalSignUpStep1 from "./ProfessionalSignUpStep1";
import ProfessionalSignUpStep2 from "./ProfessionalSignUpStep2";

const ProfessionalSignupModal = ({
  toggleState,
  handleToggle,
  initialState,
  professionalData,
  setprofessionalData,
  toggleProfessionalIdentity,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  //   useEffect(() => {
  //     if (isVerified) {
  //       setCurrentStep(0);
  //       setprofessionalData.email = "";
  //     } else {
  //       setprofessionalData.email = email;
  //       setCurrentStep(2);
  //     }
  //   }, [isVerified, email]);

  console.log("professionalData", professionalData);

  const navigate = useNavigate();

  const userDataFunc = (data) => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: "Formulaire d’inscription",
      content: (
        <ProfessionalSignUpStep1
          userDataFunc={userDataFunc}
          professionalData={professionalData}
          setprofessionalData={setprofessionalData}
          initialState={initialState}
        />
      ),
    },
    {
      title: "Thèmes Favoris",
      content: (
        <>
          <ProfessionalSignUpStep2
            userDataFunc={userDataFunc}
            professionalData={professionalData}
            setprofessionalData={setprofessionalData}
            goBack={goBack}
            handleToggle={handleToggle}
            toggleProfessionalIdentity={toggleProfessionalIdentity}
          />
        </>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "740px", width: "100%" }}
        centered
        modalClassName="SignUpModal"
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody
          className={`${styles.signUpModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.signUpModalBodyHeader}>
            <h5 id="signUpModalLabel">Inscription</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              {/* <span className={styles.close} aria-hidden={true}>
                ×
              </span> */}
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
            {/* <div
              className={`${currentStep === 0 && styles.customLineStart} 
              ${currentStep === 1 && styles.customLineStart1} 
              ${currentStep === 2 && styles.customLineStart2} `}
            ></div>
            <div
              className={`${currentStep === 0 && styles.customLineEnd} 
              ${currentStep === 1 && styles.customLineEnd1} 
              ${currentStep === 2 && styles.customLineEnd2}`}
              style={{
                background: currentStep === 2 ? "#00565b" : "#f5f7f8",
              }}
            ></div> */}
          </div>
          {/* {currentStep == 0 && (
            <p className="pb-4">
              Rejoignez la communauté pour partager les citrons et les limonades
              !
            </p>
          )}
          {currentStep == 1 && (
            <p className="pb-4">Selectionnez jusqu’à 3 thèmes favoris.</p>
          )}
          {currentStep == 2 && <p className="pb-4">Activation du compte.</p>} */}

          <div>
            <Steps
              direction="horizontal"
              // responsive={false}
              className="professional-signup-steps"
              current={currentStep}
              labelPlacement="vertical"
              items={items}
            />
            <div>{steps[currentStep]?.content}</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfessionalSignupModal;
