import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useAuth } from "../../context/AuthContext";
import styles from "../../assets/styles/menuBar.module.scss";

import ButtonFilled from "../buttonFilled";
import ButtonOutline from "../buttonOutline";
import Userlogin from "../auth/LoginUser";
import ThemesPopup from "../themesPopup";
import SubmitStory from "../../components/stories/SubmitStory";
import { Spinner } from "reactstrap";
import { ApiCall } from "../../config/apiCall";

import {
  logo,
  mobileLogo,
  user,
  userAvatar,
  home,
  theme,
  likes,
  notification,
  message,
  powerOff,
  userLight,
  notifications,
  burgerCross,
  burger,
  arrowDownWhite,
  otherResourcesIcon,
  beforeAfter,
  themeIcon,
} from "../../assets/index";
import NotificationsPopup from "../NotificationsPopup";
import ProfessionalSpaceModal from "../../pages/professionals/ProfessionalSpaceModal";
import OtherResourcesPopup from "../OtherResourcesPopup";
import { ReactSVG } from "react-svg";
import DotsLoader from "../UI/DotsLoader";

const MenuBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isAuthenticated,
    token,
    setToken,
    userInfo,
    setUserInfo,
    getProfileData,
    userId,
    setUserId,
    setSidebarSelectedTheme,
    setActiveTheme,
    loginModal,
    setLoginModal,
    adminStories,
    activeTheme,
    getGuestsStories,
  } = useAuth();

  // Modals
  const [submitStoryModal, setSubmitStoryModal] = useState(false);
  const [themesModal, setThemesModal] = useState(false);
  const [otherResourcesPopup, setOtherResourcesPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [notificationsModal, setNotificationsModal] = useState(false);
  const [noticationsData, setNoticationsData] = useState(null);
  const [professionalSpaceModal, setProfessionalSpaceModal] = useState(false);
  const [unreadChats, setUnreadChats] = useState(null);

  useEffect(() => {
    if (token) {
      getProfileData(userId);
      getNotifications();
    }
  }, [token]);

  useEffect(() => {
    const getUnreadChats = async () => {
      if (token) {
        try {
          const response = await ApiCall({
            params: {},
            route: "chat/unread-chatRooms",
            verb: "get",
            baseurl: true,
            token: token,
          });

          if (response?.status === 200) {
            setUnreadChats(response?.response?.data);
          } else {
          }
        } catch (error) {
          console.log(error);
        } finally {
        }
      }
    };
    getUnreadChats();

    // const interval = setInterval(getUnreadChats, 1000);

    // return () => clearInterval(interval);
  }, [token]);

  const userFullName = localStorage.getItem("userFullName");
  const getToken = localStorage.getItem("token");
  const getUserPic = localStorage.getItem("userpic");

  const logout = async () => {
    try {
      const response = await ApiCall({
        params: {},
        route: "auth/signout",
        verb: "put",
        baseurl: true,
        token: token,
      });

      localStorage.setItem("token", "");
      localStorage.setItem("userId", "");
      localStorage.setItem("userFullName", "");
      localStorage.setItem("userpic", "");
      setToken("");
      setUserId("");
      setUserInfo(null);
      navigate("/");
      if (response.status === 200) {
        getGuestsStories();
      }
    } catch (error) {
    } finally {
    }
  };

  const getNotifications = async () => {
    const response = await ApiCall({
      params: {},
      route: "notification/notification_listing",
      verb: "get",
      baseurl: true,
      token: token,
    });

    if (response.status === 200) {
      setNoticationsData(response.response);
    }
  };

  const changeNotificationsStatus = async () => {
    const response = await ApiCall({
      params: {},
      route: "notification/update_notifications_status",
      verb: "put",
      baseurl: true,
      token: token,
    });

    if (response.status === 200) {
    }
  };

  // show signin/signup modal
  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const professionalSpaceModalToggle = () => {
    setProfessionalSpaceModal(!professionalSpaceModal);
  };

  // show/hide notifications modal
  const toggleNotificationsModal = () => {
    setNotificationsModal(!notificationsModal);
    if (!notificationsModal) {
      changeNotificationsStatus();
      getNotifications();
    }
  };

  // show/hide mobile menu
  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  // show themes modal
  const toggleThemesModal = () => {
    setThemesModal(!themesModal);
  };

  // show themes modal
  const toggleOtherResourcesPopup = () => {
    setOtherResourcesPopup(!otherResourcesPopup);
  };

  // show submitStory modal
  const toggleSubmitStoryModal = () => {
    if (token) {
      setSubmitStoryModal(!submitStoryModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  const resetThemes = () => {
    setSidebarSelectedTheme(null);
    setActiveTheme(null);
  };

  const items = [
    {
      label: (
        <Link onClick={handleToggle} to="/profile" style={{ width: "250px" }}>
          <p>
            <img
              src={userLight}
              alt=""
              style={{ width: "15px", marginRight: "0px", marginBottom: "2px" }}
              onContextMenu={(e) => e.preventDefault()}
            />
            <span className="ps-1">Voir mon profil</span>
          </p>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link
          onClick={handleToggle}
          to="/professional-profile"
          style={{ width: "250px" }}
        >
          <p>
            <img
              src={userLight}
              alt=""
              style={{ width: "15px", marginRight: "0px", marginBottom: "2px" }}
              onContextMenu={(e) => e.preventDefault()}
            />
            <span className="ps-1">Voir mon profil professionnel</span>
          </p>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <a onClick={logout}>
          <img
            src={powerOff}
            alt=""
            style={{ width: "15px", marginRight: "5px", marginBottom: "2px" }}
            onContextMenu={(e) => e.preventDefault()}
          />
          Se déconnecter
        </a>
      ),
      key: "2",
      danger: true,
    },
  ];

  const itemsWithoutProfessional = [
    {
      label: (
        <Link onClick={handleToggle} to="/profile" style={{ width: "250px" }}>
          <p>
            <img
              src={userLight}
              alt=""
              style={{ width: "15px", marginRight: "0px", marginBottom: "2px" }}
              onContextMenu={(e) => e.preventDefault()}
            />
            <span className="ps-1">Voir mon profil</span>
          </p>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <a onClick={logout}>
          <img
            src={powerOff}
            alt=""
            style={{ width: "15px", marginRight: "5px", marginBottom: "2px" }}
            onContextMenu={(e) => e.preventDefault()}
          />
          Se déconnecter
        </a>
      ),
      key: "1",
      danger: true,
    },
  ];

  const onHomeBtnClick = () => {
    navigate("/");
    setShowMenu(false);
  };

  const onSubscriptionBtnClick = () => {
    navigate("/subscriptions");
    setShowMenu(false);
  };

  return (
    <>
      {/* Desktop sidebar*/}
      <div className={styles.sidebarLeft}>
        <div>
          <Link to="/" onClick={() => resetThemes()}>
            <img
              src={logo}
              alt="logo"
              width={150}
              height={50}
              onContextMenu={(e) => e.preventDefault()}
            />
          </Link>
        </div>

        {!getToken ? (
          <div className={styles.loginSignup} onClick={toggleLoginModal}>
            <img
              src={user}
              alt="logo"
              width={20}
              height={20}
              onContextMenu={(e) => e.preventDefault()}
            />
            <h3>Connexion/Inscription</h3>
          </div>
        ) : (
          <>
            {!userFullName ? (
              <div className={styles.loaderWrapper}>
                <DotsLoader color="white" />

                <div
                  className="dropdownLogout ps-2"
                  style={{ cursor: "pointer" }}
                >
                  <Dropdown
                    menu={
                      userInfo?.professional
                        ? {
                            items,
                          }
                        : { items: itemsWithoutProfessional }
                    }
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <img
                        src={arrowDownWhite}
                        alt="arrow-down"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </a>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <div className={styles.userInfo}>
                <Dropdown
                  menu={
                    userInfo?.professional
                      ? {
                          items,
                        }
                      : { items: itemsWithoutProfessional }
                  }
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <div className={styles.leftSide}>
                      <div className={styles.logo}>
                        <img
                          src={getUserPic === "null" ? userAvatar : getUserPic}
                          alt="pic"
                          width={40}
                          height={40}
                          style={{
                            borderRadius: "50%",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                      </div>
                      <h3>{userFullName}</h3>
                      <img
                        src={arrowDownWhite}
                        alt="arrow-down"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  </a>
                </Dropdown>
                <div
                  className={styles.rightSide}
                  onClick={toggleNotificationsModal}
                >
                  <img
                    src={notifications}
                    alt="notifications"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  {userInfo?.notifications_count > 0 && (
                    <div className={styles.dot}></div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <ButtonFilled
          title="Publier"
          textColr="white"
          handleClick={toggleSubmitStoryModal}
        />
        {userInfo?.isAdmin && (
          <Link to="/moderator">
            <ButtonOutline
              title="Modérer"
              borderColr="white"
              textColr="white"
            />
          </Link>
        )}

        <div className={styles.menu}>
          <ul>
            <li
              onClick={() => resetThemes()}
              className={
                location.pathname === "/" && !themesModal && !activeTheme
                  ? "active"
                  : ""
              }
            >
              <img
                src={home}
                alt="logo"
                width={20}
                height={20}
                onContextMenu={(e) => e.preventDefault()}
              />
              <Link to="/">Accueil</Link>
            </li>
            <li className={themesModal || activeTheme ? "active" : ""}>
              <img
                src={themeIcon}
                alt="logo"
                width={18}
                height={18}
                style={{ marginLeft: "1px" }}
                onContextMenu={(e) => e.preventDefault()}
              />
              {/* <Link>Thème</Link> */}
              <p onClick={toggleThemesModal}>Thèmes</p>
            </li>
            {token ? (
              <li
                onClick={() => resetThemes()}
                className={
                  location.pathname === "/subscriptions" &&
                  !themesModal &&
                  !activeTheme
                    ? "active"
                    : ""
                }
              >
                <img
                  src={likes}
                  alt="logo"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <Link to="/subscriptions">Mes abonnements</Link>
              </li>
            ) : (
              <li
                onClick={toggleLoginModal}
                className={
                  location.pathname === "/subscriptions" && !themesModal
                    ? "active"
                    : ""
                }
              >
                <img
                  src={likes}
                  alt="logo"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <p>Mes abonnements</p>
              </li>
            )}
            {token ? (
              <li
                onClick={() => resetThemes()}
                className={
                  location.pathname === "/featuredPosts" &&
                  !themesModal &&
                  !activeTheme
                    ? "active"
                    : ""
                }
              >
                <img
                  src={notification}
                  alt="logo"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <Link to="/featuredPosts">À la une</Link>
              </li>
            ) : (
              <li
                onClick={toggleLoginModal}
                className={
                  location.pathname === "/featuredPosts" && !themesModal
                    ? "active"
                    : ""
                }
              >
                <img
                  src={notification}
                  alt="logo"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />

                <p>À la une</p>
              </li>
            )}

            <li
              onClick={() => {
                resetThemes();
                handleToggle();
              }}
              className={
                location.pathname === "/physical-transformation" &&
                !themesModal &&
                !activeTheme
                  ? "active"
                  : ""
              }
            >
              <ReactSVG src={beforeAfter} alt="logo" />
              <Link to="/physical-transformation">Photos Avant /Après</Link>
            </li>
          </ul>
        </div>

        {isAuthenticated && (
          <Link to="/messaging">
            <div className={styles.message}>
              <div className={styles.messageLeft}>
                <img
                  src={message}
                  alt="logo"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <p>Messagerie</p>
              </div>
              {unreadChats == null ||
              unreadChats === 0 ||
              unreadChats.length === 0 ? (
                ""
              ) : (
                <div className={styles.messageRight}>{unreadChats}</div>
              )}
            </div>
          </Link>
        )}
      </div>
      <Userlogin toggleState={loginModal} handleToggle={toggleLoginModal} />
      <NotificationsPopup
        toggleState={notificationsModal}
        handleToggle={toggleNotificationsModal}
        noticationsData={noticationsData}
        handleToggleMenubar={handleToggle}
      />

      <ThemesPopup
        toggleState={themesModal}
        handleToggle={toggleThemesModal}
        handleToggleMenubar={handleToggle}
      />

      <OtherResourcesPopup
        toggleState={otherResourcesPopup}
        handleToggle={toggleOtherResourcesPopup}
        handleToggleMenubar={handleToggle}
      />
      <SubmitStory
        toggleState={submitStoryModal}
        handleToggle={toggleSubmitStoryModal}
      />
      {/* Desktop sidebar end*/}

      {/* Mobile navbar */}
      <div className={styles.mobileMenuWrapper}>
        <div className={styles.navbarMobile}>
          <div
            className={styles.logo}
            onClick={() => {
              {
                showMenu && setShowMenu(!showMenu);
              }
            }}
          >
            <Link to="/" onClick={() => resetThemes()}>
              <img
                src={mobileLogo}
                alt="logo"
                height={55}
                onContextMenu={(e) => e.preventDefault()}
              />
            </Link>
          </div>

          <div className={styles.rightSide}>
            <div className={styles.actionBtns}>
              {location.pathname !== "/" && (
                <div className={styles.btnHome} onClick={onHomeBtnClick}>
                  <img src={home} />
                </div>
              )}

              {location.pathname !== "/subscriptions" && (
                <div
                  className={styles.btnSubscription}
                  onClick={onSubscriptionBtnClick}
                >
                  <img src={likes} />
                </div>
              )}
            </div>

            <div className={styles.burger} onClick={handleToggle}>
              {showMenu ? (
                <img
                  src={burgerCross}
                  alt="logo"
                  onContextMenu={(e) => e.preventDefault()}
                />
              ) : (
                <img
                  src={burger}
                  alt="logo"
                  onContextMenu={(e) => e.preventDefault()}
                />
              )}
            </div>
          </div>
        </div>
        {showMenu && (
          <div className={styles.mobileMenu}>
            <div className={styles.mobileMenuContent}>
              {!getToken ? (
                <div className={styles.loginSignup} onClick={toggleLoginModal}>
                  <img
                    src={user}
                    alt="logo"
                    width={20}
                    height={20}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <h3>Connexion/Inscription</h3>
                </div>
              ) : (
                <>
                  {!userFullName ? (
                    <div className={styles.loaderWrapper}>
                      <DotsLoader color="white" />

                      <div
                        className="dropdownLogout ps-2"
                        style={{ cursor: "pointer" }}
                      >
                        <Dropdown
                          menu={
                            userInfo?.professional
                              ? {
                                  items,
                                }
                              : { items: itemsWithoutProfessional }
                          }
                          placement="bottomRight"
                          trigger={["click"]}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <img
                              src={arrowDownWhite}
                              alt="arrow-down"
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.userInfo}>
                      <Dropdown
                        menu={
                          userInfo?.professional
                            ? {
                                items,
                              }
                            : { items: itemsWithoutProfessional }
                        }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <div className={styles.leftSide}>
                            <img
                              src={
                                getUserPic === "null" ? userAvatar : getUserPic
                              }
                              alt="logo"
                              width={20}
                              height={20}
                              style={{
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <h3>{userFullName}</h3>
                            <img
                              src={arrowDownWhite}
                              alt="arrow-down"
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </div>
                        </a>
                      </Dropdown>
                      <div
                        className={styles.rightSide}
                        onClick={toggleNotificationsModal}
                      >
                        <img
                          src={notifications}
                          alt="notifications"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        {userInfo?.notifications_count > 0 && (
                          <div className={styles.dot}></div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <ButtonFilled
                title="Publier"
                textColr="white"
                handleClick={toggleSubmitStoryModal}
              />
              {userInfo?.isAdmin && (
                <Link to="/moderator" onClick={handleToggle}>
                  <ButtonOutline
                    title="Modérer"
                    borderColr="white"
                    textColr="white"
                  />
                </Link>
              )}
              <div className={styles.menu}>
                <ul>
                  {/* <li
                    onClick={() => {
                      resetThemes();
                      handleToggle();
                    }}
                    // onClick={handleToggle}
                    className={
                      location.pathname === "/" && !themesModal && !activeTheme
                        ? "active"
                        : ""
                    }
                  >
                    <img
                      src={home}
                      alt="logo"
                      width={20}
                      height={20}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className={themesModal || activeTheme ? "active" : ""}>
                    <img
                      src={theme}
                      alt="logo"
                      width={20}
                      height={20}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <p onClick={toggleThemesModal}>Thèmes</p>
                  </li>
                  <li
                    onClick={() => {
                      resetThemes();
                      handleToggle();
                    }}
                    className={
                      location.pathname === "/subscriptions" &&
                      !themesModal &&
                      !activeTheme
                        ? "active"
                        : ""
                    }
                  >
                    <img
                      src={likes}
                      alt="logo"
                      width={20}
                      height={20}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <Link to="/subscriptions">Mes abonnements</Link>
                  </li> */}

                  <li
                    onClick={() => {
                      resetThemes();
                      handleToggle();
                    }}
                    className={
                      location.pathname === "/physical-transformation" &&
                      !themesModal &&
                      !activeTheme
                        ? "active"
                        : ""
                    }
                  >
                    <ReactSVG src={beforeAfter} alt="logo" />
                    <Link to="/physical-transformation">
                      Photos Avant /Après
                    </Link>
                  </li>

                  {token ? (
                    <li
                      onClick={() => {
                        resetThemes();
                        handleToggle();
                      }}
                      className={
                        location.pathname === "/featuredPosts" &&
                        !themesModal &&
                        !activeTheme
                          ? "active"
                          : ""
                      }
                    >
                      <img
                        src={notification}
                        alt="logo"
                        width={20}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <Link to="/featuredPosts">À la une</Link>
                    </li>
                  ) : (
                    <li
                      onClick={toggleLoginModal}
                      className={
                        location.pathname === "/featuredPosts" && !themesModal
                          ? "active"
                          : ""
                      }
                    >
                      <img
                        src={notification}
                        alt="logo"
                        width={20}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <p>À la une</p>
                    </li>
                  )}
                </ul>
              </div>

              {isAuthenticated && (
                <Link to="/messaging" onClick={handleToggle}>
                  <div className={styles.message}>
                    <div className={styles.messageLeft}>
                      <img
                        src={message}
                        alt="logo"
                        width={20}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <p>Messagerie</p>
                    </div>

                    {unreadChats == null || unreadChats === 0 ? (
                      ""
                    ) : (
                      <div className={styles.messageRight}>{unreadChats}</div>
                    )}
                  </div>
                </Link>
              )}
              <Link to="/professionals" onClick={handleToggle}>
                <ButtonFilled
                  title="Trouver un professionnel"
                  text="ou une association"
                  textColr="white"
                />
              </Link>
              <div
                className={styles.btnWrapper}
                onClick={() => {
                  professionalSpaceModalToggle();
                }}
              >
                <ButtonOutline
                  title="ESPACE PRO"
                  text="(médecins, psychologues, avocats...)"
                  borderColr="white"
                  textColr="white"
                />
              </div>
              <div className={styles.resourcesBtnsGroup}>
                <div />

                <Link to="/ressources" onClick={handleToggle}>
                  <div className={styles.autres}>
                    <img src={otherResourcesIcon} />
                    <p>Autres ressources</p>
                  </div>
                </Link>

                <div
                  className={styles.topBtnContainer}
                  onClick={toggleOtherResourcesPopup}
                >
                  <p>TOP</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <ProfessionalSpaceModal
          toggleState={professionalSpaceModal}
          handleToggle={professionalSpaceModalToggle}
          toggleSideBar={handleToggle}
        />
      </div>
      {/* Mobile navbar end */}
    </>
  );
};

export default MenuBar;
