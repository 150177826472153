import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../../../assets/styles/stories/BeforeAfterModal.module.scss";

import { burgerCross } from "../../../assets";
import ButtonFilled from "../../buttonFilled";

const BeforeAfterModal = ({
  toggleState,
  handleToggle,
  selectedStoryIndex,
  filteredStories,
}) => {
  const date = new Date(filteredStories[selectedStoryIndex]?.createdAt);

  const now = new Date(); // Current date/time

  // Set the time to 12 AM for both dates
  date.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);

  const timeDiff = now.getTime() - date.getTime(); // Time difference in milliseconds

  // Calculate the difference in days, weeks, and months
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const weeksDiff = Math.floor(daysDiff / 7);
  const monthsDiff = Math.floor(daysDiff / 30);

  let formattedTime;

  if (monthsDiff >= 1) {
    formattedTime = `il y a ${monthsDiff} moi${monthsDiff > 1 ? "s" : ""}`;
  } else if (weeksDiff >= 1) {
    formattedTime = `il y a ${weeksDiff} semaine${weeksDiff > 1 ? "s" : ""}`;
  } else if (daysDiff >= 1) {
    formattedTime = `il y a ${daysDiff} jour${daysDiff > 1 ? "s" : ""}`;
  } else {
    formattedTime = "Aujourd'hui";
  }

  const formatPrice = (value) => {
    // Remove any non-numeric characters
    const numericValue = value?.replace(/[^0-9]/g, "");

    // Format with spacing
    const formattedValue = numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return formattedValue;
  };

  return (
    <div>
      {selectedStoryIndex !== null && (
        <Modal
          isOpen={toggleState}
          centered
          style={{ maxWidth: "1400px", width: "100%" }}
          modalClassName="beforeAfterModal"
          toggle={handleToggle}
        >
          <ModalBody>
            <div className={styles.header}>
              <div>
                <h2 className={styles.heading}>
                  {
                    filteredStories[selectedStoryIndex]?.physicalTransformation
                      ?.treatment
                  }
                </h2>
                <p className={styles.details}>
                  {formatPrice(
                    filteredStories[selectedStoryIndex]?.physicalTransformation
                      ?.price
                  )}{" "}
                  €
                </p>
              </div>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={handleToggle}
              >
                {/* <span className={styles.close} aria-hidden={true}>
                  ×
                </span> */}
                <img
                  src={burgerCross}
                  onContextMenu={(e) => e.preventDefault()}
                ></img>
              </button>
            </div>
            <div className={styles.imagesWrapper}>
              <div className={styles.leftSide}>
                {filteredStories[selectedStoryIndex]?.physicalTransformation
                  ?.beforePictureUrl ? (
                  <img
                    src={
                      filteredStories[selectedStoryIndex]
                        ?.physicalTransformation?.beforePictureUrl
                    }
                    onContextMenu={(e) => e.preventDefault()}
                  ></img>
                ) : (
                  <p className="text-black">No Image</p>
                )}
              </div>
              <div className={styles.rightSide}>
                {filteredStories[selectedStoryIndex]?.physicalTransformation
                  ?.afterPictureUrl ? (
                  <img
                    src={
                      filteredStories[selectedStoryIndex]
                        ?.physicalTransformation?.afterPictureUrl
                    }
                    onContextMenu={(e) => e.preventDefault()}
                  ></img>
                ) : (
                  <p className="text-black">No Image</p>
                )}
              </div>

              <div className={styles.content}>
                <div className={styles.contentLeft}>
                  <div className={styles.userInfo}>
                    {filteredStories[selectedStoryIndex]?.isAnonym ? (
                      <h6>
                        Par{" "}
                        {filteredStories[
                          selectedStoryIndex
                        ]?.writter?.fullname?.charAt(0)}
                        ****.
                      </h6>
                    ) : (
                      <h6>
                        Par{" "}
                        {filteredStories[selectedStoryIndex]?.writter?.fullname}
                        .
                      </h6>
                    )}

                    <p>{formattedTime}</p>
                  </div>

                  <div>
                    {filteredStories[selectedStoryIndex]?.physicalTransformation
                      ?.isSatisfied == "true" ? (
                      <div className={styles.satisfaitBtn}>Satisfait</div>
                    ) : (
                      <div className={styles.pasSatisfaitBtn}>InSatisfait</div>
                    )}
                  </div>
                </div>
                <div className={styles.contentRight}>
                  <ButtonFilled title="Voir son histoire" textColr="white" />
                </div>
              </div>
            </div>

            <div className={styles.mobileViewBtn}>
              <ButtonFilled title="Voir son histoire" textColr="white" />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default BeforeAfterModal;
