import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/resources/Resources.module.scss";
import styles2 from "../../assets/styles/resources/Resources.module.scss";
import StoryStyles from "../../assets/styles/DisplayStories.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Menu, message } from "antd";
import {
  activities13Icon,
  arrowBackGray,
  favorite,
  favorite2,
  like,
  share,
  storyEdit,
} from "../../assets";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { getShareUrl, SocialPlatforms } from "@phntms/react-share";
import { resources } from "./resourcesData";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import { ReactSVG } from "react-svg";
import { siteUrl } from "../../environment";
import StoryLoader from "../../components/UI/StoryLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCopy,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import FriendsListModal from "../../components/stories/storyModals/FriendsListModal";

const reactionsArray = [
  {
    emogi: like,
    value: "like",
    selected: false,
    id: 1,
    height: 25,
    width: 25,
    title: "J’aime !",
    length: 8,
  },
];

const ResourcesDetails4 = () => {
  const { token, loginModal, setLoginModal } = useAuth();
  const navigate = useNavigate();
  let { typeId } = useParams();
  const location = useLocation();
  const pathname = siteUrl + location.pathname + location.search;
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  const [friendsListModal, setFriendsListModal] = useState(false);
  const [shareableResourceLink, setShareableResourceLink] = useState(null);

  const [resourceData, setResourceData] = useState(null);
  const [isDescriptionShow, setDescriptionShow] = useState(null);
  const [loading, setLoading] = useState(null);

  const toggleDescriptionShow = (id) => {
    setDescriptionShow(id === isDescriptionShow ? null : id);
  };

  useEffect(() => {
    if (token) {
      getResources();
    }
  }, [token, resourceId]);

  const getResources = async () => {
    setLoading(true);

    let url;

    if (token) {
      url = `other-resources/otherResourcesPoint/${resourceId}`;
    } else {
      url = `other-resources/otherResourcesPoint-without-auth/${resourceId}`;
    }

    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setResourceData(response?.response?.data);
      }
      console.log("resp", response);
    } catch (error) {
      setLoading(false);
      message.error("Connection Error");
    } finally {
      setLoading(false);
    }
  };

  const handleFavoriteClick = async (resourceId) => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: `other-resources/add-to-favourities/${resourceId}`,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setResourceData((prev) => {
            return {
              ...prev,
              isFavourite: response.response.resource.isFavourite,
            };
          });
        }
      } catch (error) { }
    } else {
      setLoginModal(!loginModal);
    }
  };

  // Function to handle reaction click
  const handleReactionClick = async (resource) => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: `other-resources/like-resource/${resource?._id}&like`,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setResourceData((prev) => {
            return {
              ...prev,
              isLiked: response.response.resource.isLiked,
              likesCount: response.response.resource.likesCount,
            };
          });
        } else {
          console.log("something went wrong");
        }
      } catch (error) {
        message.error(error);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  // share private

  const sendInMessage = (resourceId) => {
    toggleFriendsListModal();
    setShareableResourceLink(resourceId);
  };

  const toggleFriendsListModal = () => {
    if (token) {
      setFriendsListModal(!friendsListModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  const items = [
    {
      label: "Copy link",
      key: "0",
      onClick: (index) => {
        const pathname = `${window.location.href}`;
        navigator.clipboard?.writeText(pathname);
        message.success(`Story link copied to clipboard!`);
      },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: (index) => {
        const pathname = location.pathname + location.search;
        return (
          <>
            <FacebookShareButton
              url={pathname}
            // quote={filteredStories[index]?.title}
            >
              <a className={styles.svgLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                </svg>
              </a>
            </FacebookShareButton>
          </>
        );
      },
    },

    {
      label: "WhatsApp",
      key: "3",
      render: (index) => {
        const pathname = `${window.location.href}`;
        return (
          <>
            <a href={getShareUrl(SocialPlatforms.WhatsApp, { url: pathname })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  fill="white"
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
              </svg>
            </a>
          </>
        );
      },
    },
    {
      label: "Twitter",
      key: "4",
      render: (index) => {
        const pathname = `${window.location.href}`;
        return (
          <>
            <TwitterShareButton
              url={pathname}
            // title={filteredStories[index]?.title}
            >
              <a className={styles.svgLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </a>
            </TwitterShareButton>
          </>
        );
      },
    },
    {
      label: "Share in private",
      key: "2",
      onClick: (index) => { },
      render: (resourceId) => {
        return (
          <>
            <div
              className={styles.sharePrivate}
              onClick={() => sendInMessage(resourceId)}
            >
              <ReactSVG src={storyEdit} />
            </div>
          </>
        );
      },
    },
  ];

  // console.log("resourceData", resourceData);

  const topIcon = `${siteUrl}${resourceData?.topIcon}`;
  return (
    <div className={styles.resourcesContainer}>
      <div
        className={styles2.backButton}
        onClick={() => {
          navigate("/ressources");
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>
      <div className={styles2.topContent}>
        <h2 className={styles2.resourceDetailHeading}>Autres ressources</h2>

        {resourceData?.titleInside && (
          <h1 className={styles.resourceDetailHeading2}>
            {resourceData?.titleInside}
          </h1>
        )}

        {!["46"].includes(resourceId) && resourceData?.topIcon && (
          <div
            className={styles.topIconClass}
            style={{
              padding: resourceId === "50" && "0",
              paddingBottom: resourceId === "50" && "20px",
            }}
          >
            <div
              className={styles.iconWrappPng}
              style={{
                height:
                  resourceId === "50"
                    ? "250px"
                    : resourceId === "24"
                      ? "200px"
                      : "",
                // width: resourceId === "50" ? "100%" : "",
              }}
            >
              <img src={topIcon} />
            </div>
          </div>
        )}

        {resourceId === "13" && (
          <>
            <h1 className={styles.resourceDetailHeading2}>
              Voyager et Faire des activités
            </h1>
            <div className={styles2.topIconClass}>
              <div className={styles2.iconWrapp}>
                <ReactSVG src={activities13Icon} />
              </div>
            </div>
          </>
        )}

        {!["30", "34", "35", "45"].includes(resourceId) &&
          resourceData?.data[0]?.heading && (
            <strong>{resourceData?.data[0]?.heading}</strong>
          )}

        {!["29"].includes(resourceId) && resourceData?.data[0]?.heading1 && (
          <strong>{resourceData?.data[0]?.heading1}</strong>
        )}

        {!["29", "45"].includes(resourceId) &&
          resourceData?.data[0]?.heading2 && (
            <strong>{resourceData?.data[0]?.heading2}</strong>
          )}

        {["21", "22"].includes(resourceId) &&
          resourceData?.data[0]?.description && (
            <p className="pb-3">{resourceData?.data[0]?.description}</p>
          )}

        {(resourceId == 34 || resourceId == 36) && (
          <div className={styles.discriptionsContainer}>
            {resourceData?.topIcon2 && (
              <div className={styles.topIconClass}>
                <div className={styles.iconWrappPng}>
                  <img src={`${siteUrl}${resourceData?.topIcon2}`} />
                </div>
              </div>
            )}

            {resourceData?.topIcon3 && (
              <div className={styles.topIconClass}>
                <div className={styles.iconWrappPng}>
                  <img src={`${siteUrl}${resourceData?.topIcon3}`} />
                </div>
              </div>
            )}

            {resourceData?.topIcon4 && (
              <div className={styles.topIconClass}>
                <div className={styles.iconWrappPng}>
                  <img src={`${siteUrl}${resourceData?.topIcon4}`} />
                </div>
              </div>
            )}

            {resourceData?.data[0]?.description && (
              <p>{resourceData?.data[0]?.description}</p>
            )}

            {!["34"].includes(resourceId) && resourceData?.data[0]?.description1 && (
              <p>{resourceData?.data[0]?.description1}</p>
            )}

            {!["34"].includes(resourceId) && resourceData?.data[0]?.description2 && (
              <p>{resourceData?.data[0]?.description2}</p>
            )}

            {resourceData?.data[0]?.description3 && (
              <p>{resourceData?.data[0]?.description3}</p>
            )}

            {resourceData?.data[0]?.description4 && (
              <p>{resourceData?.data[0]?.description4}</p>
            )}
            {resourceData?.data[0]?.description5 && (
              <p>{resourceData?.data[0]?.description5}</p>
            )}
            {resourceData?.data[0]?.description6 && (
              <p>{resourceData?.data[0]?.description6}</p>
            )}
            {resourceData?.data[0]?.description7 && (
              <p>{resourceData?.data[0]?.description7}</p>
            )}
          </div>
        )}

        {!["38"].includes(resourceId) && resourceData?.data[0]?.bold && (
          <>
            <br />
            <strong>{resourceData?.data[0]?.bold}</strong>
          </>
        )}
      </div>
      {/* {resourceId !== "13" && <br />} */}
      {/* mapping data list*/}
      {(resourceId == 21 || resourceId == 22) && (
        <>
          <div className={styles.resourceListWrapper}>
            {resourceData?.data[0]?.list?.map((listItem) => {
              return (
                <div className={styles.resourceDetail} key={listItem.id}>
                  <div className={styles.resource3listHeadingWrapper}>
                    <div className={styles.top}>
                      <h4 className={styles.resourceDetailHeading}>
                        {listItem.heading}
                      </h4>
                    </div>
                    <div className={styles.bottom}>
                      {listItem.des && (
                        <p className={styles.description}>{listItem.des}</p>
                      )}
                    </div>

                    {listItem?.image && (
                      <div className={styles.coverPhotoView}>
                        <img src={`${siteUrl}${listItem?.image}`} alt="cover" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <p className="pt-3">{resourceData?.data[0]?.description2}</p>
        </>
      )}
      {resourceId == 13 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={``}>TOP Tendance AIRBNB</Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={``}>TOP Activités WONDERBOX</Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={``}>TOP Idées Week-end GOVOYAGE</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 16 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    5 BONNES RAISONS DE DORMIR SUFFISAMMENT
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 5 MEILLEURES APPLICATIONS DE RELAXATION, DE MÉDITATION,
                    DE RESPIRATION ET DU SOMMEIL
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 17 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    6 BONNES RAISONS DE BOIRE SUFFISAMMENT D’EAU
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 5 MEILLEURES APPLICATIONS D’ALIMENTATION (QUALITÉ DES
                    PRODUITS, SUIVI ALIMENTAIRE, COMPTEURS DE CALORIES, ETC.)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 18 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    6 BONNES RAISONS DE PRATIQUER UNE ACTIVITÉ PHYSIQUE
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 6 MEILLEURES APPLICATIONS DE SPORT
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=c`}>
                    5 BONNES RAISONS DE S'ÉTIRER
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=d`}>
                    LES 3 MEILLEURES APPLICATIONS D’ÉTIREMENTS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 19 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    6 BONNES RAISONS DE SE RELAXER
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 5 MEILLEURES APPLICATIONS DE RELAXATION, DE MEDITATION,
                    DE RESPIRATION ET DU SOMMEIL
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 20 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    LES COSMÉTIQUES BIO, OU COMMENT ALLIER BEAUTÉ ET SANTÉ
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 23 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    LES 5 MEILLEURES APPLICATIONS DE CUISINE
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 5 MEILLEURES APPLICATIONS DE JARDINAGE, BRICOLAGE,
                    ARTISANAT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {resourceId == 24 && (
        <div className={StoryStyles.storyOuterContainer}>
          <div className={StoryStyles.DisplayStoriesContainer}>
            <div className={StoryStyles.storyBody}>
              <div className={styles2.storyContent}>
                <div className={styles2.linkswrapper}>
                  <Link to={`best-apps?resourceId=${resourceId}&type=a`}>
                    LES 3 MEILLEURES APPLICATIONS POUR APPRENDRE À JOUER AU
                    PIANO
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=b`}>
                    LES 3 MEILLEURES APPLICATIONS POUR APPRENDRE À JOUER À LA
                    GUITARE
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=c`}>
                    LES 3 MEILLEURES APPLICATIONS POUR APPRENDRE LE DESSIN ET LA
                    PEINTURE
                  </Link>
                  <br />
                  <div className="pb-3" />
                  <Link to={`best-apps?resourceId=${resourceId}&type=d`}>
                    LES 3 MEILLEURES APPLICATIONS POUR APPRENDRE LE CHANT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {[
        "25",
        "26",
        "27",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "49",
        "50",
      ].includes(resourceId) && (
          <>
            <div className={StoryStyles.storyOuterContainer}>
              <div className={StoryStyles.DisplayStoriesContainer}>
                <div className={StoryStyles.storyBody}>
                  <div className={styles2.storyContent}>
                    {resourceData?.data[0]?.heading && (
                      <h4 className={styles2.heading}>
                        {resourceData?.data[0]?.heading}
                      </h4>
                    )}

                    {resourceId === "45" && (
                      <>
                        {resourceData?.data[0]?.heading2 && (
                          <h4 className={`pt-3 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading2}
                            <span> {resourceData?.data[0]?.headingText2}</span>
                          </h4>
                        )}
                        {resourceData?.data[0]?.heading3 && (
                          <h4 className={`pt-3 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading3}
                            <span> {resourceData?.data[0]?.headingText3}</span>
                          </h4>
                        )}
                        {resourceData?.data[0]?.heading4 && (
                          <h4 className={`pt-3 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading4}
                            <span> {resourceData?.data[0]?.headingText4}</span>
                          </h4>
                        )}
                        {resourceData?.data[0]?.heading5 && (
                          <h4 className={`pt-3 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading5}
                            <span> {resourceData?.data[0]?.headingText5}</span>
                          </h4>
                        )}
                        {resourceData?.data[0]?.heading6 && (
                          <h4 className={`pt-4 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading6}
                          </h4>
                        )}
                        {resourceData?.data[0]?.heading7 && (
                          <h4 className={`pt-2 ${styles2.heading}`}>
                            {resourceData?.data[0]?.heading7}
                          </h4>
                        )}
                      </>
                    )}

                    {resourceData?.data[0]?.description && (
                      <p>{resourceData?.data[0]?.description}</p>
                    )}

                    {resourceId === "29" && resourceData?.data[0]?.heading1 && (
                      <h4 className={styles.heading}>
                        {resourceData?.data[0]?.heading1}
                      </h4>
                    )}

                    {resourceData?.data[0]?.description1 && (
                      <p>
                        {resourceId === "44" && (
                          <span style={{ color: "#01989f", fontWeight: "600" }}>
                            Patienter :{" "}
                          </span>
                        )}
                        {resourceData?.data[0]?.description1}
                      </p>
                    )}

                    {["46"].includes(resourceId) && resourceData?.topIcon && (
                      <div className={styles.topIconClass}>
                        <div className={styles.iconWrappPng}>
                          <img src={`${siteUrl}${resourceData?.topIcon}`} />
                        </div>
                      </div>
                    )}

                    {resourceId === "29" && resourceData?.data[0]?.heading2 && (
                      <>
                        <h4 className={styles.heading}>
                          {resourceData?.data[0]?.heading2}
                        </h4>
                        <div className="pb-4" />
                      </>
                    )}
                    {resourceId === "29" && resourceData?.data[0]?.heading3 && (
                      <h4 className={styles.heading}>
                        {resourceData?.data[0]?.heading3}
                      </h4>
                    )}

                    {resourceData?.data[0]?.description2 && (
                      <p>
                        {resourceId === "44" && (
                          <span style={{ color: "#01989f", fontWeight: "600" }}>
                            Relativiser :{" "}
                          </span>
                        )}
                        {resourceData?.data[0]?.description2}
                      </p>
                    )}

                    {resourceId === "29" && resourceData?.data[0]?.heading4 && (
                      <h4 className={styles.heading}>
                        {resourceData?.data[0]?.heading4}
                      </h4>
                    )}

                    {resourceData?.data[0]?.description3 && (
                      <p>
                        {resourceId === "44" && (
                          <span style={{ color: "#01989f", fontWeight: "600" }}>
                            Lâcher prise :{" "}
                          </span>
                        )}
                        {resourceId === "49" ? (
                          <del>{resourceData?.data[0]?.description3}</del>
                        ) : (
                          resourceData?.data[0]?.description3
                        )}
                      </p>
                    )}

                    {["46"].includes(resourceId) && resourceData?.topIcon2 && (
                      <div className={styles.topIconClass}>
                        <div
                          className={styles.iconWrappPng}
                          style={{ height: "240px" }}
                        >
                          <img src={`${siteUrl}${resourceData?.topIcon2}`} />
                        </div>
                      </div>
                    )}

                    {resourceId === "29" && resourceData?.data[0]?.heading5 && (
                      <h4 className={styles.heading}>
                        {resourceData?.data[0]?.heading5}
                      </h4>
                    )}

                    {resourceData?.data[0]?.description4 && (
                      <p style={{ fontWeight: resourceId === "46" ? "500" : "" }}>
                        {resourceData?.data[0]?.description4}
                      </p>
                    )}

                    {resourceId === "29" && (
                      <>
                        <div className={styles2.linkswrapper}>
                          <Link to={`../../ressources/top40personalities`}>
                            40 PERSONNALITÉS QUI N’ONT PAS ABANDONNE MALGRÉ LES
                            ÉCHECS ET LES DIFFICULTÉS
                          </Link>
                        </div>
                        <div className="pb-2" />
                      </>
                    )}

                    {resourceData?.data[0]?.description5 && (
                      <p
                        style={{
                          color: resourceId === "43" ? "#01989f" : "",
                          marginTop: "-15px",
                        }}
                      >
                        {resourceData?.data[0]?.description5}
                      </p>
                    )}

                    {["46"].includes(resourceId) && resourceData?.topIcon3 && (
                      <div className={styles.topIconClass}>
                        <div className={styles.iconWrappPng}>
                          <img src={`${siteUrl}${resourceData?.topIcon3}`} />
                        </div>
                      </div>
                    )}

                    {resourceData?.data[0]?.description6 && (
                      <p>{resourceData?.data[0]?.description6}</p>
                    )}

                    {["46"].includes(resourceId) && resourceData?.topIcon4 && (
                      <div className={styles.topIconClass}>
                        <div className={styles.iconWrappPng}>
                          <img src={`${siteUrl}${resourceData?.topIcon4}`} />
                        </div>
                      </div>
                    )}

                    {resourceData?.data[0]?.description7 && (
                      <p>{resourceData?.data[0]?.description7}</p>
                    )}

                    {resourceData?.data[0]?.description8 && (
                      <p>{resourceData?.data[0]?.description8}</p>
                    )}

                    {resourceData?.data[0]?.bold && (
                      <h4 className={styles2.heading}>
                        {resourceData?.data[0]?.bold}
                      </h4>
                    )}

                    {resourceId === "45" && (
                      <div className="pb-2" />
                    )}
                  </div>

                  {/* icons */}

                  <div className={styles.icons}>
                    {/* share resource */}
                    <div
                      className={styles.favStarIcon}
                      onClick={() => handleFavoriteClick(resourceData?._id)}
                    >
                      {resourceData?.isFavourite ? (
                        <img
                          src={favorite2}
                          alt="logo"
                          className={styles.icon}
                          width={22}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      ) : (
                        <img
                          src={favorite}
                          alt="logo"
                          className={styles.icon}
                          width={22}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )}
                    </div>

                    <Dropdown
                      overlay={
                        <Menu className={styles.dropdownMenu}>
                          {items.map((item) => (
                            <Menu.Item
                              key={item.key}
                              onClick={() => item.onClick && item.onClick()}
                            >
                              {item.render
                                ? item.render(resourceData?._id)
                                : item.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={share}
                          alt="logo"
                          className={styles.icon}
                          width={22}
                          height={22}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </a>
                    </Dropdown>
                  </div>

                  {/* reactions */}

                  <div className={styles.reactionsWrapper}>
                    {reactionsArray.map((reaction, index) => {
                      return (
                        <>
                          <div className={styles.tooltipContainer}>
                            <button
                              onClick={() => {
                                handleReactionClick(resourceData);
                              }}
                              className={` ${resourceData?.isLiked ? styles.activeReaction : ""
                                } ${styles.reaction}`}
                              data-tooltip={reaction.title}
                            >
                              <img
                                src={reaction.emogi}
                                alt="like"
                                width={reaction.width}
                                height={reaction.height}
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </button>
                            <span className={styles.tooltipText}>
                              {reaction.title}
                            </span>
                            <p>
                              <span>{resourceData?.likesCount}</span>
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {loading ? null : (
              <>
                {!["46"].includes(resourceId) && resourceData?.topIcon2 && (
                  <div className={styles.topIconClass}>
                    <div className={styles.iconWrappPng}>
                      <img src={`${siteUrl}${resourceData?.topIcon2}`} />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      {/* mapping data */}
      {["28", "35", "36"].includes(resourceId) && (
        <>
          <div className={styles.resourceListWrapper}>
            <div className={styles.resourceDetailJoin}>
              {resourceData?.data[0]?.list?.map((listItem, index) => {
                return (
                  <div
                    className={styles.resource3listHeadingWrapper}
                    key={listItem.id}
                  >
                    <div
                      className={styles.top}
                      style={{
                        paddingBottom:
                          resourceId === "28" && (index === 1 || index === 11)
                            ? "20px"
                            : "",
                      }}
                    >
                      <div className={styles.circleGreen} />
                      <h4 className={styles.resourceDetailHeading}>
                        {listItem.heading}
                      </h4>
                    </div>
                    <div className={styles.bottom}>
                      <div className={styles.details}>
                        {listItem.bold1 && (
                          <p className="pt-2">
                            <strong className={` ${styles.bold}`}>
                              {listItem.bold1}
                            </strong>
                          </p>
                        )}
                        {listItem.bold2 && (
                          <p>
                            <strong className={` ${styles.bold}`}>
                              {listItem.bold2}
                            </strong>
                          </p>
                        )}
                        <div className={styles.descriptionWrapper}>
                          {listItem.description && (
                            <p className={styles.description}>
                              {listItem.description}
                            </p>
                          )}
                        </div>

                        {listItem.point1 && (
                          <>
                            <div className={styles.descriptionPointsWrapp}>
                              <span
                                style={{
                                  height: "1px",
                                  width: "5%",
                                  background: "black",
                                  paddingLeft: "10px",
                                }}
                              />
                              {listItem.point1 && (
                                <p className={styles.descriptionPoints}>
                                  {listItem.point1}
                                </p>
                              )}
                            </div>
                            <div className={styles.descriptionPointsWrapp}>
                              <span
                                style={{
                                  height: "1px",
                                  width: "5%",
                                  background: "black",
                                  paddingLeft: "10px",
                                }}
                              />
                              {listItem.point2 && (
                                <p className={styles.descriptionPoints}>
                                  {listItem.point2}
                                </p>
                              )}
                            </div>
                            <div className={styles.descriptionPointsWrapp}>
                              <span
                                style={{
                                  height: "1px",
                                  width: "5%",
                                  background: "black",
                                  paddingLeft: "10px",
                                }}
                              />
                              {listItem.point3 && (
                                <p className={styles.descriptionPoints}>
                                  {listItem.point3}
                                </p>
                              )}
                            </div>
                            <div className={styles.descriptionPointsWrapp}>
                              <span
                                style={{
                                  height: "1px",
                                  width: "5%",
                                  background: "black",
                                  paddingLeft: "10px",
                                }}
                              />
                              {listItem.point4 && (
                                <p className={styles.descriptionPoints}>
                                  {listItem.point4}
                                </p>
                              )}
                            </div>
                            <div className={styles.descriptionPointsWrapp}>
                              <span
                                style={{
                                  height: "1px",
                                  width: "5%",
                                  background: "black",
                                  paddingLeft: "10px",
                                }}
                              />
                              {listItem.point5 && (
                                <p className={styles.description}>
                                  {listItem.point5}
                                </p>
                              )}
                            </div>
                          </>
                        )}

                        <div className={styles.descriptionWrapper}>
                          {listItem.description2 && (
                            <p className={styles.description}>
                              {listItem.description2}
                            </p>
                          )}
                        </div>

                        {listItem.bold && (
                          <p className="pb-3">
                            <strong className={` ${styles.bold}`}>
                              {listItem.bold}
                            </strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              {!["28"].includes(resourceId) && <>
                {/* icons */}

                <div className={styles.icons}>
                  {/* share resource */}
                  <div
                    className={styles.favStarIcon}
                    onClick={() => handleFavoriteClick(resourceData?._id)}
                  >
                    {resourceData?.isFavourite ? (
                      <img
                        src={favorite2}
                        alt="logo"
                        className={styles.icon}
                        width={22}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : (
                      <img
                        src={favorite}
                        alt="logo"
                        className={styles.icon}
                        width={22}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}
                  </div>

                  <Dropdown
                    overlay={
                      <Menu className={styles.dropdownMenu}>
                        {items.map((item) => (
                          <Menu.Item
                            key={item.key}
                            onClick={() => item.onClick && item.onClick()}
                          >
                            {item.render
                              ? item.render(resourceData?._id)
                              : item.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <img
                        src={share}
                        alt="logo"
                        className={styles.icon}
                        width={22}
                        height={22}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </a>
                  </Dropdown>
                </div>

                {/* reactions */}

                <div className={styles.reactionsWrapper}>
                  {reactionsArray.map((reaction, index) => {
                    return (
                      <>
                        <div className={styles.tooltipContainer}>
                          <button
                            onClick={() => {
                              handleReactionClick(resourceData);
                            }}
                            className={` ${resourceData?.isLiked ? styles.activeReaction : ""
                              } ${styles.reaction}`}
                            data-tooltip={reaction.title}
                          >
                            <img
                              src={reaction.emogi}
                              alt="like"
                              width={reaction.width}
                              height={reaction.height}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </button>
                          <span className={styles.tooltipText}>
                            {reaction.title}
                          </span>
                          <p>
                            <span>{resourceData?.likesCount}</span>
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>}

            </div>


          </div>

          <p className="pt-3">{resourceData?.data[0]?.description}</p>
        </>
      )}

      {["13"].includes(resourceId) && (
        <div
          className={styles2.nextButton}
          onClick={() => {
            navigate(
              `/ressources/details/2?resourceId=${JSON.parse(resourceId) + 1}`
            );
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}
      {/* move to next point  */}
      {[
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "48",
        "49",
      ].includes(resourceId) && (
          <div
            className={styles2.nextButton}
            onClick={() => {
              navigate(
                `/ressources/details4/2?resourceId=${JSON.parse(resourceId) + 1}`
              );
            }}
          >
            <p>SUIVANT</p>
            <ReactSVG src={arrowBackGray} />
          </div>
        )}
      {/* move to step to next point */}
      {["36", "47"].includes(resourceId) && (
        <div
          className={styles2.nextButton}
          onClick={() => {
            navigate(
              `/ressources/details4/2?resourceId=${JSON.parse(resourceId) + 2}`
            );
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}

      <FriendsListModal
        toggleState={friendsListModal}
        handleToggle={toggleFriendsListModal}
        resourceLink={pathname}
      />
    </div>
  );
};

export default ResourcesDetails4;
