import React, { useState, useEffect } from "react";
import CustomInput from "../../CustomInput";
import { Select, Spin, message } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import { ApiCall } from "../../../config/apiCall";
import { useAuth } from "../../../context/AuthContext";
import CustomTextArea from "../../CustomTetxArea";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";
const Video = ({
  handleResourceInputChange,
  handleResourceInputOnBlure,
  videoUrlError,
  resourceInputState,
  characterCount,
  errorMessage,
  setErrorMessage,
}) => {
  const { token } = useAuth();
  const [videosList, setVideosList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowAddQuoteButton, setIsShowAddQuoteButton] = useState(true);
  const [searchedVideos, setSearchedVideos] = useState("");

  console.log("resourceInputState", resourceInputState);

  useEffect(() => {
    async function getAllVideos() {
      setLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `ressource-video/allListing`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setVideosList(response?.response?.data);
          setLoading(false);
        }
        console.log("response", response);
      } catch (error) {
        message.error("Connection Error");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    getAllVideos();
  }, []);

  async function getVideos(e) {
    setSearchedVideos(e);
    setLoading(true);
    const url = e
      ? `ressource-video/filterVideo/${e}`
      : `ressource-video/allListing`;
    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setVideosList(response?.response?.data);
        setLoading(false);
      }
    } catch (error) {
      message.error("Connection Error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  let allVideos = videosList?.map((qoute) => ({
    value: qoute?._id,
    label: qoute?.videotitle,
    acteur1: qoute?.acteur1,
    videourl: qoute?.videourl,
  }));

  const videoChangeHandler = (value) => {
    const name = allVideos?.filter((el) => el.value === value)[0]?.label;
    const acteur1 = allVideos?.filter((el) => el.value === value)[0]?.acteur1;
    const videourl = allVideos?.filter((el) => el.value === value)[0]?.videourl;
    setIsShowAddQuoteButton(true);
    setErrorMessage("");
    handleResourceInputChange({
      target: { name: "videoId", value },
    });
    handleResourceInputChange({
      target: { name: "videotitle", value: name },
    });
    handleResourceInputChange({
      target: { name: "acteur1", value: acteur1 },
    });
    handleResourceInputChange({
      target: { name: "videourl", value: videourl },
    });
  };

  const setNewVideo = (value) => {
    setIsShowAddQuoteButton(false);
    allVideos?.push({ label: value, value: value });
    setVideosList((prev) => [
      ...prev,
      { _id: value, videotitle: value, acteur1: "" },
    ]);
    handleResourceInputChange({
      target: { name: "videotitle", value: value },
    });
    handleResourceInputChange({
      target: { name: "videoId", value: value },
    });
    handleResourceInputChange({
      target: { name: "acteur1", value: "" },
    });
    handleResourceInputChange({
      target: { name: "videourl", value: "" },
    });
  };
  return (
    <div className={styles.videoWrapper}>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre de la vidéo<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allVideos}
        onSearch={(e) => getVideos(e)}
        placeholder="Titre de la vidéo ..."
        selectedValue={resourceInputState?.videoId}
        handleOptionSelect={(value) => {
          videoChangeHandler(value);
        }}
        setNewOption={() => setNewVideo(searchedVideos)}
        allowNewOption={true}
        newOptionTitle="+ Ajouter cette vidéo"
        isLoading={loading}
      />
      {/* <CustomInput
        label="Titre de la vidéo"
        type="text"
        name="videotitle"
        emogie={false}
        value={resourceInputState.videotitle}
        mendatory="*"
        placeHolder="Titre de la vidéo ..."
        handleOnChange={handleResourceInputChange}
      /> */}
      {errorMessage == "Veuillez sélectionner le titre de la vidéo." && (
        <div className="errorMessage">{errorMessage}</div>
      )}
      <CustomInput
        label="Auteur de la vidéo"
        type="text"
        name="acteur1"
        emogie={false}
        value={resourceInputState.acteur1}
        placeHolder="Auteur de la vidéo ..."
        handleOnChange={handleResourceInputChange}
      />

      {/* <CustomInput
        label="Site de la vidéo"
        type="text"
        name="videourl"
        placeHolder="Url de la vidéo ..."
        handleOnChange={handleResourceInputChange}
        handleOnBlure={handleResourceInputOnBlure}
      /> */}
      <div className={styles.CustomInput}>
        <label>Site de la vidéo</label>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Url de la vidéo ..."
            id="videourl"
            name="videourl"
            value={resourceInputState?.videourl || ""}
            onBlur={handleResourceInputOnBlure}
            onChange={handleResourceInputChange}
          />
        </div>
      </div>
      {videoUrlError && (
        <p className="errorMessage">
          Url invalide. Nous n’acceptons que les vidéos Youtube, Tiktok,
          Instagram et Facebook.
        </p>
      )}

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Video;
