import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Dropdown, Menu, message } from "antd";
import { Helmet } from "react-helmet";
import { MetaHeadEmbed } from "@phntms/react-share";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { useNavigate, useLocation } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import styles from "../../assets/styles/DisplayStories.module.scss";
import {
  faCaretRight,
  faCopy,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  like,
  meToo,
  wow,
  beStrongHug,
  goodWriterGold,
  previewLogo,
  favorite,
  commentsIcon,
  share,
  report,
} from "../../assets";
import { siteUrl } from "../../environment";
import StoryLoader from "../../components/UI/StoryLoader";
import { getImageByValue } from "../../components/stories/GetResourceIcons";
import DisplayComments from "../../components/comments/DisplayComments";

const reactionsArray = [
  {
    emogi: like,
    value: "like",
    selected: false,
    id: 1,
    height: 25,
    width: 25,
  },
  {
    emogi: meToo,
    value: "meToo",
    selected: false,
    id: 2,
    height: 25,
    width: 25,
  },
  {
    emogi: beStrongHug,
    value: "beStrongHug",
    selected: false,
    id: 3,
    height: 27,
    width: 25,
  },
  {
    emogi: wow,
    value: "wow",
    selected: false,
    id: 4,
    height: 30,
    width: 25,
  },
  {
    emogi: goodWriterGold,
    value: "goodWriterGold",
    selected: false,
    id: 5,
    height: 25,
    width: 25,
  },
];
const monthNames = [
  "jan",
  "fév",
  "mar",
  "avril",
  "mai",
  "juin",
  "juil",
  "août",
  "sept",
  "oct",
  "nov",
  "déc",
];
const loginUserId = localStorage.getItem("userId");
const MAX_LENGTH = 100;

const StoryPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const commentId = searchParams.get("commentId");
  const {
    token,
    loginModal,
    setLoginModal,
    getSearchedUserProfile,
    getUserStories,
    singleUserStoryCount,
  } = useAuth();
  const navigate = useNavigate();
  const [singleStory, setsingleStory] = useState(null);
  const [loading, setloading] = useState(true);
  const [reactions, setReactions] = useState({});
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [resourceModalOpen, setresourceModalOpen] = useState(false);
  const [beforeAfterModalOpen, setBeforeAfterModalOpen] = useState(false);
  const [allComments, setallComments] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [loadingComments, setloadingComments] = useState(false);

  const items = [
    {
      label: "Copy link",
      key: "0",
      onClick: () => {
        const storyLink = `${siteUrl}/stories/${singleStory?._id}/${singleStory?.title}`;
        navigator.clipboard?.writeText(storyLink);
        message.success("Story link copied to clipboard!");
      },
      render: () => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: () => {
        const storyLink = `${siteUrl}/stories/${singleStory?._id}/${singleStory?.title}`;
        return (
          <FacebookShareButton url={storyLink} quote={singleStory?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
              </svg>
            </a>
          </FacebookShareButton>
        );
      },
    },
    {
      label: "WhatsApp",
      key: "2",
      render: () => {
        const storyLink = `${siteUrl}/stories/${singleStory?._id}/${singleStory?.title}`;
        return (
          <WhatsappShareButton url={storyLink} title={singleStory?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
              </svg>
            </a>
          </WhatsappShareButton>
        );
      },
    },
    {
      label: "Twitter",
      key: "3",
      render: () => {
        const storyLink = `${siteUrl}/stories/${singleStory?._id}/${singleStory?.title}`;
        return (
          <TwitterShareButton url={storyLink} title={singleStory?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </TwitterShareButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (token) {
      getStoryDetails();
    }
  }, [token, id]);

  const getStoryDetails = async () => {
    try {
      const response = await ApiCall({
        params: {},
        route: "story/detailStory/" + id,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status == 200) {
        setsingleStory(response.response);
      }

      console.log("SingleStory", response);
    } catch (error) {
      setloading(false);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (token && commentId) {
      getStoryComments(singleStory?._id, singleStory?.commentId);
      setShowComments(true);
    }
  }, [token, commentId, singleStory]);

  // get story comments
  const getStoryComments = async (storyId, commentId) => {
    if (token) {
      setloadingComments(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "comment/get-comment-list/" + storyId,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setallComments(response.response);

          // Scroll to the specific comment
          if (commentId) {
            const commentElement = document.getElementById(commentId);
            if (commentElement) {
              commentElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }
        }
        console.log("commentsResp", response);
      } catch (error) {
        setloadingComments(false);
        // Handle error if necessary
      } finally {
        setloadingComments(false);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  // Function to handle reaction click
  const handleReactionClick = async (
    storyId,
    selectedReactionIndex,
    story,
    status
  ) => {
    if (token) {
      try {
        const selectedReaction = reactionsArray[selectedReactionIndex].id;

        const response = await ApiCall({
          params: {},
          route: `story/add-story-reaction/${storyId}&${
            status ? 0 : selectedReaction
          }`,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setsingleStory((story) => {
            if (story?._id === response?.response?.data?._id) {
              return response?.response?.data;
            } else {
              return story;
            }
          });

          setReactions((prevReactions) => ({
            ...prevReactions,
            [storyId]: selectedReactionIndex,
          }));
        } else {
          message.error(response.response.error[0]?.message);
        }
      } catch (error) {
        message.error(error);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  const handleFavoriteClick = async (storyId) => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: `story/addToFavourities/` + storyId,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          getStoryDetails();
        }
      } catch (error) {}
    } else {
      setLoginModal(!loginModal);
    }
  };

  const handleUserClick = (id) => {
    // console.log("loginUserId", loginUserId);
    // console.log("id", id);
    if (loginUserId === id) {
      navigate("/profile");
    } else {
      getSearchedUserProfile(id);
      getUserStories(id, singleUserStoryCount);
      navigate("/userProfile");
    }
  };

  const handleResourceModal = (index) => {
    setSelectedStoryIndex(0);
    setresourceModalOpen(!resourceModalOpen);
  };

  const handleBeforeAfterModal = (index) => {
    setSelectedStoryIndex(0);
    setBeforeAfterModalOpen(!beforeAfterModalOpen);
  };

  // For less/more story description
  //   const [showMoreDescription, setShowMoreDescription] = useState(
  //     Array(stories?.length).fill(false)
  //   );

  //   // show more/less story content
  //   const toggleShowMore = (index) => {
  //     const newShowMoreDescription = [...showMoreDescription];
  //     newShowMoreDescription[index] = !newShowMoreDescription[index];
  //     setShowMoreDescription(newShowMoreDescription);
  //   };

  //   const showMore = showMoreDescription[index];
  const date = new Date(singleStory?.createdAt);
  let DateFormate = "";
  const currentDate = new Date();
  if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  ) {
    const timeDifferenceInMilliseconds = currentDate - date;
    const timeDifferenceInMinutes = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );
    if (timeDifferenceInMinutes < 60) {
      DateFormate = `il y a ${timeDifferenceInMinutes} min`;
    } else {
      const hours = Math.floor(timeDifferenceInMinutes / 60);
      DateFormate = `il y a ${hours} h`;
    }
  } else if (date.getFullYear() === currentDate.getFullYear()) {
    DateFormate = `${date.getDate()} ${monthNames[date.getMonth()]}`;
  } else {
    DateFormate = `${date.getDate()} ${
      monthNames[date.getMonth()]
    } ${date.getFullYear()} `;
  }

  const selectedReactionIndex = reactions[singleStory?._id];

  const imageUrl = getImageByValue(
    singleStory?.ressource?.complete?.resourcetype
  );

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  let selectedIndex = -1;

  return (
    <div>
      {/* <MetaHeadEmbed
        render={(meta) => <Helmet>{meta}</Helmet>}
        siteTitle="PsyUP"
        pageTitle=""
        titleTemplate="[siteTitle]"
        description={singleStory?.writter?.fullname}
        baseSiteUrl="http://44.203.197.155"
        pagePath={`stories/${id}`}
        keywords={["psyup", "citron", "psyup.fr"]}
        imageUrl={previewLogo}
        imageAlt="PsyUP logo."
        twitter={{
          cardSize: "large",
          siteUsername: `${singleStory?.writter?.username}`,
          creatorUsername: `${singleStory?.writter?.username}`,
        }}
      /> */}
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>PsyUP story</title>
        <link rel="canonical" href="http://psyup.fr/" />
        <meta name="description" content={singleStory?.writter?.fullname} />

        <meta property="og:title" content={singleStory?.writter?.fullname} />

        <meta property="og:url" content="https://psyup.fr/" />

        <meta
          property="og:description"
          content={singleStory?.writter?.fullname}
        />

        <meta property="og:image" content={previewLogo} />

        <meta property="og:type" content="website" />
      </Helmet> */}

      {token && (
        <>
          {loading ? (
            <StoryLoader />
          ) : (
            <>
              <div className={`${styles.storyOuterContainer}`}>
                <div className={styles.DisplayStoriesContainer}>
                  <div className={styles.storyHeader}>
                    <div className={styles.storyHeaderLeft}>
                      <p className={styles.theme}>{singleStory?.theme?.name}</p>
                      <div className={styles.date}>
                        {singleStory?.isAnonym ? (
                          <p className={`${styles.userAnonym}`}>
                            {/* Par {singleStory?.writter?.fullname?.charAt(0)} */}
                            Par **** ,
                          </p>
                        ) : (
                          <p
                            className={
                              !singleStory?.writter?.isAdmin
                                ? styles.userName
                                : styles.userAnonym
                            }
                            onClick={() => {
                              if (!singleStory?.writter?.isAdmin)
                                handleUserClick(singleStory?.writter?._id);
                            }}
                          >
                            Par{" "}
                            <span className={styles.userNameLink}>
                              {singleStory?.writter?.username},
                            </span>
                          </p>
                        )}

                        <p className={`${styles.dateP} ps-1`}>{DateFormate}</p>
                      </div>
                    </div>
                    <div className={styles.storyHeaderRight}>
                      <h2 className={styles.heading}>{singleStory?.title}</h2>
                    </div>
                  </div>
                  <div className={styles.storyBody}>
                    <p>{singleStory?.content}</p>
                    {/* <p>
            {showMore
              ? singleStory?.content
              : singleStory?.content.slice(0, MAX_LENGTH) + "..."}
          </p>
          <div className={styles.showMore}>
            <button onClick={() => toggleShowMore(index)}>
              {!showMore ? "Lire la suite" : "Voir moins"}{" "}
            </button>
          </div> */}

                    {/* first storyline  */}
                    {/* {!singleStory?.professionalConsulted?.fullname &&
            !singleStory?.ressource?.complete &&
            (!singleStory?.physicalTransformation?.beforePictureUrl ||
              !singleStory?.physicalTransformation?.afterPictureUrl) ? (
              <div className="py-0"></div>
            ) : (
              <hr />
            )} */}

                    {/* <div className={styles.prefessionalResourcesWrapper}>
              {singleStory?.professionalConsulted?.fullname && (
                <div>
                  <p>Professionnel</p>
                  <h6>
                    <span className="font-bold text-black">
                      {
                        singleStory?.professionalConsulted?.fullname
                      }
                    </span>
                  </h6>
                </div>
              )}

              {singleStory?.ressource?.complete && (
                <div onClick={() => handleResourceModal()}>
                  <p className={styles.ressource}>Ressource</p>
                  <img
                    src={imageUrl}
                    alt="img"
                    className={styles.icon}
                    width={40}
                    height={40}
                  />
                </div>
              )}
              {(singleStory?.physicalTransformation?.beforePictureUrl ||
                singleStory?.physicalTransformation?.afterPictureUrl) && (
                <div onClick={() => handleBeforeAfterModal()}>
                  <p>Avant /Après</p>
                  <img
                    src={beforeAfter}
                    alt="logo"
                    className={styles.icon}
                    width={35}
                    height={40}
                  />
                </div>
              )}
            </div> */}

                    {/* second storyline */}
                    {/* {!singleStory?.professionalConsulted?.fullname &&
            !singleStory?.ressource?.complete &&
            (!singleStory?.physicalTransformation?.beforePictureUrl ||
              !singleStory?.physicalTransformation?.afterPictureUrl) ? (
              <div className="py-0"></div>
            ) : (
              <hr />
            )} */}

                    <div className={styles.details}>
                      <div className={styles.icons}>
                        <div
                          className={styles.favStarIcon}
                          onClick={() => handleFavoriteClick(singleStory._id)}
                        >
                          {singleStory.isFavourite ? (
                            <div className={styles.icon}>
                              <FontAwesomeIcon
                                icon={faStar}
                                style={{ color: "#F5D003" }}
                              />
                            </div>
                          ) : (
                            <img
                              src={favorite}
                              alt="logo"
                              className={styles.icon}
                              width={22}
                              height={20}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          )}
                        </div>
                        <div
                          className={styles.commentsWrapper}
                          onClick={() => {
                            //   setShowCommentsIndex(index);
                            //   getStoryComments(singleStory?._id);
                          }}
                        >
                          <img
                            src={commentsIcon}
                            className={styles.icon}
                            alt="comments"
                            width={20}
                            height={20}
                          />
                          <p>({singleStory?.comments_count})</p>
                        </div>

                        <Dropdown
                          overlay={
                            <Menu className={styles.dropdownMenu}>
                              {items.map((item) => (
                                <Menu.Item
                                  key={item.key}
                                  onClick={() => item.onClick && item.onClick()}
                                >
                                  {item.render ? item.render() : item.label}
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <img
                              src={share}
                              alt="logo"
                              className={styles.icon}
                              width={20}
                              height={20}
                            />
                          </a>
                        </Dropdown>
                        <img
                          src={report}
                          alt="logo"
                          className={styles.icon}
                          width={20}
                          height={20}
                        />
                      </div>
                      {/* {singleStory?.professionalConsulted && (
                        <p>
                          Professionnel :{" "}
                          <span className="font-bold text-black">
                            {singleStory?.professionalConsulted?.fullname}
                          </span>
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div className={styles.reactionsWrapper}>
                    {reactionsArray.map((reaction, index) => {
                      selectedIndex = reactions[singleStory?._id];
                      return (
                        <div>
                          <button
                            onClick={() => {
                              handleReactionClick(
                                singleStory?._id,
                                index,
                                singleStory,
                                selectedIndex === index ||
                                  (!selectedIndex &&
                                    singleStory?.reactionId - 1 === index)
                              );
                            }}
                            className={` ${
                              singleStory?.reactionId - 1 === index
                                ? styles.activeReaction
                                : ""
                            } ${styles.reaction}`}
                            data-tooltip={reaction.title}
                          >
                            <img
                              src={reaction.emogi}
                              alt="like"
                              width={reaction.width}
                              height={reaction.height}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </button>
                          <span className={styles.tooltipText}>
                            {reaction.title}
                          </span>
                        </div>
                      );
                    })}

                    {singleStory?.totalReactions > 0 && (
                      <button>
                        <span>
                          {singleStory?.totalReactions}
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className={styles.fontawesome}
                          />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {showComments && token && (
                <DisplayComments
                  storyId={singleStory?._id}
                  allComments={allComments}
                  setallComments={setallComments}
                  loadingComments={loadingComments}
                  getStoryComments={getStoryComments}
                  // getStoryMostreleventComments={getStoryMostreleventComments}
                />
              )}
            </>
          )}
        </>
      )}
      {/* ) : token && <h2>Login first</h2>} */}
    </div>
  );
};

export default StoryPage;
