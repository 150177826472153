import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/resources/Resources.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  expand,
  relax,
  arrowBackGray,
  nextarrowRed,
  storyEdit,
} from "../../assets";
import RecommendedResourceModal from "./RecommendedResourceModal";
import StoryLoader from "../../components/UI/StoryLoader";

import { resources } from "./resourcesData";
import { ReactSVG } from "react-svg";
import { siteUrl } from "../../environment";
import { useAuth } from "../../context/AuthContext";
import SearchableSelect from "../../components/SearchableDropdown/SearchableDropdown";
import { ApiCall } from "../../config/apiCall";
import { Spin, message } from "antd";

const ResourceDetails = () => {
  const { allThemes, token, userId, userInfo } = useAuth();
  let { typeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  localStorage.setItem("resourceId", resourceId);
  const [recommendResourceModal, setRecommendResourceModal] = useState(false);

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const [topResources, setTopResources] = useState([]);
  const [topResourcesLoading, setTopResourcesLoading] = useState(false);
  const [updateResourceLoading, setUpdateResourceLoading] = useState(false);

  const [activeTheme, setActiveTheme] = useState({ name: "", value: "" });
  const [activeResource, setActiveResource] = useState({ name: "", value: "" });
  const [selectedTheme, setSelectedTheme] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editedResourceData, seteditedResourceData] = useState([]);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const getTopResources = async () => {
    setTopResourcesLoading(true);
    if (activeTheme?.value !== "") {
      try {
        const response = await ApiCall({
          params: { themeid: activeTheme.value },
          route: `recommend-resource/recommend-resource-listing/${selectedResource?.resourceType}`,
          verb: "get",
          baseurl: true,
          token: token ? token : "",
        });

        if (response.status === 200) {
          setTopResources(response?.response?.data?.data);
        }
      } catch (error) {
        setTopResourcesLoading(false);
        message.error("Connection Error");
      } finally {
        setTopResourcesLoading(false);
      }
    }
  };

  const themesOptions = allThemes
    ? allThemes.map((theme) => {
        return {
          id: theme._id,
          label: theme.name,
          value: theme._id,
          poster: theme.poster,
        };
      })
    : [];

  const topResourcesListWithLabel = topResources
    ? topResources.map((option) => {
        return {
          value:
            option?.fields?.value ||
            option?.fields?._id ||
            option?.fields?.livreId ||
            option?.fields?.mosiqueId ||
            option?.fields?.filmetitle ||
            option?.fields?.podcastId,
          label:
            option.label ||
            option?.fields?.filmetitle ||
            option?.fields?.mosiquetitle ||
            option?.fields?.podcasttitle ||
            option?.fields?.quote ||
            option?.fields?.influenceurtitle ||
            option?.fields?.videotitle ||
            option?.fields?.livretitle,
        };
      })
    : [];

  const handletopResourcesListSelect = (value, index) => {
    const name = topResourcesListWithLabel?.filter(
      (el) => el.value === value
    )[0]?.label;
    const id = topResourcesListWithLabel?.filter((el) => el.value === value)[0]
      ?.value;
    const foundObject = topResources.find(
      (el) =>
        (el?.fields?.value || el?.fields?._id || el?.fields?.livreId) == value
    );

    seteditedResourceData(
      editedResourceData?.map((data, idx) => {
        if (index === idx) {
          return {
            ...data,
            title: name,
            recommendCount: foundObject?.fields?.recommendCount,
            videourl: foundObject?.fields?.videourl,
            link: foundObject?.fields?.link,
            id: id,
          };
        } else {
          return data;
        }
      })
    );

    setActiveResource({ name: name, value: value });
  };

  const handeltopResourcesListClear = () => {};

  // useEffect(() => {
  //   if (themesOptions && themesOptions.length > 0) {
  //     setSelectedTheme(themesOptions[0].value);
  //   }
  // }, [themesOptions]);

  // useEffect(() => {
  //   setActiveTheme(selectedTheme);
  // }, [selectedTheme]);

  const selectedResource = resources
    .find((resource) => resource.id === typeId)
    ?.resourceList?.find((resource) => resource.id === resourceId);

  const [selectedResourceBox, setSelectedResourceBox] = useState(true);
  const [anotherSelectedResourceBox, setAnotherSelectedResourceBox] =
    useState(false);

  // get data

  const getData = async () => {
    setDataLoading(true);

    let url;
    if (resourceId == "3" || resourceId == "4" || resourceId == "7") {
      url = `recommend-resource/recommend-resource-listing/${selectedResource?.resourceType}`;
    } else {
      if (token) {
        url = `other-resources/otherResourcesPoint/${resourceId}`;
      } else {
        url = `other-resources/otherResourcesPoint-without-auth/${resourceId}`;
      }
    }

    try {
      const response = await ApiCall({
        params: { themeid: activeTheme?.value },
        route: url,
        verb: "get",
        baseurl: true,
        token: token ? token : "",
      });

      if (response.status === 200) {
        setData(response?.response?.data);
        let filteredData = response?.response?.data?.data?.map((data) => {
          return {
            id:
              data?.id || data?.fields?.influenceurId || data?.fields?.videoId,
            parentId: data?._id,
            title:
              data.title ||
              data?.fields?.influenceurtitle ||
              data?.fields?.videotitle ||
              data?.fields?.citationtitle ||
              data?.heading,
            logo: data?.logo,
            image: data?.image,
            description: data.description || data.acteur1 || data.author,
            description2: data.description2,
            points: data.points,
            link: data.link || data?.fields?.link,
            themeID: data.themeID,
            recommendCount: data.recommendCount || data?.fields?.recommendCount,
            coverPhoto: data?.coverPhoto,
            logoH: data?.logoStyles?.logoH,
            logoW: data?.logoStyles?.logoW,
          };
        });
        setFilteredData(filteredData);
      }

      // console.log("resp", response?.response?.data);
    } catch (error) {
      setDataLoading(false);
      message.error("Connection Error");
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [activeTheme, anotherSelectedResourceBox, resourceId]);

  // Edit resource

  const editResource = () => {
    setIsEditing(true);
    getTopResources();
    seteditedResourceData([...filteredData]);
  };

  const handleResourceChange = (event, index) => {
    const { name, value } = event.target;
    seteditedResourceData(
      editedResourceData?.map((data, idx) => {
        if (index === idx) {
          return { ...data, [name]: value };
        } else {
          return data;
        }
      })
    );
  };

  const saveResources = async () => {
    setUpdateResourceLoading(true);
    let url;
    if (
      ["3", "4", "7"].includes(resourceId)
        ? (url = `recommend-resource/update-recommend-resource`)
        : (url = `other-resources/updateResource/${resourceId}/${data?._id}`)
    )
      try {
        const response = await ApiCall({
          params: { themeid: activeTheme?.value, data: editedResourceData },
          route: url,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setIsEditing(false);
          getData();
          seteditedResourceData([]);
          message.success("Updated Successfully");
        }
        // console.log("resp", response);
      } catch (error) {
        setUpdateResourceLoading(false);
        message.error("Connection Error");
      } finally {
        setUpdateResourceLoading(false);
      }
  };

  const toggleRecommendedResourceModal = () => {
    setRecommendResourceModal(!recommendResourceModal);
  };

  const handleThemeSelect = (value) => {
    const name = themesOptions?.filter((el) => el.value === value)[0]?.label;

    setActiveTheme({ name: name, value: value });
  };

  const handelThemeClear = () => {
    setActiveTheme({});
  };

  const topIcon = `${siteUrl}${data?.topIcon}`;

  const handleCoverPhotoUpload = async (event, index) => {
    const file = event.target.files[0];

    const params = new FormData();
    params.append("image", file);

    setIsImageUploading(true);
    try {
      const response = await ApiCall({
        params: params,
        route: `other-resources/upload-image`,
        verb: "post",
        baseurl: true,
        token: token ? token : "",
      });

      if (response.status === 200) {
        if (response?.response?.image) {
          seteditedResourceData((prev) =>
            prev?.map((el, idx) => {
              if (idx === index) {
                return {
                  ...el,
                  coverPhoto: response?.response?.image,
                };
              } else {
                return el;
              }
            })
          );
        }
        setIsImageUploading(false);
      }
    } catch (error) {
      setIsImageUploading(false);
    } finally {
      setIsImageUploading(false);
    }
  };

  console.log("filteredData", filteredData);
  return (
    <div className={styles.resourcesContainer}>
      <div
        className={styles.backButton}
        onClick={() => {
          navigate(`/ressources?resourceId=${resourceId}&typeId=${typeId}`);
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>
      <div className={styles.topContent}>
        <h2 className={styles.resourceDetailHeading}>Autres ressources</h2>

        {data?.description && (
          <p className={styles.description}>{data?.description}</p>
        )}

        {data?.description2 && (
          <p className={styles.description}>{data?.description2}</p>
        )}

        {data?.topIcon && resourceId !== "8" && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrapp}>
              <ReactSVG src={topIcon} />
            </div>
          </div>
        )}

        <h1 className={styles.resourceDetailHeading2}>{data?.titleInside}</h1>
      </div>

      {resourceId !== "8" &&
        resourceId !== "9" &&
        resourceId !== "10" &&
        resourceId !== "12" &&
        resourceId !== "14" && (
          <>
            {/* Resource boxs */}

            <div className={styles.resourceBoxes}>
              <div
                className={`${styles.resourceBox} ${
                  selectedResourceBox ? styles.selectedResourceBox : ""
                }`}
                onClick={() => {
                  setSelectedResourceBox(!selectedResourceBox);
                  if (resourceId === "2") {
                    setAnotherSelectedResourceBox(!anotherSelectedResourceBox);
                  }
                }}
              >
                <div className={styles.resourceIconWrapper}>
                  <img
                    src={
                      selectedResourceBox
                        ? selectedResource?.iconWhite
                        : selectedResource?.icon
                    }
                    className={`${
                      selectedResource?.recommendedModalName === "Podcast"
                        ? styles.resourceIconPodcast
                        : styles.resourceIcon
                    }`}
                    alt="resourceIcon"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
                <p className={styles.resourceDetailSubHeading}>
                  {selectedResource?.id == 2
                    ? "Voir le TOP 10 des films les plus recommandées par thèmes"
                    : selectedResource?.description}
                </p>
                {selectedResource?.subTitle && (
                  <p className={styles.resourceDetailSubTitle}>
                    {selectedResource?.subTitle}
                  </p>
                )}

                <div className={styles.icon}>
                  <img
                    src={selectedResourceBox ? relax : expand}
                    alt="resourceIcon"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
              </div>

              {selectedResource?.id == 2 && (
                <div
                  className={`${styles.resourceBox} ${
                    anotherSelectedResourceBox ? styles.selectedResourceBox : ""
                  }`}
                  onClick={() => {
                    setSelectedResourceBox(!selectedResourceBox);
                    setAnotherSelectedResourceBox(!anotherSelectedResourceBox);
                  }}
                >
                  <div className={styles.resourceIconWrapper}>
                    <img
                      src={
                        anotherSelectedResourceBox
                          ? selectedResource?.iconWhite
                          : selectedResource?.icon
                      }
                      className={`${styles.resourceIcon}`}
                      alt="resourceIcon"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <p className={styles.resourceDetailSubHeading}>
                    Voir le TOP 10 des séries les plus recommandées par thèmes
                  </p>
                  {selectedResource?.subTitle && (
                    <p className={styles.resourceDetailSubTitle}>
                      {selectedResource?.subTitle}
                    </p>
                  )}

                  <div className={styles.icon}>
                    <img
                      src={anotherSelectedResourceBox ? relax : expand}
                      alt="resourceIcon"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Resource dropdown */}

            <div className={styles.resourceDetailsDropdown}>
              <SearchableSelect
                placeholder="Choisissez un thème"
                options={themesOptions}
                selectedValue={activeTheme?.value}
                handleOptionSelect={handleThemeSelect}
                onCrossClick={handelThemeClear}
              />
            </div>
          </>
        )}

      {/* Edit resource button */}

      {userInfo?.isAdmin &&
        [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
        ].includes(resourceId) && (
          <div className={styles.editButtonWrapper}>
            <img
              title="Edit"
              onContextMenu={(e) => e.preventDefault()}
              src={storyEdit}
              alt="edit"
              className={styles.editIcon}
              onClick={() => editResource()}
            />
          </div>
        )}

      {/* Resource title */}

      {activeTheme?.value && (
        <h1 className={styles.title}>
          {anotherSelectedResourceBox ? data?.topHeadingTwo : data?.topHeading}
        </h1>
      )}

      {/* Resource list */}

      {isEditing ? (
        <Spin spinning={updateResourceLoading}>
          <div className={styles.resourceListWrapper}>
            <form>
              {editedResourceData?.map((resourceDetail, index) => {
                return (
                  <div
                    className={styles.resourcesInputs}
                    key={resourceDetail.id}
                  >
                    {["1", "2", "3", "4", "5", "6", "7"].includes(
                      resourceId
                    ) && (
                      <div className={styles.photoInputWrapp}>
                        <div className={styles.PhysicalInput}>
                          <Spin size="small" spinning={isImageUploading}>
                            <h3 className={styles.label}>Covor Photo</h3>
                            <div className={styles.physicalinputWrapper}>
                              <input
                                id={`coverPhoto${resourceDetail.id}_${index}`}
                                name="coverPhoto"
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleCoverPhotoUpload(e, index)
                                }
                              />
                            </div>
                            <label
                              htmlFor={`coverPhoto${resourceDetail.id}_${index}`}
                              className={styles.CustomizeInput}
                            >
                              {resourceDetail?.coverPhoto ? (
                                <p>{resourceDetail?.coverPhoto} </p>
                              ) : (
                                <p> Ajouter un fichier...</p>
                              )}
                            </label>
                          </Spin>
                        </div>
                        <div className={styles.photoWrapp}>
                          <img src={resourceDetail?.coverPhoto} />
                        </div>
                      </div>
                    )}

                    {["1", "2", "5", "6"].includes(resourceId) && (
                      <>
                        <h3 className={`${styles.label}`}>Choose resource</h3>
                        <SearchableSelect
                          placeholder="Choisissez une resource"
                          options={topResourcesListWithLabel}
                          selectedValue={
                            resourceDetail?.title || activeResource?.value
                          }
                          handleOptionSelect={(e) =>
                            handletopResourcesListSelect(e, index)
                          }
                          onCrossClick={handeltopResourcesListClear}
                        />
                        <p style={{ paddingBottom: "3px" }}></p>
                      </>
                    )}
                    <h3 className={styles.label}>Resource title</h3>
                    <input
                      type="text"
                      name="title"
                      value={resourceDetail?.title}
                      onChange={(e) => handleResourceChange(e, index)}
                      disabled={["1", "2", "3", "4", "5", "6", "7"].includes(
                        resourceId
                      )}
                      className={styles.input}
                    />

                    {!["1", "2", "3", "4", "5", "6", "7"].includes(
                      resourceId
                    ) && (
                      <>
                        <h3 className={styles.label}>Resource description</h3>
                        <input
                          type="text"
                          name="description"
                          value={resourceDetail?.description}
                          onChange={(e) => handleResourceChange(e, index)}
                          className={styles.input}
                        />
                      </>
                    )}

                    <h3 className={styles.label}>Resource link</h3>
                    <input
                      type="text"
                      name="link"
                      value={resourceDetail?.link}
                      onChange={(e) => handleResourceChange(e, index)}
                      className={styles.input}
                    />
                  </div>
                );
              })}
            </form>
            <div className={styles.buttonWrapper}>
              <div
                className={`${styles.button} ${styles.buttonCancle}`}
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </div>
              <div
                className={`${styles.button} ${styles.buttonSave}`}
                onClick={saveResources}
              >
                Save
              </div>
            </div>
          </div>
        </Spin>
      ) : (
        <>
          {(anotherSelectedResourceBox ||
            selectedResourceBox ||
            resourceId == "8" ||
            resourceId == "9" ||
            resourceId == "10" ||
            resourceId == "12" ||
            resourceId == "14") && (
            <div className={styles.resourceListWrapper}>
              {dataLoading ? (
                <>
                  <StoryLoader oneLine={true} />
                  <StoryLoader oneLine={true} />
                </>
              ) : (
                <>
                  {filteredData?.map((resourceDetail, index) => {
                    return (
                      <div
                        className={styles.resourceDetail}
                        key={resourceDetail.id + index}
                      >
                        <div className={styles.resourcelistHeadingWrapper}>
                          <div className={styles.top}>
                            <h4 className={styles.resourceDetailHeading}>
                              {resourceDetail?.title}
                            </h4>
                            {resourceDetail?.logo && (
                              <div
                                className={styles.iconWrapper}
                                style={{
                                  height: "100%",
                                  // width: "100%",
                                }}
                              >
                                <img
                                  style={{
                                    height: resourceDetail?.logoH,
                                    width: resourceDetail?.logoW,
                                  }}
                                  src={`${siteUrl}${resourceDetail?.logo}`}
                                  alt="logo"
                                />
                              </div>
                            )}
                          </div>
                          <div className={styles.bottom}>
                            <div className={styles.details}>
                              {["1", "2", "3", "4", "5", "6", "7"].includes(
                                resourceId
                              ) ? (
                                <div className={styles.descriptionWrapper}>
                                  {/* <p className={styles.description}>
                                    Recommandé par{" "}
                                    {resourceDetail?.recommendCount}
                                  </p> */}
                                </div>
                              ) : (
                                <div className={styles.descriptionWrapper}>
                                  {resourceId == "8" && (
                                    <p className={styles.symbol}>√</p>
                                  )}

                                  <p className={styles.description}>
                                    {resourceDetail?.description}
                                  </p>
                                </div>
                              )}

                              <div className={styles.descriptionWrapper}>
                                {resourceId == "8" &&
                                  resourceDetail?.description2 && (
                                    <p className={styles.symbol}>√</p>
                                  )}
                                {resourceDetail?.description2 && (
                                  <p className={styles.description}>
                                    {resourceDetail?.description2}
                                  </p>
                                )}
                              </div>

                              {resourceDetail?.points?.map((point) => {
                                return (
                                  <div
                                    className={styles.descriptionPointsWrapp}
                                  >
                                    <span />
                                    <p className={styles.descriptionPoints}>
                                      {point}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                            {/* {!resourceDetail?.image && (
                              <Link
                                to={resourceDetail?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  title="Open link"
                                  src={nextarrowRed}
                                  alt="next"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </Link>
                            )} */}
                          </div>

                          {resourceDetail?.coverPhoto && (
                            <div className={styles.coverPhotoView}>
                              <img
                                src={resourceDetail.coverPhoto}
                                alt="cover"
                              />
                            </div>
                          )}
                        </div>

                        {resourceDetail?.image && (
                          <div className={styles.imageContainer}>
                            <div className={styles.imageWrapper}>
                              <img
                                src={`${siteUrl}${resourceDetail?.image}`}
                                alt="logo"
                              />
                            </div>
                            <Link
                              to={resourceDetail?.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                title="Open link"
                                src={nextarrowRed}
                                alt="next"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </>
      )}

      {["1", "2", "3", "4", "5", "6", "7"].includes(resourceId) && (
        <>
          {/* Recommend resource button */}

          <div
            className={styles.recommendResourceBtn}
            onClick={() => {
              setRecommendResourceModal(!recommendResourceModal);
            }}
          >
            <p>Recommander une ressource</p>
          </div>

          <RecommendedResourceModal
            toggleState={recommendResourceModal}
            handleToggle={toggleRecommendedResourceModal}
            name={selectedResource?.recommendedModalName}
            type={selectedResource?.type}
            resourceType={selectedResource?.resourceType}
            title={selectedResource?.recommendedModalTitle}
            subTitle={selectedResource?.recommendedModalSubTitle}
          />
        </>
      )}

      {resourceId === "14" && (
        <p className={styles.description}>{data?.description3}</p>
      )}

      {!["10", "14"].includes(resourceId) && (
        <div
          className={styles.nextButton}
          onClick={() => {
            console.log("resourceId", resourceId);
            if (["11"].includes(resourceId)) {
              navigate(
                `/ressources/details2/2?resourceId=${JSON.parse(resourceId)}`
              );
            }
            navigate(`?resourceId=${JSON.parse(resourceId) + 1}`);
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}

      {["10"].includes(resourceId) && (
        <div
          className={styles.nextButton}
          onClick={() => {
            navigate(
              `/ressources/details2/2?resourceId=${JSON.parse(resourceId) + 1}`
            );
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}

      {["14"].includes(resourceId) && (
        <div
          className={styles.nextButton}
          onClick={() => {
            navigate(
              `/ressources/details4/2?resourceId=${JSON.parse(resourceId) + 2}`
            );
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}
    </div>
  );
};

export default ResourceDetails;
