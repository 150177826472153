import React, { useState, useRef } from "react";
import TextEditor from "../../../utils/TextEditor/TextEditor";
import { ApiCall } from "../../../config/apiCall";
import { useAuth } from "../../../context/AuthContext";
import { Spin, message } from "antd";

// tiny
import { Editor } from "@tinymce/tinymce-react";

const AddPersonaliities = () => {
  const { token, loginModal, setLoginModal } = useAuth();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      // console.log("Logs", editorRef.current.getContent());
      setContent(editorRef.current.getContent());
    }
  };

  console.log("content", content);
  const submit = async () => {
    const formData = new FormData();
    formData.append("data", content);

    if (content != null) {
      setLoading(true);
      try {
        const res = await ApiCall({
          route: `other-resources/add-personality`,
          token: token,
          verb: "put",
          params: formData,
        });

        if (res?.status === 200) {
          console.log("response", res);
          setContent(null);
          message.success("Added personality");
          setLoading(false);
        } else {
          console.log("error", res);
          setLoading(false);
        }
      } catch (e) {
        console.log("saga doctor add error -- ", e.toString());
      }
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Editor
          apiKey="o26kfl6dfoxj3yv3uverykywo6grt4y0ml5pt8t0hwarjnyq"
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            plugins:
              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
            editimage_cors_hosts: ["picsum.photos"],
            menubar: "file edit view insert format tools table help",
            toolbar:
              "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
            ai_request: (request, respondWith) =>
              respondWith.string(() =>
                Promise.reject("See docs to implement AI Assistant")
              ),
          }}
          onChange={log}
          initialValue=""
        />

        {loading ? (
          <Spin />
        ) : (
          <button
            style={{
              background: "#01989f",
              padding: "5px 10px",
              borderRadius: "5px",
              color: "white",
              marginTop: "10px",
            }}
            onClick={submit}
            type="submit"
          >
            Add Personality
          </button>
        )}
      </div>
    </div>
  );
};

export default AddPersonaliities;
