import React from "react";
import ButtonFilled from "../components/buttonFilled";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };
  return (
    <div>
      <h2 className="py-3 text-center">PAGE NOT FOUND</h2>
      <ButtonFilled
        title="Go to home page"
        textColr="white"
        handleClick={goHome}
      />
    </div>
  );
};

export default PageNotFound;
