import React, { useState, useEffect } from "react";
import CustomInput from "../../CustomInput";
import CustomTextArea from "../../CustomTetxArea";
import { Select, Spin } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import axios from "axios";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";

const Film = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
}) => {
  const [moviesList, setMoviesList] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.betaseries.com/movies/search",
        {
          params: {
            title: e.trim(),
          },
          headers: {
            "X-BetaSeries-Key": process.env.REACT_APP_BETASERIES_API_KEY,
            Authorization: `Bearer ${process.env.REACT_APP_BETASERIES_OAUTH_KEY}`,
          },
        }
      );

      setMoviesList(response.data.movies);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // console.log("moviesList", moviesList);

  let allMovies = moviesList?.map((movie) => ({
    value: movie.title,
    label: movie.title,
    director: movie.director,
  }));

  // console.log("allMovies", allMovies);

  return (
    <div className={styles.filmWrapper}>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre du film<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allMovies}
        onSearch={(e) => fetchData(e)}
        placeholder="Titre du film ..."
        selectedValue={resourceInputState?.filmetitle}
        handleOptionSelect={(value) => {
          const director = allMovies?.filter((el) => el.value === value)[0]
            ?.director
            ? allMovies?.filter((el) => el.value === value)[0]?.director
            : "";
          handleResourceInputChange({ target: { name: "filmetitle", value } });
          handleResourceInputChange({
            target: { name: "réalisateur", value: director },
          });
        }}
        isLoading={loading}
      />
      {/* <Select
        showSearch
        placeholder="Titre du film ..."
        optionFilterProp="children"
        onChange={(value) => {
          const director = allMovies?.filter((el) => el.value === value)[0]
            .director;
          handleResourceInputChange({ target: { name: "filmetitle", value } });
          handleResourceInputChange({
            target: { name: "réalisateur", value: director },
          });
        }}
        onSearch={(e) => fetchData(e)}
        notFoundContent={
          loading ? (
            <Spin
              size="small"
              style={{ display: "flex", justifyContent: "center" }}
              className="py-3"
            />
          ) : null
        }
        options={allMovies}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
      ></Select> */}
      {errorMessage == "Veuillez sélectionner le titre du film." && (
        <div className="errorMessage">{errorMessage}</div>
      )}

      <div className={styles.CustomInput}>
        <label>Réalisateur</label>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Réalisateur ..."
            id="réalisateur"
            name="réalisateur"
            value={resourceInputState?.réalisateur || ""}
            onChange={handleResourceInputChange}
          />
        </div>
      </div>

      <CustomInput
        label="Acteur principal"
        type="text"
        name="acteur1"
        emogie={false}
        value={resourceInputState.acteur1}
        placeHolder="Acteur principal ..."
        handleOnChange={handleResourceInputChange}
      />
      <CustomInput
        label="Acteur secondaire"
        type="text"
        name="acteur2"
        emogie={false}
        value={resourceInputState.acteur2}
        placeHolder="Acteur secondaire ..."
        handleOnChange={handleResourceInputChange}
      />
      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Film;
