import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "./FriendsListModal.module.scss";
import { burgerCrossBlack, search, userAvatar } from "../../../assets";
import { ApiCall } from "../../../config/apiCall";
import { Spin, message } from "antd";
import { useAuth } from "../../../context/AuthContext";

const FriendsListModal = ({
  toggleState,
  handleToggle,
  storyId,
  resourceLink,
}) => {
  const { token } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [sharePrivateLoading, setSharePrivateLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [actionbtnTextArray, setActionbtnTextArray] = useState([]);

  const getUsersData = async () => {
    try {
      setDataLoading(true);
      const response = await ApiCall({
        params: {},
        route: "user/subscribedUserList/1",
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setUsersData(response.response);
      }
    } catch (error) {
      setDataLoading(false);
      message.error("Connection Error");
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getUsersData();
    }
  }, [token]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = usersData.filter((user) =>
      Object.values(user).some(
        (value) =>
          typeof value === "string" && value.toLowerCase().includes(term)
      )
    );

    setFilteredUsers(filtered);
  };

  const shareToInbox = async (userId, index) => {
    const newArray = [...actionbtnTextArray];
    newArray[index] = "Envoi en cours";
    setActionbtnTextArray(newArray);
    try {
      setSharePrivateLoading(true);
      const response = await ApiCall({
        params: {},
        route: `share/shareStory?receiver=${userId}&storyId=${storyId}&resourceLink=${resourceLink}`,
        verb: "post",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        newArray[index] = "Envoyé";
        setActionbtnTextArray(newArray);
      }
    } catch (error) {
      setSharePrivateLoading(false);
      message.error("Connection Error");
    } finally {
      setSharePrivateLoading(false);
      setTimeout(() => {
        newArray[index] = "Envoyer";
        setActionbtnTextArray(newArray);
      }, 1000);
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="loginModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody>
          <div className={styles.header}>
            <h5 id="signUpModalLabel">Partager dans un message privé</h5>
            <button aria-label="Close" type="button" onClick={handleToggle}>
              <img
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.body}>
            <form>
              <div className={styles.searchContainer}>
                <img
                  src={search}
                  alt="search"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <input
                  type="text"
                  value={searchTerm}
                  placeholder="Rechercher …"
                  onChange={handleSearch}
                />
              </div>
            </form>

            <Spin spinning={dataLoading}>
              <div className={styles.usersContainer}>
                {(searchTerm ? filteredUsers : usersData)?.map(
                  (user, index) => {
                    return (
                      <div className={styles.userWrapper}>
                        <div className={styles.contentContainer}>
                          <div className={styles.imgWrapper}>
                            <img
                              src={
                                user?.profilePicture?.url
                                  ? user?.profilePicture?.url
                                  : userAvatar
                              }
                            />
                          </div>
                          <div className={styles.content}>
                            <h5 className={styles.name}>{user.fullname}</h5>
                            <p className={styles.userName}>{user.username}</p>
                          </div>
                        </div>
                        <div className={styles.actionContainer}>
                          <button
                            className={`${styles.actionbtn} ${
                              actionbtnTextArray[index] == "Envoyé"
                                ? styles.actionbtnSent
                                : ""
                            }`}
                            onClick={() => {
                              shareToInbox(user._id, index);
                            }}
                            disabled={sharePrivateLoading}
                          >
                            <p>{actionbtnTextArray[index] || "Envoyer"}</p>
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </Spin>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FriendsListModal;
