import React, { useEffect, useState } from "react";
import CustomTextArea from "../../CustomTetxArea";
import { Select, Spin } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const Mosique = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
  setErrorMessage,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [musicList, setMusicList] = useState();
  const [loading, setLoading] = useState(false);

  // console.log("mosiquetitle " + resourceInputState?.mosiquetitle);
  useEffect(() => {
    // API access token
    const authParameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=client_credentials&client_id=" +
        CLIENT_ID +
        "&client_secret=" +
        CLIENT_SECRET,
    };
    fetch("https://accounts.spotify.com/api/token", authParameters)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token));
  }, []);

  async function search(e) {
    setLoading(true);
    var trackParameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await fetch(
      "https://api.spotify.com/v1/search?q=" + e + "&type=track",
      trackParameters
    )
      .then((response) => response.json())
      .then((data) => {
        setMusicList(data?.tracks?.items);
        setLoading(false);
      });
  }

  // console.log("musicList", musicList);

  let allMusic = musicList?.map((song) => ({
    value: song.id,
    label: song.name + " (Par " + song.artists[0].name + ")",
    labelToSend: song.name,
    artist: song.artists[0].name,
  }));

  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre de la chanson<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allMusic}
        onSearch={(e) => search(e)}
        placeholder="Titre de la chanson ..."
        selectedValue={resourceInputState?.mosiqueId}
        handleOptionSelect={(value) => {
          const name = allMusic?.filter((el) => el.value === value)[0].labelToSend;
          const artist = allMusic?.filter((el) => el.value === value)[0].artist;
          setErrorMessage("");
          handleResourceInputChange({
            target: { name: "mosiqueId", value },
          });
          handleResourceInputChange({
            target: { name: "mosiquetitle", value: name },
          });
          handleResourceInputChange({
            target: { name: "artistname", value: artist },
          });
        }}
        isLoading={loading}
      />

      {errorMessage == "Veuillez sélectionner le titre de la chanson." && (
        <div className="errorMessage">{errorMessage}</div>
      )}
      <div className={styles.CustomInput}>
        <label>
          Nom de l'artiste <span className="text-danger">*</span>{" "}
        </label>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Nom de l'artiste ..."
            id="artistname"
            name="artistname"
            value={resourceInputState?.artistname || ""}
            onChange={handleResourceInputChange}
          />
        </div>
        {errorMessage == "Veuillez renseigner le nom de l’artiste." && (
          <div className="errorMessage">{errorMessage}</div>
        )}
      </div>

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Mosique;
