import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../assets/styles/NotificationsPopup.module.scss";
import {
  beStrongHug,
  burgerCrossBlack,
  commentsIcon,
  dislike,
  dislikeNotification,
  favorite,
  goodWriterGold,
  like,
  likeNotification,
  meToo,
  share,
  starYellow,
  storyEdit,
  userAvatar,
  wow,
} from "../assets";
import { useNavigate } from "react-router-dom";
import { siteUrl } from "../environment";

const NotificationsPopup = ({
  toggleState,
  handleToggleMenubar,
  handleToggle,
  noticationsData,
}) => {
  const navigate = useNavigate();
  let formattedTime;

  const getFormatedDate = (createdAt) => {
    const date = new Date(createdAt);

    // Formatting the date
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    formattedTime = `${day} ${month}, ${hours % 12}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  };

  const emogies = [
    like,
    meToo,
    beStrongHug,
    wow,
    goodWriterGold,
    likeNotification,
    dislikeNotification,
    starYellow,
    storyEdit,
    commentsIcon,
    share,
  ];

  const navigatToNification = (data) => {
    // console.log(data);
    const searchParams = data?.commentId ? `commentId=${data?.commentId}` : "";
    let storyLink;
    if (data?.notificationType == "chatMessage") {
      storyLink = `/messaging`;
    } else {
      storyLink = `/stories/${data?.storyId}?${searchParams}`;
    }
    navigate(storyLink);
    handleToggle();
    handleToggleMenubar();
  };

  // console.log("noticationsData", noticationsData);

  return (
    <Modal
      size="sm"
      modalClassName="notificationsModal"
      backdropClassName={styles.customBackdrop}
      toggle={handleToggle}
      isOpen={toggleState}
      style={{ maxWidth: "400px", width: "100%" }}
    >
      <ModalBody>
        <div className={styles.notificationsPopup}>
          <div className={styles.header}>
            <h5 id="signUpModalLabel">Notifications</h5>
            <button aria-label="Close" type="button" onClick={handleToggle}>
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.notificationsList}>
            {noticationsData?.list?.length == 0 && <p>Aucune notification</p>}
            {noticationsData?.list?.map((noticationsData) => {
              getFormatedDate(noticationsData?.createdAt);
              {
                /* const splitStrings =
                noticationsData.notification.split(/(«[^»]*»)/); */
              }

              const transformedNotification = noticationsData?.notification
                .replace(
                  /\@\[([^\]]+)]\{([^\}]+)\}/g,
                  (match, displayName, userId) => {
                    return `@${displayName}`;
                  }
                )
                .split(/\s+/)
                .slice(0, 50)
                .join(" ");

              return (
                <div
                  className={`${styles.notification} ${
                    !noticationsData?.isSeen ? styles.unReadNotification : ""
                  } `}
                  onClick={() => navigatToNification(noticationsData)}
                >
                  <div className={styles.leftSide}>
                    <div className={styles.imgWrapper}>
                      <img
                        className={styles.profilePicture}
                        src={
                          noticationsData?.sender[
                            noticationsData?.sender?.length - 1
                          ]?.userId?.profilePicture?.url
                            ? noticationsData?.sender[
                                noticationsData?.sender?.length - 1
                              ]?.userId?.profilePicture?.url
                            : userAvatar
                        }
                      />
                      {noticationsData?.sender[
                        noticationsData?.sender?.length - 1
                      ]?.smiley && (
                        <div className={styles.emogieWrapper}>
                          <img
                            className={styles.emogie}
                            src={
                              emogies[
                                noticationsData?.sender[
                                  noticationsData?.sender?.length - 1
                                ]?.smiley - 1
                              ]
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.content}>
                    {/* <p className={styles.notify}>
                      {splitStrings[0]} <strong>{splitStrings[1]}</strong>
                    </p> */}

                    <p className={styles.notify}>
                      <strong>
                        {
                          noticationsData?.sender[
                            noticationsData?.sender?.length - 1
                          ]?.userId?.fullname
                        }
                      </strong>
                      {/* {noticationsData?.sender?.map((sender, index) => (
                        <React.Fragment key={sender?.userId?.id}>
                          <strong>{sender?.userId?.fullname}</strong>
                          {index < noticationsData.sender.length - 1 && ", "}
                        </React.Fragment>
                      ))} */}{" "}
                      {noticationsData?.personsCount && "et "}
                      <strong>{noticationsData?.personsCount}</strong>{" "}
                      {/* {noticationsData.notification} */}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: transformedNotification,
                        }}
                      />
                    </p>

                    <p className={styles.date}>{formattedTime}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NotificationsPopup;
