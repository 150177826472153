import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/contact";
import AboutUs from "./pages/aboutUs";
import Cookies from "./pages/legal/Cookies";
import LegalNotice from "./pages/legal/LegalNotice";
import Privacy from "./pages/legal/Privacy";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import AdminLogin from "./pages/auth/adminlogin";
import FeaturedPosts from "./pages/posts/featuredPosts";
import MainProfile from "./pages/profile/mainProfile";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyEmail from "./pages/auth/verifyEmail";
import SearchUser from "./pages/searchUserProfile/SearchUser";
import UserMainProfile from "./pages/searchUserProfile/UserMainProfile";
import StoryPage from "./pages/posts/StoryPage";
import Modrator from "./pages/auth/moderator/Modrator";
import Messages from "./pages/messages/Messages";
import Professionnels from "./pages/professionals/Professionnels";
import Reviews from "./pages/professionals/Reviews";
import Resources from "./pages/resources/Resources";
import ResourceDetails from "./pages/resources/ResourceDetails";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import PhysicalTransformation from "./pages/physicalTransformation/PhysicalTransformation";
import ChatBbot from "./pages/chatBot/ChatBot";
import NewMessages from "./pages/messages/NewMessage";
import ResourceDetails2 from "./pages/resources/ResourceDetails2";
import ResourceDetails3 from "./pages/resources/ResourceDetails3";
import ResourcesDetails4 from "./pages/resources/ResourcesDetails4";
import Top40Personalities from "./pages/resources/Top40Personalities";
import AllPersonalities from "./pages/resources/Top40Personalities/AllPersonalities";
import BestApps from "./pages/resources/BestApps/BestApps";
import VerifyUserByThirdParty from "./pages/auth/VerifyUserByThirdParty";
import ProfessionalSignUp from "./pages/professionals/ProfessionalSignUp";
import PricingPlans from "./pages/professionals/PricingPlans";
import ProfessionalProfile from "./pages/professionals/ProfessionalProfile/ProfessionalProfile";
import Appointment from "./pages/professionals/Appointment/Appointment";
import FeatherUsers from "./pages/featherUsers/FeatherUsers";

const Router = ({ showTimeFrameFilters }) => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Home showTimeFrameFilters={showTimeFrameFilters} />}
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/feather-users" element={<FeatherUsers />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/legalnotice" element={<LegalNotice />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/moderator" element={<Modrator />} />
        <Route path="/featuredPosts" element={<FeaturedPosts />} />
        <Route path="/profile" element={<MainProfile />} />
        <Route path="/professional-profile" element={<ProfessionalProfile />} />
        <Route path="/professional-profile/appointment" element={<Appointment />} />
        <Route path="/professionals" element={<Professionnels />} />
        <Route path="/professionals/sign-up" element={<ProfessionalSignUp />} />
        <Route path="/professionals/sign-up/plans" element={<PricingPlans />} />
        <Route path="/professionals/reviews/:proId" element={<Reviews />} />
        <Route path="/messaging" element={<Messages />} />
        <Route path="/chat-bot" element={<ChatBbot />} />
        <Route path="/userProfile" element={<UserMainProfile />} />
        <Route path="/search" element={<SearchUser />} />
        <Route path="/search/:userName" element={<SearchUser />} />
        <Route path="/stories/:id" element={<StoryPage />} />
        <Route path="/reset-password/:tokenId" element={<ResetPassword />} />
        <Route path="/verifyemail/:userId" element={<VerifyEmail />} />
        <Route path="/auth/success" element={<VerifyUserByThirdParty />} />
        <Route path="/ressources" element={<Resources />} />
        <Route
          path="/ressources/details/:typeId"
          element={<ResourceDetails />}
        />
        <Route
          path="/ressources/details2/:typeId"
          element={<ResourceDetails2 />}
        />
        <Route
          path="/ressources/details3/:typeId"
          element={<ResourceDetails3 />}
        />
        <Route
          path="/ressources/details4/:typeId"
          element={<ResourcesDetails4 />}
        />
        <Route
          path="/ressources/details4/:typeId/best-apps"
          element={<BestApps />}
        />
        <Route
          path="/ressources/top40personalities"
          element={<Top40Personalities />}
        />
        <Route
          path="/ressources/allPersonalities"
          element={<AllPersonalities />}
        />
        <Route
          path="/physical-transformation"
          element={<PhysicalTransformation />}
        />
        <Route path="/newMessage" element={<NewMessages />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default Router;
