import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useAuth } from "../context/AuthContext";
import styles from "../assets/styles/themesModal.module.scss";
import { burgerCross } from "../assets/index";

import { useNavigate } from "react-router-dom";
const ThemesPopup = ({ handleToggle, handleToggleMenubar, toggleState }) => {
  const {
    sidebarSelectedTheme,
    setSidebarSelectedTheme,
    activeTheme,
    setActiveTheme,
    getThemes,
    allThemes,
  } = useAuth();
  const navigate = useNavigate();
  // console.log(allThemes);
  useEffect(() => {
    getThemes();
  }, []);

  // console.log("sidebarSelectedTheme", sidebarSelectedTheme);

  const handleThemeChange = (themeid, poster) => {
    // console.log("themeid", themeid);
    // console.log("poster", poster);
    setSidebarSelectedTheme({ themeid, poster });
    navigate("/");
    handleToggle();
    handleToggleMenubar();
  };

  const setThemeName = (name) => {
    if (name.includes("|")) {
      var parts = name.split("|");
      var name = parts
        .map(function (part) {
          return "<nobr>" + part + "</nobr>";
        })
        .join(" | ");
    }
    return name;
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "500px", width: "100%" }}
        modalClassName="themesModal"
        backdropClassName={styles.customBackdrop}
        backdrop="static"
        // centered
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody className={`${styles.ModalBody}`}>
          <div className={styles.ModalBodyHeader}>
            <h5 id="signUpModalLabel">Choix du thème</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              <img
                src={burgerCross}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.themes}>
            {allThemes
              ?.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((theme, index) => (
                <div className={styles.themeFiltratin} key={theme._id}>
                  <div className={styles.selector}>
                    <div className={styles.selectorItem}>
                      <input
                        type="radio"
                        id={theme._id + index}
                        name="resource"
                        className={`${styles.selectorItemRadio}`}
                        value={theme._id}
                        checked={theme._id === sidebarSelectedTheme}
                        onChange={(e) => {
                          handleThemeChange(e.target.value, theme.poster);
                          setActiveTheme(theme?._id);
                        }}
                      />
                      <label
                        htmlFor={theme._id + index}
                        className={`${styles.selectorItemLabel} ${
                          activeTheme === theme?._id
                            ? styles.activeConversation
                            : ""
                        }`}
                      >
                        <img
                          src={theme?.white_image}
                          alt=""
                          width={40}
                          height={40}
                          style={{ color: "white" }}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: setThemeName(theme.name),
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ThemesPopup;
