import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/featuredPosts.module.scss";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";
import { Select, message } from "antd";

import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTetxArea";
import DisplayAdminStories from "../../components/stories/DisplayAdminStories";
import { burgerCrossBlack } from "../../assets";

const FeaturedPosts = () => {
  const {
    token,
    userInfo,
    storiesLoading,
    setAdminStoryCount,
    adminStories,
    getAdmintories,
    setAdminStories,
    adminstoryCount,
    loginModal,
    setLoginModal,
    allThemes,
  } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const [post, setPost] = useState({
    featured_story: true,
    title: "",
    content: "",
  });
  const [postUpdate, setPostUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState();

  const mappedThemes = allThemes
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((theme) => ({
      value: theme._id,
      label: theme.name,
    }));

  useEffect(() => {
    if (mappedThemes && mappedThemes.length > 0) {
      setSelectedTheme(mappedThemes[0].value);
    }
  }, [mappedThemes]);

  useEffect(() => {
    setPost((prevState) => {
      return { ...prevState, theme: selectedTheme };
    });
  }, [userInfo, selectedTheme, adminStories]);

  //checking if loggedIn in useEffect
  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const onThemeChange = (value) => {
    setPost((prevState) => {
      return { ...prevState, theme: value };
    });
  };

  const togglePostModal = () => {
    setModalOpen((prevModalOpen) => !prevModalOpen);
    // setPost({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPost((prevState) => {
      return { ...prevState, [name]: value, writter: userInfo?._id };
    });
  };

  const handlePostSubmit = async () => {
    if (post?.theme?.trim() === "") {
      message.error("Please Select theme");
    } else if (post?.title?.trim() === "") {
      message.error("Please Select title");
    } else if (post?.content?.trim() === "") {
      message.error("Please Select content");
    } else {
      try {
        setIsLoading(true);

        const paramsForImages = new FormData();

        for (const key in post) {
          if (post.hasOwnProperty(key)) {
            paramsForImages.append(key, post[key]);
          }
        }

        // // appi call

        const url = postUpdate ? `story/update/${post?._id}` : `story/create`;
        const response = await ApiCall({
          params: paramsForImages,
          route: url,
          verb: "post",
          token: token,
          baseurl: true,
        });
        if (response.status === 200) {
          message.success(`${response.response.message}`);
          setModalOpen(!modalOpen);
          setPost({ featured_story: true, title: "", content: "" });

          if (!postUpdate) {
            setAdminStories([response.response.data, ...adminStories]);
          } else {
            setAdminStories(
              adminStories.map((item) => {
                if (item._id === post._id) {
                  return response.response.data;
                } else {
                  return item;
                }
              })
            );
            setPostUpdate(false);
          }
        } else {
          message.error(`${response.response.errorMessage}`);
        }
        console.log("response", response);
      } catch (error) {
        message.error(error);
        setIsLoading(false);
        // Handle error if necessary
      } finally {
        setIsLoading(false);
      }
    }
  };

  console.log("post", post);
  return (
    <div>
      {/* display only when admin login */}
      {userInfo?.isAdmin && (
        <div className={styles.creatPost}>
          <button onClick={togglePostModal}>Commencer un post</button>
        </div>
      )}

      <DisplayAdminStories
        stories={adminStories}
        setAdminStories={setAdminStories}
        setStoryCount={setAdminStoryCount}
        loading={storiesLoading}
        setPost={setPost}
        setPostUpdate={setPostUpdate}
        setModalOpen={setModalOpen}
      />

      <Modal
        centered
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
      >
        <div className="modal-header">
          <h5>Créer une publication</h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={togglePostModal}
          >
            <img
              className="py-2"
              src={burgerCrossBlack}
              alt="close"
              onContextMenu={(e) => e.preventDefault()}
            />
          </button>
        </div>
        <ModalBody className="Dashboard-policies-popup-body">
          <form onSubmit={(e) => e.preventDefault()}>
            <CustomInput
              label="Post title"
              type="text"
              name="title"
              emogie={false}
              value={post.title}
              mendatory="*"
              placeHolder="Post#1"
              handleOnChange={handleInputChange}
            />
            {/* <CustomDropdown
              label="Choix du thème"
              placeHolder="Mr Noma"
              mendatory="*"
              name="theme"
              options={myThemes}
              handleOnChange={handleInputChange}
            /> */}
            <h3 className={`${styles.resourcesTitle}`}>
              Choix du thème <span className="text-danger">*</span>
            </h3>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={onThemeChange}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mappedThemes}
              defaultValue={selectedTheme}
            />
            <CustomTextArea
              label="Post content"
              emogie={true}
              placeHolder="De quoi veux-tu parler?"
              mendatory="*"
              name="content"
              value={post.content}
              handleOnChange={handleInputChange}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <Spinner color="info" />
          ) : (
            <button
              onClick={handlePostSubmit}
              className="modal-button-accept"
              type="button"
            >
              Soumettre
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FeaturedPosts;
