import React, { useState, useEffect } from "react";
import { Tabs, message } from "antd";
import ReportedStories from "./ReportedStories";
import ReportedComments from "./ReportedComments";
import ReportedProfiles from "./ReportedProfiles";

import { ApiCall } from "../../../config/apiCall";
import { useAuth } from "../../../context/AuthContext";
import AddPersonaliities from "./AddPersonaliities";
import Plans from "./Plans/Plans";

const Modrator = () => {
  const { token, loginModal, setLoginModal } = useAuth();
  const [reportedStories, setReportedStories] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [reportedProfiles, setReportedProfiles] = useState([]);
  const [isStoryloading, setIsStoryloading] = useState(false);
  const [isCommentloading, setIsCommentloading] = useState(false);
  const [isProfileloading, setIsProfileloading] = useState(false);
  const [resportedStoryCount, setresportedStoryCount] = useState(1);
  const [resportedCommentCount, setresportedCommentCount] = useState(1);
  const [resportedProfileCount, setresportedProfileCount] = useState(1);

  useEffect(() => {
    if (token) {
      getReportedStories(resportedStoryCount);
      getReportedComments(resportedCommentCount);
      getReportedProfiles(resportedProfileCount);
    }
  }, [token]);

  const getReportedStories = async (count) => {
    try {
      setIsStoryloading(true);
      const response = await ApiCall({
        params: {},
        route: "story/reportedStoryList/" + count,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setReportedStories(response.response.list);
      }
      console.log("reportedStoriesResp", response);
    } catch (error) {
      setIsStoryloading(false);
      message.error("Connection Error");
    } finally {
      setIsStoryloading(false);
    }
  };

  const getReportedComments = async (count) => {
    try {
      setIsCommentloading(true);
      const response = await ApiCall({
        params: {},
        route: "comment/reportedCommentList/" + count,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setAllComments(response.response.data);
      }
      console.log("reportedCommentsResp", response.response.data);
    } catch (error) {
      setIsCommentloading(false);
      message.error("Connection Error");
    } finally {
      setIsCommentloading(false);
    }
  };

  const getReportedProfiles = async (count) => {
    try {
      setIsProfileloading(true);
      const response = await ApiCall({
        params: {},
        route: "user/reportedUserList/" + count,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setReportedProfiles(response.response);
      }
      console.log("ReportedProfilesResp", response);
    } catch (error) {
      setIsProfileloading(false);
      message.error("Connection Error");
    } finally {
      setIsProfileloading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: `Histoires signalées`,
      children: (
        <ReportedStories
          reportedStories={reportedStories}
          setReportedStories={setReportedStories}
          isStoryloading={isStoryloading}
        />
      ),
    },
    {
      key: "2",
      label: `Commentaires signalés`,
      children: (
        <ReportedComments
          allComments={allComments}
          setAllComments={setAllComments}
          isCommentloading={isCommentloading}
        />
      ),
    },
    {
      key: "3",
      label: `Profils signalés`,
      children: (
        <ReportedProfiles
          reportedProfiles={reportedProfiles}
          setReportedProfiles={setReportedProfiles}
          isProfileloading={isProfileloading}
        />
      ),
    },
    {
      key: "4",
      label: `Add Personalities`,
      children: <AddPersonaliities />,
    },
    // {
    //   key: "5",
    //   label: `Plans`,
    //   children: <Plans />,
    // },
  ];

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  return (
    token && (
      <div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    )
  );
};

export default Modrator;
