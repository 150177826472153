import React from "react";
import styles from "./LegalPages.module.scss";
import { Link } from "react-router-dom";

const LegalNotice = () => {
  return (
    <div className={styles["super-container"]}>
      <div className={styles.container}>
        <h3 className={styles.heading}>Mentions légales </h3>

        <h5 className="pt-3">Mentions relatives à l’éditeur</h5>
        <p className={styles.paragraph}>
          PsyUP est édité par la société RIVELEZ SASU, société par actions
          simplifiée unipersonnelle au capital social de 1 euros, inscrite au
          registre du commerce et des sociétés de Paris sous le numéro 888 155
          348, dont le siège social est 6 rue dArmaillé 75017 Paris. Numéro de
          TVA Intracommunautaire : FR 22 888 155 348.
        </p>
        <p>
          Pour nous contacter :{" "}
          <Link to="/contact">contact@psyup.fr</Link>
        </p>
        <div style={{ marginTop: "15px" }}>
          <h5>Mentions relatives à la rédaction </h5>
          <p className={styles.paragraph}>
            Les internautes sont les principaux rédacteurs des contributions.
          </p>
          <p>
            La directrice de la publication et responsable de la rédaction est
            la Présidente de RIVELEZ SASU, Jessy CLIO, joignable à l’adresse
            contact@psyup.fr.
          </p>
        </div>
        <div style={{ marginTop: "15px" }}>
          <h5>Mentions relatives à l’hébergeur</h5>
          <p className={styles.paragraph}>
            PsyUP est hébergé par la société AMAZON WEB SERVICES INC, dont le
            siège social est situé à 410 Terry Avenue North Seattle, WA 98109
            United States, joignable par le moyen suivant:{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://aws.amazon.com/"
            >
              https://aws.amazon.com/
            </Link>
          </p>
        </div>
        <div>
          <h5 className="pb-3">Mentions relatives aux marques</h5>
          <p>Copyright © 2023 RIVELEZ SASU - Tous droits réservés.</p>
          <p>PsyUP est une marque déposée de RIVELEZ SASU. </p>
          <p className={styles.paragraph}>
            Les noms des sociétés ou produits mentionnés sont les marques de
            leurs propriétaires respectifs. Tous les droits qui ne sont pas
            expressément concédés sont réservés par RIVELEZ SASU.
          </p>
          <p>
            Ainsi, toute représentation totale ou partielle de ce site, par
            quelque procédé que ce soit, sans lautorisation expresse de son
            exploitant est interdite et constituerait une contrefaçon
            sanctionnée par le Code de la propriété intellectuelle.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
