import { Dropdown, Menu, Spin } from "antd";
import styles from "../../assets/styles/DisplayStories.module.scss";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  beStrongHug,
  beforeAfter,
  burgerCross,
  commentsIcon,
  favorite,
  goodWriterGold,
  like,
  meToo,
  report,
  share,
  wow,
} from "../../assets";
import { getImageByValue } from "../../components/stories/GetResourceIcons";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faCaretRight,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";

const StoryModal = ({ toggleState, handleToggle, storyId }) => {
  const { token, userId } = useAuth();
  const [singleStory, setsingleStory] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setloading] = useState(false);

  const [isCommentsOpen, setisCommentsOpen] = useState();
  const [favoriteStatus, setFavoriteStatus] = useState({});
  useEffect(() => {
    if (token) {
      getStoryDetails();
      setImageUrl(
        getImageByValue(singleStory?.ressource?.complete?.resourcetype)
      );
    }
  }, [token, storyId]);

  const getStoryDetails = async () => {
    setloading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `story/detailStory/${storyId}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status == 200) {
        setsingleStory(response.response);
      }

      console.log("SingleStory", response);
    } catch (error) {
      setloading(false);
    } finally {
      setloading(false);
    }
  };

  const userProfileLink =
    singleStory?.writter?._id === userId
      ? `/profile?userId=${singleStory?.writter?._id}`
      : `/userProfile?userId=${singleStory?.writter?._id}`;

  const totalReactionsOnPost = [
    {
      key: "1",
      render: (story) => {
        return (
          <>
            <img src={like} onContextMenu={(e) => e.preventDefault()} />
            <p>{story?.smiley1_count}</p>
          </>
        );
      },
    },
    {
      key: "2",
      render: (story) => (
        <>
          <img src={meToo} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley2_count}</p>
        </>
      ),
    },
    {
      key: "3",
      render: (story) => (
        <>
          <img src={beStrongHug} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley3_count}</p>
        </>
      ),
    },
    {
      key: "4",
      render: (story) => (
        <>
          <img src={wow} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley4_count}</p>
        </>
      ),
    },
    {
      key: "5",
      render: (story) => (
        <>
          <img src={goodWriterGold} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley5_count}</p>
        </>
      ),
    },
  ];

  const conditionallyRenderedItems = (story) => {
    const filteredItems = totalReactionsOnPost.filter(
      (item) => story[`smiley${item.key}_count`] > 0
    );

    return filteredItems.map((item) => (
      <React.Fragment key={item.key}>{item.render(story)}</React.Fragment>
    ));
  };

  const showMore = true;
  const MAX_LENGTH = 100;
  let selectedIndex = -1;

  const items = [
    // {
    //   label: "Copy link",
    //   key: "0",
    //   onClick: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     navigator.clipboard?.writeText(storyLink);
    //     message.success("Story link copied to clipboard!");
    //   },
    //   render: (index) => (
    //     <>
    //       <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
    //     </>
    //   ),
    // },
    // {
    //   label: "Facebook",
    //   key: "1",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <FacebookShareButton
    //         url={storyLink}
    //         quote={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
    //           </svg>
    //         </a>
    //       </FacebookShareButton>
    //     );
    //   },
    // },
    // {
    //   label: "WhatsApp",
    //   key: "2",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <WhatsappShareButton
    //         url={storyLink}
    //         title={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 448 512"
    //           >
    //             <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
    //           </svg>
    //         </a>
    //       </WhatsappShareButton>
    //     );
    //   },
    // },
    // {
    //   label: "Twitter",
    //   key: "3",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <TwitterShareButton
    //         url={storyLink}
    //         title={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
    //           </svg>
    //         </a>
    //       </TwitterShareButton>
    //     );
    //   },
    // },
  ];

  const storyOptions = [
    {
      label: "Modifier ma publication",
      key: "0",
      onClick: (story) => {
        // setStoryDataForUpdate(story);
        // toggleSubmitStoryModal();
        // message.success("Modifier Ma Publication");
      },
    },
    {
      label: "Supprimer ma publication",
      key: "1",
      onClick: (story) => {
        // handleDeleteStory(story._id);
        // message.success("Publication deleted successfully");
      },
    },
  ];

  const reactionsArray = [
    {
      emogi: like,
      value: "like",
      selected: false,
      id: 1,
      height: 25,
      width: 25,
      title: "J’aime !",
      length: 8,
    },
    {
      emogi: meToo,
      value: "meToo",
      selected: false,
      id: 2,
      height: 25,
      width: 25,
      title: "J’ai vécu la même (ou presque) !",
      length: 32,
    },
    {
      emogi: beStrongHug,
      value: "beStrongHug",
      selected: false,
      id: 3,
      height: 27,
      width: 25,
      title: "Ensemble !",
      length: 10,
    },
    {
      emogi: wow,
      value: "wow",
      selected: false,
      id: 4,
      height: 30,
      width: 25,
      title: "Wow !",
      length: 5,
    },
    {
      emogi: goodWriterGold,
      value: "goodWriterGold",
      selected: false,
      id: 5,
      height: 25,
      width: 25,
      title: "Belle plume !",
      length: 13,
    },
  ];

  return (
    <div>
      <Modal
        isOpen={toggleState}
        centered
        style={{ maxWidth: "1400px", width: "100%" }}
        modalClassName="mySubscriptionsStories"
        toggle={handleToggle}
      >
        <ModalBody>
          <div className={styles.sliderWrapper}>
            <Spin spinning={loading}>
              <div className={`${styles.storiesModalContainer}`}>
                <div>
                  <div className={styles.subscriptionStoryHeader}>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={handleToggle}
                    >
                      <img
                        src={burgerCross}
                        onContextMenu={(e) => e.preventDefault()}
                      ></img>
                    </button>
                  </div>

                  <div className={`${styles.storyOuterContainer}`}>
                    <div className={styles.DisplayStoriesContainer}>
                      <div className={styles.storyHeader}>
                        <div className={styles.storyHeaderLeft}>
                          <p className={styles.theme}>
                            {singleStory?.theme?.name}
                          </p>
                          <div className={styles.date}>
                            {singleStory?.isAnonym ? (
                              <p className={`${styles.userAnonym}`}>
                                Par **** ,
                              </p>
                            ) : (
                              <p
                                className={
                                  !singleStory?.writter?.isAdmin
                                    ? styles.userName
                                    : styles.userAnonym
                                }
                                // onClick={() => {
                                //   if (!singleStory?.writter?.isAdmin)
                                //     handleUserClick(singleStory?.writter?._id);
                                // }}
                              >
                                Par{" "}
                                <Link
                                  to={userProfileLink}
                                  className={styles.userNameLink}
                                >
                                  {singleStory?.writter?.username}
                                </Link>
                              </p>
                            )}

                            <p className={`${styles.dateP} ps-1`}> 12/23/23</p>
                          </div>
                        </div>
                        <div className={styles.storyHeaderRight}>
                          <h2 className={styles.heading}>
                            {singleStory?.title}
                          </h2>
                        </div>
                      </div>
                      <div className={`${styles.storyBody} `}>
                        {showMore ? (
                          <div className={styles.storyContent}>
                            <p>
                              {singleStory?.content}
                              {singleStory?.content.length > MAX_LENGTH && (
                                <span
                                  className={styles.contentBtn}
                                  // onClick={() => toggleShowMore(selectedSubscriptionIndex)}
                                >
                                  Replier ▲
                                </span>
                              )}{" "}
                            </p>
                          </div>
                        ) : (
                          <div className={styles.storyContent}>
                            <p>
                              {singleStory?.content.slice(0, MAX_LENGTH)}
                              {singleStory?.content.length > MAX_LENGTH &&
                                "..."}
                              {singleStory?.content.length > MAX_LENGTH && (
                                <span
                                  className={styles.contentBtn}
                                  // onClick={() => toggleShowMore(selectedSubscriptionIndex)}
                                >
                                  Lire la suite
                                </span>
                              )}
                            </p>
                          </div>
                        )}

                        {/* first storyline  */}
                        {!singleStory?.professionalConsulted?.fullName &&
                        !singleStory?.ressource?.complete?.resourcetype &&
                        !(
                          singleStory?.physicalTransformation
                            ?.beforePictureUrl ||
                          singleStory?.physicalTransformation?.afterPictureUrl
                        ) ? (
                          <div className="py-0"></div>
                        ) : (
                          <hr />
                        )}

                        <div className={styles.prefessionalResourcesWrapper}>
                          {singleStory?.professionalConsulted?.fullName && (
                            <div>
                              <p>Professionnel</p>
                              <h6>
                                <span className="font-bold text-black">
                                  {singleStory?.professionalConsulted?.fullName}
                                </span>
                              </h6>
                            </div>
                          )}

                          {singleStory?.ressource?.complete?.resourcetype && (
                            <div
                            // onClick={() => handleResourceModal(selectedSubscriptionIndex)}
                            >
                              <p className={styles.ressource}>Ressource</p>
                              <img
                                src={imageUrl}
                                alt="img"
                                className={styles.icon}
                                width={40}
                                height={40}
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </div>
                          )}
                          {(singleStory?.physicalTransformation
                            ?.beforePictureUrl ||
                            singleStory?.physicalTransformation
                              ?.afterPictureUrl) && (
                            <div
                            // onClick={() => handleBeforeAfterModal(selectedSubscriptionIndex)}
                            >
                              <p>Avant /Après</p>
                              <img
                                src={beforeAfter}
                                alt="logo"
                                className={styles.icon}
                                width={35}
                                height={40}
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </div>
                          )}
                        </div>

                        {/* second storyline */}
                        {!singleStory?.professionalConsulted?.fullName &&
                        !singleStory?.ressource?.complete?.resourcetype &&
                        !(
                          singleStory?.physicalTransformation
                            ?.beforePictureUrl ||
                          singleStory?.physicalTransformation?.afterPictureUrl
                        ) ? (
                          <div className="py-0"></div>
                        ) : (
                          <hr />
                        )}

                        <div className={styles.details}>
                          <div className={styles.icons}>
                            <div
                              className={styles.favStarIcon}
                              // onClick={() => handleFavoriteClick(singleStory?._id)}
                            >
                              {favoriteStatus[singleStory?._id] ||
                              singleStory?.isFavourite ? (
                                <div className={styles.icon}>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    style={{ color: "#F5D003" }}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={favorite}
                                  alt="logo"
                                  className={styles.icon}
                                  width={22}
                                  height={20}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              )}
                            </div>

                            <div
                              className={styles.commentsWrapper}
                              // onClick={() => {
                              //   getStoryMostreleventComments(singleStory?._id);
                              //   if (token) {
                              //     setShowCommentsIndex(selectedSubscriptionIndex);
                              //     setisCommentsOpen((prevState) => [selectedSubscriptionIndex]);
                              //   }
                              // }}
                            >
                              <img
                                src={commentsIcon}
                                className={styles.icon}
                                alt="comments"
                                width={20}
                                height={20}
                                onContextMenu={(e) => e.preventDefault()}
                              />
                              <p>({singleStory?.comments_count})</p>
                            </div>

                            <Dropdown
                              overlay={
                                <Menu className={styles.dropdownMenu}>
                                  {items.map((item) => (
                                    <Menu.Item
                                      key={item.key}
                                      onClick={() =>
                                        item.onClick && item.onClick()
                                      }
                                    >
                                      {item.render ? item.render() : item.label}
                                    </Menu.Item>
                                  ))}
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <img
                                  src={share}
                                  alt="logo"
                                  className={styles.icon}
                                  width={22}
                                  height={22}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </a>
                            </Dropdown>

                            {singleStory?.writter?._id !== userId && (
                              <div
                              // onClick={() => handleReportStory(singleStory?._id)}
                              >
                                <img
                                  src={report}
                                  alt="logo"
                                  className={styles.icon}
                                  width={20}
                                  height={20}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </div>
                            )}
                            {/* 
                          {userInfo?.isAdmin &&
                            singleStory?.writter?._id !== userId && (
                              <>
                                <div
                                // onClick={() => handleDeleteStory(singleStory?._id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{
                                      color: "gray",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </>
                            )} */}

                            {singleStory?.writter?._id === userId && (
                              <Dropdown
                                overlay={
                                  <Menu>
                                    {storyOptions.map((item) => (
                                      <Menu.Item
                                        key={item.key}
                                        // onClick={() =>
                                        //   item.onClick && item.onClick(story)
                                        // }
                                      >
                                        {item.render
                                          ? item.render()
                                          : item.label}
                                      </Menu.Item>
                                    ))}
                                  </Menu>
                                }
                                trigger={["click"]}
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    style={{
                                      color: "#5c5c5c",
                                      cursor: "pointer",
                                    }}
                                  />
                                </a>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.reactionsWrapper}>
                        {reactionsArray.map((reaction, index) => {
                          return (
                            <>
                              <div className={styles.tooltipContainer}>
                                <button
                                  // onClick={() => {
                                  //   handleReactionClick(
                                  //     singleStory?._id,
                                  //     index,
                                  //     selectedSubscriptionIndex,
                                  //     story,
                                  //     selectedIndex === index ||
                                  //       (!selectedIndex &&
                                  //         singleStory?.reactionId - 1 === index)
                                  //   );
                                  // }}
                                  className={` ${
                                    singleStory?.reactionId - 1 === index
                                      ? styles.activeReaction
                                      : ""
                                  } ${styles.reaction}`}
                                  data-tooltip={reaction.title}
                                >
                                  <img
                                    src={reaction.emogi}
                                    alt="like"
                                    width={reaction.width}
                                    height={reaction.height}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </button>
                                <span className={styles.tooltipText}>
                                  {reaction.title}
                                </span>
                              </div>
                            </>
                          );
                        })}

                        {singleStory?.reaction?.length > 0 && (
                          <>
                            <Dropdown
                              overlay={
                                <Menu>
                                  {conditionallyRenderedItems(singleStory).map(
                                    (item) => (
                                      <Menu.Item key={item.key}>
                                        <div
                                          className={
                                            styles.totalReactionsWrapper
                                          }
                                        >
                                          {item}
                                        </div>
                                      </Menu.Item>
                                    )
                                  )}
                                </Menu>
                              }
                              trigger={["click"]}
                              placement="bottomLeft"
                            >
                              <button>
                                <span>
                                  {singleStory?.reaction?.length}
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className={styles.fontawesome}
                                  />
                                </span>
                              </button>
                            </Dropdown>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default StoryModal;
