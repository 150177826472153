import React, { useState } from "react";
import styles from "./Top40personalities.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { expand, nextarrowRed, relax } from "../../assets";
import { resources } from "./resourcesData";

const top40personalitiesArray = [
  {
    id: 1,
    title: "ENTREPRENEURS",
    list: [
      {
        name: "1. HENRY FORD",
        description: " : Un projet re-et-retravaillé !",
        type: "description",
      },
      {
        name: "2. AKIO MORITA (Sony)",
        description: " : Un changement de produit !",
        type: "description",
      },
      {
        name: "3. SOICHIRO HONDA (Honda)",
        description: " : Une longue période de chômage !",
        type: "description",
      },
      {
        name: "4. WALTER ELIAS DISNEY (Walt Disney",
        description: " : Licenciement et faillites d’entreprises !",
        type: "description",
      },
      {
        name: "5. HARLAND DAVID SANDERS (Kentucky Fried Chicken -KFC-)",
        description:
          " : Quand tu es fauché depuis ta naissance et que malgré tes efforts et ton travail tu enchaînes les coups durs !",
        type: "description",
      },
      {
        name: "6. LÉNA MAHFOUF (@LÉNA SITUATIONS)",
        description:
          " : « Ce n’est pas parfait, mais, c’est fait avec le cœur » et « les chiens aboient, la caravane passe » !",
        type: "description",
      },
    ],
  },
  {
    id: 2,
    title: "INVENTEURS ET SCIENTIFIQUES",
    list: [
      {
        name: "7.  STEPHEN HAWKING",
        description:
          " : Handicap et lutte contre la maladie - Tant qu’il y a de la vie, il y a de l’espoir !",
        type: "description",
      },
      {
        name: "8. THOMAS EDISON",
        description:
          " : Paroles dénigrantes/blessantes et nombreuses tentatives avant la réussite !",
        type: "description",
      },
      {
        name: "9. ALBERT EINSTEIN",
        description:
          " : Retard de développement, échec à un examen avant la réussite et l’excellence !",
        type: "description",
      },
      {
        name: "10. MARIA MONTESSORI",
        description:
          " : Quand tes idées révolutionnent ton domaine, mais qu’elles sont trop en avance sur leur temps !",
        type: "description",
      },
      {
        name: "11. DANIEL HALE WILLIAMS, VIVIEN THOMAS ET ÉSOPE",
        description:
          " : Quand l’Histoire finit par révéler des talents cachés…",
        type: "description",
      },
    ],
  },
  {
    id: 3,
    title: "ÉCRIVAINS",
    list: [
      {
        name: "12. J. K. ROWLING (Harry Potter",
        description:
          " : Expériences, leçons de vie, divorce, précarité, dépression, refus de publication, travail, patience !",
        type: "description",
      },
      {
        name: "13. PAULO COELHO",
        description:
          " : Les citrons sur la route de la réussite et de la sagesse.",
        type: "description",
      },
      {
        name: "14. ARTHUR CONAN DOYLE (Les aventures de Sherlock Holmes)",
        description:
          " : Sciences et Littérature, qui a dit qu’un cerveau ne pouvait pas exceller dans les deux ? Nous sommes multi-fonctions !",
        type: "description",
      },
      {
        name: "15. ANTOINE SAINT-EXUPÉRY (Le Petit Prince)",
        description:
          " : Les notes scolaires peuvent se tromper ! Travailler dur avec bon sens, c’est la clé !",
        type: "description",
      },
    ],
  },
  {
    id: 4,
    title: "PERSONNALITÉS PUBLIQUES ET POLITIQUES",
    list: [
      {
        name: "16. PROTECTION DE L’HOMME ET DE L’ENVIRONNEMENT : la lutte d’une vie…",
        description: "",
        type: "list",
        list: [
          {
            name: "MERE TERESA",
            description: " : Tout pour les hommes et les femmes délaissés !",
          },
          {
            name: "COLUCHE",
            description: " : Tout pour le rire et la fin de la faim !",
          },
          {
            name: "DENIS MUKWEGE",
            description:
              " : Tout pour la reconstruction des victimes de violences sexuelles !",
          },
          {
            name: "RACHAEL DENHOLLANDER ET MAGGIE NICHOLS",
            description:
              " : Tout pour la fin des violences sexuelles dans le sport !",
          },
          {
            name: "MALALA YOUSAFZAI",
            description: " : Tout pour l’éducation des filles !",
          },
          {
            name: "WANGARI MAATHA",
            description: "I : Tout pour les arbres !",
          },
          {
            name: "GRETA THUNBERG",
            description: " : Tout pour le climat !",
          },
          {
            name: "VANESSA NAKATE",
            description: " : Tout pour le climat !",
          },
          {
            name: "SVANTE ARRHENIUS",
            description: " : Il l’avait prédit il y a plus de 100 ans !",
          },
          {
            name: "PAUL WATSON",
            description: " : Tout pour la protection de la vie marine !",
          },
          {
            name: "JANE GOODALL",
            description: " : Tout pour les chimpanzés !",
          },
          {
            name: "DAVID ATTENBOROUGH",
            description: " : Tout pour la Nature !",
          },
          {
            name: "NAOMI KLEIN",
            description: " : Rien pour les dérives du capitalisme !",
          },
          {
            name: "VANDANA SHIVA",
            description:
              " : Tout pour l’harmonie, la fin des OGM et de la biopiraterie",
          },
          {
            name: "LES GRANDS MESSAGERS",
            description: " : Tout pour les âmes !",
          },
        ],
      },
      {
        name: "17. POLITICIENS, ÉCONOMISTES ET HÉROS NATIONAUX souvent au prix de leur vie…",
        description: "",
        type: "list",
        list: [
          {
            name: "CHARLES DE GAULLE  ET JEANNE D'ARC",
            description: ", des héros de la France.",
          },
          {
            name: "HÉROS ET HERITAGE ITALIENS",
            description: "",
          },
          {
            name: "LES HÉROS MYTHOLOGIQUES ET HISTORIQUES DE LA GRÈCE",
            description: "",
          },
          {
            name: "RENÉ LÉVESQUE",
            description: ", un des héros du Québec.",
          },
          {
            name: "RAGNAR LODBROK, LEIF ERIKSON, ALFRED NOBEL, TOVE JANSSON et HANS CHRISTIAN ANDERSEN",
            description: ", des héros scandinaves.",
          },
          {
            name: "WILLIAM WALLACE, ROBERT THE BRUCE et ADAM SMITH",
            description: ", des héros de l’Écosse.",
          },
          {
            name: "SISSI L’IMPERATRICE et les autres pères du capitalisme",
            description: ", des héros de l’Autriche.",
          },
          {
            name: "WINSTON CHURCHILL et les grands économistes britanniques",
            description: ", des héros du Royaume-Uni.",
          },
          {
            name: "MAHATMA GANDHI",
            description: ", le héros de l’Inde.",
          },
          {
            name: "NELSON MANDELA",
            description: ", le héros de l’Afrique du Sud.",
          },
          {
            name: "ABRAHAM LINCOLN",
            description: ", un des héros des États-Unis d’Amérique.",
          },
          { name: "GENGHIS KHAN", description: ", le héros des Mongols." },
          { name: "L'EMPEREUR MEIJI", description: ", un des héros du Japon." },
          {
            name: "JOSIP BROZ TITO",
            description: ", le héros de la Yougoslavie.",
          },
          {
            name: "OTTO VON BISMARCK, ANNE FRANCK et KARL MARX",
            description: ", des héros de l’Allemagne.",
          },
          {
            name: "LÉNINE",
            description: ", un des héros de l’Union soviétique.",
          },
          { name: "HO CHI MINH", description: ", le héros du Vietnam." },
          { name: "MAO ZEDONG", description: ", un des héros de la Chine." },
          {
            name: "FIDEL CASTRO et ERNESTO CHE GUEVARA",
            description: ", les héros de Cuba.",
          },
          {
            name: "THOMAS SANKARA",
            description: ", le héros du Burkina Faso.",
          },
          { name: "KWAME NKRUMAH", description: ", le héros du Ghana." },
          {
            name: "LÉOPOLD SEDAR SENGHOR et CHEIKH ANTA DIOP",
            description: ", des héros du Sénégal.",
          },
          { name: "SOUNDIATA KEÏTA", description: ", un des héros du Mali." },
          {
            name: "TOUSSAINT LOUVERTURE et JEAN-JACQUES DESSALINES",
            description: ", les héros d’Haïti.",
          },
          {
            name: "FRANTZ FANON",
            description: ", un des héros de la Martinique.",
          },
          {
            name: "L'ÉMIR ABD EL-KADER",
            description: ", un des héros de l’Algérie.",
          },
          {
            name: "DAVID BEN GOURION ET LES DESCENDANTS DE JACOB",
            description: ", des héros d’Israël.",
          },
          { name: "YASSER ARAFAT", description: ", le héros de la Palestine." },
          { name: "SALADIN", description: ", un héros du monde arabe." },
          {
            name: "GAMAL ABD EL-NASSER",
            description: ", un des héros de l’Égypte et du monde arabe.",
          },
          { name: "MUAMMAR KADHAFI", description: ", le héros de la Libye." },
          { name: "HUGO CHAVEZ", description: ", le héros du Venezuela." },
          {
            name: "SIMON BOLIVAR et JOSE DE SAN MARTIN",
            description: ", des héros de l’Amérique Latine.",
          },
          {
            name: "DOLORES IBARRURI, La Pasionaria",
            description: ", une héroïne de l’Espagne.",
          },
          {
            name: "TUPAC AMARU II",
            description:
              "un des héros des peuples autochtones d’Amérique Latine.",
          },
          {
            name: "JOSE 'PEPE' MUJICA",
            description: ", le héros de l’Uruguay.",
          },
          {
            name: "HENRI LE NAVIGATEUR ET VASCO DE GAMA",
            description: ", des héros du Portugal.",
          },
          {
            name: "ZUMBI DOS PALMARES, DOM PEDRO I, TIRADENTES et JUSCELINO KUBITSCHEK",
            description: ", des héros du Brésil.",
          },
        ],
      },
      {
        name: "18. JEAN-PIERRE PERNAULT",
        description:
          " : Difficultés scolaires, progression professionnelle, cancer, rumeurs et orgueil blessé.",
        type: "list",
        list: [],
      },
      {
        name: "19. PHILIPPE ETCHEBEST & OLIVIER STREIFF",
        description: "",
        type: "list",
        list: [
          {
            name: "PHILIPPE ETCHEBEST",
            description: " : Endurance, travail bien fait et mental !",
          },
          {
            name: "OLIVIER STREIFF",
            description: " : Au-delà des apparences…",
          },
        ],
      },
      {
        name: "20. JEAN-LUC DELARUE, CHRISTINE KELLY, JULIA ROBERTS, AL PACINO",
        description:
          " : La famille est souvent source d’amour et de joie, mais elle peut également être de synonyme de violence, de tristesse, de souffrance, d’inquiétudes ou de pression... et pourtant il faut continuer à avancer !",
        type: "list",
        list: [
          {
            name: "JEAN-LUC DELARUE",
            description:
              " : Vivre avec l’absence de reconnaissance de ses parents, et lutter contre son autodestruction…",
          },
          {
            name: "CHRSTINE KELLY",
            description:
              " : Fille battue, femmes aux 3 valeurs (1) capacité d’adaptation (2) maîtrise des émotions (3) détermination !",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: "ACTEURS ET CINÉASTES",
    list: [
      {
        name: "21. LEONADO DICAPRIO",
        description:
          " : Une reconnaissance tardive - Faire avec les règles de son industrie (business is business).",
        type: "description",
      },
      {
        name: "22. CHADWICK BOSEMAN",
        description: " : Sous les feux des projecteurs malgré la maladie.",
        type: "description",
      },
    ],
  },
  {
    id: 6,
    title: "CRÉATEURS DE CONTENUS, INFLUENCEURS ET MANNEQUINS",
    list: [
      {
        name: "23. JAMES CHARLES",
        description: " : Rester soi-même, et bouleverser les codes !",
        type: "description",
      },
      {
        name: "24. VICTORIA’S SECRET vs SAVAGE X FENTY (de RIHANNA)",
        description: " : Bouleverser les standards de beauté !",
        type: "description",
      },
      {
        name: "25. ENJOYPHOENIX",
        description: " : Utiliser son citron pour créer sa bulle.",
        type: "description",
      },
      {
        name: "26. NATOO",
        description:
          " : Vaincre sa « timidité maladive » et surmonter sa souffrance au travail grâce à sa passion.",
        type: "description",
      },
    ],
  },
  {
    id: 7,
    title: "SPORTIFS",
    list: [
      {
        name: "27. KOBE BRYANT",
        description: " : La  « Mamba Mentality », la mentalité du winner !",
        type: "description",
      },
      {
        name: "28. RAFAEL NADAL",
        description: " : Champion et obsessions ?",
        type: "description",
      },
      {
        name: "29. MICHAEL PHELPS",
        description: " : Champion et dépression…",
        type: "description",
      },
      {
        name: "30. TEDDY RINER",
        description:
          ": « Assumer d’être suivi, c'est un signe d'intelligence ».",
        type: "description",
      },
      {
        name: "31. ZINEDINE ZIDANE",
        description: " : Une erreur. des conséquences. du courage. un retour.",
        type: "description",
      },
      {
        name: "32. MICHAEL JORDAN & ANTOINE GRIEZMAN",
        description: " Au début, ils peuvent ne pas repérer ton talent !",
        type: "description",
      },
    ],
  },
  {
    id: 8,
    title: "CHANTEURS ET MUSICIENS",
    list: [
      {
        name: "33. RAY CHARLES et STEVIE WONDER",
        description: ": Du jamais vu !",
        type: "description",
      },
      {
        name: "34. LUDWIG VAN BEETHOVEN",
        description: " : Une histoire inouïe…",
        type: "description",
      },
      {
        name: "35. CÉLINE DION et son mari RENE ANGELIL",
        description:
          " : Complexes, harcèlement et difficultés scolaires, soutien familial, audace, couple avec différence d’âge, sacrifices, difficultés à concevoir un enfant, addiction au jeu, perte de son partenaire et maladie",
        type: "description",
      },
      {
        name: "36. JEAN JACQUES GOLDMAN",
        description: " : Fuir la pression médiatique.",
        type: "description",
      },
      {
        name: "37. COLDPLAY",
        description: " : Clash entre « associés » et poursuites judiciaires.",
        type: "description",
      },
      {
        name: "38. STROMAE",
        description: " : Dépression, crise d’angoisse et hallucinations.",
        type: "description",
      },
      {
        name: "39. LE RAPGAME (JUL, PNL, NINHO, DAMSO, NEKFEU, BOOBA, NISKA, DRAKE, GIMS, ORELSAN, SOPRANO, KALASH)",
        description:
          " : Fais ce que tu aimes, le « hors-norme » d’hier peut devenir la norme d’aujourd’hui !",
        type: "description",
      },
      {
        name: "40. BOB Marley",
        description: " : One love…",
        type: "description",
      },
    ],
  },
];

const Top40Personalities = () => {
  const navigate = useNavigate();
  let { typeId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  const [isDescriptionShow, setDescriptionShow] = useState(null);
  console.log("isDescriptionShow", isDescriptionShow);

  const toggleDescriptionShow = (id) => {
    setDescriptionShow(id === isDescriptionShow ? null : id);
  };

  const navigateToPersonalityPage = () => {
    navigate("/ressources/allPersonalities");
  };

  return (
    <div className={styles.resourcesContainer}>
      <div className={styles.topContent}>
        <h6 className={styles.heading}>Autres ressources</h6>
        <p className={styles.subHeading}>
          + de 40 personnalités qui n’ont pas abandonné malgré les échecs et les
          difficultés.
        </p>
        <p className={styles.paragraph}>
          Essayer encore et encore ; soit on gagne, soit on apprend.
        </p>
      </div>

      <br />

      {top40personalitiesArray.map((personality, index) => {
        return (
          <div className={styles.resourcedetails4ListWrapper}>
            <div className={styles.resourceList}>
              <div
                className={styles.resourcelistHead}
                onClick={() => toggleDescriptionShow(personality?.id)}
              >
                <h4 className={styles.resourcelistHeading}>
                  {personality.title}
                </h4>
                {isDescriptionShow === personality?.id ? (
                  <img
                    className={styles.arrowDown}
                    src={nextarrowRed}
                    alt="next"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : (
                  <img
                    className={styles.arrowUp}
                    src={nextarrowRed}
                    alt="next"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
              </div>
              {isDescriptionShow === personality?.id && (
                <div className={styles.descriptionWrapper}>
                  {personality.list.map((personality) => (
                    <>
                      {personality?.type === "description" ? (
                        <p
                          className={styles.description}
                          onClick={navigateToPersonalityPage}
                        >
                          <span>{personality.name}</span>
                          {personality.description}
                        </p>
                      ) : (
                        <>
                          <p className={styles.description}>
                            <span>{personality.name}</span>
                            {personality.description}
                          </p>
                          {personality.list.map((person) => {
                            return (
                              <p className={styles.innerList}>
                                <span>{person.name}</span>
                                {person.description}
                              </p>
                            );
                          })}
                        </>
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Top40Personalities;
