import React, { useState } from "react";
import styles from "../../assets/styles/sidebarRight.module.scss";

import ButtonFilled from "../buttonFilled";
import ButtonOutline from "../buttonOutline";
import { Link } from "react-router-dom";
import ProfessionalSpaceModal from "../../pages/professionals/ProfessionalSpaceModal";

const SidebarRight = () => {
  const [professionalSpaceModal, setProfessionalSpaceModal] = useState(false);

  const professionalSpaceModalToggle = () => {
    setProfessionalSpaceModal(!professionalSpaceModal);
  };

  return (
    <>
      <div className={styles.sidebarRight}>
        <Link to="/professionals">
          <ButtonFilled
            title="Trouver un professionnel"
            text="ou une association"
            textColr="white"
          />
        </Link>
        <div onClick={professionalSpaceModalToggle}>
          <ButtonOutline
            title="ESPACE PRO"
            text="(médecins, psychologues, avocats...)"
            textColr="black"
            borderColr="black"
          />
        </div>
        <div className={styles.autres}>
          <Link to="/ressources">
            <p>Autres ressources</p>
          </Link>
        </div>
        <div className={styles.public}>
          <h4>Publicité</h4>
        </div>
      </div>

      <ProfessionalSpaceModal
        toggleState={professionalSpaceModal}
        handleToggle={professionalSpaceModalToggle}
      />
    </>
  );
};

export default SidebarRight;
