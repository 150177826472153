import React, { useState } from "react";
import { message } from "antd";

import StoryLoader from "../../../components/UI/StoryLoader";
import styles from "../../../assets/styles/comments/DisplayComments.module.scss";
import { report, userAvatar } from "../../../assets";
import { useAuth } from "../../../context/AuthContext";
import { faTrashCan, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall } from "../../../config/apiCall";

const ReportedComments = ({
  allComments,
  setAllComments,
  isCommentloading,
}) => {
  const { token } = useAuth();
  const [deleteCommentLoading, setdeleteCommentLoading] = useState(false);
  // For less/more story description
  const [showMoreDescription, setShowMoreDescription] = useState(
    Array(allComments?.length).fill(false)
  );

  // show more/less story content
  const toggleShowMore = (index) => {
    const newShowMoreDescription = [...showMoreDescription];
    newShowMoreDescription[index] = !newShowMoreDescription[index];
    setShowMoreDescription(newShowMoreDescription);
  };

  const deleteComment = async (commentId, storyId) => {
    setdeleteCommentLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `comment/delete-comment/${storyId}&${commentId}`,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        console.log("ac", allComments);
        setAllComments(
          allComments.filter((comment) => comment._id !== commentId)
        );

        message.success(response.response);
      }
      console.log("deleteCommentResp", response);
    } catch (error) {
      setdeleteCommentLoading(false);
    } finally {
      setdeleteCommentLoading(false);
    }
  };

  return (
    <div>
      {isCommentloading ? (
        <StoryLoader oneLine={true} />
      ) : (
        <>
          {allComments.length == 0 && <p>Aucune commentaire trouvé.</p>}
          {allComments?.map((myComment, indx) => {
            const date = new Date(myComment?.createdAt);
            const showMore = showMoreDescription[indx];
            const DateFormate = `le ${date.toLocaleDateString()} à ${date.toLocaleTimeString(
              [],
              { hour: "numeric", minute: "2-digit" }
            )}`;
            const transformedComment = myComment?.comment.replace(
              /\@\[([^\]]+)]\{([^\}]+)\}/g,
              (match, displayName, userId) => {
                return `<a href="/userProfile?userId=${userId}" style="color: #01989f; cursor: pointer;" data-userid="${userId}"">${displayName}</a>`;
              }
            );
            return (
              <>
                <div className={styles.DisplayStoriesContainer}>
                  <div className={styles.storyHeader}>
                    <div className={styles.storyHeaderLeft}>
                      <p className={styles.theme}>
                        {myComment.storyId?.theme?.name}
                      </p>
                      <div className={styles.date}>
                        {myComment.storyId?.isAnonym ? (
                          <p>
                            Par,{" "}
                            {myComment.storyId?.writter?.fullname?.charAt(0)}
                            ****
                          </p>
                        ) : (
                          <p>Par {myComment.storyId?.writter?.fullname},</p>
                        )}

                        <p>{DateFormate}</p>
                      </div>
                    </div>
                    <div className={styles.storyHeaderRight}>
                      <h2 className={styles.heading}>
                        {myComment.storyId?.title}
                      </h2>
                    </div>
                  </div>
                  <div className={styles.storyBody}>
                    {showMore ? (
                      <div className={styles.storyContent}>
                        <p>
                          {myComment.storyId?.content}
                          {myComment.storyId?.content?.length > 100 && (
                            <span
                              className={styles.contentBtn}
                              onClick={() => toggleShowMore(indx)}
                            >
                              Replier ▲
                            </span>
                          )}{" "}
                        </p>
                      </div>
                    ) : (
                      <div className={styles.storyContent}>
                        <p>
                          {myComment.storyId?.content?.slice(0, 100)}
                          {myComment.storyId?.content?.length > 100 && "..."}
                          {myComment.storyId?.content?.length > 100 && (
                            <span
                              className={styles.contentBtn}
                              onClick={() => toggleShowMore(indx)}
                            >
                              Lire la suite
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.commentWrapper} key={indx}>
                  <div className={styles.top}>
                    <div className={styles.leftSide}>
                      <img
                        src={
                          myComment?.author?.profilePicture?.url
                            ? myComment.author?.profilePicture.url
                            : userAvatar
                        }
                        alt="userAvatar"
                        width={40}
                        height={40}
                      />
                      <div className={styles.nameDate}>
                        <h2>{myComment?.author?.fullname}</h2>
                        <p className="pt-1">{DateFormate}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                      __html: transformedComment,
                    }}
                  />
                  <div className={styles.bottom}>
                    <div className={styles.bottomLeft}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={styles.commentsWrapper}
                      >
                        <img
                          src={report}
                          alt="logo"
                          className={styles.icon}
                          width={20}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        <p>({myComment.reports_count})</p>
                      </div>
                      <div
                        onClick={() =>
                          !deleteCommentLoading &&
                          deleteComment(myComment._id, myComment.storyId?._id)
                        }
                      >
                        <FontAwesomeIcon
                          className={styles.icon}
                          icon={faTrashCan}
                          style={{ color: "gray", cursor: "pointer" }}
                        />
                      </div>
                      <div
                        onClick={() =>
                          !deleteCommentLoading &&
                          deleteComment(myComment._id, myComment.storyId?._id)
                        }
                      >
                        <FontAwesomeIcon
                          className={styles.icon}
                          icon={faSquareCheck}
                          style={{ color: "gray" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReportedComments;
