import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/comments/DisplayComments.module.scss";

import { useAuth } from "../../context/AuthContext";
import { Dropdown, Menu, message } from "antd";
import {
  like,
  dislike,
  sendGreenIcon,
  sendIcon,
  smilyIcon,
  userAvatar,
  minus,
  add,
  report,
} from "../../assets/index";
import { ApiCall } from "../../config/apiCall";
import StoryLoader from "../UI/StoryLoader";
import DisplayCommentsNestedReplies from "./DisplayCommentsNestedReplies";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Mention, MentionsInput } from "react-mentions";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DisplayCommentsReplys = ({
  storyId,
  commentId,
  authorName,
  authorId,
  commentActions,
  setCommentActions,
}) => {
  const { token, userInfo, userId } = useAuth();

  const [commentReply, setCommentReply] = useState("");
  const [commentReplies, setCommentReplies] = useState([]);
  const [loadingCommentReplies, setloadingCommentReplies] = useState(false);

  const [commentReplyLoading, setCommentReplyLoading] = useState(false);
  const [isNestedRepliesOpen, setIsNestedRepliesOpen] = useState([]);
  const [showNestedRepliesIndex, setShowNestedRepliesIndex] = useState(-1);
  const [isLiked, setIsLiked] = useState(-1);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [editableComment, setEditableComment] = useState(null);
  const [author, setAuthor] = useState({
    id: authorId,
    display: authorName,
  });

  // set it by default
  useEffect(() => {
    const defaultValue = `@[${authorName}]{${authorId}} `;
    setCommentReply(defaultValue);
  }, []);

  const getCommentReplies = async (commentId) => {
    if (token) {
      setloadingCommentReplies(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "comment/get-comment-detail/" + commentId,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setCommentReplies(response.response);
        }
      } catch (error) {
        setloadingCommentReplies(false);
        // Handle error if necessary
      } finally {
        setloadingCommentReplies(false);
      }
    } else {
      message.error("Besoin de se connecter d'abord");
    }
  };

  useEffect(() => {
    getCommentReplies(commentId);
  }, []);

  const renderUserSuggestion = (suggestion, search, highlightedDisplay) => (
    <div>@ {highlightedDisplay}</div>
  );

  const handleLikeClick = async (index) => {
    const replyId = commentReplies?.detail[index]._id;

    try {
      const response = await ApiCall({
        params: {},
        route: `comment/comment-reaction/${replyId}&like`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        console.log("response.response.comment", response.response.comment);
        console.log("cmt", commentReplies);
        // setCommentReplies((prev) => {
        //   return prev?.detail?.map((reply) => {
        //     if (reply._id == response.response.comment._id) {
        //       return response.response.comment;
        //     } else {
        //       return reply;
        //     }
        //   });
        // });

        setCommentReplies((prev) => {
          const updatedDetails = prev?.detail?.map((reply) => {
            if (reply._id === response.response.comment._id) {
              return response.response.comment;
            } else {
              return reply;
            }
          });

          return { ...prev, detail: updatedDetails };
        });


        // getCommentReplies(commentId);
      } else {
        message.error(response.response.error[0]?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleDislikeClick = async (index) => {
    const replyId = commentReplies?.detail[index]._id;

    try {
      const response = await ApiCall({
        params: {},
        route: `comment/comment-reaction/${replyId}&dislike`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // message.success(response.response.message);

        getCommentReplies(commentId);
      } else {
        message.error(response.response.error[0]?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    setIsLiked(-1);
  }, [commentReplies]);

  const handleCommentReplyInput = (event) => {
    const { value, scrollHeight, clientHeight } = event.target;
    // event.target.style.height = "auto"; // Reset height to auto

    // // Calculate the new height based on the scrollHeight and clientHeight
    // const newHeight = Math.max(scrollHeight, clientHeight);
    // event.target.style.height = newHeight + "px";
    setCommentReply(value);

    // Reset textarea height if value is empty
    // if (value.trim() === "") {
    //   event.target.style.height = "41px";
    // }
  };

  const handleEmojiSelect = (emoji) => {
    setCommentReply(commentReply + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const calculateTimeElapsed = (date) => {
    const now = new Date();
    const elapsedTime = now - date;

    const seconds = Math.floor(elapsedTime / 1000) % 60;
    const minutes = Math.floor(elapsedTime / (1000 * 60)) % 60;
    const hours = Math.floor(elapsedTime / (1000 * 60 * 60)) % 24;
    const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24)) % 7;
    const weeks = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 7));

    return {
      weeks: weeks,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  };

  const TimeElapsed = (time) => {
    if (time.weeks > 0) {
      return `${time.weeks} sem`;
    } else if (time.days > 0) {
      return `${time.days} j`;
    } else if (time.hours > 0) {
      return `${time.hours} h`;
    } else {
      return `${time.minutes} min`;
    }
  };

  const submitCommentReply = async (e) => {
    e.preventDefault();
    setCommentReplyLoading(true);
    try {
      const response = await ApiCall({
        params: {
          storyId,
          parent: commentId,
          reply: commentReply,
        },
        route: "comment/add-comment-reply",
        verb: "post",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setCommentReplies((prev) => {
          return { ...prev, detail: [response.response.data, ...prev?.detail] };
        });

        setCommentReply("");
        setShowNestedRepliesIndex("abc");
        document.getElementById("ReplyTextarea").style.height = "44px";
      }
    } catch (error) {
      setCommentReplyLoading(false);
    } finally {
      setCommentReplyLoading(false);
    }
  };

  const deleteComment = async (commentId, storyId) => {
    // setStoriesLoading(true);
    // try {
    //   const response = await ApiCall({
    //     params: {},
    //     route: `comment/delete-comment/${storyId}&${commentId}`,
    //     verb: "delete",
    //     baseurl: true,
    //     token: token,
    //   });
    //   if (response.status === 200) {
    //     setCommentsList({
    //       ...commentsList,
    //       data: {
    //         list: commentsList.data.list.filter(
    //           (comment) => comment._id !== commentId
    //         ),
    //         comments_count: commentsList.data.comments_count,
    //       },
    //     });
    //     setCommentCounts(storyId, response?.response?.comments_count);
    //   }
    //   console.log("deleteCommentResp", response);
    // } catch (error) {
    //   // setStoriesLoading(false);
    //   // Handle error if necessary
    // } finally {
    //   // setStoriesLoading(false);
    // }
  };

  const updateComment = async () => {
    // setStoriesLoading(true);
    // try {
    //   const response = await ApiCall({
    //     params: {
    //       comment: editableComment?.comment?.comment,
    //       taggedUserIds: taggedUsers,
    //     },
    //     route: `comment/update-comment/${editableComment?.storyId}&${editableComment?.comment?._id}`,
    //     verb: "put",
    //     baseurl: true,
    //     token: token,
    //   });
    //   if (response.status === 200) {
    //     setCommentsList({
    //       ...commentsList,
    //       data: {
    //         list: commentsList.data.list.map((comment) => {
    //           if (comment._id === editableComment?.comment?._id) {
    //             return {
    //               ...comment,
    //               comment: editableComment?.comment?.comment,
    //             };
    //           } else {
    //             return comment;
    //           }
    //         }),
    //         comments_count: commentsList.data.comments_count,
    //       },
    //     });
    //     setEditableComment(null);
    //     // message.success(response.response);
    //   }
    // } catch (error) {
    //   // setStoriesLoading(false);
    //   // Handle error if necessary
    // } finally {
    //   // setStoriesLoading(false);
    // }
  };

  const reportComment = async (commentId, storyId) => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `comment/reportComment/${storyId}&${commentId}`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // setCommentsList({
        //   ...commentsList,
        //   data: {
        //     list: commentsList.data.list.filter(
        //       (comment) => comment._id !== commentId
        //     ),
        //     comments_count: commentsList.data.comments_count,
        //   },
        // });
        message.success(response.response);
      }
      console.log("reportCommentResp", response);
    } catch (error) {
      // setStoriesLoading(false);
      // Handle error if necessary
    } finally {
      // setStoriesLoading(false);
    }
  };

  const commentOptions = [
    {
      label: "Modifier mon commentaire",
      key: "0",
      onClick: (comment, storyId) => {
        console.log(comment);
        setEditableComment({ storyId, comment });
      },
    },
    {
      label: "Supprimer mon commentaire",
      key: "1",
      onClick: (comment, storyId) => {
        deleteComment(comment._id, storyId);
      },
    },
  ];

  return (
    <>
      {commentActions.find((el) => el?.id === commentId)?.input && (
        <div className={styles.repliesInput}>
          <form className={styles.writeComment}>
            <img
              src={
                userInfo?.profilePicture?.url
                  ? userInfo?.profilePicture?.url
                  : userAvatar
              }
              alt="userpic"
              width={30}
              height={30}
            />

            <div className={styles.textareaWrapperForCommentReplies}>
              <MentionsInput
                value={commentReply}
                onChange={handleCommentReplyInput}
                placeholder="Ajouter une réponse …"
                className="mentions"
                classNames={styles}
              >
                <Mention
                  data={author}
                  markup="@[__display__]{__id__}"
                  renderSuggestion={renderUserSuggestion}
                  className={styles.mentions__mention}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
            </div>
            <span className={styles.replyEmogie} onClick={toggleEmojiPicker}>
              <img
                src={smilyIcon}
                alt="smily"
                onContextMenu={(e) => e.preventDefault()}
              />
            </span>
            {showEmojiPicker && (
              <div className={styles.emogiePicker} ref={emojiPickerRef}>
                <Picker
                  data={data}
                  previewPosition="none"
                  theme="light"
                  onEmojiSelect={handleEmojiSelect}
                />
              </div>
            )}

            {commentReply === "" ? (
              <button disabled className={styles.sendCommntBtn}>
                <img
                  src={sendIcon}
                  alt="send"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </button>
            ) : (
              <button
                onClick={submitCommentReply}
                className={styles.sendCommntBtn}
              >
                <img
                  src={sendGreenIcon}
                  alt="send"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </button>
            )}
          </form>
        </div>
      )}

      {commentActions.find((el) => el?.id === commentId)?.comments &&
        (loadingCommentReplies ? (
          <div className={styles.ReplyloaderContainer}>
            <StoryLoader oneLine={true} />
          </div>
        ) : (
          <>
            {commentReplies?.detail?.length > 0 && (
              <div className={styles.commentReplyContainer}>
                {commentReplies?.detail?.map((myComment, index) => {
                  const date = new Date(myComment?.createdAt);
                  const showNestedReplies = showNestedRepliesIndex === index;

                  const timeElapsed = calculateTimeElapsed(date);
                  const DateFormate = TimeElapsed(timeElapsed);

                  const transformedCommentReply = myComment?.comment?.replace(
                    /\@\[([^\]]+)]\{([^\}]+)\}/g,
                    (match, displayName, userId) => {
                      return `<a href="/userProfile?userId=${userId}" style="color: #01989f; cursor: pointer;" data-userid="${userId}"">${displayName}</a>`;
                    }
                  );

                  return (
                    <>
                      <div className={styles.commentReplyWrapper} key={index}>
                        <div className={styles.top}>
                          <div className={styles.leftSide}>
                            <img
                              src={
                                myComment?.author?.profilePicture?.url
                                  ? myComment?.author?.profilePicture.url
                                  : userAvatar
                              }
                              alt="userAvatar"
                              width={30}
                              height={30}
                            />
                            <div className={styles.nameDate}>
                              <h2>{myComment?.author?.fullname}</h2>
                              <p className="pt-1">{DateFormate}</p>
                            </div>
                          </div>
                        </div>

                        {editableComment &&
                          editableComment?.comment?._id === myComment?._id ? (
                          <div
                            className={`${styles.editableContent} ${styles.writeComment}`}
                          >
                            <div className={`${styles.textareaWrapper} py-3`}>
                              <MentionsInput
                                value={editableComment?.comment?.comment}
                                onChange={(e) =>
                                  setEditableComment((prev) => ({
                                    ...prev,
                                    comment: {
                                      ...prev?.comment,
                                      comment: e.target.value,
                                    },
                                  }))
                                }
                                placeholder="Ça a commencé par ..."
                                className="mentions"
                                classNames={styles}
                              >
                                <Mention
                                  trigger={"@"}
                                  data={author}
                                  markup="@[__display__]{__id__}"
                                  renderSuggestion={renderUserSuggestion}
                                  className={styles.mentions__mention}
                                  appendSpaceOnAdd={true}
                                />
                              </MentionsInput>
                            </div>
                            {editableComment?.comment?.comment === "" ? (
                              <button disabled className={styles.sendCommntBtn}>
                                <img
                                  src={sendIcon}
                                  alt="send"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </button>
                            ) : (
                              <button
                                onClick={updateComment}
                                className={styles.sendCommntBtn}
                              >
                                <img
                                  src={sendGreenIcon}
                                  alt="send"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </button>
                            )}
                          </div>
                        ) : (
                          <>
                            {/* <p className={styles.content}>{myComment.comment}</p> */}
                            <div
                              className={styles.content}
                              dangerouslySetInnerHTML={{
                                __html: transformedCommentReply,
                              }}
                            />
                          </>
                        )}

                        <div className={styles.bottom}>
                          <div className={styles.bottomLeft}>
                            <div className={styles.reactionsWrapper}>
                              <div>
                                <button
                                  onClick={() => handleLikeClick(index)}
                                  className={`
                                ${myComment.isReacted === 0
                                      ? styles.activeComment
                                      : ""
                                    }
                                ${styles.comment}`}
                                >
                                  <img
                                    src={like}
                                    alt="like"
                                    width={20}
                                    height={18}
                                    className={styles.like}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <span>{myComment.likes_count}</span>
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() => handleDislikeClick(index)}
                                  className={`
                                ${myComment?.isReacted === 1
                                      ? styles.activeComment
                                      : ""
                                    }
                                ${styles.comment}`}
                                >
                                  <img
                                    src={dislike}
                                    alt="dislike"
                                    width={20}
                                    height={18}
                                    className={styles.dislike}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <span>{myComment?.dislikes_count}</span>
                                </button>
                              </div>
                            </div>

                            <h4
                              className={styles.respond}
                              onClick={() => {

                                if (isNestedRepliesOpen.includes(index)) {
                                  setShowNestedRepliesIndex("abc");
                                  setIsNestedRepliesOpen((prevState) =>
                                    prevState.filter((item) => item !== index)
                                  );
                                } else {
                                  setShowNestedRepliesIndex(index);
                                  // getCommentReplies(myComment._id);
                                  setIsNestedRepliesOpen((prevState) => [
                                    index,
                                  ]);
                                }

                                // setCommentActions((prev) =>
                                //   prev.map((el) => {
                                //     if (el?.id === commentId) {
                                //       return { ...el, input: true };
                                //     } else {
                                //       return el;
                                //     }
                                //   })
                                // );
                                const user = `@[${myComment?.author?.fullname}]{${myComment?.author?._id}} `;
                                setCommentReply(user);
                              }}
                            >
                              Répondre
                            </h4>

                            {/* modify and delete  */}
                            {myComment?.author?._id === userId && (
                              <Dropdown
                                overlay={
                                  <Menu>
                                    {commentOptions.map((item) => (
                                      <Menu.Item
                                        key={item.key}
                                        onClick={() =>
                                          item.onClick &&
                                          item.onClick(myComment, storyId)
                                        }
                                      >
                                        <p style={{ width: "100px" }}>
                                          {item.render
                                            ? item.render(storyId)
                                            : item.label}
                                        </p>
                                      </Menu.Item>
                                    ))}
                                  </Menu>
                                }
                                trigger={["click"]}
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    style={{
                                      color: "#5c5c5c",
                                      cursor: "pointer",
                                    }}
                                  />
                                </a>
                              </Dropdown>
                            )}

                            {/* report */}

                            {!userInfo?.isAdmin &&
                              myComment?.author?._id !== userId && (
                                <div
                                  onClick={() =>
                                    reportComment(myComment._id, storyId)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "-2px",
                                  }}
                                >
                                  <img
                                    src={report}
                                    alt="logo"
                                    className={styles.icon}
                                    width={17}
                                    height={17}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </div>
                              )}
                          </div>

                          {myComment?.replies_count > 0 && (
                            <div>
                              {isNestedRepliesOpen.includes(index) ? (
                                <div
                                  className={styles.bottomRight}
                                  onClick={() => {
                                    setShowNestedRepliesIndex("abc");
                                    setIsNestedRepliesOpen((prevState) =>
                                      prevState.filter((item) => item !== index)
                                    );
                                  }}
                                >
                                  <img src={minus} alt="hide" />
                                </div>
                              ) : (
                                <div
                                  className={styles.bottomRight}
                                  onClick={() => {
                                    setShowNestedRepliesIndex(index);
                                    setIsNestedRepliesOpen((prevState) => [
                                      index,
                                    ]);
                                  }}
                                >
                                  <img
                                    src={add}
                                    alt="add"
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </div>
                              )}
                            </div>
                          )}


                        </div>
                      </div>

                      {showNestedReplies && token && (
                        <div className={styles.repliesInput}>
                          <form className={styles.writeComment}>
                            <img
                              src={
                                userInfo?.profilePicture?.url
                                  ? userInfo?.profilePicture?.url
                                  : userAvatar
                              }
                              alt="userpic"
                              width={30}
                              height={30}
                            />

                            <div className={styles.textareaWrapperForCommentReplies}>
                              <MentionsInput
                                value={commentReply}
                                onChange={handleCommentReplyInput}
                                placeholder="Ajouter une réponse …"
                                className="mentions"
                                classNames={styles}
                              >
                                <Mention
                                  data={author}
                                  markup="@[__display__]{__id__}"
                                  renderSuggestion={renderUserSuggestion}
                                  className={styles.mentions__mention}
                                  appendSpaceOnAdd={true}
                                />
                              </MentionsInput>
                            </div>
                            <span className={styles.replyEmogie} onClick={toggleEmojiPicker}>
                              <img
                                src={smilyIcon}
                                alt="smily"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </span>
                            {showEmojiPicker && (
                              <div className={styles.emogiePicker} ref={emojiPickerRef}>
                                <Picker
                                  data={data}
                                  previewPosition="none"
                                  theme="light"
                                  onEmojiSelect={handleEmojiSelect}
                                />
                              </div>
                            )}

                            {commentReply === "" ? (
                              <button disabled className={styles.sendCommntBtn}>
                                <img
                                  src={sendIcon}
                                  alt="send"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </button>
                            ) : (
                              <button
                                onClick={submitCommentReply}
                                className={styles.sendCommntBtn}
                              >
                                <img
                                  src={sendGreenIcon}
                                  alt="send"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </button>
                            )}
                          </form>
                        </div>
                      )}
                      {/* {showNestedReplies && token && (
                        <DisplayCommentsNestedReplies
                          storyId={storyId}
                          commentId={myComment?._id}
                          authorName={authorName}
                          authorId={authorId}
                        />
                      )} */}
                    </>
                  );
                })}
              </div>
            )}
          </>
        ))}
    </>
  );
};

export default DisplayCommentsReplys;
