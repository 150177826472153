import React, { useState, useEffect } from "react";
import CustomInput from "../../CustomInput";
import { Select, Spin, message } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import { ApiCall } from "../../../config/apiCall";
import { useAuth } from "../../../context/AuthContext";
import CustomTextArea from "../../CustomTetxArea";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";
const Citation = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
  setErrorMessage,
}) => {
  const { token } = useAuth();
  const [qoutesList, setQoutesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowAddQuoteButton, setIsShowAddQuoteButton] = useState(true);
  const [searchedQoute, setSearchedQoute] = useState("");

  console.log("resourceInputState", resourceInputState);

  useEffect(() => {
    async function getAllQoutes() {
      setLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `quote/allListing`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setQoutesList(response?.response?.data);
          setLoading(false);
        }
        console.log("response", response);
      } catch (error) {
        message.error("Connection Error");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    getAllQoutes();
  }, []);

  async function getQoutes(e) {
    setSearchedQoute(e);
    setLoading(true);
    const url = e ? `quote/filterQuotes/${e}` : `quote/allListing`;
    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setQoutesList(response?.response?.data);
        setLoading(false);
      }
    } catch (error) {
      message.error("Connection Error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  let allQoutes = qoutesList?.map((qoute) => ({
    value: qoute?._id,
    label: qoute?.quote,
    author: qoute?.author,
  }));

  const quoteChangeHandler = (value) => {
    const name = allQoutes?.filter((el) => el.value === value)[0]?.label;
    const author = allQoutes?.filter((el) => el.value === value)[0]?.author;
    setIsShowAddQuoteButton(true);
    setErrorMessage("");
    handleResourceInputChange({
      target: { name: "citationId", value },
    });
    handleResourceInputChange({
      target: { name: "citationtitle", value: name },
    });
    handleResourceInputChange({
      target: { name: "auteur1", value: author },
    });
  };

  const setNewQoute = (value) => {
    setIsShowAddQuoteButton(false);
    allQoutes.push({ label: value, value: value });
    setQoutesList((prev) => [
      ...prev,
      { _id: value, quote: value, author: "" },
    ]);
    handleResourceInputChange({
      target: { name: "citationtitle", value: value },
    });
    handleResourceInputChange({
      target: { name: "citationId", value: value },
    });
    handleResourceInputChange({
      target: { name: "auteur1", value: "" },
    });
  };

  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Citation<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allQoutes}
        onSearch={(e) => getQoutes(e)}
        placeholder="Quand la vie te donne un citron, fais-en une limonade ..."
        selectedValue={resourceInputState?.citationId}
        handleOptionSelect={(value) => {
          quoteChangeHandler(value);
        }}
        setNewOption={() => setNewQoute(searchedQoute)}
        allowNewOption={true}
        newOptionTitle="+ Ajouter cette citation"
        isLoading={loading}
      />

      {errorMessage == "Veuillez sélectionner le titre de la citation." && (
        <div className="errorMessage">{errorMessage}</div>
      )}

      <CustomInput
        label="Auteur de la citation"
        type="text"
        name="auteur1"
        emogie={false}
        value={resourceInputState.auteur1}
        placeHolder="Inconnu ..."
        handleOnChange={handleResourceInputChange}
      />

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Citation;
