import {
  Citation,
  CitationWhite,
  Film,
  FilmWhite,
  Influenceur,
  InfluenceurWhite,
  LivreWhite,
  Musique,
  MusiqueWhite,
  Podcast,
  PodcastWhite,
  Vidéo,
  VidéoWhite,
  activitiesIcon,
  bulbFilter,
  bulbFilterWhite,
  bulbGlob,
  bulbGlobWhite,
  cosmaticBioIcon,
  datingApp,
  eauIcon,
  etirementsIcon,
  friendsIcon,
  livre,
  nourritureIcon,
  respirerIcon,
} from "../../assets";

export const resources = [
  {
    id: "1",
    heading: "7 ressources",
    subHeading: "classées par thèmes",
    iconGreen: bulbFilter,
    iconWhite: bulbFilterWhite,
    resourceList: [
      {
        id: "1",
        title: "1 . Lire un livre",
        titleInside: "Lire un livre",
        icon: livre,
        type: "livre",
        resourceType: "Livre",
        recommendedModalName: "Livre",
        recommendedModalTitle: "Titre du livre",
        recommendedModalSubTitle: "Auteur du livre",
        iconWhite: LivreWhite,
        description:
          "Voir le TOP 10 des livres les plus recommandés par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "2",
        title: "2 . Regarder un film/une série",
        titleInside: "Regarder un film/une série",
        icon: Film,
        type: "film",
        resourceType: "Film",
        recommendedModalName: "Film OU Série",
        recommendedModalTitle: "Titre du film ou série",
        recommendedModalSubTitle: "Réalisateur",
        iconWhite: FilmWhite,
        description:
          "Voir le TOP 10 des films et des séries les plus recommandés par thèmes",
        // description2: "Top 10 des séries les plus recommandées par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "3",
        title: "3 . Suivre un(e) influenceur(se) / une personnalité publique",
        titleInside: "Suivre un(e) influenceur(se) / une personnalité publique",
        icon: Influenceur,
        type: "influencer",
        resourceType: "Influenceur",
        recommendedModalName: "Influenceur",
        recommendedModalTitle: "Nom du compte Youtube / Instagram / TikTok",
        iconWhite: InfluenceurWhite,
        description:
          "Voir le TOP 10 des influenceurs les plus recommandés par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "4",
        title: "4 . Regarder des vidéos",
        titleInside: "Regarder des vidéos",
        icon: Vidéo,
        type: "ressource-video",
        resourceType: "Vidéo",
        recommendedModalName: "Vidéo",
        recommendedModalTitle: "Titre de la vidéo",
        recommendedModalSubTitle: "Auteur de la vidéo",
        iconWhite: VidéoWhite,
        description:
          "Voir le TOP 10 des vidéos les plus recommandées par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "5",
        title: "5 . Écouter de la musique",
        titleInside: "Écouter de la musique",
        icon: Musique,
        type: "music",
        resourceType: "Musique",
        recommendedModalName: "Musique",
        recommendedModalTitle: "Titre de la chanson",
        recommendedModalSubTitle: "Nom de l'artiste",
        iconWhite: MusiqueWhite,
        subTitle:
          "[Mention spéciale pour la musique sous la douche, en voiture, dans les lieux publics avec ses écouteurs/casque ou en faisant des tâches ménagères]",
        description:
          "Voir le TOP 10 des musiques les plus recommandées par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "6",
        title: "6 . Écouter un podcast intéressant",
        titleInside: "Écouter un podcast intéressant",
        icon: Podcast,
        type: "podcast",
        resourceType: "Podcast",
        recommendedModalName: "Podcast",
        recommendedModalTitle: "Titre du podcast",
        recommendedModalSubTitle: "Auteur du podcast",
        iconWhite: PodcastWhite,
        description:
          "Voir le TOP 10 des podcasts les plus recommandées par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
      {
        id: "7",
        title: "7 . Lire une citation qui vous parle",
        titleInside: "Lire une citation qui vous parle",
        icon: Citation,
        type: "quote",
        resourceType: "Citation",
        recommendedModalName: "Citation",
        recommendedModalTitle: "Citation",
        recommendedModalSubTitle: "Auteur de la citation",
        iconWhite: CitationWhite,
        description:
          "Voir le TOP 10 des citations les plus recommandées par thèmes",
        resourceDetail: [
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
          {
            id: "1",
            title: "1 . Lire un livre",
            icon: livre,
            description:
              "Voir le TOP 10 des livres les plus recommandés par thèmes",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    heading: "43 ressources",
    subHeading: "générales",
    iconGreen: bulbGlob,
    iconWhite: bulbGlobWhite,
    resourceList: [
      {
        id: "8",
        title: "8 . Éviter la solitude",
        titleInside: "Éviter la solitude",
        description: "Voir le Top 10 des applications de rencontres en France",
        stories: [
          {
            title: "Entretenir ses relations",
            img: friendsIcon,
            activitiesList: [
              "Appels, messages, lettres (des nouvelles de temps en temps ça fait du bien !)",
              "Visites (le contact physique… indémodable !)",
              "Invitations à la maison (les joies du partage !)",
              "Sorties en famille ou entre amis (moments précieux !)",
              "Petites attentions (ça fait toujours plaisir !)",
              "Aide, « coups de main », soutien (dans une relation équilibrée bien sûr…)",
            ],
            paragraphsWithoutList: [
              "[N’oublions pas d’être reconnaissant envers ceux qui nous accordent temps, énergie, argent !]",
            ],

            links: [],
          },
          {
            title: "Faire des rencontres…",
            img: datingApp,
            paragraphs: [
              "… en pratiquant ses centres d’intérêt",
              "… sur les sites de rencontres ou sur les réseaux sociaux [ex. psyup.fr  Qui se comprend, s’assemble !]",
              "… partout où le destin voudra !",
            ],
            links: [
              {
                name: "TOP 10 DES APPLICATIONS DE RENCONTRES EN FRANCE",
                url: "/ressources/details/1?resourceId=8",
              },
            ],
          },
        ],
        internalList: [
          {
            id: 1,
            name: "Meetic",
            subscription: "Gratuite",
          },
          {
            id: 2,
            name: "Disons Demain",
            subscription: "Gratuite",
            description: "Réservée aux plus de 50 ans",
          },
          {
            id: 3,
            name: "Elite Rencontre",
            subscription: "Gratuite",
            description:
              "Remplissez un questionnaire très avancé contenant une centaine de questions, et celles-ci sont ensuite analysées par un algorithme puissant afin de ne vous proposer que des profils « matchant » avec vous.",
          },
          {
            id: 4,
            name: "Fruitz",
            subscription: "Gratuite",
            description:
              "Lors de votre inscription, vous allez pouvoir choisir un Fruit qui déterminera le type de rencontre que vous voulez faire : Cerise pour les personnes qui veulent du sérieux, Pêche pour les amateurs de coups d'un soir, Pastèque pour celles et ceux qui recherchent un plan cul régulier, Raisin pour une rencontre sans prise de tête et sans coup d'un soir.",
          },
          {
            id: 5,
            name: "Tinder",
            subscription:
              "Gratuite, mais certaines fonctionnalités sont payantes",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, vous permettant de commencer la discussion.",
          },
          {
            id: 6,
            name: "Happn",
            subscription: "Gratuite",
            description:
              "Rencontrez des gens qui se sont rendus dans les mêmes lieux que vous !",
          },
          {
            id: 7,
            name: "Bumble",
            subscription: "Gratuite",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, MAIS la femme aura 24h pour envoyer le premier message et l'homme aura 24h pour répondre à ce message.",
          },
          {
            id: 8,
            name: "Hinge",
            subscription: "Gratuite",
          },
          {
            id: 9,
            name: "Grindr",
            subscription: "Gratuite",
            description: "LBGTQA+",
          },
          {
            id: 10,
            name: "Adopte",
            subscription: "Gratuite",
            description:
              "Seules les femmes peuvent démarrer une conversation. Les hommes ne peuvent entrer directement en contact avec le profil d'une femme.",
          },
        ],
      },
      {
        id: "9",
        title: "9 . Adopter un animal",
        titleInside: "Adopter un animal",
        subTitle:
          "(effets positifs sur l’humeur confirmés par les scientifiques !)",
        description: "Voir le TOP 10 des races d’animaux à avoir chez soi",
        internalList: [
          {
            id: 1,
            name: "Meetic",
            subscription: "Gratuite",
          },
          {
            id: 2,
            name: "Disons Demain",
            subscription: "Gratuite",
            description: "Réservée aux plus de 50 ans",
          },
          {
            id: 3,
            name: "Elite Rencontre",
            subscription: "Gratuite",
            description:
              "Remplissez un questionnaire très avancé contenant une centaine de questions, et celles-ci sont ensuite analysées par un algorithme puissant afin de ne vous proposer que des profils « matchant » avec vous.",
          },
          {
            id: 4,
            name: "Fruitz",
            subscription: "Gratuite",
            description:
              "Lors de votre inscription, vous allez pouvoir choisir un Fruit qui déterminera le type de rencontre que vous voulez faire : Cerise pour les personnes qui veulent du sérieux, Pêche pour les amateurs de coups d'un soir, Pastèque pour celles et ceux qui recherchent un plan cul régulier, Raisin pour une rencontre sans prise de tête et sans coup d'un soir.",
          },
          {
            id: 5,
            name: "Tinder",
            subscription:
              "Gratuite, mais certaines fonctionnalités sont payantes",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, vous permettant de commencer la discussion.",
          },
          {
            id: 6,
            name: "Happn",
            subscription: "Gratuite",
            description:
              "Rencontrez des gens qui se sont rendus dans les mêmes lieux que vous !",
          },
          {
            id: 7,
            name: "Bumble",
            subscription: "Gratuite",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, MAIS la femme aura 24h pour envoyer le premier message et l'homme aura 24h pour répondre à ce message.",
          },
          {
            id: 8,
            name: "Hinge",
            subscription: "Gratuite",
          },
          {
            id: 9,
            name: "Grindr",
            subscription: "Gratuite",
            description: "LBGTQA+",
          },
          {
            id: 10,
            name: "Adopte",
            subscription: "Gratuite",
            description:
              "Seules les femmes peuvent démarrer une conversation. Les hommes ne peuvent entrer directement en contact avec le profil d'une femme.",
          },
        ],
      },
      {
        id: "10",
        title: "10 . Jouer",
        titleInside: "Jouer",
        description: "Voir le TOP 10 des jeux (vidéos et de société) en France",
        subTitle:
          "(et si la clé du bonheur était de conserver son âme d’enfant ?!)",
        internalList: [
          {
            id: 1,
            name: "Meetic",
            subscription: "Gratuite",
          },
          {
            id: 2,
            name: "Disons Demain",
            subscription: "Gratuite",
            description: "Réservée aux plus de 50 ans",
          },
          {
            id: 3,
            name: "Elite Rencontre",
            subscription: "Gratuite",
            description:
              "Remplissez un questionnaire très avancé contenant une centaine de questions, et celles-ci sont ensuite analysées par un algorithme puissant afin de ne vous proposer que des profils « matchant » avec vous.",
          },
          {
            id: 4,
            name: "Fruitz",
            subscription: "Gratuite",
            description:
              "Lors de votre inscription, vous allez pouvoir choisir un Fruit qui déterminera le type de rencontre que vous voulez faire : Cerise pour les personnes qui veulent du sérieux, Pêche pour les amateurs de coups d'un soir, Pastèque pour celles et ceux qui recherchent un plan cul régulier, Raisin pour une rencontre sans prise de tête et sans coup d'un soir.",
          },
          {
            id: 5,
            name: "Tinder",
            subscription:
              "Gratuite, mais certaines fonctionnalités sont payantes",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, vous permettant de commencer la discussion.",
          },
          {
            id: 6,
            name: "Happn",
            subscription: "Gratuite",
            description:
              "Rencontrez des gens qui se sont rendus dans les mêmes lieux que vous !",
          },
          {
            id: 7,
            name: "Bumble",
            subscription: "Gratuite",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, MAIS la femme aura 24h pour envoyer le premier message et l'homme aura 24h pour répondre à ce message.",
          },
          {
            id: 8,
            name: "Hinge",
            subscription: "Gratuite",
          },
          {
            id: 9,
            name: "Grindr",
            subscription: "Gratuite",
            description: "LBGTQA+",
          },
          {
            id: 10,
            name: "Adopte",
            subscription: "Gratuite",
            description:
              "Seules les femmes peuvent démarrer une conversation. Les hommes ne peuvent entrer directement en contact avec le profil d'une femme.",
          },
        ],
      },
      {
        id: "11",
        title: "11 . Débuter des cours en présentiel ou en ligne",
        titleInside: "Débuter des cours en présentiel ou en ligne",
        subTitle:
          "(il n’est jamais trop tard pour apprendre, progresser, faire sa passion !)",
        description: "Lire la suite",
        stories: [
          {
            title: "",
            description:
              "(il n’est jamais trop tard pour apprendre, progresser, faire sa passion !)",
            paragraphs: [
              "Développement (langages de programmation, web, mobile, jeux..)",
              "Business (commerce en ligne, entrepreneuriat, droit des affaires…)",
              "Finance et comptabilité (comptes, Excel impôts, trading, cryptomonnaies, éco…)",
              "Informatique et logiciels (certification, réseaux & sécurité, logiciels…)",
              "Productivité bureautique (Microsoft, Apple, Google, SAP, Oracle…)",
              "Développement personnel (spiritualité, leadership, éducation enfant, confiance en soi, créativité, gestion du stress, bonheur…)",
              "Design (web, jeux, graphisme, animation, mode, architecture, déco d’intérieur…)",
              "Marketing (SEO, réseaux sociaux, relations publiques, publicité, affiliation…)",
              "Mode de vie (arts et artisanat, beauté et maquillage, soins et dressage des animaux, bricolage, jardinage, DIY, voyage…)",
              "Photographie et vidéo (méthodes, outils photographiques…)",
              "Santé et bien-être (santé mentale et générale, alimentation, sports, yoga, méditation, danse, autodéfense, sécurité et premiers secours…)",
              "Musique (instruments, production musicale, chant, logiciels de musique…)",
              "Formations et diplômes (langues, mathématiques, ingénierie, sciences, psychologie, philosophie, histoire, préparation aux examens…)",
            ],
            links: [
              {
                name: "TOP 20 DES COURS EN LIGNE UDEMY LES PLUS POPULAIRES PAR CATEGORIES",
                url: "/ressources/details/1?resourceId=11",
              },
            ],
          },
        ],
        internalList: [
          {
            id: 1,
            name: "Design",
            link: "",
          },
          {
            id: 2,
            name: "Développement",
            link: "",
          },
          {
            id: 3,
            name: "Marketing",
            link: "",
          },
          {
            id: 4,
            name: "Informatique et logiciels",
            link: "",
          },
          {
            id: 5,
            name: "Développement personnel",
            link: "",
          },
          {
            id: 6,
            name: "Business",
            link: "",
          },
          {
            id: 7,
            name: "Photographie",
            link: "",
          },
          {
            id: 8,
            name: "Musique",
            link: "",
          },
        ],
      },
      {
        id: "12",
        title:
          "12 . Se rendre à un concert, un festival, un spectacle, une pièce de théâtre, un évènement sportif",
        titleInside:
          "Se rendre à un concert, un festival, un spectacle, une pièce de théâtre, un évènement sportif",
        description: "Voir le TOP 20 des plus gros évènements à venir",
        subTitle: "(simple et efficace !)",
      },
      {
        id: "13",
        title:
          "13 . Voyager et Faire des activités (parcs et jardins, musées, hôtels, spa, restaurants/bars, attractions, randonnées, activités nautiques, ski, motoneige, paintball, bowling, escape game, laser game, quad, karting, patinoire, saut en parachute, montgolfière, zoo, accrobranche, camping, cirque, etc.)",
        titleInside:
          "Voyager et Faire des activités (parcs et jardins, musées, hôtels, spa, restaurants/bars, attractions, randonnées, activités nautiques, ski, motoneige, paintball, bowling, escape game, laser game, quad, karting, patinoire, saut en parachute, montgolfière, zoo, accrobranche, camping, cirque, etc.)",
        subTitle: "(#GoodLife !)",
        description: "Lire la suite",
      },
      {
        id: "14",
        title:
          "14 . Admirer un beau paysage, un coucher du soleil, un arc-en-ciel, les étoiles ; marcher pieds nus dans l’herbe, le sable ; écouter le chant des oiseaux, le bruit des vagues ; Sentir des fleurs, respirer une odeur qu’on apprécie ; Juste s’arrêter, et profiter de la nature",
        titleInside: "Profiter de la nature",
          description:
          "Voir le TOP 10 des huiles essentielles aux meilleures senteurs",
        internalList: [
          {
            id: 1,
            name: "Meetic",
            subscription: "Gratuite",
          },
          {
            id: 2,
            name: "Disons Demain",
            subscription: "Gratuite",
            description: "Réservée aux plus de 50 ans",
          },
          {
            id: 3,
            name: "Elite Rencontre",
            subscription: "Gratuite",
            description:
              "Remplissez un questionnaire très avancé contenant une centaine de questions, et celles-ci sont ensuite analysées par un algorithme puissant afin de ne vous proposer que des profils « matchant » avec vous.",
          },
          {
            id: 4,
            name: "Fruitz",
            subscription: "Gratuite",
            description:
              "Lors de votre inscription, vous allez pouvoir choisir un Fruit qui déterminera le type de rencontre que vous voulez faire : Cerise pour les personnes qui veulent du sérieux, Pêche pour les amateurs de coups d'un soir, Pastèque pour celles et ceux qui recherchent un plan cul régulier, Raisin pour une rencontre sans prise de tête et sans coup d'un soir.",
          },
          {
            id: 5,
            name: "Tinder",
            subscription:
              "Gratuite, mais certaines fonctionnalités sont payantes",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, vous permettant de commencer la discussion.",
          },
          {
            id: 6,
            name: "Happn",
            subscription: "Gratuite",
            description:
              "Rencontrez des gens qui se sont rendus dans les mêmes lieux que vous !",
          },
          {
            id: 7,
            name: "Bumble",
            subscription: "Gratuite",
            description:
              "Swipez à droite pour aimer, swipez à gauche pour passer au prochain profil. Si vous aimez une personne qui vous a aussi aimé(e), un match se passe, MAIS la femme aura 24h pour envoyer le premier message et l'homme aura 24h pour répondre à ce message.",
          },
          {
            id: 8,
            name: "Hinge",
            subscription: "Gratuite",
          },
          {
            id: 9,
            name: "Grindr",
            subscription: "Gratuite",
            description: "LBGTQA+",
          },
          {
            id: 10,
            name: "Adopte",
            subscription: "Gratuite",
            description:
              "Seules les femmes peuvent démarrer une conversation. Les hommes ne peuvent entrer directement en contact avec le profil d'une femme.",
          },
        ],
      },
      {
        id: "15",
        title: "15 . Faire du bénévolat, des actions caritatives",
        titleInside: "Faire du bénévolat, des actions caritatives",
        subTitle: "",
        description:
          "Trouvez l’association qui vous convient dans notre Annuaire",
        buttonTitle: "Trouver une association",
        link: "/professionals",
      },
      {
        id: "16",
        title: "16 . Dormir suffisamment",
        titleInside: "Dormir suffisamment",
        subTitle:
          "Voici 5 bonnes raisons pour lesquelles il est crucial de prioriser le sommeil.",
        description: "Lire la suite",
        reasonsListDescription:
          "Le sommeil est un élément essentiel de notre bien-être général, et son impact sur la santé physique, mentale et émotionnelle ne doit pas être sous-estimé. Pourtant, dans notre société moderne, nous sommes nombreux à souvent sacrifier des heures de sommeil au profit d'obligations professionnelles, sociales ou de divertissements. Cependant, il est impératif de reconnaître l'importance de dormir suffisamment pour maintenir un mode de vie sain et équilibré.",
        stories: [
          {
            title: "",
            description: "",
            paragraphs: [
              "Au fait, à quoi réfléchissons-nous quand nous sommes dans notre lit ? Car la qualité de notre sommeil dépendra, entre autres, de la qualité de nos pensées avant de nous endormir. Alors, laissons la résolution de nos problèmes pour demain ! Promis, on y réfléchira demain !",
              "Et aussi, dormir à des heures constantes, il paraît que ça aide à faire un bon dodo !",
              "Tristesse et insomnies… Un jour…la situation s’améliorera, et le sommeil reviendra… (si vous n’y arrivez pas seule ou avec votre entourage, un professionnel peut vous aider…).",
            ],
            links: [
              {
                name: "5 BONNES RAISONS DE DORMIR SUFFISAMMENT",
                url: "/ressources/details3/2?resourceId=16&reasons=true",
              },
              {
                name: "LES 5 MEILLEURES APPLICATIONS DE RELAXATION, DE MÉDITATION, DE RESPIRATION ET DU SOMMEIL",
                url: "/ressources/details3/2?resourceId=16&applications=true",
              },
            ],
          },
        ],
        reasonsList: [
          {
            id: 1,
            name: "1. Régénération Physique",
            description:
              "Le sommeil est le moment où le corps se régénère et se répare. Pendant le sommeil, la production d'hormones de croissance est à son apogée, favorisant la réparation des tissus, le renouvellement cellulaire et le renforcement du système immunitaire. Un sommeil suffisant permet au corps de récupérer après une journée d'activités physiques et de stress.",
          },
          {
            id: 2,
            name: "2. Fonctions Cognitives Améliorées",
            description:
              "Le sommeil joue un rôle crucial dans le fonctionnement cognitif. Des études ont montré qu’ilcontribue à consolider la mémoire, à renforcer la capacité d'apprentissage et à stimuler la créativité. Une bonne nuit de sommeil permet d'améliorer la concentration, la résolution de problèmes et la prise de décision.",
          },
          {
            id: 3,
            name: "3. Régulation Émotionnelle",
            description:
              "Le manque de sommeil est souvent lié à des fluctuations émotionnelles et à un niveau de stress accru. Un sommeil suffisant favorise l'équilibre émotionnel en régulant les niveaux d'hormones responsables du stress et de l'anxiété. Cela contribue à maintenir une santé mentale optimale et à améliorer la gestion des émotions au quotidien.",
          },
          {
            id: 4,
            name: "4. Prévention des Problèmes de Santé",
            description:
              "Des recherches ont établi des liens entre le manque de sommeil et divers problèmes de santé, tels que l'hypertension, le diabète, l'obésité et les maladies cardiovasculaires. Dormir suffisamment joue un rôle préventif crucial dans le maintien d'une bonne santé physique à long terme.",
          },
          {
            id: 5,
            name: "5. Performance Professionnelle et Académique",
            description:
              "Un sommeil adéquat est associé à une meilleure performance au travail et à l'école. Les individus qui dorment suffisamment ont tendance à être plus productifs, créatifs et capables de relever les défis professionnels et académiques avec plus de facilité.",
          },
        ],
        applicationsList: [
          {
            id: 1,
            name: "1. Relax Mélodies: Sommeil, Méditation et Détente (+10M d’utilisateurs)",
            description:
              "Musiques et méditations pour réduire l’anxiété, s’endormir.",
          },
          {
            id: 2,
            name: "2. Calm - Méditer, dormir, se relaxer (+10M d’utilisateurs)",
            description:
              "Calm est l’appli n°1 pour la méditation et le sommeil.",
          },
          {
            id: 3,
            name: "3. Meditopia: Méditation, Dormir, Musique Relaxante (+10M d’utilisateurs)",
            description:
              "Histoires pour s’endormir, pleine conscience et méditation guidée en Français.",
          },
          {
            id: 4,
            name: "4. Respiration Pranique: Méditation & Sérénité (+1M d’utilisateurs)",
            description:
              "Optimisez la pleine conscience, la santé, le stress avec la respiration/méditation.",
          },
          {
            id: 5,
            name: "5. Sleep Monitor - Suivi du Sommeil, Enregistrement (+1M d’utilisateurs)",
            description:
              "Catte app vous aide à comprendre les cycles de sommeil, à enregistrer des sons.",
          },
        ],
        reasonsConsclusions: [
          "En conclusion, sacrifier le sommeil au profit d'autres engagements peut avoir des conséquences néfastes sur la santé physique et mentale. Prioriser le sommeil devrait être considéré comme un investissement dans notre bien-être global. Adopter des habitudes de sommeil saines contribue à une vie équilibrée, améliore la performance quotidienne et préserve la santé à long terme. En fin de compte, accorder à notre corps et à notre esprit le repos nécessaire est un choix éclairé et bienveillant en faveur d'une vie épanouissante.",
        ],
      },
      {
        id: "17",
        title: "17 . Bien manger et boire suffisamment d’eau",
        titleInside: "[Bisou bisou à la TeamGourmand(e)(s) ! ]",
        subTitle: "",
        topIcon: nourritureIcon,
        topIcon1: eauIcon,
        description: "Lire la suite",
        reasonsListDescription:
          "L'eau, souvent appelée « l'élixir de la vie », est un élément essentiel à notre survie et à notre bien-être. Pourtant, dans notre quotidien frénétique, il est parfois facile de négliger notre consommation d'eau au profit d'autres boissons ou activités. Cependant, les bonnes raisons de boire suffisamment d'eau sont nombreuses et impactent positivement notre santé physique, mentale et émotionnelle. Examinons pourquoi cette habitude simple mais cruciale devrait être une priorité dans notre vie quotidienne.",
        stories: [
          {
            title: "",
            description: "",
            paragraphs: [
              "Tristesse et boulimie ou perte d’appétit… Un jour…la situation s’améliorera… (si vous n’y arrivez pas seule ou avec votre entourage, un professionnel peut vous aider…)",
            ],
            links: [
              {
                name: "6 BONNES RAISONS DE BOIRE SUFFISAMMENT D’EAU",
                url: "/ressources/details3/2?resourceId=17&reasons=true",
              },
              {
                name: "LES 5 MEILLEURES APPLICATIONS D’ALIMENTATION (QUALITÉ DES PRODUITS, SUIVI ALIMENTAIRE, COMPTEURS DE CALORIES, ETC.)",
                url: "/ressources/details3/2?resourceId=17&applications=true",
              },
            ],
          },
        ],
        reasonsList: [
          {
            id: 1,
            name: "1. Hydratation Optimale pour le Corps",
            description:
              "L'eau constitue la majeure partie de notre corps, contribuant à maintenir des fonctions biologiques vitales. Une hydratation adéquate favorise le bon fonctionnement des organes, des cellules et des systèmes corporels. Cela facilite la digestion, la régulation de la température corporelle, et le transport des nutriments à travers le sang.",
          },
          {
            id: 2,
            name: "2. Énergie et Vitalité",
            description:
              "Boire suffisamment d'eau joue un rôle clé dans le maintien de niveaux d'énergie optimaux. Une déshydratation même légère peut entraîner une sensation de fatigue et de manque de motivation. En maintenant une hydratation adéquate, nous favorisons la production d'énergie cellulaire et améliorons notre résistance physique.",
          },
          {
            id: 3,
            name: "3. Meilleure Concentration et Clarté Mentale",
            description:
              "L'eau est essentielle au bon fonctionnement du cerveau. Une hydratation adéquate améliore la concentration, la mémoire et la clarté mentale. Des études ont montré que même une déshydratation légère peut entraîner une diminution des performances cognitives.",
          },
          {
            id: 4,
            name: "4. Élimination des Toxines",
            description:
              "L'eau agit comme un purificateur naturel en éliminant les toxines du corps. Une hydratation suffisante favorise le bon fonctionnement des reins, permettant ainsi l'élimination efficace des déchets et des substances nocives.",
          },
          {
            id: 5,
            name: "5. Soutien à la Perte de Poids",
            description:
              "Boire de l'eau avant les repas peut contribuer à une gestion du poids plus efficace. L'eau aide à créer une sensation de satiété, réduisant ainsi la probabilité de suralimentation. Elle favorise également la digestion et le métabolisme.",
          },
          {
            id: 6,
            name: "6. Santé de la Peau",
            description:
              "Une hydratation adéquate favorise une peau saine et éclatante. L'eau hydrate les cellules cutanées, prévient la sécheresse et contribue à réduire les signes de vieillissement prématuré.",
          },
        ],
        applicationsList: [
          {
            id: 1,
            name: "1. Relax Mélodies: Sommeil, Méditation et Détente (+10M d’utilisateurs)",
            description:
              "Musiques et méditations pour réduire l’anxiété, s’endormir.",
          },
          {
            id: 2,
            name: "2. Calm - Méditer, dormir, se relaxer (+10M d’utilisateurs)",
            description:
              "Calm est l’appli n°1 pour la méditation et le sommeil",
          },
          {
            id: 3,
            name: "3. Meditopia: Méditation, Dormir, Musique Relaxante (+10M d’utilisateurs)",
            description:
              "Histoires pour s’endormir, pleine conscience et méditation guidée en Français.",
          },
          {
            id: 4,
            name: "4. Respiration Pranique: Méditation & Sérénité (+1M d’utilisateurs)",
            description:
              "Optimisez la pleine conscience, la santé, le stress avec la respiration/méditation.",
          },
          {
            id: 5,
            name: "5. Sleep Monitor - Suivi du Sommeil, Enregistrement (+1M d’utilisateurs)",
            description:
              "Top app vous aide à comprendre les cycles de sommeil, à enregistrer des sons.",
          },
        ],

        reasonsConsclusions: [
          "En conclusion, boire suffisamment d'eau est bien plus qu'une simple habitude ; c'est un acte d'amour envers notre propre corps et notre bien-être global. Les bienfaits de l'hydratation vont au-delà de la simple satisfaction de la soif. Ils influent sur notre énergie, notre concentration, notre santé physique et mentale. Adopter la pratique régulière de boire de l'eau est une étape simple mais puissante vers une vie saine et équilibrée. Alors, à chaque gorgée, nous investissons dans notre santé !",
        ],
      },
      {
        id: "18",
        title:
          "18 . Faire du sport, des étirements, de la danse, toutes activités physiques qui nous font du bien…",
        titleInside:
          "Faire du sport, des étirements, de la danse, toutes activités physiques qui nous font du bien…",
        subTitle: "",
        topIcon: activitiesIcon,
        topIcon1: etirementsIcon,
        description: "Lire la suite",
        subTitle: "",
        reasonsListDescription:
          "L'activité physique est bien plus qu'un simple moyen de sculpter son corps. C'est un pilier essentiel pour promouvoir une vie saine et équilibrée. Qu'il s'agisse de la danse, du yoga, de la course à pied, de la musculation ou d'autres formes d'exercice, l'engagement dans une activité physique régulière offre une multitude de bienfaits pour le corps et l'esprit. Découvrons ensemble les 6 bonnes raisons de faire de l'exercice une priorité dans notre vie quotidienne.",
        stretchReasonsListDescription:
          "Les étirements, souvent relégués au simple rôle de préparation à l'exercice, sont en réalité une composante essentielle d'une routine de bien-être complète. Que vous soyez sportif ou que vous passiez de longues heures assis devant un bureau, les étirements offrent des avantages significatifs pour le corps et l'esprit. Découvrez 5 bonnes raisons de faire des étirements et pourquoi vous devriez les intégrer dans votre quotidien.",
        stories: [
          {
            title: "",
            description: "",
            paragraphs: [],
            links: [
              {
                name: "6 BONNES RAISONS DE PRATIQUER UNE ACTIVITÉ PHYSIQUE",
                url: "/ressources/details3/2?resourceId=18&reasons=true",
              },
              {
                name: "LES 6 MEILLEURES APPLICATIONS DE SPORT",
                url: "/ressources/details3/2?resourceId=18&applications=true",
              },
              {
                name: "5 BONNES RAISONS DE S'ÉTIRER",
                url: "/ressources/details3/2?resourceId=18&stretchReasons=true",
              },
              {
                name: "LES 3 MEILLEURES APPLICATIONS D’ÉTIREMENTS",
                url: "/ressources/details3/2?resourceId=18&stretchApplications=true",
              },
            ],
          },
        ],
        reasonsList: [
          {
            id: 1,
            name: "1. Amélioration de la Santé Cardiovasculaire",
            description:
              "L'exercice régulier renforce le cœur et les poumons, améliorant ainsi la circulation sanguine et réduisant le risque de maladies cardiovasculaires. Une activité physique soutenue contribue à maintenir une pression artérielle saine et à réguler le taux de cholestérol.",
          },
          {
            id: 2,
            name: "2. Gestion du Poids et Tonification Musculaire",
            description:
              "L'activité physique est un allié puissant dans la gestion du poids. Elle brûle des calories, favorise la perte de graisse et tonifie les muscles. En intégrant des exercices de force, on peut également sculpter et renforcer différentes parties du corps.",
          },
          {
            id: 3,
            name: "3. Stimulation du Cerveau et Amélioration de la Concentration",
            description:
              "L'exercice n'est pas seulement bénéfique pour le corps, il stimule également le cerveau. Des études ont montré que l'activité physique régulière favorise la libération de substances chimiques cérébrales, améliorant la concentration, la mémoire et les capacités cognitives.",
          },
          {
            id: 4,
            name: "4. Réduction du Stress et de l'Anxiété",
            description:
              "L'exercice est un puissant antidote au stress quotidien. Il libère des endorphines, les hormones du bonheur, qui agissent comme des analgésiques naturels. En participant régulièrement à une activité physique, on peut réduire l'anxiété et promouvoir une meilleure santé mentale.",
          },
          {
            id: 5,
            name: "5. Renforcement du Système Immunitaire",
            description:
              "Une activité physique modérée renforce le système immunitaire en stimulant la circulation des cellules immunitaires dans tout le corps. Cela contribue à prévenir les maladies et à favoriser une meilleure résistance aux infections.",
          },
          {
            id: 6,
            name: "6. Promotion d'un Sommeil de Qualité",
            description:
              "L'exercice régulier favorise un sommeil réparateur. Il contribue à réguler le cycle veille-sommeil, améliorant ainsi la qualité du repos nocturne. Un bon sommeil, à son tour, soutient la récupération physique et mentale.",
          },
        ],
        applicationsList: [
          {
            id: 1,
            name: "1. Exercices à la maison - Sans équipement (+100M d’utilisateurs)",
            description:
              "Des exercices à la maison pour se muscler à maison sans équipements.",
          },
          {
            id: 2,
            name: "2. Des muscles abdominaux en 30 jours (+100M d’utilisateurs)",
            description:
              "Entraînement pour les abdos. Perdez du gras & gagnez des abdos chez vous.",
          },
          {
            id: 3,
            name: "3. Perte de Poids pour Femmes - Exercices à la Maison (+50M d’utilisateurs)",
            description:
              "Perdez du poids et du ventre avec des exercices brûle-graisses pour femmes.",
          },
          {
            id: 4,
            name: "4. Fitness femme – Entraînement pour femmes (+50M d’utilisateurs)",
            description:
              "Mincissez et restez en forme chez vous ! La meilleure app de fitness féminin.",
          },
          {
            id: 5,
            name: "5. Exercices brûle-graisses - Perdre du poids (+10M d’utilisateurs)",
            description:
              "Perdre rapidement du poids & être en forme avec des exos brûle-graisses chez soi.",
          },
          {
            id: 6,
            name: "6. Entraînement jambes & fessiers (+10M d’utilisateurs)",
            description:
              "Vous voulez muscler vos jambes et vos fessiers ? Ce programme est pour vous !",
          },
        ],

        stretchReasonsList: [
          {
            id: 1,
            name: "1. Amélioration de la souplesse",
            description:
              "L'amélioration de la flexibilité est l'un des avantages les plus évidents des étirements. Une flexibilité accrue signifie une plus grande amplitude de mouvement, ce qui peut améliorer vos performances sportives, réduire le risque de blessures et faciliter les activités quotidiennes. Des muscles et des articulations souples contribuent à une meilleure posture, prévenant ainsi les douleurs musculaires et articulaires liées à une position assise prolongée.",
          },
          {
            id: 2,
            name: "2. Réduction des Tensions Musculaires",
            description:
              "Les étirements sont un moyen efficace de relâcher les tensions musculaires accumulées. Que ce soit à cause du stress, de l'exercice intense ou d'une mauvaise posture, les muscles peuvent devenir tendus et inconfortables. Les étirements permettent de libérer cette tension, favorisant ainsi une sensation de détente et de relâchement. Cela contribue également à réduire le risque de courbatures après l'exercice.",
          },
          {
            id: 3,
            name: "3. Prévention des Blessures",
            description:
              "Intégrer des étirements dans votre routine peut contribuer significativement à la prévention des blessures. Des muscles flexibles sont moins sujets aux élongations et aux déchirures. En travaillant sur la souplesse musculaire et en améliorant l'amplitude des mouvements, vous renforcez la résilience de votre corps face aux sollicitations physiques.",
          },
          {
            id: 4,
            name: "4. Amélioration de la Circulation Sanguine",
            description:
              "Les étirements stimulent la circulation sanguine vers les muscles, favorisant ainsi un apport accru d'oxygène et de nutriments. Une circulation sanguine optimale contribue à la santé des tissus musculaires et accélère la récupération après l'exercice. Une meilleure circulation peut également aider à prévenir la sensation de jambes lourdes et favoriser une peau plus saine.",
          },
          {
            id: 5,
            name: "5. Soulagement du Stress et Amélioration du Bien-être Mental",
            description:
              "Les étirements ne sont pas seulement bénéfiques pour le corps, mais aussi pour l'esprit. En relâchant les tensions physiques, les étirements contribuent à apaiser le système nerveux. Cette pratique peut aider à réduire le niveau de stress, favorisant ainsi un état mental plus calme et équilibré. Intégrer des étirements dans votre routine peut être une stratégie efficace pour détendre votre esprit après une journée bien remplie.",
          },
        ],
        stretchApplicationsList: [
          {
            id: 1,
            name: "1. Exercices D'étirement à la Maison - Flexibilité (+10M d’utilisateurs)",
            description:
              "Étirements pour tous les groupes de muscles, souplesse, soulager la douleur.",
          },
          {
            id: 2,
            name: "2. Grand Écart - Entraînement Grand Écart en 30 Jours (+10M d’utilisateurs)",
            description:
              "Faites le grand écart en 30 jours avec cet entraînement. Convient aux débutants.",
          },
          {
            id: 3,
            name: "3. BetterMe : Entraînement à domicile et alimentation (+10M d’utilisateurs)",
            description:
              "Entraînements minceur : Abdos, Fessier, Jambes. Régimes : Kéto, Végétarien, Jeûne.",
          },
        ],
        reasonsConsclusions: [
          "En conclusion, intégrer une activité physique dans notre quotidien est un investissement précieux dans notre bien-être global. Que ce soit pour les avantages physiques, mentaux ou émotionnels, l'exercice régulier offre une palette de bienfaits qui contribuent à une vie plus saine et plus épanouissante.",
        ],
        stretchReasonsConsclusions: [
          "En conclusion, les étirements vont bien au-delà de la simple flexibilité musculaire. Ils offrent une multitude d'avantages, de la prévention des blessures à la détente mentale. Intégrer quelques minutes d'étirements dans votre quotidien peut faire une grande différence dans votre bien-être global. Alors, que vous soyez débutant ou athlète aguerri, prenez le temps de vous étirer et de ressentir les bienfaits pour votre corps et votre esprit.",
        ],
      },
      {
        id: "19",
        title: "19 . Se relaxer, faire des exercices de respiration",
        titleInside: "Se relaxer, faire des exercices de respiration",
        subTitle: "",
        topIcon: respirerIcon,
        description: "Lire la suite",
        subTitle: "",
        reasonsListDescription:
          "Dans la frénésie quotidienne de nos vies modernes, il est facile d'oublier l'importance fondamentale de la respiration et de la relaxation. Pourtant, ces pratiques simples détiennent les clés d'un équilibre mental, émotionnel et physique. En explorant les profondeurs de notre propre souffle et en accordant du temps à la relaxation, nous découvrons des bienfaits qui transcendent le simple acte de respirer. Voici pourquoi prendre le temps de respirer profondément et de se relaxer régulièrement devrait être une priorité dans notre quête du bien-être.",
        stories: [
          {
            title: "",
            description: "",
            paragraphs: [],
            links: [
              {
                name: "6 BONNES RAISONS DE SE RELAXER",
                url: "/ressources/details3/2?resourceId=19&reasons=true",
              },
              {
                name: "LES 5 MEILLEURES APPLICATIONS DE RELAXATION, DE MEDITATION, DE RESPIRATION ET DU SOMMEIL",
                url: "/ressources/details3/2?resourceId=19&applications=true",
              },
            ],
          },
        ],
        reasonsList: [
          {
            id: 1,
            name: "1. Réduction du Stress et de l'Anxiété",
            description:
              "La respiration profonde et la relaxation sont des antidotes naturels au stress. Des techniques de respiration consciente aident à calmer le système nerveux, réduisant ainsi les niveaux de cortisol, l'hormone du stress. La relaxation, quant à elle, favorise la libération de tensions physiques et mentales.",
          },
          {
            id: 2,
            name: "2. Amélioration de la Concentration et de la Clarté Mentale",
            description:
              "En prenant le temps de respirer consciemment, nous oxygénons notre cerveau de manière plus efficace. Cela se traduit par une amélioration de la concentration, de la créativité et de la clarté mentale. Des moments de relaxation permettent au cerveau de se régénérer, favorisant ainsi une pensée plus lucide.",
          },
          {
            id: 3,
            name: "3. Soulagement des Tensions Physiques",
            description:
              "La respiration profonde atteint les muscles tendus, relâchant les nœuds de tension. La relaxation musculaire qui en résulte améliore la souplesse, réduit les douleurs chroniques et contribue à une posture corporelle plus équilibrée.",
          },
          {
            id: 4,
            name: "4. Meilleure Gestion des Émotions",
            description:
              "La respiration consciente est un moyen puissant de réguler les émotions. Elle offre un espace entre le stimulus et la réponse, permettant ainsi une réaction plus réfléchie. La relaxation, de son côté, favorise une perspective calme et équilibrée face aux défis émotionnels.",
          },
          {
            id: 5,
            name: "5. Amélioration de la Qualité du Sommeil",
            description:
              "Pratiquer la relaxation avant le coucher prépare le corps et l'esprit à un sommeil réparateur. Des techniques de respiration lente et profonde induisent un état de détente propice à un repos nocturne de qualité.",
          },
          {
            id: 6,
            name: "6. Renforcement du Système Immunitaire",
            description:
              "La détente et la respiration profonde stimulent le système parasympathique, qui favorise le bon fonctionnement du système immunitaire. En permettant au corps de passer plus de temps dans un état de repos, nous renforçons notre capacité à lutter contre les maladies.",
          },
        ],
        applicationsList: [
          {
            id: 1,
            name: "1. Relax Mélodies: Sommeil, Méditation et Détente (+10M d’utilisateurs)",
            description:
              "Musiques et méditations pour réduire l’anxiété, s’endormir.",
          },
          {
            id: 2,
            name: "2. Calm - Méditer, dormir, se relaxer (+10M d’utilisateurs)",
            description:
              "Calm est l’appli n°1 pour la méditation et le sommeil",
          },
          {
            id: 3,
            name: "3. Meditopia: Méditation, Dormir, Musique Relaxante (+10M d’utilisateurs)",
            description:
              "Histoires pour s’endormir, pleine conscience et méditation guidée en Français.",
          },
          {
            id: 4,
            name: "4. Respiration Pranique: Méditation & Sérénité (+1M d’utilisateurs)",
            description:
              "Optimisez la pleine conscience, la santé, le stress avec la respiration/méditation.",
          },
          {
            id: 5,
            name: "5. Sleep Monitor - Suivi du Sommeil, Enregistrement (+1M d’utilisateurs)",
            description:
              "Top app vous aide à comprendre les cycles de sommeil, à enregistrer des sons.",
          },
        ],

        reasonsConsclusions: [
          "En conclusion, respirer consciemment et se relaxer régulièrement sont des cadeaux que nous pouvons nous offrir à tout moment de la journée. Ces pratiques simples, accessibles à tous, sont des outils puissants pour cultiver un bien-être global. En les intégrant dans notre routine quotidienne, nous découvrons que la véritable essence de la vie réside dans la paix intérieure et la joie qui émane de la conscience du souffle. Alors, prenons le temps de respirer et de nous relaxer, car dans ces moments, nous trouvons un refuge précieux dans le tourbillon de la vie moderne.",
        ],
      },
      {
        id: "20",
        title: "20 . Prendre soin de son corps",
        titleInside: "Prendre soin de son corps",
        subTitle: "",
        topIcon: cosmaticBioIcon,
        description: "Lire la suite",
        reasonsListDescription:
          "À l'heure où la conscience environnementale et la recherche d'une vie saine s'imposent, les cosmétiques biologiques se présentent comme une réponse pertinente à la quête de beauté et de bien-être. Plus qu'une simple tendance, le choix de produits cosmétiques BIO représente une véritable déclaration d'intention en faveur de notre santé et de notre planète.",
        stories: [
          {
            title: "",
            description: "",
            paragraphs: [],
            links: [
              {
                name: "LES COSMÉTIQUES BIO, OU COMMENT ALLIER BEAUTÉ ET SANTÉ",
                url: "/ressources/details3/2?resourceId=20&reasons=true",
              },
            ],
          },
        ],
        reasonsList: [
          {
            id: 1,
            name: "1. Des Ingrédients Naturels et Bienfaisants",
            description:
              "La caractéristique principale des cosmétiques BIO réside dans l'utilisation d'ingrédients naturels, issus de l'agriculture biologique. Ces ingrédients sont souvent riches en nutriments, vitamines, et antioxydants, favorisant la santé de la peau sans recourir à des produits chimiques agressifs.",
          },
          {
            id: 2,
            name: "2. Exclusion des Substances Nocives",
            description:
              "Les produits de beauté conventionnels peuvent contenir une pléthore de produits chimiques potentiellement nocifs pour la peau et la santé en général. Les cosmétiques BIO excluent généralement ces substances, comme les parabènes, les sulfates, et les phtalates, privilégiant ainsi une approche plus douce et respectueuse de l'épiderme.",
          },
          {
            id: 3,
            name: "3. Respect de l'Équilibre Cutané",
            description:
              "Les cosmétiques BIO sont conçus pour respecter l'équilibre naturel de la peau. En évitant les ingrédients agressifs, ils permettent à la peau de maintenir son niveau d'hydratation, réduisant ainsi le risque d'irritations et de réactions allergiques.",
          },
          {
            id: 4,
            name: "4. Éthique et Durabilité",
            description:
              "Choisir des cosmétiques BIO est également un acte éthique. Les marques engagées dans la production de produits biologiques sont souvent soucieuses de l'environnement, privilégiant les emballages recyclables et la durabilité des ingrédients. Cela contribue à réduire notre empreinte écologique.",
          },
          {
            id: 5,
            name: "5. Bienfaits pour la Santé Générale",
            description:
              "Ce n'est pas seulement la peau qui bénéficie des cosmétiques BIO, mais aussi la santé générale. En évitant l'exposition régulière à des produits chimiques potentiellement nocifs, on contribue à préserver la santé du corps dans son ensemble.",
          },
          {
            id: 6,
            name: "6. Innovation et Efficacité",
            description:
              "L'idée selon laquelle les produits biologiques seraient moins efficaces est largement dépassée. Les avancées dans la recherche et le développement des cosmétiques BIO permettent de créer des produits tout aussi performants, voire plus, grâce à des formulations innovantes.",
          },
        ],
        reasonsConsclusions: [
          "En conclusion, les cosmétiques BIO offrent bien plus qu'une simple routine de beauté. Ils représentent un choix conscient en faveur de notre bien-être et de celui de notre planète. En alliant des ingrédients naturels, le respect de l'équilibre cutané, et une approche durable, ces produits incarnent une nouvelle ère de beauté où esthétique et santé se conjuguent harmonieusement. Alors, optons pour le BIO, pour une beauté qui transcende l'apparence pour embrasser la santé et la durabilité !",
        ],
      },
      {
        id: "21",
        title:
          "21 . Décorer sa maison (en y mettant des plantes naturelles par exemple)",
        titleInside:
          "Décorer sa maison (en y mettant des plantes naturelles par exemple)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "22",
        title:
          "22 . Ranger, trier (maison, papiers, sacs, voiture, ordinateur, smartphone, appareil photo, boîte mails, etc.)",
        titleInside:
          "Ranger, trier (maison, papiers, sacs, voiture, ordinateur, smartphone, appareil photo, boîte mails, etc.)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "23",
        title:
          "23 . Faire des activités manuelles (cuisine, jardinage, bricolage, DIY, artisanat, etc.)",
        titleInside:
          "Faire des activités manuelles (cuisine, jardinage, bricolage, DIY, artisanat, etc.)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "24",
        title:
          "24 . Pratiquer un art : musique, cinéma, écriture, dessin, graphisme, peinture, théâtre, humour, chant, danse, photographie, décoration florale, art culinaire, sculpture, architecture et décoration d’intérieur, etc.",
        titleInside:
          "Pratiquer un art : musique, cinéma, écriture, dessin, graphisme, peinture, théâtre, humour, chant, danse, photographie, décoration florale, art culinaire, sculpture, architecture et décoration d’intérieur, etc.",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "25",
        title:
          "25 . Prendre conscience qu’il est normal de se sentir mal dans un environnement « malade » c’est-à-dire injuste, immoral, malsain, nuisible pour notre santé ou notre équilibre familial (ex. milieu professionnel, milieu scolaire, entourage, autre groupes de personnes…)",
        titleInside:
          "Prendre conscience qu’il est normal de se sentir mal dans un environnement « malade » c’est-à-dire injuste, immoral, malsain, nuisible pour notre santé ou notre équilibre familial (ex. milieu professionnel, milieu scolaire, entourage, autre groupes de personnes…)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "26",
        title:
          "26 . Avoir le courage d’entreprendre un changement nécessaire dans sa vie.",
        titleInside:
          "Avoir le courage d’entreprendre un changement nécessaire dans sa vie.",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "27",
        title: "27 . Agir en fonction de ses espoirs et non de ses peurs.",
        titleInside: "Agir en fonction de ses espoirs et non de ses peurs.",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "28",
        title: "28 . Faire de son mieux pour réaliser ses rêves",
        titleInside: "Faire de son mieux pour réaliser ses rêves",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "29",
        title:
          "29 . Commencer aujourd’hui. Pas demain, pas la semaine prochaine, pas dans un mois. Aujourd’hui.",
        titleInside:
          "Commencer aujourd’hui. Pas demain, pas la semaine prochaine, pas dans un mois. Aujourd’hui.",
        subTitle: "",
        description: "Même si c’est par une minuscule action…",
        description2: "Lire la suite",
      },
      {
        id: "30",
        title:
          "30 . Prendre la pleine responsabilité de ses succès, mais aussi de ses « échecs », de ses abandons, de ses regrets.",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "31",
        title:
          "31 . Ne retenir que les conseils constructifs de son entourage (ceux qui même s'ils sont négatifs, nous permettent de voir les choses sous un autre angle)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "32",
        title: "32 . Ignorer le regard des autres !",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "33",
        title:
          "33 . Être prêt à se remettre en question, à tirer des leçons de chacune de ses expériences, à travailler sur soi, à changer, à se transformer, à évoluer, à réfléchir à ses blessures/à ses traumatismes, et discuter avec son entourage (certains poids familiaux peuvent se retrouver dans plusieurs générations passées et futures ; en prendre conscience crée l’occasion de traiter le problème pour soi et pour sa descendance #CouperLesLiens)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "34",
        title:
          "34 . Éviter de réagir à chaud ; quand nos émotions nous envahissent, essayons de laisser la pression redescendre",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "35",
        title: "35 . Conserver son éthique et ses valeurs",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "36",
        title: "36 . Être tolérant et bienveillant",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "37",
        title:
          "37 . Rechercher l’équilibre, le juste milieu, dans toutes les situations que nous rencontrons… ",
        subTitle: "",
        description:
          "« Pas assez » peut poser problème, et l’excès en tout nuit.",
      },
      {
        id: "38",
        title:
          "38 . Savoir reconnaître et apprécier comment l’autre nous démontre son amour (ça vaut pour notre amour, notre famille, nos amis)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "39",
        title:
          "39 . Diminuer nos attentes envers notre entourage (chacun pense différemment…)",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "40",
        title:
          "40 . Pardonner (#Vengeance) ; Se pardonner ; Savoir demander pardon #BonnesOndes #LePositifAttireLePositif #+=+",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "41",
        title:
          "41 . Tout faire pour ne pas envier le bonheur d’autrui #jalousie #jugements #dénigrement #haine #StratégieDeDéfenseQuiCacheQuoi ? #MauvaisesOndes #LeNégatifAttireLeNégatif",
        title2: "#-=-",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "42",
        title: "42 . Rester humble, nul ne sait ce que l’avenir lui réserve",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "43",
        title:
          "43 . Parents, prenons conscience du fait que nos enfants naissent avec leur destinée…",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "44",
        title: "44 . Patienter ; Relativiser ; Lâcher prise",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "45",
        title: "45 . Accepter ce que l’on ne peut changer…",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "46",
        title:
          "46 . Prendre conscience que la « souffrance » fait partie de la vie",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "47",
        title:
          "47 . Prendre LE TEMPS QU’IL FAUT pour se remettre d’une situation difficile",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "48",
        title:
          "48 . Être conscient que la méchanceté et la cruauté humaine existent (l’Homme est capable du meilleur comme du pire, et certains persisteront à être les meilleurs dans le pire!) ; MAIS choisir malgré tout de se focaliser sur le Bien, le Beau, le Bon, le Juste, le Miam-Miam, le MDRRR, le Chouu, l’Agréable, le Sensationnel, « l’Extra-ordinaire », la Reconstruction, l’Espoir",
        subTitle: "",
        description: "",
      },
      {
        id: "49",
        title:
          "49 . S’émerveiller devant l’infiniment grand, mais aussi devant l’infiniment petit, comme font souvent les enfants",
        subTitle: "",
        description: "Lire la suite",
      },
      {
        id: "50",
        title:
          "50 . Puiser sa force dans sa philosophie de vie, dans sa spiritualité",
        subTitle: "",
        description: "Lire la suite",
      },
    ],
  },
];
