import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../../assets/styles/professionals/Professionnels.module.scss";
import ButtonFilled from "../../components/buttonFilled";

const PermissionsModal = ({
  toggleState,
  handleToggle,
  handleDeny,
  handleAllow,
}) => {
  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "370px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        isOpen={toggleState}
        toggle={handleDeny}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <h5 id="signUpModalLabel">Accès à la géolocalisation</h5>
          </div>
          <div className={styles.modalBody}>
            <p className={styles.text}>
              Pensez à activer votre géolocalisation pour faire une recherche
              autour de vous.
            </p>

            <div className="pt-3 mx-3">
              <ButtonFilled
                title="OK, J'AI COMPRIS"
                textColr="white"
                handleClick={handleAllow}
              />
            </div>

            {/* <div className={styles.annuler} onClick={handleDeny}>
                <p>Annuler</p>
              </div> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PermissionsModal;
