import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";

import { Helmet } from "react-helmet";
import { MetaHeadEmbed } from "@phntms/react-share";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ConfigProvider } from "antd";

// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// components
import MenuBar from "./components/sidebars/menuBar";
import SidebarRight from "./components/sidebars/SidebarRight";
import Footer from "./components/Footer";
import Router from "./Router";
import OtherStoryFiltration from "./components/home/OtherStoryFiltration";
import Subscription from "./components/auth/Subscription";
import { useAuth } from "./context/AuthContext";
import { ApiCall } from "./config/apiCall";

const stripePromise = loadStripe(
  "pk_test_51OcjeeSImi2IkKfDCRUny9GOZl3yJrX4dvR6G91gO0IpQw52dqFvSPkjHR0r4dNseZtjKMstO9qaNonBp3ZeYfiy00mO4R6r3g"
);

function App() {
  const location = useLocation();
  const { token, userInfo } = useAuth();
  const [stripeClientSecret, setStripeClientSecret] = useState(null);

  const [showTimeFrameFilters, setshowTimeFrameFilters] = useState(false);
  let proId = localStorage.getItem("proId");
  let resourceId = localStorage.getItem("resourceId");

  useEffect(() => {
    if (
      userInfo?.professional &&
      userInfo?.professional?.package?.payment == false
    ) {
      getStripeClientSecret();
    }
  }, [token, userInfo]);

  const getStripeClientSecret = async () => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: "planProfessional/create-paymentIntent",
          verb: "post",
          token: token,
          baseurl: true,
        });

        if (response.status === 200) {
          setStripeClientSecret(response.response.client_secret);
        } else if (response.status === 400) {
          console.error(response.response.message);
        }
        return response;
      } catch (error) {
        console.error(error);
      } finally {
      }
    }
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: stripeClientSecret,
  };

  return (
    <>
      {/* <MetaHeadEmbed
        render={(meta) => <Helmet>{meta}</Helmet>}
        siteTitle="PsyUP"
        pageTitle=""
        titleTemplate="[siteTitle]"
        description="PsyUP : Élevez. Connectez. Discutez"
        baseSiteUrl="http://44.203.197.155"
        pagePath=""
        keywords={["psyup", "citron", "psyup.fr"]}
        imageUrl={previewLogo}
        imageAlt="PsyUP logo."
        twitter={{
          cardSize: "large",
          siteUsername: `${userInfo?.username}`,
          creatorUsername: `${userInfo?.username}`,
        }}
      /> */}
      <div className="main-body-container">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#01989f",
            },
          }}
        >
          <div className="App">
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            {userInfo?.professional && stripeClientSecret && (
              <Elements stripe={stripePromise} options={options}>
                <Subscription />
              </Elements>
            )}

            <MenuBar />
            <div className="innerComponents">
              {![
                "/messaging",
                "/ressources",
                `/ressources/details/${resourceId}`,
                `/ressources/details2/${resourceId}`,
                "/newMessage",
                "/chat-bot",
                "/physical-transformation",
                "/professionals",
                "/professional-profile",
                "/professional-profile/appointment",
                "/professionals/sign-up",
                "/professionals/sign-up/plans",
                `/professionals/reviews/${proId}`,
              ].includes(location.pathname) && (
                <OtherStoryFiltration
                  setshowTimeFrameFilters={setshowTimeFrameFilters}
                />
              )}

              <Router showTimeFrameFilters={showTimeFrameFilters} />

              {location.pathname !== "/messaging" &&
                location.pathname !== `/professionals/reviews/${proId}` && (
                  <Footer />
                )}
            </div>
            {location.pathname !== "/ressources" &&
              location.pathname !== `/ressources/details/${resourceId}` &&
              location.pathname !== `/ressources/details2/${resourceId}` && (
                <SidebarRight />
              )}
          </div>
        </ConfigProvider>
      </div>
    </>
  );
}

export default App;
