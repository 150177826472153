import { createContext, useContext, useState, useEffect } from "react";
import { ApiCall } from "../config/apiCall";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import Step3 from "../components/auth/signUp/SignUpStep3";
export const useAuth = () => useContext(AuthContext);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [searchedUserInfo, setsearchedUserInfo] = useState(null);
  const [storyCount, setStoryCount] = useState(1);
  const [singleUserStoryCount, setSingleUserStoryCount] = useState(1);
  const [adminstoryCount, setAdminStoryCount] = useState(1);
  const [favstoryCount, setfavStoryCount] = useState(1);
  const [allUsersStories, setallUsersStories] = useState([]);
  const [favStories, setFavStories] = useState([]);
  const [storiesLoading, setStoriesLoading] = useState(false);
  const [adminStoriesLoading, setAdminStoriesLoading] = useState(false);
  const [guestStoriesLoading, setGuestStoriesLoading] = useState(false);
  const [singleStoriesLoading, setSingleStoriesLoading] = useState(false);
  const [favStoriesLoading, setFavStoriesLoading] = useState(false);
  const [searchedUserLoading, setsearchedUserLoading] = useState(false);
  const [sidebarSelectedTheme, setSidebarSelectedTheme] = useState(null);
  const [activeTheme, setActiveTheme] = useState(null);
  const [adminStories, setAdminStories] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [allThemes, setAllThemes] = useState([]);
  const [storyFilter, setStoryFilter] = useState("reacted");
  const [activeResourceBtn, setactiveResourceBtn] = useState("");
  const [searchedUserId, setSearchedUserId] = useState(null);

  const [timeFrameForStoryFiltration, setTimeFrameForStoryFiltration] =
    useState(null);
  const navigate = useNavigate();

  // console.log("storyCount", storyCount);
  // console.log("storyFilter", storyFilter);

  let userToken = localStorage.getItem("token");

  const isAuthenticated = !!token;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const getToken = localStorage.getItem("token");

      const getId = localStorage.getItem("userId");
      if (getToken) {
        setToken(getToken);
      }
      if (getId) {
        setUserId(getId);
      }
    }
  }, []);

  const login = async (
    email,
    password,
    setEmail,
    setPassword,
    setLoading,
    { handleToggle }
  ) => {
    try {
      setLoading(true);
      const response = await ApiCall({
        params: { email, password },
        route: "auth/signin",
        verb: "post",
        token: "jwt_token",
        baseurl: true,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.response.authToken);
        localStorage.setItem("userId", response.response.user.id);
        setToken(response.response.authToken);
        setUserId(response.response.user.id);

        setEmail("");
        setPassword("");
        handleToggle();
        getAllUserStories(storyCount);
      } else if (response.status === 400) {
        if (response.response.errors) {
          response.response.errors?.forEach(function (error) {
            var x = error.message;
            message.error(x);
          });
        } else {
          message.error(response.response.message);
          if (
            response.response.message ==
            "L'utilisateur n'est pas encore vérifié"
          ) {
            handleToggle();
          }
        }
      }

      // console.log("response", response);
      return response;
    } catch (error) {
      setLoading(false);
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const adminLogin = async (
    email,
    password,
    setEmail,
    setPassword,
    setLoading
  ) => {
    try {
      setLoading(true);
      const response = await ApiCall({
        params: { email, password },
        route: "auth/signin-admin",
        verb: "post",
        token: "jwt_token",
        baseurl: true,
      });
      if (response.status === 200) {
        localStorage.setItem("token", response.response.authToken);
        localStorage.setItem("userId", response.response.user.id);
        setToken(response.response.authToken);
        setUserId(response.response.user.id);
        setEmail("");
        setPassword("");
        message.success(`${response.response.message}`);
        navigate("/");
      } else if (response.status === 400) {
        if (response.response.errors) {
          response.response.errors?.forEach(function (error) {
            var x = error.message;
            message.error(x);
          });
        } else {
          message.error(`${response.response.message}`);
        }
      }
      // console.log("responcs", response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getProfileData = async (id) => {
    if (token) {
      try {
        setSingleStoriesLoading(true);
        const response = await ApiCall({
          params: {},
          route: "user/userDetail/" + id,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setUserInfo(response.response);
          localStorage.setItem("userFullName", response.response.fullname);
          localStorage.setItem(
            "userpic",
            response.response.profilePicture?.url
          );
        }
        // console.log("profileResp", response);
      } catch (error) {
        setSingleStoriesLoading(false);
        message.error("Connection Error");
      } finally {
        setSingleStoriesLoading(false);
      }
    }
  };

  const getUserStories = async (id, count) => {
    if (token) {
      setStoriesLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "story/userStories/" + id + "&" + count,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setUserStories(response.response);
        }
        // console.log("UserStories", response);
      } catch (error) {
        setStoriesLoading(false);
      } finally {
        setStoriesLoading(false);
      }
    }
  };
  const getThemes = async () => {
    const response = await ApiCall({
      params: {},
      route: "theme/all",
      verb: "get",
      baseurl: true,
    });

    if (response.status === 200) {
      const themesWithSelectedFlag = response?.response?.map((theme) => ({
        ...theme,
        isSelected: false,
      }));
      setAllThemes(themesWithSelectedFlag);
    }
    // console.log("responsetheme", response);
  };
  const getSearchedUserProfile = async (id) => {
    if (token) {
      setsearchedUserLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "user/userDetail/" + id,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setsearchedUserInfo(response.response);
        }
        // console.log("resp", response);
      } catch (error) {
        setsearchedUserLoading(false);
        message.error("Connection Error");
      } finally {
        setsearchedUserLoading(false);
        // setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllUserStories(storyCount);
    getUserStories(userId, storyCount);
    getSearchedUserProfile(userId, singleUserStoryCount);
    getAdmintories(adminstoryCount);
    getProfileData(userId);
  }, [token, storyCount, userId]);

  useEffect(() => {
    if (!userToken) {
      getGuestsStories();
    }
  }, []);

  const getAllUserStories = async (count) => {
    if (token) {
      setStoriesLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `story/filteredStories/${storyFilter}&${count}`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setallUsersStories(response.response);
        }
        // console.log("AllStoryResp", response);
      } catch (error) {
        setStoriesLoading(false);
      } finally {
        setStoriesLoading(false);
      }
    }
  };

  const getUserFavouriteStories = async (count) => {
    if (token) {
      setStoriesLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "story/userFavouriteStories/" + count,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setFavStories(response.response);
        }
        // console.log("FavStoriesResp", response);
      } catch (error) {
        setStoriesLoading(false);
      } finally {
        setStoriesLoading(false);
      }
    }
  };

  const getGuestsStories = async () => {
    setGuestStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "story/guestStories",
        verb: "get",
        baseurl: true,
      });

      if (response.status === 200) {
        setallUsersStories(response.response);
      }
      // console.log("GuestsResp", response);
    } catch (error) {
      setGuestStoriesLoading(false);
    } finally {
      setGuestStoriesLoading(false);
    }
  };

  const getAdmintories = async (count) => {
    if (token) {
      setAdminStoriesLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "story/adminStories/" + count,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setAdminStories(response.response);
        }
        // console.log("adminStoryResp", response);
      } catch (error) {
        setAdminStoriesLoading(false);
      } finally {
        setAdminStoriesLoading(false);
      }
    }
  };

  const deleteStory = async (id) => {
    try {
      const response = await ApiCall({
        params: {},
        route: "story/deleteStory/" + id,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setallUsersStories(allUsersStories.filter((story) => story._id !== id));
        setUserStories(userStories.filter((story) => story._id !== id));
        message.success(response.response);
      }
      // console.log("deleteStoryResp", response);
    } catch (error) {
    } finally {
    }
  };

  const reportStory = async (id) => {
    try {
      const response = await ApiCall({
        params: {},
        route: "story/reportStory/" + id,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        message.success(response.response);
      }
      // console.log("reportStoryResp", response);
    } catch (error) {
    } finally {
    }
  };

  const contextValue = {
    // auth
    isAuthenticated,
    token,
    userId,
    setUserId,
    setToken,
    login,
    adminLogin,

    // login modal
    loginModal,
    setLoginModal,

    // profile info
    getProfileData,
    userInfo,
    setUserInfo,
    singleStoriesLoading,
    // all stories
    storiesLoading,
    setStoriesLoading,
    guestStoriesLoading,
    storyCount,
    setStoryCount,
    getAllUserStories,
    setallUsersStories,
    allUsersStories,

    // guestStories
    getGuestsStories,

    storyFilter,
    setStoryFilter,

    searchedUserId,
    setSearchedUserId,

    // single user stories
    getUserStories,
    userStories,
    setUserStories,
    singleUserStoryCount,
    setSingleUserStoryCount,
    // fav stories
    getUserFavouriteStories,
    favStories,
    setFavStories,
    favStoriesLoading,
    favstoryCount,
    setfavStoryCount,

    // admin stories
    getAdmintories,
    setAdminStories,
    adminStories,
    adminstoryCount,
    setAdminStoryCount,
    adminStoriesLoading,
    // searched user info
    getSearchedUserProfile,
    allThemes,
    setAllThemes,
    getThemes,
    searchedUserInfo,
    searchedUserLoading,
    // sorting on selected themes
    sidebarSelectedTheme,
    setSidebarSelectedTheme,
    activeTheme,
    setActiveTheme,

    // delete story
    deleteStory,
    // report story
    reportStory,
    timeFrameForStoryFiltration,
    setTimeFrameForStoryFiltration,

    activeResourceBtn,
    setactiveResourceBtn,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
