import { useState, useEffect } from "react";
import { message } from "antd";
import styles from "./ProfessionalSignUpStep2.module.scss";

import { ApiCall } from "../../../config/apiCall";
import Themes from "../../../components/themes";
import { Spinner } from "reactstrap";

const ProfessionalSignUpStep2 = ({
  professionalData,
  setprofessionalData,
  goBack,
  handleToggle,
  toggleProfessionalIdentity,
}) => {
  const [themes, setThemes] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedThemes, setselectedThemes] = useState([]);

  useEffect(() => {
    const getThemes = async () => {
      const response = await ApiCall({
        params: {},
        route: "theme/all",
        verb: "get",
        baseurl: true,
      });

      if (response.status === 200) {
        const themesWithSelectedFlag = response.response.map((theme) => ({
          ...theme,
          isSelected: false,
        }));
        setThemes(themesWithSelectedFlag);
      }
      console.log("responsetheme", response);
    };
    getThemes();
  }, []);

  function handleCardClick(id) {
    const selectedThemesFilter = themes.filter((theme) => theme.isSelected);
    const clickedTheme = themes.find((theme) => theme._id === id);
    if (!clickedTheme.isSelected && selectedThemesFilter.length < 3) {
      setThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: true } : theme
        )
      );
    } else {
      setThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: false } : theme
        )
      );
    }
  }

  useEffect(() => {
    const selectedThemesFilter = themes.filter((theme) => theme.isSelected);
    const selectedThemes = selectedThemesFilter.map((theme) => theme._id);
    setprofessionalData((prevState) => ({ ...prevState, selectedThemes }));
  }, [themes]);

  const handleSubmitStep2 = async () => {
    console.log("handleSubmitStep2", professionalData);
    handleToggle();
    toggleProfessionalIdentity();
    // try {
    //   setloading(true);
    //   // Submit sign up details to backend
    //   const response = await ApiCall({
    //     params: professionalData,
    //     route: "auth/signup",
    //     verb: "post",
    //     token: "jwt_token",
    //     baseurl: true,
    //   });
    //   if (response.status === 200) {
    //     // message.success(`${response.response.message}`);
    //     userDataFunc();
    //   } else {
    //     message.error(response.response.message);
    //     // message.error(response.response.error?.message);
    //   }
    //   console.log("responcs", response);
    // } catch (error) {
    //   setloading(false);
    //   message.error(error);
    // } finally {
    //   setloading(false);
    // }
  };
  return (
    <div>
      <Themes themes={themes} handleCardClick={handleCardClick} />
      <div>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <Spinner color="info" />
          </div>
        ) : (
          <div className={styles.NextButtonWrapper}>
            <button onClick={handleSubmitStep2} className={styles.NextButton}>
              Étape suivante
            </button>
            <button className={styles.backBtn} onClick={goBack}>
              Retour
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfessionalSignUpStep2;
