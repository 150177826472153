import React, { useState } from "react";
import ToggleButton from "../../components/ToggleButton";
import styles from "./LegalPages.module.scss";

const Cookie = () => {
  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionId) => {
    if (openSection === sectionId) {
      setOpenSection(null);
    } else {
      setOpenSection(sectionId);
    }
  };

  return (
    <div className={styles["super-container"]}>
      <div className={styles.container}>
        <h3 className={styles.heading}>Politique d’utilisation des cookies</h3>

        <p className={styles.paragraph}>
          Nos partenaires et nous-mêmes utilisons différentes technologies,
          telles que les cookies, afin de stocker des informations (comme vos
          identifiants) sur votre navigateur web ou sur votre appareil.
        </p>

        <h5>Quand utilisons-nous des cookies ?</h5>
        <p className={styles.paragraph}>
          Nous utilisons des cookies lors de votre navigation sur psyup.fr.
        </p>

        <h5>Pourquoi utilisons-nous des cookies ?</h5>

        <ul className={styles.cookiesCollapse}>
          <li>
            <div
              className={styles.sectionHead}
              onClick={() => handleSectionClick(1)}
            >
              <h6>Authentification</h6>
              <h5 style={{ cursor: "pointer" }}>
                {openSection === 1 ? "-" : "+"}
              </h5>
            </div>
            {openSection === 1 && (
              <p className="pb-3">
                Nous avons par exemple recours aux cookies _ctuid et
                ctConversion, qui enregistrent votre navigateur web, pour que
                vous n’ayez pas à ressaisir vos identifiants chaque fois que
                vous souhaitez accéder au site, ou encore pour que vous restiez
                connecté(e) lorsque vous naviguez d’une page à l’autre du site.
                La durée de vie de ces cookies est de 365 jours.
              </p>
            )}
          </li>
          <li>
            <div
              className={styles.sectionHead}
              onClick={() => handleSectionClick(2)}
            >
              <h6>Sécurité</h6>
              <h5 style={{ cursor: "pointer" }}>
                {openSection === 2 ? "-" : "+"}
              </h5>
            </div>
            {openSection === 2 && (
              <div>
                <p>
                  Les cookies rt et r.ack nous permettent par exemple de vous
                  identifier en toute sécurité
                </p>

                <p className={styles.paragraph}>
                  Si vous nous prévenez que votre compte a été piraté, grâce aux
                  cookies, nous avons la possibilité de demander une
                  authentificatoin supplémentaire. Les cookies nous permettent
                  également de récupérer votre compte si vous oubliez votre mot
                  de passe.
                </p>

                <ul className={styles.dashaedUL}>
                  Enfin, PsyUP peut contrôler les comportements et connexions
                  non désirables telles que :
                  <li>
                    l’inscription de personnes non autorisées en raison de leur
                    âge
                  </li>
                  <li>
                    les spams et tentatives d’hameçonnage (les cookies nous
                    permettent d’identifier des ordinateurs utilisés pour créer
                    un nombre important de faux comptes) ;
                  </li>
                  <li>
                    les logiciels malveillants (le cookie optout nous aide par
                    exemple à empêcher des attaques de type).
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <div
              className={styles.sectionHead}
              onClick={() => handleSectionClick(3)}
            >
              <h6>
                Personnalisation des contenus & publicités, statistiques et
                recommandations
              </h6>
              <h5 style={{ cursor: "pointer" }}>
                {openSection === 3 ? "-" : "+"}
              </h5>
            </div>
            {openSection === 3 && (
              <div>
                <p>
                  Nous utilisons des cookies afin de proposer du contenu et des
                  publicités personnalisés, d’analyser les données de notre
                  audience, et de faire des recommandations stratégiques à nos
                  partenaires.
                </p>
                <ul className={styles.dashaedUL}>
                  <p className={styles.paragraph}>
                    Nous vous proposons par exemple des publicités en fonction
                    de vos habitudes de navigation et de vos achats en ligne.
                    Pour cela, nous avons entre autres recours au cookies fr
                    pour diffuser, mesurer et améliorer la pertinence des
                    publicités proposées (sa durée de vie est de 90 jours).
                  </p>
                  <p>
                    Nous mesurons également la performance des contenus et des
                    publicités. Nous analysons par exemple la fréquence à
                    laquelle une publicité est diffusée et son taux de
                    conversion (rapport entre le nombre d’internautes touchés
                    par la campagne publicitaire et le nombre de personnes ayant
                    finalement effectué un achat).
                  </p>
                  <p className="pt-2">
                    Les cookies nous permettent enfin de réaliser des
                    statistiques pour que nos partenaires et nous-mêmes
                    puissions connaître les caractéristiques de l’audience de
                    PsyUP.
                  </p>
                </ul>
              </div>
            )}
          </li>
          <li>
            <div
              className={styles.sectionHead}
              onClick={() => handleSectionClick(4)}
            >
              <h6>Performance et amélioration du site</h6>
              <h5 style={{ cursor: "pointer" }}>
                {openSection === 4 ? "-" : "+"}
              </h5>
            </div>
            {openSection === 4 && (
              <div>
                <p>
                  Les cookies nous permettent d’analyser et améliorer la
                  performance de notre site et de son contenu.
                </p>
                <ul className={styles.dashaedUL}>
                  <p className="pt-2">
                    Nous cherchons par exemple à comprendre quelle est la
                    vitesse de chargement des pages de PsyUP ou encore quelles
                    parties du site vous intéressent le plus !
                  </p>
                </ul>
              </div>
            )}
          </li>
          <li>
            <div
              className={styles.sectionHead}
              onClick={() => handleSectionClick(5)}
            >
              <h6>Google Analytics </h6>
              <h5 style={{ cursor: "pointer" }}>
                {openSection === 5 ? "-" : "+"}
              </h5>
            </div>
            {openSection === 5 && (
              <div>
                <p>
                  Nous plaçons également des cookies depuis le domaine PsyUP.com
                  qui fonctionnent avec le service Google Analytics, afin de
                  comprendre comment les entreprises utilisent les sites de
                  développeurs de PsyUP. ces cookies ont des noms tels que
                  _ctuid, ctConversion, cto_lwid, eid, IDE.
                </p>
              </div>
            )}
          </li>
        </ul>

        <h5>Comment pouvez-vous contrôler l’utilisation des cookies ?</h5>
        <p className={styles.paragraph}>
          Vous pouvez contrôler la façon dont nous exploitons vos données grâce
          aux outils décrits cidessous.
        </p>

        <h6 className={styles.subtitleL1}>
          Contrôle du principal cookie publicitaire de PsyUP{" "}
        </h6>

        <p className={styles.paragraph}>
          Vous pouvez accepter ou refuser l’utilisation du cookie publicitaire
          principal.{" "}
        </p>

        <div className={styles.cookiesBorderSection}>
          <p>
            Accepter le cookie publicitaire principal de PsyUP, qui est
            principalement utilisé pour diffuser, mesurer et améliorer la
            pertinence des publicités proposées ? Si vous n’acceptez pas de ce
            cookie, vous verrez toujours des annonces sur notre site, mais
            cellesci ne seront pas personnalisées.{" "}
          </p>
          <div className={styles.cookiesToggle}>
            <ToggleButton checked="checked" />
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <h6 className={styles.subtitleL1}>
            Contrôle des cookies de votre navigateur
          </h6>
          <p className={styles.paragraph}>
            Vous pouvez choisir vos cookies de navigation directement dans les
            paramètres de votre navigateur Internet. Nous vous alertons sur le
            fait que certaines parties du site PsyUP peuvent ne pas fonctionner
            correctement si vous désactivez l’utilisation des cookies dans votre
            navigateur. Ci-dessous les liens de gestion des cookies des
            principaux navigateurs.
          </p>
          <ul>
            <li>
              <a href="https://support.google.com/chrome/answer/9564 ">
                Google Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/en‐us/windows/delete‐and‐ 
manage‐cookies‐168dab11‐0753‐043d‐7c16‐ede5947fc64d "
              >
                Internet Exporer
              </a>
            </li>
            <li>
              <a href="https://support.mozilla.org/en‐US/kb/enhanced‐tracking‐protection‐firefox‐desktop">
                Firefox
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/en‐ie/guide/safari/sfri11471/mac ">
                Safari
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/en‐us/HT201265">
                Safari Mobile
              </a>
            </li>
            <li>
              <a href="https://blogs.opera.com/news/2015/08/how‐to‐manage‐cookies‐in‐opera">
                Opera
              </a>
            </li>
          </ul>
        </div>

        <h5>Informations globales sur la publicité en ligne</h5>
        <p className={styles.paragraph}>
          Pour en savoir plus sur la manière dont les annonceurs publicitaires
          utilisent généralement les cookies et sur les choix dont vous
          disposez, vous pouvez consultez les liens suivants :
        </p>
        <ul style={{ marginTop: "10px" }}>
          <li>
            <a href="https://optout.aboutads.info/?c=2&lang=EN">
              Alliance de la publicité numérique
            </a>
          </li>
          <li>
            <a href="https://youradchoices.ca/ ">
              Alliance canadienne de la publicité numérique
            </a>
          </li>
          <li>
            <a href="http://www.youronlinechoices.eu/ ">
              Alliance européenne pour l’éthique en matière de publicité
              numérique
            </a>
          </li>
        </ul>
        <p>
          Vous pouvez vous désinscrire de toutes publicités en ligne par le
          biais de la{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://optout.aboutads.info/?c=2&lang=EN"
          >
            Digital Advertising Alliance
          </a>{" "}
          aux États-Unis, la{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://youradchoices.ca/"
          >
            Digital Advertising Alliance of Canada
          </a>{" "}
          au Canada ou la{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://youronlinechoices.eu/"
          >
            European Interactive Digital Advertising Alliance
          </a>{" "}
          en Europe, ou par le biais des paramètres de votre appareil mobile
          (Android ou iOS).
        </p>
      </div>
    </div>
  );
};

export default Cookie;
