import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/stories/SubmitStory.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { Spin, message } from "antd";

import CustomInput from "../CustomInput";
import { ApiCall } from "../../config/apiCall";

import { useAuth } from "../../context/AuthContext";
import { Spinner } from "reactstrap";
import Film from "./storyResources/Film";
import Livre from "./storyResources/Livre";
import Serie from "./storyResources/Serie";
import Influenceur from "./storyResources/Influenceur";
import Video from "./storyResources/Video";
import Podcast from "./storyResources/Podcast";
import Mosique from "./storyResources/Mosique";
import Citation from "./storyResources/Citation";
import { burgerCrossBlack, smilyIcon, theme } from "../../assets";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import SearchableSelect from "../SearchableDropdown/SearchableDropdown";
import { Mention, MentionsInput } from "react-mentions";
import { ReactSVG } from "react-svg";
import DotsLoader from "../UI/DotsLoader";

const resources = [
  {
    name: "Film",
    value: "Film",
  },
  {
    name: "Livre",
    value: "Livre",
  },
  {
    name: "Série",
    value: "Série",
  },
  {
    name: "Influenceur",
    value: "Influenceur",
  },
  {
    name: "Vidéo",
    value: "Vidéo",
  },
  {
    name: "Podcast",
    value: "Podcast",
  },
  {
    name: "Musique",
    value: "Musique",
  },
  {
    name: "Citation",
    value: "Citation",
  },
];

const profesionalsList = [
  { key: "professionnel", label: "Un professionnel de santé" },
  { key: "psychologue", label: "Un psychologue" },
  { key: "avocat", label: "Un avocat" },
  { key: "coach", label: "Un coach" },
  { key: "thérapeute", label: "Un thérapeute" },
  { key: "expert-comptable", label: "Un expert-comptable" },
  { key: "association", label: "Une association" },
  { key: "autre", label: "Autre" },
];

const SubmitStory = ({ toggleState, handleToggle, storyDataForUpdate }) => {
  const { token, userInfo, setallUsersStories, allUsersStories, allThemes } =
    useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  const [storyState, setStoryState] = useState({
    theme: "",
    content: "",
    title: "",
    isAnonym: false,
    featured_story: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isAnonymChecked, setisAnonymChecked] = useState(false);
  const [isPhysicalChecked, setIsPhysicalChecked] = useState(false);

  const [activeResourceBtn, setactiveResourceBtn] = useState("");
  const [resourceInputState, setResourceInputState] = useState({});
  const [physicalTransformationState, setPhysicalTransformationState] =
    useState((prevState) => ({
      ...prevState,
      isSatisfied: "true",
      price: "",
    }));
  const [cunsultedProfessional, setCunsultedProfessional] = useState({});

  const [photoBeforeState, setphotoBeforeState] = useState(null);
  const [photoBeforeUpState, setphotoBeforeUpState] = useState(null);
  const [photoAfterState, setphotoAfterState] = useState(null);
  const [photoAfterUpState, setphotoAfterUpState] = useState(null);
  const [maxCharacterError, setMaxCharacterError] = useState(false);
  const [videoUrlError, setvideoUrlError] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  const [searchedProfessionals, setSearchedProfessionals] = useState([]);
  const [allSearchedProfessionals, setAllSearchedProfessionals] = useState([]);

  const [searchedProfessionalsSubType, setSearchedProfessionalsSubType] =
    useState([]);
  const [allSearchedProfessionalsSubType, setAllSearchedProfessionalsSubType] =
    useState([]);

  const [
    isAllSearchedProfessionalsLoading,
    setIsAllSearchedProfessionalsLoading,
  ] = useState(false);
  const [
    isAllSearchedProfessionalsSubTypeLoading,
    setIsAllSearchedProfessionalsSubTypeLoading,
  ] = useState(false);
  const [professionalDetailsLoading, setProfessionalDetailsLoading] =
    useState(false);

  const [searchedQoute, setSearchedQoute] = useState("");
  const [tempProfessionalName, setTempProfessionalName] = useState("");
  const [isShowAddQuoteButton, setIsShowAddQuoteButton] = useState(true);

  const [matchingUsers, setMatchingUsers] = useState([]);
  const [bodyParts, setBodyParts] = useState(null);
  const [bodyPartsLoading, setBodyPartsLoading] = useState(false);
  const [treatments, setTreatments] = useState(null);
  const [treatmentsLoading, setTreatmentsLoading] = useState(false);
  const [professionalSubTypeCode, setProfessionalSubTypeCode] = useState(null);

  const mappedThemes = allThemes
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name))

    .map((theme) => ({
      value: theme._id,
      label: theme.name,
    }));

  useEffect(() => {
    if (storyDataForUpdate) {
      if (storyDataForUpdate?.isAnonym) {
        setisAnonymChecked(true);
      }

      setResourceInputState({ ...storyDataForUpdate?.ressource?.complete });
      setStoryState({
        writter: storyDataForUpdate?.writter?._id,
        theme: storyDataForUpdate?.theme?._id,
        title: storyDataForUpdate?.title,
        content: storyDataForUpdate?.content,
        isFavourite: storyDataForUpdate?.isFavourite,
        isAnonym: storyDataForUpdate?.isAnonym || false,
        featured_story: storyDataForUpdate?.featured_story,
      });

      if (storyDataForUpdate?.physicalTransformation?.bodyPart) {
        setIsPhysicalChecked(true);
      }
      setPhysicalTransformationState({
        ...storyDataForUpdate?.physicalTransformation,
      });

      setSelectedTitle(storyDataForUpdate?.physicalTransformation?.bodyPart);
      setSelectedTreatment(
        storyDataForUpdate?.physicalTransformation?.treatment
      );

      setSelectedTheme(storyDataForUpdate?.theme?._id);
      const index = resources.findIndex(
        (el) =>
          el.value === storyDataForUpdate?.ressource?.complete?.resourcetype
      );
      setactiveResourceBtn(index);
    }
  }, [storyDataForUpdate]);

  useEffect(() => {
    if (mappedThemes && mappedThemes.length > 0) {
      setSelectedTheme(mappedThemes[0].value);
    }
  }, [mappedThemes]);

  useEffect(() => {
    setStoryState((prevState) => {
      return {
        theme: selectedTheme,
        writter: userInfo?._id,
        content: "",
        title: "",
        isAnonym: false,
        featured_story: false,
      };
    });
  }, [selectedTheme, userInfo]);

  const getAllBodyParts = async () => {
    if (token) {
      setBodyPartsLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `bodypart/bodyparts-name-listing`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setBodyParts(response?.response);
          setBodyPartsLoading(false);
        }
      } catch (error) {
        message.error("Connection Error");
        setBodyPartsLoading(false);
      } finally {
        setBodyPartsLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllBodyParts();
  }, [token]);

  const allBodyParts = bodyParts
    ?.map((bodyPart) => {
      if (bodyPart.name.toLowerCase() !== "all") {
        return {
          label: bodyPart.name,
          value: bodyPart._id,
        };
      }
      return null; // Skip 'all'
    })
    .filter(Boolean) // Remove null values
    .sort((a, b) => a.label.localeCompare(b.label));

  const onTitleChange = async (value) => {
    setErrorMessage("");
    const bodyPartName = allBodyParts?.filter((el) => el.value === value)[0]
      ?.label;

    setSelectedTitle(bodyPartName);
    setSelectedTreatment(null); // Reset the selected treatment when changing the title
    setPhysicalTransformationState((prevState) => {
      return {
        ...prevState,
        bodyPart: bodyPartName,
        bodyPartId: value,
        treatment: null,
      };
    });

    setTreatmentsLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `bodypart/bodyparts-treatment/${value}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setTreatments(response?.response);
        setTreatmentsLoading(false);
      }
    } catch (error) {
      setTreatmentsLoading(false);
      message.error("Connection Error");
    } finally {
      setTreatmentsLoading(false);
    }
  };

  const onTreatmentChange = (treatment) => {
    setErrorMessage("");
    setSelectedTreatment(treatment);
    setPhysicalTransformationState((prevState) => {
      return { ...prevState, treatment: treatment };
    });
  };

  const MAX_CHARACTERS = 2200;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setStoryState((prevState) => {
      return { ...prevState, [name]: value, writter: userInfo?._id };
    });
  };

  const handleEmojiSelect = (emoji) => {
    setStoryState((prevState) => ({
      ...prevState,
      content: storyState.content + emoji.native,
    }));
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onCunsultedProfessionalChange = (event) => {
    const { name, value } = event.target;
    setCunsultedProfessional((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleResourceInputOnBlure = (event) => {
    const { name, value } = event.target;

    if (value !== "") {
      if (
        value.toLowerCase().includes("facebook.com") ||
        value.toLowerCase().includes("tiktok.com") ||
        value.toLowerCase().includes("vimeo.com") ||
        value.toLowerCase().includes("instagram.com") ||
        value.toLowerCase().includes("youtube.com")
      ) {
        setvideoUrlError(false);
      } else {
        setvideoUrlError(true);
      }
    } else {
      setvideoUrlError(false);
    }
  };

  const handleResourceInputChange = (event) => {
    const { name, value } = event.target;
    setErrorMessage("");
    if (name === "resourceReason") {
      if (value.length <= MAX_CHARACTERS) {
        setResourceInputState((prevState) => ({ ...prevState, [name]: value }));
        setMaxCharacterError(false);
      } else {
        setMaxCharacterError(true);
      }
    } else {
      setResourceInputState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleProfessionalTypeSelect = async (value) => {
    setCunsultedProfessional((prevState) => {
      return { professionaltype: value };
    });
    setSearchedProfessionals([]);
    setSearchedProfessionalsSubType([]);
    setTempProfessionalName("");
    setAllSearchedProfessionals([]);

    if (value === "professionnel" || value === "psychologue") {
      try {
        const response = await ApiCall({
          params: { professionalType: "professionnel" },
          route: `story/get-professional`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          // console.log("professionnel subtypes", response?.response?.professions);

          setSearchedProfessionalsSubType(response?.response?.professions);
        }
      } catch (error) {
        // setIsAllSearchedProfessionalsSubTypeLoading(false);
        message.error("Connection Error");
      } finally {
        // setIsAllSearchedProfessionalsSubTypeLoading(false);
      }
    }
  };

  // console.log(
  //   "allSearchedProfessionalsSubType",
  //   allSearchedProfessionalsSubType
  // );

  // console.log("resourceInputState", resourceInputState);

  async function searchForProfessionalNamesList(e) {
    setSearchedQoute(e);
    setIsShowAddQuoteButton(true);

    if (cunsultedProfessional?.professionaltype === "professionnel") {
      setIsAllSearchedProfessionalsLoading(true);
      try {
        const response = await ApiCall({
          params: {
            professionalType: cunsultedProfessional?.professionaltype,
            code: professionalSubTypeCode,
            search: e,
          },
          route: `story/get-professional/subtype`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          // console.log("professions-by-code", response?.response?.data);

          setSearchedProfessionals(response?.response?.data);
          setIsAllSearchedProfessionalsLoading(false);
        }
      } catch (error) {
        message.error("Connection Error");
        setIsAllSearchedProfessionalsLoading(false);
      } finally {
        setIsAllSearchedProfessionalsLoading(false);
      }
    } else {
      setIsAllSearchedProfessionalsLoading(true);
      try {
        const response = await ApiCall({
          params: {
            professionalType: cunsultedProfessional?.professionaltype,
            professionalSubType: cunsultedProfessional?.professionalSubType,
            professionalFullName: e,
          },
          route: `story/get-professional`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          console.log("Search successful", response?.response?.data);
          setSearchedProfessionals(response?.response?.data);
          setIsAllSearchedProfessionalsLoading(false);
        }
      } catch (error) {
        setIsAllSearchedProfessionalsLoading(false);
        message.error("Connection Error");
      } finally {
        setIsAllSearchedProfessionalsLoading(false);
      }
    }
  }

  // console.log("professionalSubType", searchedProfessionalsSubType);
  // console.log("cunsultedProfessional", cunsultedProfessional);
  useEffect(() => {
    setAllSearchedProfessionals(
      searchedProfessionals?.slice(0, 100)?.map((prof) => ({
        value: prof?._id,
        label: `${
          prof?.fullName ||
          prof?.lastName?.toUpperCase() +
            " " +
            prof?.firstName?.charAt(0)?.toUpperCase() +
            prof?.firstName?.slice(1)?.toLowerCase()
        }`,
        firstName: prof?.firstName,
        labelToSend: `${prof?.fullName || prof?.lastName}`,
        organizationID: prof?.organizationID,
      }))
    );
  }, [searchedProfessionals]);

  useEffect(() => {
    setAllSearchedProfessionalsSubType(
      searchedProfessionalsSubType?.slice(0, 100)?.map((prof) => ({
        value: prof?.code,
        label: `${prof?.displayName}`,
      }))
    );
  }, [searchedProfessionalsSubType]);

  const handleProfessionalSubTypeSelect = async (value) => {
    setProfessionalSubTypeCode(value);
    const name = allSearchedProfessionalsSubType?.filter(
      (el) => el.value === value
    )[0]?.label;

    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        professionalSubType: name,
        professionalSubtypeId: value,
      };
    });

    // console.log("value", value);
    // console.log("name", name);
    setIsAllSearchedProfessionalsLoading(true);
    try {
      const response = await ApiCall({
        params: {
          professionalType: cunsultedProfessional?.professionaltype,
          code: value,
        },
        route: `story/get-professional/subtype`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // console.log("professions-by-code", response?.response?.data);

        setSearchedProfessionals(response?.response?.data);
        setIsAllSearchedProfessionalsLoading(false);
      }
    } catch (error) {
      message.error("Connection Error");
      setIsAllSearchedProfessionalsLoading(false);
    } finally {
      setIsAllSearchedProfessionalsLoading(false);
    }
  };

  const handleConsultedProfessionalNameChange = async (value) => {
    const name = allSearchedProfessionals?.filter((el) => el.value === value)[0]
      ?.label;
    const nameReverse = allSearchedProfessionals?.filter(
      (el) => el.value === value
    )[0]?.labelToSend;
    const firstName = allSearchedProfessionals?.filter(
      (el) => el.value === value
    )[0]?.firstName;
    const organizationID = allSearchedProfessionals?.filter(
      (el) => el.value === value
    )[0]?.organizationID;

    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        fullName: nameReverse,
        firstName: firstName,
        professionalId: value,
      };
    });

    if (name) {
      setProfessionalDetailsLoading(true);
      try {
        const response = await ApiCall({
          params: { organizationID },
          route: `story/get-selected-professional/${cunsultedProfessional?.professionaltype}&${value}`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setCunsultedProfessional((prevState) => {
            return {
              ...prevState,
              address: response?.response?.data?.address,
              lastName: response?.response?.data?.lastName,
            };
          });

          setTempProfessionalName(response?.response?.data?.address);
          setProfessionalDetailsLoading(false);
        }
      } catch (error) {
        message.error("Connection Error");
        setProfessionalDetailsLoading(false);
      } finally {
        setProfessionalDetailsLoading(false);
      }
    }
  };

  // console.log("physicalTransformationState", physicalTransformationState);

  const handlePhysicalTransformationInputChange = (event) => {
    const { name, value } = event.target;
    const formattedValue = name === "price" ? formatPrice(value) : value;

    setPhysicalTransformationState((prevState) => {
      return { ...prevState, [name]: formattedValue };
    });
    setErrorMessage("");
  };

  const formatPrice = (value) => {
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    // Format with spacing
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return formattedValue;
  };

  const toggleStoryModal = () => {
    handleToggle();
  };

  const handleIPhotoBeforeUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setphotoBeforeState(URL.createObjectURL(file));
      setphotoBeforeUpState(file);
      setErrorMessage("");
    }
  };

  const handleIPhotoAfterUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setphotoAfterState(URL.createObjectURL(file));
      setphotoAfterUpState(file);
      setErrorMessage("");
    }
  };

  // console.log("storyState", storyState);
  // console.log("resourceInputState", resourceInputState);

  const onStorySubmit = async () => {
    if (!storyState) {
      message.error("L'histoire est vide.");
    }
    if (storyState?.theme?.trim() === "") {
      setErrorMessage("Veuillez sélectionner un thème.");
      return;
    }
    if (storyState?.title?.trim() === "") {
      setErrorMessage("Veuillez entrer le titre de l'histoire.");
      return;
    }
    if (storyState?.content?.trim() === "") {
      setErrorMessage("Veuillez saisir les détails de l'histoire.");
      return;
    }
    if (activeResourceBtn === 0 && !resourceInputState?.filmetitle) {
      setErrorMessage("Veuillez sélectionner le titre du film.");
    } else if (activeResourceBtn === 1 && !resourceInputState?.livretitle) {
      setErrorMessage("Veuillez sélectionner le titre du livre.");
    } else if (activeResourceBtn === 1 && !resourceInputState?.acteur1) {
      setErrorMessage("Veuillez renseigner l’auteur du livre");
    } else if (activeResourceBtn === 2 && !resourceInputState?.serietitle) {
      setErrorMessage("Veuillez sélectionner le titre de la série.");
    } else if (
      activeResourceBtn === 3 &&
      !resourceInputState?.influenceurtitle
    ) {
      setErrorMessage("Veuillez renseigner le nom de l’influenceur.");
    } else if (activeResourceBtn === 4 && !resourceInputState?.videotitle) {
      setErrorMessage("Veuillez sélectionner le titre de la vidéo.");
    } else if (videoUrlError) {
      message.error(`Invalid video url.`);
    } else if (activeResourceBtn === 5 && !resourceInputState?.podcasttitle) {
      setErrorMessage("Veuillez sélectionner le titre du podcast.");
    } else if (activeResourceBtn === 5 && !resourceInputState?.acteur1) {
      setErrorMessage("Veuillez renseigner l’auteur du podcast.");
    } else if (activeResourceBtn === 6 && !resourceInputState?.mosiquetitle) {
      setErrorMessage("Veuillez sélectionner le titre de la chanson.");
    } else if (activeResourceBtn === 6 && !resourceInputState?.artistname) {
      setErrorMessage("Veuillez renseigner le nom de l’artiste.");
    } else if (activeResourceBtn === 7 && !resourceInputState?.citationtitle) {
      setErrorMessage("Veuillez sélectionner le titre de la citation.");
    } else if (isPhysicalChecked && selectedTitle == null) {
      setErrorMessage("Veuillez sélectionner une partie du corps.");
    } else if (isPhysicalChecked && selectedTreatment == null) {
      setErrorMessage("Veuillez sélectionner une solution.");
    } else if (isPhysicalChecked && photoBeforeUpState == null) {
      setErrorMessage("Veuillez insérer vos photos.");
    } else if (isPhysicalChecked && photoAfterUpState == null) {
      setErrorMessage("Veuillez insérer vos photos.");
    } else if (
      isPhysicalChecked &&
      physicalTransformationState.price.trim() === ""
    ) {
      setErrorMessage("Veuillez indiquer le prix de votre solution.");
    } else {
      // appi call
      try {
        setLoading(true);

        const storyParams = new FormData();
        if (photoBeforeUpState) {
          storyParams.append("beforePictureUrl", photoBeforeUpState);
        }

        if (photoAfterUpState) {
          storyParams.append("afterPictureUrl", photoAfterUpState);
        }

        const newObj = { ...storyState };
        delete newObj?.physicalTransformation;
        delete newObj?.ressource;

        for (const key in newObj) {
          if (storyState.hasOwnProperty(key)) {
            storyParams.append(key, newObj[key]);
          }
        }

        const resourcesObj = {};

        for (const key in resourceInputState) {
          if (resourceInputState.hasOwnProperty(key)) {
            resourcesObj[key] = resourceInputState[key];
          }
        }

        storyParams.append("resources", JSON.stringify(resourcesObj));

        // const cunsultedProfessionalObject = {};
        // for (const key in cunsultedProfessional) {
        //   if (cunsultedProfessional.hasOwnProperty(key)) {
        //     cunsultedProfessionalObject[key] = cunsultedProfessional[key];
        //   }
        // }

        // storyParams.append(
        //   "professional",
        //   JSON.stringify(cunsultedProfessionalObject)
        // );

        const cunsultedProfessionalObject = { ...cunsultedProfessional };

        // Check if both firstName and lastName exist
        if (
          cunsultedProfessionalObject.hasOwnProperty("fullName") &&
          cunsultedProfessionalObject.hasOwnProperty("lastName") &&
          !tempProfessionalName
        ) {
          // Merge firstName and lastName into a new property fullName
          cunsultedProfessionalObject.fullName = `${cunsultedProfessionalObject.fullName} ${cunsultedProfessionalObject.lastName}`;

          // Remove firstName and lastName properties
          // delete cunsultedProfessionalObject.firstName;
          // delete cunsultedProfessionalObject.lastName;
        }

        // Now, append the modified object to storyParams
        storyParams.append(
          "professional",
          JSON.stringify(cunsultedProfessionalObject)
        );

        const physicalTransformationObj = {};

        if (isPhysicalChecked) {
          for (const key in physicalTransformationState) {
            if (physicalTransformationState.hasOwnProperty(key)) {
              physicalTransformationObj[key] = physicalTransformationState[key];
            }
          }

          storyParams.append(
            "physicalTransformation",
            JSON.stringify(physicalTransformationObj)
          );
        }

        const storyRout = storyDataForUpdate
          ? `story/update/${storyDataForUpdate._id}`
          : "story/create";

        const response = await ApiCall({
          params: storyParams,
          route: storyRout,
          verb: "post",
          token: token,
          baseurl: true,
        });
        if (response.status === 200) {
          toggleStoryModal();
          message.success(`${response.response.message}`);
          if (!storyDataForUpdate) {
            setallUsersStories([response.response.data, ...allUsersStories]);
          } else {
            setallUsersStories(
              allUsersStories.map((item) => {
                if (item._id === storyDataForUpdate._id) {
                  return response.response.data;
                } else {
                  return item;
                }
              })
            );
          }
          setStoryState((prevState) => {
            return {
              ...prevState,
              title: "",
              isAnonym: false,
              content: "",
            };
          });
          setResourceInputState({});
          setIsPhysicalChecked();
          setisAnonymChecked();
          setErrorMessage("");
          setCunsultedProfessional({});
          setphotoBeforeUpState(null);
          setphotoAfterUpState(null);
          setPhysicalTransformationState((prevState) => ({
            ...prevState,
            isSatisfied: "true",
            price: "",
          }));
        } else {
          response?.response?.errorMessage
            ? message.error(`${response.response.errorMessage}`)
            : message.error(`Network Error`);
          // message.error(`${response.response.errorMessage}`);
        }
      } catch (error) {
        // message.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const characterCount =
    MAX_CHARACTERS - resourceInputState.resourceReason?.length ||
    MAX_CHARACTERS;

  const resourceItems = [
    <Film
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Livre
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Serie
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Influenceur
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Video
      handleResourceInputChange={handleResourceInputChange}
      handleResourceInputOnBlure={handleResourceInputOnBlure}
      videoUrlError={videoUrlError}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Podcast
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Mosique
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      characterCount={characterCount}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
    <Citation
      handleResourceInputChange={handleResourceInputChange}
      resourceInputState={resourceInputState}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />,
  ];

  const textareaStyles = {
    border: isTextareaFocused ? "1px solid #01989f" : "1px solid #191919",
  };

  const setNewQoute = (value) => {
    setIsShowAddQuoteButton(false);
    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        fullName: value,
        professionalId: value,
      };
    });
    setTempProfessionalName("");
    setAllSearchedProfessionals((prevState) => [
      ...prevState,
      { value, label: value },
    ]);
  };

  const setNewAutreProfessionalSubType = (value) => {
    setIsShowAddQuoteButton(false);
    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        professionalSubtype: value,
        professionalSubtypeId: value,
        firstName: "",
        fullName: "",
        lastName: "",
        address: "",
        professionalId: "",
      };
    });
    setTempProfessionalName("");
    setAllSearchedProfessionalsSubType((prevState) => [
      ...prevState,
      { value, label: value },
    ]);
  };

  const setNewAutreProfessional = (value) => {
    setIsShowAddQuoteButton(false);
    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        fullName: value,
        professionalId: value,
      };
    });
    setTempProfessionalName("");
    setAllSearchedProfessionals((prevState) => [
      ...prevState,
      { value, label: value },
    ]);
  };

  const handleThemeSelect = (value) => {
    setStoryState((prevState) => {
      return { ...prevState, theme: value };
    });
  };

  const onProfessionaltypeCancel = () => {
    setCunsultedProfessional({});
  };

  const onProfessionalSubtypeCancel = () => {
    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        professionalSubType: "",
        professionalSubtypeId: "",
      };
    });
  };

  const onProfessionalNameCancel = () => {
    setCunsultedProfessional((prevState) => {
      return {
        ...prevState,
        professionalFullName: "",
        professionalId: "",
        address: "",
        lastName: "",
      };
    });
    setTempProfessionalName(false);
  };

  useEffect(() => {
    if (token) {
      fetchInitialUsers();
    }
  }, [token]);

  // Fetch the initial ten users from the API when the component loads
  async function fetchInitialUsers() {
    try {
      const response = await ApiCall({
        params: {}, // You can pass query parameters for limiting the initial data
        route: "user/get-tagged-users", // Replace with your API route
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setMatchingUsers(response.response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  let allMentionsUsers = matchingUsers?.map((user) => ({
    id: user?._id,
    display: user?.username,
  }));

  const renderUserSuggestion = (suggestion, search, highlightedDisplay) => (
    <div>@ {highlightedDisplay}</div>
  );

  const handleContentChange = (e, newValue, newPlainTextValue, mentions) => {
    const value = e.target.value;
    setErrorMessage("");
    if (value.length <= MAX_CHARACTERS) {
      if (storyState?.content === "" && (value === " " || value === "\n")) {
        setStoryState((prevState) => {
          return {
            ...prevState,
            content: "Ça a commencé par ",
          };
        });
        return;
      }
      setStoryState((prevState) => {
        return { ...prevState, content: value };
      });
      setMaxCharacterError(false);
    } else {
      setMaxCharacterError(true);
    }

    // setPlainComment(newPlainTextValue)
    // console.log(newPlainTextValue, 'pleain text value')
  };

  return (
    <div>
      <Modal
        size="md"
        style={{ maxWidth: "1400px", width: "100%" }}
        centered
        modalClassName="SubmitStoryModal"
        toggle={() => {
          handleToggle();
          setStoryState((prevState) => ({
            ...prevState,
            content: "",
          }));
        }}
        isOpen={toggleState}
      >
        <ModalBody className={styles.StoryModalBody}>
          <div className={styles.header}>
            <div className={styles.leftSide}>
              <h5 className={styles.headerTitle}>Storytime</h5>
              <ReactSVG src={theme} />
            </div>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => {
                handleToggle();
                setStoryState((prevState) => ({
                  ...prevState,
                  content: "",
                }));
              }}
            >
              <img
                src={burgerCrossBlack}
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.content}>
            <p>Racontez ce citron qui acidifie votre vie…</p>
            <p>
              {" "}
              Ou racontez comment vous avez transformé le plus acide des{" "}
              <strong>citrons</strong> en <strong>bonne limonade</strong>, ça
              pourrait aider&nbsp;!
            </p>
            {/* History */}
            <div className={styles.title}>
              <h5>Histoire</h5>
              <div className={styles.line}></div>
            </div>
            <h3 className={`${styles.resourcesTitle}`}>
              Choix du thème <span className="text-danger">*</span>
            </h3>

            <SearchableSelect
              placeholder="Choix du thème ..."
              options={mappedThemes}
              selectedValue={storyState?.theme}
              handleOptionSelect={handleThemeSelect}
            />

            {errorMessage == "Veuillez sélectionner un thème." && (
              <div className="errorMessage">{errorMessage}</div>
            )}
            <CustomInput
              label="Titre de votre histoire"
              type="text"
              name="title"
              mendatory="*"
              emogie={false}
              value={storyState.title}
              placeHolder="Titre de votre histoire ..."
              handleOnChange={handleInputChange}
            />
            {errorMessage == "Veuillez entrer le titre de l'histoire." && (
              <div className="errorMessage">{errorMessage}</div>
            )}
            <div className={styles.textArea}>
              <h3 className={`${styles.resourcesTitle}`}>
                Racontez ! <span className="text-danger">*</span>{" "}
              </h3>
              {/* <div className={styles.inputWrapper} style={textareaStyles}>
                <textarea
                  id="content"
                  name="content"
                  value={storyState?.content}
                  rows="4"
                  cols="50"
                  onBlur={() => setIsTextareaFocused(false)}
                  onFocus={() => setIsTextareaFocused(true)}
                  placeholder="Ça a commencé par ..."
                  onChange={handleInputChange}
                />
                <span className={styles.emogie} onClick={toggleEmojiPicker}>
                  <img
                    src={smilyIcon}
                    alt="smily"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </span>
                {showEmojiPicker && (
                  <div className={styles.emogiePicker} ref={emojiPickerRef}>
                    <Picker
                      data={data}
                      previewPosition="none"
                      theme="light"
                      onEmojiSelect={handleEmojiSelect}
                    />
                  </div>
                )}
                <p className={styles.charCount}>
                  {`${MAX_CHARACTERS - storyState?.content?.length}`} caractères
                  restants.
                </p>
              </div> */}

              <div className={styles.inputWrapper} style={textareaStyles}>
                <MentionsInput
                  value={storyState?.content}
                  onChange={handleContentChange}
                  placeholder="Ça a commencé par ..."
                  className="mentions"
                  classNames={styles}
                  onBlur={() => setIsTextareaFocused(false)}
                  onFocus={() => setIsTextareaFocused(true)}
                >
                  <Mention
                    trigger={"@"}
                    data={allMentionsUsers}
                    markup="@[__display__]{__id__}"
                    renderSuggestion={renderUserSuggestion}
                    className={styles.mentions__mention}
                    appendSpaceOnAdd={true}
                  />
                </MentionsInput>

                <span className={styles.emogie} onClick={toggleEmojiPicker}>
                  <img
                    src={smilyIcon}
                    alt="smily"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </span>
                {showEmojiPicker && (
                  <div className={styles.emogiePicker} ref={emojiPickerRef}>
                    <Picker
                      data={data}
                      previewPosition="none"
                      theme="light"
                      onEmojiSelect={handleEmojiSelect}
                    />
                  </div>
                )}
                <p className={styles.charCount}>
                  {`${MAX_CHARACTERS - storyState?.content?.length}`} caractères
                  restants.
                </p>
              </div>
            </div>
            {errorMessage == "Veuillez saisir les détails de l'histoire." && (
              <div className="errorMessage">{errorMessage}</div>
            )}
            {maxCharacterError && (
              <div className="errorMessage">
                Vous avez atteint la limite de 2200 caractères.
              </div>
            )}
            {/* Professional */}
            <div className={styles.title}>
              <h5>
                Professionnel <span>(facultatif)</span>
              </h5>
              <div className={styles.line}></div>
            </div>
            <h3 className={`${styles.resourcesTitle}`}>
              Mentionner le professionnel consulté
            </h3>
            <div>
              <SearchableSelect
                options={profesionalsList.map((item) => ({
                  value: item.key,
                  label: item.label,
                }))}
                placeholder="De qui (ou de quoi) s'agit-il ?"
                selectedValue={
                  cunsultedProfessional?.professionaltype || undefined
                }
                handleOptionSelect={handleProfessionalTypeSelect}
                onCrossClick={onProfessionaltypeCancel}
              />
            </div>

            {/* input for professionals*/}

            <div>
              {["professionnel", "psychologue"].includes(
                cunsultedProfessional?.professionaltype
              ) && (
                <>
                  <h3 className={`${styles.resourcesTitle}`}></h3>
                  <SearchableSelect
                    options={allSearchedProfessionalsSubType}
                    placeholder="Quelle est sa profession ?"
                    selectedValue={cunsultedProfessional?.professionalSubtypeId}
                    handleOptionSelect={handleProfessionalSubTypeSelect}
                    onCrossClick={onProfessionalSubtypeCancel}
                    isLoading={isAllSearchedProfessionalsSubTypeLoading}
                  />
                </>
              )}
            </div>

            {/* input for others */}
            <div>
              {cunsultedProfessional?.professionaltype == "autre" && (
                <>
                  <h3 className={`${styles.resourcesTitle}`}></h3>
                  <SearchableSelect
                    options={allSearchedProfessionalsSubType}
                    placeholder="Quelle est sa profession ?"
                    selectedValue={cunsultedProfessional?.professionalSubtypeId}
                    handleOptionSelect={handleProfessionalSubTypeSelect}
                    onCrossClick={onProfessionalSubtypeCancel}
                    setNewOption={() =>
                      setNewAutreProfessionalSubType(searchedQoute)
                    }
                    allowNewOption={
                      cunsultedProfessional?.professionaltype == "autre" && true
                    }
                    newOptionTitle="+ Ajouter cette profession"
                    isLoading={isAllSearchedProfessionalsSubTypeLoading}
                  />
                </>
              )}
            </div>

            <div>
              {(["professionnel", "psychologue"].includes(
                cunsultedProfessional?.professionaltype
              )
                ? cunsultedProfessional?.professionalSubType
                : cunsultedProfessional?.professionaltype) && (
                <>
                  <h3 className={`${styles.resourcesTitle}`}></h3>
                  <SearchableSelect
                    options={allSearchedProfessionals}
                    onSearch={(e) => searchForProfessionalNamesList(e)}
                    placeholder="Quel est son nom ?"
                    selectedValue={cunsultedProfessional?.professionalId}
                    handleOptionSelect={handleConsultedProfessionalNameChange}
                    onCrossClick={onProfessionalNameCancel}
                    setNewOption={() => setNewQoute(searchedQoute)}
                    allowNewOption={
                      !(allSearchedProfessionals?.length > 0) &&
                      (cunsultedProfessional.professionaltype == "coach" ||
                        cunsultedProfessional.professionaltype ==
                          "thérapeute" ||
                        cunsultedProfessional.professionaltype == "autre") &&
                      searchedQoute?.trim() !== "" &&
                      isShowAddQuoteButton &&
                      true
                    }
                    newOptionTitle="+ Ajouter cette profession"
                    isLoading={isAllSearchedProfessionalsLoading}
                  />

                  {cunsultedProfessional.professionaltype ==
                    "professionnel" && (
                    <p className={styles.infoAboutProfessional}>
                      Vous ne pouvez mentionner un professionnel de santé que
                      s’il est enregistré et vérifié par nos équipes.
                      Recherchez-le en saisissant son nom.
                    </p>
                  )}
                  {cunsultedProfessional.professionaltype == "psychologue" && (
                    <p className={styles.infoAboutProfessional}>
                      Vous ne pouvez mentionner un psychologue que s’il est
                      enregistré et vérifié par nos équipes. Recherchez-le en
                      saisissant son nom.
                    </p>
                  )}
                  {cunsultedProfessional.professionaltype == "avocat" && (
                    <p className={styles.infoAboutProfessional}>
                      Vous ne pouvez mentionner un avocat que s’il est
                      enregistré et vérifié par nos équipes. Recherchez-le en
                      saisissant son nom.
                    </p>
                  )}
                  {cunsultedProfessional.professionaltype == "association" && (
                    <p className={styles.infoAboutProfessional}>
                      Vous ne pouvez mentionner une association que si elle est
                      enregistrée et vérifiée par nos équipes. Recherchez-la en
                      saisissant son nom.
                    </p>
                  )}
                  {cunsultedProfessional.professionaltype ==
                    "expert-comptable" && (
                    <p className={styles.infoAboutProfessional}>
                      Vous ne pouvez mentionner un expert-comptable que s’il est
                      enregistré et vérifié par nos équipes. Recherchez-le en
                      saisissant son nom.
                    </p>
                  )}
                </>
              )}
            </div>

            {/* ["coach", "autre", "thérapeute", "professionnel"].includes(
                cunsultedProfessional?.professionaltype
              ) && */}
            {/* <div>
              {cunsultedProfessional?.fullName && (
                <Spin size="small" spinning={professionalDetailsLoading}>
                  <div>
                    <CustomInput
                      label=""
                      type="text"
                      name="lastName"
                      // emogie={true}
                      value={cunsultedProfessional?.lastName}
                      readOnly={tempProfessionalName ? true : false}
                      handleOnChange={onCunsultedProfessionalChange}
                      placeHolder="Quel est son prénom ?"
                    />
                  </div>
                </Spin>
              )}
            </div> */}

            {cunsultedProfessional?.fullName && (
              <Spin size="small" spinning={professionalDetailsLoading}>
                <div>
                  <CustomInput
                    label=""
                    type="text"
                    name="address"
                    // emogie={true}
                    value={cunsultedProfessional?.address}
                    readOnly={tempProfessionalName ? true : false}
                    handleOnChange={onCunsultedProfessionalChange}
                    placeHolder="Quelle est sa ville ?"
                  />
                </div>
              </Spin>
            )}

            <div className={styles.title}>
              <h5>
                Ressources <span>(facultatif)</span>
              </h5>
              <div className={styles.line}></div>
            </div>
            <h3 className={styles.resourcesTitle}>
              Recommander une ressource liée à votre histoire
            </h3>
            <div className={styles.resourceContainer}>
              {resources.map((resource, index) => {
                return (
                  <div>
                    <button
                      key={index}
                      onClick={() => {
                        if (activeResourceBtn !== index) {
                          setactiveResourceBtn(index);
                          setResourceInputState((prevState) => {
                            return {
                              resourcetype: resource.value,
                            };
                          });
                        } else {
                          setactiveResourceBtn("");
                          setResourceInputState((prevState) => {
                            return { ...prevState, resourcetype: "" };
                          });
                          setResourceInputState({});
                        }
                      }}
                      className={`${
                        activeResourceBtn === index ? styles.activeResource : ""
                      } ${styles.resource}`}
                    >
                      {resource.name}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className={styles.resourceContainer}>
              <div style={{ width: "100%" }}>
                {resourceItems[activeResourceBtn]}
              </div>
            </div>
            {!isPhysicalChecked ? (
              <div
                className={` ${styles.transformCheckbox}`}
                onClick={() => setIsPhysicalChecked(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <p>Transformation physique</p>
                  <p> Chirurgie réparatrice ou esthétique</p>
                </div>
              </div>
            ) : (
              <div
                className={` ${styles.transformCheckbox}`}
                onClick={() => {
                  setIsPhysicalChecked(false);
                  setSelectedTitle(null);
                  setSelectedTreatment(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <p>Transformation physique</p>
                  <p>Chirurgie réparatrice ou esthétique</p>
                </div>
              </div>
            )}
            {/* show/hide physical content */}
            {isPhysicalChecked && (
              <>
                {" "}
                <div className={styles.physicalTransformation}>
                  <div
                    className={`${styles.physicalTransformationSelect} physicalTransformationSelect`}
                  >
                    <h3 className={`${styles.resourcesTitle1}`}>
                      Partie du corps <span className="text-danger">*</span>
                    </h3>
                    <SearchableSelect
                      options={allBodyParts} // Sort the options alphabetically
                      placeholder="Partie du corps ..."
                      selectedValue={physicalTransformationState?.bodyPartId}
                      handleOptionSelect={onTitleChange}
                      // setNewOption={() => setNewQoute(searchedQoute)}
                      // allowNewOption={true}
                      // newOptionTitle="+ Ajouter une nouvelle partie du corps"
                      isLoading={bodyPartsLoading}
                    />

                    {errorMessage ==
                      "Veuillez sélectionner une partie du corps." && (
                      <div className="errorMessage">{errorMessage}</div>
                    )}
                  </div>
                  <div
                    className={`${styles.physicalTransformationSelect} physicalTransformationSelect`}
                  >
                    <h3 className={`${styles.resourcesTitle}`}>
                      Traitement / Opération / Programme{" "}
                      <span className="text-danger">*</span>
                    </h3>
                    <SearchableSelect
                      options={treatments?.treatments?.map((treatment) => ({
                        label: treatment,
                        value: treatment,
                      }))}
                      placeholder="Nom de la solution choisie ..."
                      selectedValue={physicalTransformationState?.treatment}
                      handleOptionSelect={onTreatmentChange}
                      isLoading={treatmentsLoading}
                    />

                    {errorMessage == "Veuillez sélectionner une solution." && (
                      <div className="errorMessage">{errorMessage}</div>
                    )}
                  </div>

                  <div>
                    <div className={styles.PhysicalInput}>
                      <label htmlFor="photoBefore" className={styles.label}>
                        Photo avant <span className="text-danger">*</span>
                      </label>
                      <div className={styles.physicalinputWrapper}>
                        <input
                          id="photoBefore"
                          name="photoBefore"
                          type="file"
                          accept="image/*"
                          onChange={handleIPhotoBeforeUpload}
                        />
                      </div>
                      <label
                        htmlFor="photoBefore"
                        className={styles.CustomizeInput}
                      >
                        {physicalTransformationState?.beforePictureUrl ? (
                          <p>
                            {physicalTransformationState?.beforePictureUrl}{" "}
                          </p>
                        ) : photoBeforeUpState ? (
                          <p>{photoBeforeUpState?.name} </p>
                        ) : (
                          <p> Ajouter un fichier...</p>
                        )}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 20 20"
                          className="renderTransformationPart_icon__hCEOx"
                        >
                          <path
                            d="M18.036 12.143c.272 0 .496.201.531.463l.005.073v1.071a2.68 2.68 0 0 1-2.536 2.675l-.142.004H4.108a2.68 2.68 0 0 1-2.675-2.537l-.004-.142v-1.071a.536.536 0 0 1 1.066-.073l.006.073v1.071c0 .85.66 1.547 1.497 1.603l.11.004h11.786c.85 0 1.546-.66 1.603-1.497l.004-.11v-1.071c0-.296.24-.536.535-.536zM10.001 3.57c.27 0 .495.202.53.463l.005.073v7.277l2.3-2.299a.536.536 0 0 1 .698-.051l.06.051c.19.19.207.488.052.698l-.052.06-3.214 3.214a.61.61 0 0 1-.067.057l.067-.057a.544.544 0 0 1-.148.105l-.035.015c-.016.006-.032.012-.05.017l-.034.008-.05.009-.044.003H9.97l-.038-.004.069.004a.52.52 0 0 1-.12-.013c-.015-.004-.029-.007-.043-.012l-.036-.013a.43.43 0 0 1-.101-.054l-.019-.013-.06-.052-3.214-3.214a.535.535 0 0 1 .697-.809l.06.051 2.3 2.3V4.106c0-.296.24-.536.536-.536z"
                            fill="#191919"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </label>
                    </div>
                    {errorMessage == "Veuillez insérer vos photos." && (
                      <div className="errorMessage">{errorMessage}</div>
                    )}
                    <div className={styles.PhysicalInput}>
                      <label htmlFor="photoAfter" className={styles.label}>
                        Photo après <span className="text-danger">*</span>
                      </label>
                      <div className={styles.physicalinputWrapper}>
                        <input
                          id="photoAfter"
                          name="photoAfter"
                          type="file"
                          accept="image/*"
                          onChange={handleIPhotoAfterUpload}
                        />
                      </div>
                      <label
                        htmlFor="photoAfter"
                        className={styles.CustomizeInput}
                      >
                        {physicalTransformationState?.beforePictureUrl ? (
                          <p>
                            {physicalTransformationState?.beforePictureUrl}{" "}
                          </p>
                        ) : photoAfterUpState ? (
                          <p>{photoAfterUpState?.name} </p>
                        ) : (
                          <p> Ajouter un fichier...</p>
                        )}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 20 20"
                          className="renderTransformationPart_icon__hCEOx"
                        >
                          <path
                            d="M18.036 12.143c.272 0 .496.201.531.463l.005.073v1.071a2.68 2.68 0 0 1-2.536 2.675l-.142.004H4.108a2.68 2.68 0 0 1-2.675-2.537l-.004-.142v-1.071a.536.536 0 0 1 1.066-.073l.006.073v1.071c0 .85.66 1.547 1.497 1.603l.11.004h11.786c.85 0 1.546-.66 1.603-1.497l.004-.11v-1.071c0-.296.24-.536.535-.536zM10.001 3.57c.27 0 .495.202.53.463l.005.073v7.277l2.3-2.299a.536.536 0 0 1 .698-.051l.06.051c.19.19.207.488.052.698l-.052.06-3.214 3.214a.61.61 0 0 1-.067.057l.067-.057a.544.544 0 0 1-.148.105l-.035.015c-.016.006-.032.012-.05.017l-.034.008-.05.009-.044.003H9.97l-.038-.004.069.004a.52.52 0 0 1-.12-.013c-.015-.004-.029-.007-.043-.012l-.036-.013a.43.43 0 0 1-.101-.054l-.019-.013-.06-.052-3.214-3.214a.535.535 0 0 1 .697-.809l.06.051 2.3 2.3V4.106c0-.296.24-.536.536-.536z"
                            fill="#191919"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </label>
                    </div>
                    {errorMessage == "Veuillez insérer vos photos." && (
                      <div className="errorMessage">{errorMessage}</div>
                    )}
                  </div>

                  {/* Radioboxes */}

                  <div className={styles.priceSatisfyGrp}>
                    <div className={styles.priceWrapper}>
                      <CustomInput
                        label="Price"
                        type="text"
                        name="price"
                        mendatory="*"
                        emogie={false}
                        value={physicalTransformationState.price}
                        placeHolder="Price ..."
                        handleOnChange={handlePhysicalTransformationInputChange}
                      />
                      <p className={styles.priceSign}>€</p>
                    </div>
                    {errorMessage ==
                      "Veuillez indiquer le prix de votre solution." && (
                      <div className="errorMessage">{errorMessage}</div>
                    )}
                    <div>
                      <h3 className={styles.resourcesTitle}>
                        Êtes-vous satisfait(e) ?
                      </h3>

                      <div className={styles.radioButtonsWrapper}>
                        <div className={styles.radioButtonsGrp}>
                          <input
                            type="radio"
                            id="oui"
                            name="isSatisfied"
                            value="true"
                            checked={
                              physicalTransformationState?.isSatisfied == "true"
                            }
                            onChange={handlePhysicalTransformationInputChange}
                          />
                          <label htmlFor="oui">OUI</label>
                        </div>

                        <div className={styles.radioButtonsGrp}>
                          <input
                            type="radio"
                            id="Non"
                            name="isSatisfied"
                            value="false"
                            checked={
                              physicalTransformationState?.isSatisfied ==
                              "false"
                            }
                            onChange={handlePhysicalTransformationInputChange}
                          />
                          <label htmlFor="Non">NON</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!isAnonymChecked ? (
              <div
                className={` ${styles.isAnonymCheckbox}`}
                onClick={() => {
                  setisAnonymChecked(true);
                  setStoryState((prevState) => {
                    return { ...prevState, isAnonym: true };
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <p>Je souhaite rester anonyme.</p>
                </div>
              </div>
            ) : (
              <div
                className={` ${styles.isAnonymCheckbox}`}
                onClick={() => {
                  setisAnonymChecked(false);
                  setStoryState((prevState) => {
                    return { ...prevState, isAnonym: false };
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <p>Je souhaite rester anonyme.</p>
                </div>
              </div>
            )}
            {/* Submit button */}
            {loading ? (
              <div className={styles.loaderWrapper}>
                <DotsLoader color="white" />
              </div>
            ) : (
              <div className={styles.SubmitStoryButtonWrapper}>
                <button onClick={onStorySubmit}>
                  {storyDataForUpdate ? "mettre à jour l'histoire" : "PUBLIER"}{" "}
                </button>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SubmitStory;
