import React, { useState } from "react";
import styles from "../../assets/styles/userLogin.module.scss";
import { Modal, ModalBody } from "reactstrap";

import ButtonFilled from "../buttonFilled";
import UserSignup from "./signUp/SignupUser";
import ForgotPassword from "./ForgotPassword";
import { useAuth } from "../../context/AuthContext";
import { baseUrl } from "../../environment";

import {
  google,
  facebook,
  insta,
  burgerCrossBlack,
  eyeIcon,
  eyeSlash,
} from "../../assets";
import { useNavigate } from "react-router-dom";

import DotsLoader from "../UI/DotsLoader";

const Userlogin = ({ toggleState, handleToggle }) => {
  const { login, getAllUserStories } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [signUpModal, setsignUpModal] = useState(false);
  const [forgotPassModal, setForgotPassModal] = useState(false);
  const [isVerified, setIsVerified] = useState(true);

  const navigate = useNavigate();

  //   for signup modal
  const togglesignUpModal = () => {
    setsignUpModal(!signUpModal);
    if (!signUpModal) {
      handleToggle();
    }
  };

  //   for forgot Password modal
  const toggleForgotPassModal = () => {
    setForgotPassModal(!forgotPassModal);
    if (!forgotPassModal) {
      handleToggle();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await login(
      email,
      password,
      setEmail,
      setPassword,
      setLoading,
      (handleToggle = { handleToggle })
    );

    getAllUserStories(1);

    if (response.status === 400) {
      if (
        response.response.message === "L'utilisateur n'est pas encore vérifié"
      ) {
        setIsVerified(false);
        setsignUpModal(!signUpModal);
        // handleLoginModalClose();
      }
    }
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // on closing login modal without loggin In, get redirectedd to home.
  const handleLoginModalClose = () => {
    handleToggle();
    navigate("/");
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="loginModal"
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.loginModalBodyHeader}>
            <h5 id="signUpModalLabel">Connexion</h5>
            {/* <button aria-label="Close" type="button" onClick={handleToggle}> */}
            <button
              aria-label="Close"
              type="button"
              onClick={handleLoginModalClose}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.loginForm}>
            <div className={styles.socialLoginsWrapper}>
              <a href={`${baseUrl}/auth/facebook`}>
                <img
                  src={facebook}
                  alt="facebook"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </a>

              <a href={`${baseUrl}/auth/google`}>
                <img
                  src={google}
                  alt="google"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </a>

              <a
                href={`https://api.instagram.com/oauth/authorize?client_id=256227790671891&redirect_uri=https://psyup.fr/auth/instagram/callback&scope=user_profile&response_type=code`}
              >
                <img
                  src={insta}
                  alt="insta"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </a>
            </div>

            <div className={styles.devider}>
              <p></p>
              <h4>OU</h4>
              <p></p>
            </div>
            <form>
              <div className={styles.inputContainer}>
                <label>Adresse mail</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="exemple@exemple.com"
                  />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <label>Mot de passe</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={handleToggleShowPassword}
                  >
                    <>
                      <img
                        className={styles.eyeClose}
                        src={showPassword ? eyeIcon : eyeSlash}
                        alt="eyeClose"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      {/* <div className={showPassword ? styles.slash : ""}></div> */}
                    </>
                  </button>
                </div>
              </div>
              {loading ? (
                <div className={styles.loaderWrapper}>
                  <DotsLoader color="white" />
                </div>
              ) : (
                <div className={styles.loginButton}>
                  <ButtonFilled
                    title="Se connecter"
                    textColr="white"
                    handleClick={handleSubmit}
                  />
                </div>
              )}

              <div className={styles.signupLink} onClick={togglesignUpModal}>
                <p>Créer mon compte</p>
              </div>

              <div
                className={styles.forgotLink}
                onClick={toggleForgotPassModal}
              >
                <p>Informations de compte oubliées</p>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ForgotPassword
        toggleState={forgotPassModal}
        handleToggle={toggleForgotPassModal}
      />
      <UserSignup
        toggleState={signUpModal}
        handleToggle={togglesignUpModal}
        isVerified={isVerified}
        setIsVerified={setIsVerified}
        email={email}
      />
      {/* <Step3
        userDataFunc={{}}
        userData={{}}
        setCurrentStep={{}}
        handleToggle={{}}
      /> */}
    </div>
  );
};

export default Userlogin;
