import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

import UserProfileDetails from "./UserProfileDetails";
import DisplayStories from "../../components/stories/DisplayStories";
import StoryLoader from "../../components/UI/StoryLoader";

const UserMainProfile = () => {
  const {
    token,
    searchedUserInfo,
    userStories,
    setUserStories,
    setStoryCount,
    storyCount,
    loginModal,
    setLoginModal,
  } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  // const [storyCount, setStoryCount] = useState(1);

  useEffect(() => {
    setUserDetails(searchedUserInfo);
  }, [searchedUserInfo]);

  useEffect(() => {
    const USER_ID = localStorage.getItem("userId");
    if (!USER_ID) {
      setLoginModal(!loginModal);
    }
  }, []);

  return (
    <>
    {/* {!userDetails && (
        <div>
          <StoryLoader />
          <StoryLoader />
        </div>
      )} */}
      {!userDetails ? (
        <div>
          <StoryLoader />
          <StoryLoader />
        </div>
      ) : (
        token && (
          <div>
            <UserProfileDetails
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              selectedTheme={selectedTheme}
              setSelectedTheme={setSelectedTheme}
            />
            <DisplayStories
              stories={userStories}
              setStories={setUserStories}
              selectedTheme={selectedTheme}
              setStoryCount={setStoryCount}
              storyCount={storyCount}
            />
          </div>
        )
      )}
    </>
  );
};

export default UserMainProfile;
