import React, { useEffect, useState } from "react";
import { Dropdown, Menu, message } from "antd";
import { getShareUrl, SocialPlatforms } from "@phntms/react-share";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { useNavigate } from "react-router";
import styles from "../../assets/styles/DisplayStories.module.scss";

import {
  faCaretRight,
  faCopy,
  faEllipsisVertical,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayComments from "../comments/DisplayComments";
import ButtonFilled from "../buttonFilled";
import { useAuth } from "../../context/AuthContext";

import {
  like,
  meToo,
  wow,
  beStrongHug,
  goodWriterGold,
  favorite,
  commentsIcon,
  share,
  report,
  beforeAfter,
  storyEdit,
  userAvatar,
} from "../../assets/index";
import { siteUrl } from "../../environment";
import StoryLoader from "../UI/StoryLoader";
import { ApiCall } from "../../config/apiCall";
import ResourcesModal from "./storyModals/ResourcesModal";
import BeforeAfterModal from "./storyModals/BeforeAfterModal";
import { getImageByValue } from "./GetResourceIcons";
import SubmitStory from "./SubmitStory";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import FriendsListModal from "./storyModals/FriendsListModal";

const MAX_LENGTH = 100;
const currentDate = new Date();
const monthNames = [
  "jan",
  "fév",
  "mar",
  "avril",
  "mai",
  "juin",
  "juil",
  "août",
  "sept",
  "oct",
  "nov",
  "déc",
];
const reactionsArray = [
  {
    emogi: like,
    value: "like",
    selected: false,
    id: 1,
    height: 25,
    width: 25,
    title: "J’aime !",
    length: 8,
  },
  {
    emogi: meToo,
    value: "meToo",
    selected: false,
    id: 2,
    height: 25,
    width: 25,
    title: "J’ai vécu la même (ou presque) !",
    length: 32,
  },
  {
    emogi: beStrongHug,
    value: "beStrongHug",
    selected: false,
    id: 3,
    height: 27,
    width: 25,
    title: "Ensemble !",
    length: 10,
  },
  {
    emogi: wow,
    value: "wow",
    selected: false,
    id: 4,
    height: 30,
    width: 25,
    title: "Wow !",
    length: 5,
  },
  {
    emogi: goodWriterGold,
    value: "goodWriterGold",
    selected: false,
    id: 5,
    height: 25,
    width: 25,
    title: "Belle plume !",
    length: 13,
  },
];

const DisplayStories = ({
  stories,
  setStories,
  selectedTheme,
  setStoryCount,
}) => {
  const {
    token,
    userId,
    getUserFavouriteStories,
    favstoryCount,
    userInfo,
    deleteStory,
    reportStory,
    storiesLoading,
    loginModal,
    setLoginModal,
    getSearchedUserProfile,
    getUserStories,
    singleUserStoryCount,
    timeFrameForStoryFiltration,
  } = useAuth();
  const navigate = useNavigate();

  const [resourceModalOpen, setresourceModalOpen] = useState(false);
  const [beforeAfterModalOpen, setBeforeAfterModalOpen] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [reactions, setReactions] = useState({});
  const [storyDataForUpdate, setStoryDataForUpdate] = useState(null);
  const [isCommentsOpen, setisCommentsOpen] = useState([]);

  const [showCommentsIndex, setShowCommentsIndex] = useState(-1);
  const [submitStoryModal, setSubmitStoryModal] = useState(false);
  const [friendsListModal, setFriendsListModal] = useState(false);
  const [storyId, setStoryId] = useState(null);

  useEffect(() => {
    setisCommentsOpen([]);
    setShowCommentsIndex(-1);
  }, [stories]);

  // show submitStory modal
  const toggleSubmitStoryModal = () => {
    if (token) {
      setSubmitStoryModal(!submitStoryModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  const toggleFriendsListModal = () => {
    if (token) {
      setFriendsListModal(!friendsListModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  let selectedIndex = -1;

  let filteredStories = [];
  // story filtration by themes
  try {
    filteredStories = selectedTheme
      ? stories?.filter((story) => story?.theme?._id === selectedTheme)
      : stories;
  } catch (error) {}

  // Define date ranges for different time frames
  const dateRanges = {
    "This Day": {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      ),
    },
    "This Week": {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 7 - currentDate.getDay()
      ),
    },
    "This Month": {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
    },
    "This Year": {
      start: new Date(currentDate.getFullYear(), 0, 1),
      end: new Date(currentDate.getFullYear() + 1, 0, 1),
    },
  };

  // Filter stories by the selected time frame
  filteredStories = timeFrameForStoryFiltration
    ? stories?.filter((story) => {
        const { start, end } = dateRanges[timeFrameForStoryFiltration];
        const storyDate = new Date(story.createdAt);
        return storyDate >= start && storyDate < end;
      })
    : filteredStories
    ? filteredStories
    : stories;

  const items = [
    {
      label: "Copy link",
      key: "0",
      onClick: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        navigator.clipboard?.writeText(storyLink);
        message.success("Story link copied to clipboard!");
      },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <>
            {/* <a href={getShareUrl(SocialPlatforms.Facebook, { url: storyLink })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  fill="white"
                  d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                />
              </svg>
            </a> */}
            <FacebookShareButton
              url={storyLink}
              quote={filteredStories[index]?.title}
            >
              <a className={styles.svgLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                </svg>
              </a>
            </FacebookShareButton>
          </>
        );
      },
    },

    {
      label: "WhatsApp",
      key: "3",
      render: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <>
            <a href={getShareUrl(SocialPlatforms.WhatsApp, { url: storyLink })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  fill="white"
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
              </svg>
            </a>
            {/* <WhatsappShareButton
              url={storyLink}
              title={filteredStories[index]?.title}
            >
              <a className={styles.svgLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                </svg>
              </a>
            </WhatsappShareButton> */}
          </>
        );
      },
    },
    {
      label: "Twitter",
      key: "4",
      render: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <>
            {/* <a href={getShareUrl(SocialPlatforms.Twitter, { url: storyLink })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  fill="white"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                />
              </svg>
            </a> */}
            <TwitterShareButton
              url={storyLink}
              title={filteredStories[index]?.title}
            >
              <a className={styles.svgLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </a>
            </TwitterShareButton>
          </>
        );
      },
    },
    {
      label: "Share in private",
      key: "2",
      onClick: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
      },
      render: (index) => {
        const selectedStory = filteredStories[index];
        const storyId = `${selectedStory._id}`;
        return (
          <>
            <div
              className={styles.sharePrivate}
              onClick={() => sendInMessage(storyId)}
            >
              <ReactSVG src={storyEdit} />
            </div>
          </>
        );
      },
    },
    {
      label: "Share on feed",
      key: "5",
      onClick: (index) => {
        const selectedStory = filteredStories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        shareStoryOnFeed(selectedStory._id);
        // navigator.clipboard?.writeText(storyLink);
        // message.success("Story link copied to clipboard!");
      },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faUser} style={{ color: "#fff" }} />
        </>
      ),
    },
  ];

  const shareStoryOnFeed = async (storyId) => {
    try {
      const response = await ApiCall({
        params: {},
        route: `story/repostStory/${storyId}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        message.success("Partagé dans votre Book");
      }
      // console.log("repostStory", response);
    } catch (error) {}
  };

  const sendInMessage = (storyId) => {
    toggleFriendsListModal();
    setStoryId(storyId);
    // navigate(`/newMessage?storyId=${storyLink}`);
  };

  const storyOptions = [
    {
      label: "Modifier ma publication",
      key: "0",
      onClick: (story) => {
        setStoryDataForUpdate(story);
        toggleSubmitStoryModal();
        // message.success("Modifier Ma Publication");
      },
    },
    {
      label: "Supprimer ma publication",
      key: "1",
      onClick: (story) => {
        handleDeleteStory(story._id);
        // message.success("Publication deleted successfully");
      },
    },
  ];

  const totalReactionsOnPost = [
    {
      key: "1",
      render: (story) => {
        return (
          <>
            <img height={22} width={22} src={like} onContextMenu={(e) => e.preventDefault()} />
            <p>{story?.smiley1_count}</p>
          </>
        );
      },
    },
    {
      key: "2",
      render: (story) => (
        <>
          <img src={meToo} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley2_count}</p>
        </>
      ),
    },
    {
      key: "3",
      render: (story) => (
        <>
          <img src={beStrongHug} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley3_count}</p>
        </>
      ),
    },
    {
      key: "4",
      render: (story) => (
        <>
          <img src={wow} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley4_count}</p>
        </>
      ),
    },
    {
      key: "5",
      render: (story) => (
        <>
          <img src={goodWriterGold} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley5_count}</p>
        </>
      ),
    },
  ];

  const conditionallyRenderedItems = (story) => {
    const filteredItems = totalReactionsOnPost.filter(
      (item) => story[`smiley${item.key}_count`] > 0
    );

    return filteredItems.map((item) => (
      <React.Fragment key={item.key}>{item.render(story)}</React.Fragment>
    ));
  };

  // Function to handle reaction click
  const handleReactionClick = async (
    storyId,
    selectedReactionIndex,
    storyIndex,
    story,
    status
  ) => {
    if (token) {
      try {
        const selectedReaction = reactionsArray[selectedReactionIndex].id;

        const response = await ApiCall({
          params: {},
          route: `story/add-story-reaction/${storyId}&${
            status ? 0 : selectedReaction
          }`,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setStories((prev) =>
            prev.map((el) => {
              if (el?._id === response?.response?.data?._id) {
                return response?.response?.data;
              } else {
                return el;
              }
            })
          );

          setReactions((prevReactions) => ({
            ...prevReactions,
            [storyId]: selectedReactionIndex,
          }));
        } else {
          message.error(response.response.error[0]?.message);
        }
      } catch (error) {
        message.error(error);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  const handleFavoriteClick = async (storyId) => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: `story/addToFavourities/` + storyId,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          // Update the favorite status for the clicked story

          setStories((prev) =>
            prev.map((el) => {
              if (el?._id === response?.response?.data?._id) {
                // Update the isFavourite property of the corresponding story
                return {
                  ...el,
                  isFavourite: response?.response?.data?.isFavourite,
                };
              } else {
                return el;
              }
            })
          );
          getUserFavouriteStories(favstoryCount);
        }
        // console.log("fav", response);
      } catch (error) {
        // Handle error if necessary
      }
    } else {
      setLoginModal(!loginModal);
      // toast.error("Besoin de se connecter d'abord");
    }
  };

  // For less/more story description
  const [showMoreDescription, setShowMoreDescription] = useState(
    Array(stories?.length).fill(false)
  );

  // show more/less story content
  const toggleShowMore = (index) => {
    const newShowMoreDescription = [...showMoreDescription];
    newShowMoreDescription[index] = !newShowMoreDescription[index];
    setShowMoreDescription(newShowMoreDescription);
  };

  // load more stories
  const loadMore = () => {
    if (token) {
      setStoryCount((prevCount) => prevCount + 1);
    } else {
      setLoginModal(!loginModal);
    }
  };

  // Delete story
  const handleDeleteStory = async (storyId) => {
    await deleteStory(storyId);
    setShowCommentsIndex(-1);
  };

  // Report story
  const handleReportStory = (storyId) => {
    if (token) {
      reportStory(storyId);
    } else {
      setLoginModal(!loginModal);
    }
  };

  const handleResourceModal = (index) => {
    setSelectedStoryIndex(index);
    setresourceModalOpen(!resourceModalOpen);
  };

  const handleBeforeAfterModal = (index) => {
    setSelectedStoryIndex(index);
    setBeforeAfterModalOpen(!beforeAfterModalOpen);
  };

  const setCommentCounts = (storyId, count) => {
    setStories((prev) => {
      const storyIndex = prev?.findIndex((el) => el?._id === storyId);
      let temp = prev;
      temp[storyIndex].comments_count = count;
      console.log("count", count);
      console.log(
        "temp[storyIndex].comments_count",
        temp[storyIndex].comments_count
      );
      return temp;
    });
  };

  return (
    <>
      <>
        {!filteredStories ? (
          <div>
            <StoryLoader />
            <StoryLoader />
          </div>
        ) : (
          <>
            {filteredStories?.length == 0 && <p>Aucune histoire trouvée.</p>}
            {filteredStories ? (
              <div>
                {filteredStories?.map((story, storyIndex) => {
                  const showMore = showMoreDescription[storyIndex];
                  const showComments = showCommentsIndex === storyIndex;

                  selectedIndex = reactions[story._id];

                  const imageUrl = getImageByValue(
                    story?.ressource?.complete?.resourcetype
                  );
                  let DateFormate = "";
                  const date = new Date(story?.createdAt);
                  const currentDate = new Date();
                  if (
                    date.getFullYear() === currentDate.getFullYear() &&
                    date.getMonth() === currentDate.getMonth() &&
                    date.getDate() === currentDate.getDate()
                  ) {
                    const timeDifferenceInMilliseconds = currentDate - date;
                    const timeDifferenceInMinutes = Math.floor(
                      timeDifferenceInMilliseconds / (1000 * 60)
                    );
                    if (timeDifferenceInMinutes < 60) {
                      DateFormate = `il y a ${timeDifferenceInMinutes} min`;
                    } else {
                      const hours = Math.floor(timeDifferenceInMinutes / 60);
                      DateFormate = `il y a ${hours} h`;
                    }
                  } else if (date.getFullYear() === currentDate.getFullYear()) {
                    DateFormate = `${date.getDate()} ${
                      monthNames[date.getMonth()]
                    }`;
                  } else {
                    DateFormate = `${date.getDate()} ${
                      monthNames[date.getMonth()]
                    } ${date.getFullYear()} `;
                  }

                  const userProfileLink =
                    story.writter?._id === userId
                      ? `/profile?userId=${story.writter?._id}`
                      : `/userProfile?userId=${story.writter?._id}`;

                  const transformedContent = story?.content.replace(
                    /\@\[([^\]]+)]\{([^\}]+)\}/g,
                    (match, displayName, userId) => {
                      return `<a href="/userProfile?userId=${userId}" style="color: #01989f; cursor: pointer;" data-userid="${userId}"">${displayName}</a>`;
                    }
                  );

                  return (
                    <>
                      <div key={storyIndex}>
                        <div
                          className={`${
                            story?.originalWritter
                              ? styles.storyOuterContainerForShareablePost
                              : ""
                          } ${styles.storyOuterContainer}`}
                        >
                          {story?.originalWritter && (
                            <div className={styles.repostedUserWrapper}>
                              <div className={styles.picWrapper}>
                                <img
                                  src={
                                    story?.writter?.profilePicture?.url
                                      ? story?.writter?.profilePicture?.url
                                      : userAvatar
                                  }
                                />
                              </div>
                              <div className={styles.contentWrapper}>
                                <p>{story?.writter?.username} </p>
                                <p>{DateFormate} </p>
                              </div>
                            </div>
                          )}

                          <div
                            className={styles.DisplayStoriesContainer}
                            onClick={() => {
                              if (story?.originalWritter) {
                                navigate(`/stories/${story?.originalStory?._id}`);
                              }
                            }}
                          >
                            <div className={styles.storyHeader}>
                              <div className={styles.storyHeaderLeft}>
                                <p className={styles.theme}>
                                  {story.theme?.name}
                                </p>
                                <div className={styles.date}>
                                  {story.isAnonym ||
                                  story?.originalStory?.isAnonym ? (
                                    <p className={`${styles.userAnonym}`}>
                                      Par ****
                                    </p>
                                  ) : (
                                    <p
                                      className={
                                        !story.writter?.isAdmin
                                          ? styles.userName
                                          : styles.userAnonym
                                      }
                                    >
                                      Par{" "}
                                      <Link
                                        to={userProfileLink}
                                        className={
                                          story?.originalWritter
                                            ? styles.originalUserNameLink
                                            : styles.userNameLink
                                        }
                                      >
                                        {story?.originalWritter
                                          ? story?.originalWritter?.username
                                          : story.writter?.username}
                                      </Link>
                                    </p>
                                  )}

                                  {!story?.originalWritter && (
                                    <p className={`${styles.dateP} ps-1`}>
                                      {DateFormate}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className={styles.storyHeaderRight}>
                                <h2 className={styles.heading}>
                                  {story.title}
                                </h2>
                              </div>
                            </div>
                            <div className={styles.storyBody}>
                              {showMore ? (
                                <div className={styles.storyContent}>
                                  <p
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{
                                      __html: transformedContent,
                                    }}
                                  />
                                  {/* <p>
                                  {story.content}
                                  {story.content.length > MAX_LENGTH && (
                                    <span
                                      className={styles.contentBtn}
                                      onClick={() => toggleShowMore(storyIndex)}
                                    >
                                      Replier ▲
                                    </span>
                                  )}{" "}
                                </p> */}
                                </div>
                              ) : (
                                <div className={styles.storyContent}>
                                  <p
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{
                                      __html: transformedContent,
                                    }}
                                  />
                                  {/* <p>
                                  {story.content.slice(0, MAX_LENGTH)}
                                  {story.content.length > MAX_LENGTH && "..."}
                                  {story.content.length > MAX_LENGTH && (
                                    <span
                                      className={styles.contentBtn}
                                      onClick={() => toggleShowMore(storyIndex)}
                                    >
                                      Lire la suite
                                    </span>
                                  )}
                                </p> */}
                                </div>
                              )}

                              {/* first storyline  */}
                              {!story.professionalConsulted?.fullName &&
                              !story.ressource?.complete?.resourcetype &&
                              !(
                                story.physicalTransformation
                                  ?.beforePictureUrl ||
                                story.physicalTransformation?.afterPictureUrl
                              ) ? (
                                <div className="py-0"></div>
                              ) : (
                                <hr />
                              )}

                              <div
                                className={styles.prefessionalResourcesWrapper}
                              >
                                {story.professionalConsulted?.fullName && (
                                  <div>
                                    <p>Professionnel</p>
                                    <h6>
                                      <span className="font-bold text-black">
                                        {story.professionalConsulted?.fullName}{" "}
                                        {story.professionalConsulted?.firstName}
                                      </span>
                                    </h6>
                                  </div>
                                )}

                                {story.ressource?.complete?.resourcetype && (
                                  <div
                                    onClick={() =>
                                      handleResourceModal(storyIndex)
                                    }
                                  >
                                    <p className={styles.ressource}>
                                      Ressource
                                    </p>
                                    <img
                                      src={imageUrl}
                                      alt="img"
                                      className={styles.icon}
                                      width={
                                        story.ressource?.complete
                                          ?.resourcetype == "Podcast"
                                          ? 30
                                          : 40
                                      }
                                      height={40}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </div>
                                )}
                                {(story.physicalTransformation
                                  ?.beforePictureUrl ||
                                  story.physicalTransformation
                                    ?.afterPictureUrl) && (
                                  <div
                                    onClick={() =>
                                      handleBeforeAfterModal(storyIndex)
                                    }
                                  >
                                    <p>Avant /Après</p>
                                    <img
                                      src={beforeAfter}
                                      alt="logo"
                                      className={styles.icon}
                                      width={35}
                                      height={40}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* second storyline */}
                              {story?.originalWritter ||
                              (!story.professionalConsulted?.fullName &&
                                !story.ressource?.complete?.resourcetype &&
                                !(
                                  story.physicalTransformation
                                    ?.beforePictureUrl ||
                                  story.physicalTransformation?.afterPictureUrl
                                )) ? (
                                <div className="py-0"></div>
                              ) : (
                                <hr />
                              )}

                              {/* for original story */}
                              {!story?.originalWritter && (
                                <div className={styles.details}>
                                  <div className={styles.icons}>
                                    <div
                                      className={styles.favStarIcon}
                                      onClick={() =>
                                        handleFavoriteClick(story._id)
                                      }
                                    >
                                      {story.isFavourite ? (
                                        <div className={styles.icon}>
                                          <FontAwesomeIcon
                                            icon={faStar}
                                            style={{ color: "#F5D003" }}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          src={favorite}
                                          alt="logo"
                                          className={styles.icon}
                                          width={22}
                                          height={20}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                      )}
                                    </div>

                                    {isCommentsOpen.includes(storyIndex) ? (
                                      <div
                                        className={styles.commentsWrapper}
                                        onClick={() => {
                                          if (token) {
                                            setShowCommentsIndex("abc");
                                            setisCommentsOpen((prevState) =>
                                              prevState.filter(
                                                (item) => item !== storyIndex
                                              )
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          src={commentsIcon}
                                          className={styles.icon}
                                          alt="comments"
                                          width={20}
                                          height={20}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                        <p>({story.comments_count})</p>
                                      </div>
                                    ) : (
                                      <div
                                        className={styles.commentsWrapper}
                                        onClick={() => {
                                          if (token) {
                                            setShowCommentsIndex(storyIndex);
                                            setisCommentsOpen((prevState) => [
                                              storyIndex,
                                            ]);
                                          } else {
                                            setLoginModal(!loginModal);
                                          }
                                        }}
                                      >
                                        <img
                                          src={commentsIcon}
                                          className={styles.icon}
                                          alt="comments"
                                          width={20}
                                          height={20}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                        <p>({story.comments_count})</p>
                                      </div>
                                    )}

                                    <Dropdown
                                      overlay={
                                        <Menu className={styles.dropdownMenu}>
                                          {items.map((item) => (
                                            <Menu.Item
                                              key={item.key}
                                              onClick={() =>
                                                item.onClick &&
                                                item.onClick(storyIndex)
                                              }
                                            >
                                              {item.render
                                                ? item.render(storyIndex)
                                                : item.label}
                                            </Menu.Item>
                                          ))}
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                    >
                                      <a onClick={(e) => e.preventDefault()}>
                                        <img
                                          src={share}
                                          alt="logo"
                                          className={styles.icon}
                                          width={22}
                                          height={22}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                      </a>
                                    </Dropdown>

                                    {story?.writter?._id !== userId && (
                                      <div
                                        onClick={() =>
                                          handleReportStory(story._id)
                                        }
                                      >
                                        <img
                                          src={report}
                                          alt="logo"
                                          className={styles.icon}
                                          width={20}
                                          height={20}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                      </div>
                                    )}

                                    {userInfo?.isAdmin &&
                                      story?.writter?._id !== userId && (
                                        <>
                                          <div
                                            onClick={() =>
                                              handleDeleteStory(story._id)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashCan}
                                              style={{
                                                color: "gray",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </>
                                      )}

                                    {story?.writter?._id === userId && (
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            {storyOptions.map((item) => (
                                              <Menu.Item
                                                key={item.key}
                                                onClick={() =>
                                                  item.onClick &&
                                                  item.onClick(story)
                                                }
                                              >
                                                {item.render
                                                  ? item.render(storyIndex)
                                                  : item.label}
                                              </Menu.Item>
                                            ))}
                                          </Menu>
                                        }
                                        trigger={["click"]}
                                      >
                                        <a onClick={(e) => e.preventDefault()}>
                                          <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                            style={{
                                              color: "#5c5c5c",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </a>
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>

                            {/* for original story */}
                            {!story?.originalWritter && (
                              <div className={styles.reactionsWrapper}>
                                {reactionsArray.map((reaction, index) => {
                                  return (
                                    <>
                                      <div className={styles.tooltipContainer}>
                                        <button
                                          onClick={() => {
                                            handleReactionClick(
                                              story._id,
                                              index,
                                              storyIndex,
                                              story,
                                              selectedIndex === index ||
                                                (!selectedIndex &&
                                                  story.reactionId - 1 ===
                                                    index)
                                            );
                                          }}
                                          className={` ${
                                            story.reactionId - 1 === index
                                              ? styles.activeReaction
                                              : ""
                                          } ${styles.reaction}`}
                                          data-tooltip={reaction.title}
                                        >
                                          <img
                                            src={reaction.emogi}
                                            alt="like"
                                            width={reaction.width}
                                            height={reaction.height}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </button>
                                        <span className={styles.tooltipText}>
                                          {reaction.title}
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}

                                {story?.reaction?.length > 0 && (
                                  <>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {conditionallyRenderedItems(
                                            story
                                          ).map((item) => (
                                            <Menu.Item key={item.key}>
                                              <div
                                                className={
                                                  styles.totalReactionsWrapper
                                                }
                                              >
                                                {item}
                                              </div>
                                            </Menu.Item>
                                          ))}
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                      placement="bottomLeft"
                                    >
                                      <button>
                                        <span>
                                          {story?.reaction?.length}
                                          <FontAwesomeIcon
                                            icon={faCaretRight}
                                            className={styles.fontawesome}
                                          />
                                        </span>
                                      </button>
                                    </Dropdown>
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          {/* for shareable story */}
                          {story?.originalWritter && (
                            <>
                              <div className={styles.details}>
                                <div className={styles.icons}>
                                  <div
                                    className={styles.favStarIcon}
                                    onClick={() =>
                                      handleFavoriteClick(story._id)
                                    }
                                  >
                                    {story.isFavourite ? (
                                      <div className={styles.icon}>
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          style={{ color: "#F5D003" }}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        src={favorite}
                                        alt="logo"
                                        className={styles.icon}
                                        width={22}
                                        height={20}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    )}
                                  </div>

                                  {isCommentsOpen.includes(storyIndex) ? (
                                    <div
                                      className={styles.commentsWrapper}
                                      onClick={() => {
                                        if (token) {
                                          setShowCommentsIndex("abc");
                                          setisCommentsOpen((prevState) =>
                                            prevState.filter(
                                              (item) => item !== storyIndex
                                            )
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src={commentsIcon}
                                        className={styles.icon}
                                        alt="comments"
                                        width={20}
                                        height={20}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                      <p>({story.comments_count})</p>
                                    </div>
                                  ) : (
                                    <div
                                      className={styles.commentsWrapper}
                                      onClick={() => {
                                        if (token) {
                                          setShowCommentsIndex(storyIndex);
                                          setisCommentsOpen((prevState) => [
                                            storyIndex,
                                          ]);
                                        } else {
                                          setLoginModal(!loginModal);
                                        }
                                      }}
                                    >
                                      <img
                                        src={commentsIcon}
                                        className={styles.icon}
                                        alt="comments"
                                        width={20}
                                        height={20}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                      <p>({story.comments_count})</p>
                                    </div>
                                  )}

                                  <Dropdown
                                    overlay={
                                      <Menu className={styles.dropdownMenu}>
                                        {items.map((item) => (
                                          <Menu.Item
                                            key={item.key}
                                            onClick={() =>
                                              item.onClick &&
                                              item.onClick(storyIndex)
                                            }
                                          >
                                            {item.render
                                              ? item.render(storyIndex)
                                              : item.label}
                                          </Menu.Item>
                                        ))}
                                      </Menu>
                                    }
                                    trigger={["click"]}
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <img
                                        src={share}
                                        alt="logo"
                                        className={styles.icon}
                                        width={22}
                                        height={22}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    </a>
                                  </Dropdown>

                                  {story?.writter?._id !== userId && (
                                    <div
                                      onClick={() =>
                                        handleReportStory(story._id)
                                      }
                                    >
                                      <img
                                        src={report}
                                        alt="logo"
                                        className={styles.icon}
                                        width={20}
                                        height={20}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    </div>
                                  )}

                                  {userInfo?.isAdmin &&
                                    story?.writter?._id !== userId && (
                                      <>
                                        <div
                                          onClick={() =>
                                            handleDeleteStory(story._id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashCan}
                                            style={{
                                              color: "gray",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}

                                  {story?.writter?._id === userId && (
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {storyOptions.map((item) => (
                                            <Menu.Item
                                              key={item.key}
                                              onClick={() =>
                                                item.onClick &&
                                                item.onClick(story)
                                              }
                                            >
                                              {item.render
                                                ? item.render(storyIndex)
                                                : item.label}
                                            </Menu.Item>
                                          ))}
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                    >
                                      <a onClick={(e) => e.preventDefault()}>
                                        <FontAwesomeIcon
                                          icon={faEllipsisVertical}
                                          style={{
                                            color: "#5c5c5c",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </a>
                                    </Dropdown>
                                  )}
                                </div>
                              </div>

                              <div className={styles.reactionsWrapper}>
                                {reactionsArray.map((reaction, index) => {
                                  return (
                                    <>
                                      <div className={styles.tooltipContainer}>
                                        <button
                                          onClick={() => {
                                            handleReactionClick(
                                              story._id,
                                              index,
                                              storyIndex,
                                              story,
                                              selectedIndex === index ||
                                                (!selectedIndex &&
                                                  story.reactionId - 1 ===
                                                    index)
                                            );
                                          }}
                                          className={` ${
                                            story.reactionId - 1 === index
                                              ? styles.activeReaction
                                              : ""
                                          } ${styles.reaction}`}
                                          data-tooltip={reaction.title}
                                        >
                                          <img
                                            src={reaction.emogi}
                                            alt="like"
                                            width={reaction.width}
                                            height={reaction.height}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </button>
                                        <span className={styles.tooltipText}>
                                          {reaction.title}
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}

                                {story?.reaction?.length > 0 && (
                                  <>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {conditionallyRenderedItems(
                                            story
                                          ).map((item) => (
                                            <Menu.Item key={item.key}>
                                              <div
                                                className={
                                                  styles.totalReactionsWrapper
                                                }
                                              >
                                                {item}
                                              </div>
                                            </Menu.Item>
                                          ))}
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                      placement="bottomLeft"
                                    >
                                      <button>
                                        <span>
                                          {story?.reaction?.length}
                                          <FontAwesomeIcon
                                            icon={faCaretRight}
                                            className={styles.fontawesome}
                                          />
                                        </span>
                                      </button>
                                    </Dropdown>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {showComments && token && (
                          <DisplayComments
                            storyId={story._id}
                            setCommentCounts={setCommentCounts}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
                {filteredStories.length !== 0 && (
                  <>
                    {storiesLoading ? (
                      <div className="pt-5">
                        <ButtonFilled title="Loading..." textColr="white" />
                      </div>
                    ) : (
                      <div className="pt-5">
                        <ButtonFilled
                          title="VOIR PLUS"
                          textColr="white"
                          handleClick={loadMore}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <p className="pt-5 text-center">
                It seems you are not connected with internet
              </p>
            )}
            <ResourcesModal
              toggleState={resourceModalOpen}
              handleToggle={handleResourceModal}
              selectedStoryIndex={selectedStoryIndex}
              filteredStories={filteredStories}
            />
            <BeforeAfterModal
              toggleState={beforeAfterModalOpen}
              handleToggle={handleBeforeAfterModal}
              selectedStoryIndex={selectedStoryIndex}
              filteredStories={filteredStories}
            />

            <SubmitStory
              storyDataForUpdate={storyDataForUpdate}
              toggleState={submitStoryModal}
              handleToggle={toggleSubmitStoryModal}
            />

            <FriendsListModal
              toggleState={friendsListModal}
              handleToggle={toggleFriendsListModal}
              storyId={storyId}
            />
          </>
        )}
      </>
    </>
  );
};

export default DisplayStories;
