import React from "react";
import styles from "../assets/styles/profile/themes.module.scss";

import ThemesCard from "./themesCard";

function Themes({ themes, handleCardClick }) {
  return (
    <div>
      <div className={styles.cardsWrapper}>
        {themes?.map((theme) => (
          <ThemesCard
            key={theme._id}
            title={theme.name}
            imageURL={theme.image}
            imageBlack={theme?.black_image}
            selected={theme.isSelected}
            onClick={() => handleCardClick(theme._id)}
          />
        ))}
      </div>
    </div>
  );
}
export default Themes;
