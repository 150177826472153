import React, { useState } from "react";
import styles from "../../assets/styles/professionals/ProfessionalSpaceModal.module.scss";
import { Modal, ModalBody } from "reactstrap";
import ButtonFilled from "../../components/buttonFilled";
import UpdateProfessional from "./UpdateProfessional";
import { useNavigate } from "react-router-dom";
import ProfessionalSignIn from "./ProfessionalSignIn/ProfessionalSignIn";
import { useAuth } from "../../context/AuthContext";

const ProfessionalSpaceModal = ({
  toggleState,
  handleToggle,
  toggleSideBar,
}) => {
  const { token, searchedUserInfo } = useAuth();
  const navigate = useNavigate();
  const [signInProfessionalModal, setSignInProfessionalModal] = useState(false);
  const [updateProfessionalModal, setUpdateProfessionalModal] = useState(false);

  //   signuin professional modal toggle
  const signInProfessionalModalToggle = () => {
    setSignInProfessionalModal(!signInProfessionalModal);
  };

  //   update professional modal toggle
  const updateProfessionalModalToggle = () => {
    setUpdateProfessionalModal(!updateProfessionalModal);
  };

  return (
    <div>
      <div>
        <Modal
          size="sm"
          style={{ maxWidth: "370px", width: "100%" }}
          centered
          modalClassName="blockUserModal"
          toggle={handleToggle}
          isOpen={toggleState}
        >
          <ModalBody>
            <div className={styles.body}>
              <h5 onClick={handleToggle}>Espace professionnel</h5>

              <div
                className="pt-3 mx-3"
                onClick={() => {
                  if (token) {
                    if (searchedUserInfo?.professional) {
                      updateProfessionalModalToggle();
                    } else {
                      navigate("/professionals/sign-up");
                      if (toggleSideBar) {
                        toggleSideBar();
                      }
                    }
                  } else {
                    signInProfessionalModalToggle();
                  }
                  handleToggle();
                }}
              >
                <ButtonFilled title="Gérer mes rdv" textColr="white" />
              </div>

              <h5
                onClick={() => {
                  if (token) {
                    if (searchedUserInfo?.professional) {
                      updateProfessionalModalToggle();
                    } else {
                      navigate("/professionals/sign-up");
                      if (toggleSideBar) {
                        toggleSideBar();
                      }
                    }
                  } else {
                    signInProfessionalModalToggle();
                  }
                  handleToggle();
                }}
                className={styles.modifyButton}
              >
                Modifier mon profil professionnel
              </h5>

              <div
                className={styles.annuler}
                onClick={() => {
                  navigate("/professionals/sign-up");
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }}
              >
                <p>Créer mon compte</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      <div>
        <UpdateProfessional
          toggleState={updateProfessionalModal}
          handleToggle={updateProfessionalModalToggle}
          toggleSideBar={toggleSideBar}
        />
      </div>

      <div>
        <ProfessionalSignIn
          toggleState={signInProfessionalModal}
          handleToggle={signInProfessionalModalToggle}
        />
      </div>
    </div>
  );
};

export default ProfessionalSpaceModal;
