import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/resources/RecommendedResourceModal.module.scss";
import { Modal, ModalBody } from "reactstrap";
import ButtonFilled from "../../components/buttonFilled";
import { burgerCrossBlack } from "../../assets";
import { Spinner } from "reactstrap";
import ResourceSuccessModal from "./ResourceSuccessModal";
import { ApiCall } from "../../config/apiCall";
import { message } from "antd";
import { useAuth } from "../../context/AuthContext";
import SearchableSelect from "../../components/SearchableDropdown/SearchableDropdown";

const RecommendedResourceModal = ({
  toggleState,
  handleToggle,
  name,
  type,
  resourceType,
  title,
  subTitle,
}) => {
  const { token, allThemes } = useAuth();
  const [formData, setFormData] = useState({
    resourcetype: resourceType,
  });
  const [formErrors, setFormErrors] = useState({
    resourcetype: resourceType,
  });
  const [data, setData] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resourceSuccessModal, setUserBlockModal] = useState(false);
  const [activeTheme, setActiveTheme] = useState({ name: "", value: "" });
  const [activeResource, setActiveResource] = useState({ name: "", value: "" });
  const toggleResourceSuccessModal = () => {
    setUserBlockModal(!resourceSuccessModal);
  };

  const themesOptions = allThemes
    ? allThemes.map((theme) => {
        return {
          id: theme._id,
          label: theme.name,
          value: theme._id,
          poster: theme.poster,
        };
      })
    : [];

  const handleThemeSelect = (value) => {
    const name = themesOptions?.filter((el) => el.value === value)[0]?.label;
    setFormData((prevState) => {
      return { ...prevState, theme: value };
    });
    setActiveTheme({ name: name, value: value });
  };

  const handelThemeClear = () => {
    setActiveTheme({});
  };

  // get data

  const getData = async (e) => {
    setLoadingData(true);
    const ResourceName =
      name === "Film OU Série"
        ? "Film"
        : name === "ressource-video"
        ? "Vidéo"
        : name;
    try {
      const response = await ApiCall({
        params: {},
        route: `recommend-resource/get-resources/${ResourceName}/${e}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setData(response?.response?.data);
      }
      console.log("resp", response?.response?.data);
    } catch (error) {
      setLoadingData(false);
      message.error("Connection Error");
    } finally {
      setLoadingData(false);
    }
  };

  const options = data
    ? data.map((option) => {
        return {
          value: option.value || option._id,
          label: option.label
            ? option.label + " (Par " + (option.artist || option.author) + ")"
            : option?.quote
            ? option.quote
            : option?.influenceurtitle
            ? option.influenceurtitle
            : option?.videotitle,
        };
      })
    : [];

  const handleOptionSelect = (value) => {
    const name = options?.filter((el) => el.value === value)[0]?.value;
    console.log("value: " + value);
    console.log("value2: " + name);
    setActiveResource({ name: name, value: value });
    // setFormData((prev) => ({
    //   ...prev,
    //   ...data?.filter((el) => el.value === value || el._id === value)[0],
    // }));
    setFormData((prevState) => {
      return {
        ...prevState,
        ...data?.filter((el) => el.value === value || el._id === value)[0],
      };
    });
  };

  const handelOptionClear = () => {
    setFormData({
      resourcetype: resourceType,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  // console.log("data", data);
  console.log("formData", formData);
  // console.log("activeResource", activeResource);

  const renderError = (fieldName) => {
    if (formErrors[fieldName]) {
      return <p className={` ${styles.errorMsg} `}>{formErrors[fieldName]}</p>;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (formData.fullname.trim() === "") {
    //   errors.fullname = "Merci de renseigner votre Nom complet.";
    //   hasErrors = true;
    // }
    setLoading(true);
    try {
      const response = await ApiCall({
        params: formData,
        route: `recommend-resource/create-recommend-resource`,
        verb: "post",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        message.success("Created successfully");
        handleToggle();
        setUserBlockModal(!resourceSuccessModal);
      }
      console.log("resp", response?.response?.data);
    } catch (error) {
      setLoading(false);
      message.error("Connection Error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="loginModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.loginModalBodyHeader}>
            <h5 id="signUpModalLabel">Recommander une ressource</h5>
            <button aria-label="Close" type="button" onClick={handleToggle}>
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <h2 className={styles.resourceName}>{name}</h2>
          <div className={styles.loginForm}>
            <form>
              <div className={styles.inputContainer}>
                <label>
                  {title}
                  <span className="mendatory"> *</span>{" "}
                </label>
                <div className={styles.resourceDetailsDropdown}>
                  <SearchableSelect
                    placeholder=""
                    onSearch={(e) => getData(e)}
                    options={options}
                    selectedValue={activeResource?.value}
                    handleOptionSelect={handleOptionSelect}
                    onCrossClick={handelOptionClear}
                    isLoading={loadingData}
                  />
                </div>
              </div>

              {/* {(name !== "Influenceur" && name !== "Podcast") && (
                <div className={styles.inputContainer}>
                  <label>
                    {subTitle}{" "}
                    {name !== "Vidéo" && <span className="mendatory"> *</span>}
                  </label>
                  <div className={styles.inputEyeWrapper}>
                    <input
                      type="text"
                      name="acteur1"
                      value={formData?.acteur1}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              )} */}

              {(name === "Podcast" || name === "Musique") && (
                <div className={styles.inputContainer}>
                  <label>{subTitle} </label>
                  <div className={styles.inputEyeWrapper}>
                    <input
                      type="text"
                      name="artist"
                      value={formData?.artist}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              )}

              {(name === "Livre" || name === "Film OU Série") && (
                <div className={styles.inputContainer}>
                  <label>{subTitle} </label>
                  <div className={styles.inputEyeWrapper}>
                    <input
                      type="text"
                      name="author"
                      value={formData?.author}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              )}

              {name === "Citation" && (
                <div className={styles.inputContainer}>
                  <label>{subTitle} </label>
                  <div className={styles.inputEyeWrapper}>
                    <input
                      type="text"
                      name="auteur1"
                      value={formData?.author}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              )}

              {name === "Vidéo" && (
                <div className={styles.inputContainer}>
                  <label>{subTitle} </label>
                  <div className={styles.inputEyeWrapper}>
                    <input
                      type="text"
                      name="videourl"
                      value={formData?.videourl}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className="pt-1" />
              <div className={styles.inputContainer}>
                <label>
                  Thème
                  <span className="mendatory"> *</span>{" "}
                </label>

                <div className={styles.resourceDetailsDropdown}>
                  <SearchableSelect
                    placeholder="Choisissez un thème"
                    options={themesOptions}
                    selectedValue={activeTheme?.value}
                    handleOptionSelect={handleThemeSelect}
                    onCrossClick={handelThemeClear}
                  />
                </div>
              </div>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "23px",
                    paddingBottom: "22px",
                  }}
                >
                  <Spinner color="danger">Loading...</Spinner>
                </div>
              ) : (
                <div className={styles.submitButton}>
                  <ButtonFilled
                    title="recommander"
                    textColr="white"
                    handleClick={handleSubmit}
                  />
                </div>
              )}
            </form>
          </div>
        </ModalBody>
      </Modal>

      <ResourceSuccessModal
        toggleState={resourceSuccessModal}
        handleToggle={toggleResourceSuccessModal}
        name={name}
      />
    </div>
  );
};

export default RecommendedResourceModal;
