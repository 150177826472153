import React, { useState } from "react";
import styles from "./ProfessionalSignIn.module.scss";
import { Modal, ModalBody } from "reactstrap";

import ButtonFilled from "../../../components/buttonFilled";
import { useAuth } from "../../../context/AuthContext";

import { burgerCrossBlack, eyeIcon, eyeSlash } from "../../../assets";
import { useNavigate } from "react-router-dom";

import DotsLoader from "../../../components/UI/DotsLoader";
import { ApiCall } from "../../../config/apiCall";
import { message } from "antd";

const ProfessionalSignIn = ({ toggleState, handleToggle }) => {
  const { getAllUserStories, setToken, setUserId, storyCount } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [signUpModal, setsignUpModal] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await ApiCall({
        params: { email, password },
        route: "auth/signin",
        verb: "post",
        token: "jwt_token",
        baseurl: true,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.response.authToken);
        localStorage.setItem("userId", response.response.user.id);
        setToken(response.response.authToken);
        setUserId(response.response.user.id);

        setEmail("");
        setPassword("");
        handleToggle();
        getAllUserStories(storyCount);
      } else if (response.status === 400) {
        if (response.response.errors) {
          response.response.errors?.forEach(function (error) {
            var x = error.message;
            message.error(x);
          });
        } else {
          message.error(response.response.message);
          if (
            response.response.message ==
            "L'utilisateur n'est pas encore vérifié"
          ) {
            handleToggle();
          }
        }
      }

      // console.log("response", response);
      return response;
    } catch (error) {
      setLoading(false);
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // on closing login modal without loggin In, get redirectedd to home.
  const handleLoginModalClose = () => {
    handleToggle();
    navigate("/");
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="loginModal"
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.loginModalBodyHeader}>
            <h5 id="signUpModalLabel">Connexion</h5>
            {/* <button aria-label="Close" type="button" onClick={handleToggle}> */}
            <button
              aria-label="Close"
              type="button"
              onClick={handleLoginModalClose}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.loginForm}>
            <form>
              <div className={styles.inputContainer}>
                <label>Adresse mail</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="exemple@exemple.com"
                  />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <label>Mot de passe</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={handleToggleShowPassword}
                  >
                    <>
                      <img
                        className={styles.eyeClose}
                        src={showPassword ? eyeIcon : eyeSlash}
                        alt="eyeClose"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      {/* <div className={showPassword ? styles.slash : ""}></div> */}
                    </>
                  </button>
                </div>
              </div>
              {loading ? (
                <div className={styles.loaderWrapper}>
                  <DotsLoader color="white" />
                </div>
              ) : (
                <div className={styles.loginButton}>
                  <ButtonFilled
                    title="Se connecter"
                    textColr="white"
                    handleClick={handleSubmit}
                  />
                </div>
              )}
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfessionalSignIn;
