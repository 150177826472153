import React, { useEffect, useState } from "react";
import styles from "./FeatherUsers.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { arrowBackGray, goodWriterGold, storyAdmin, userAvatar } from "../../assets";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";

const FeatherUsers = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [users, setUsers] = useState(null);
  const [usersLoading, setUsersLoading] = useState(false);

  const getUsersList = async () => {
    setUsersLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `user/feather-users-list`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setUsers(response.response);
        setUsersLoading(false);
      }
    } catch (error) {
      setUsersLoading(false);
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <div className={styles.featherUsersContainer}>
      <div
        className={styles.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>
      <div className={styles.topContent}>
        <h2 className={styles.resourceDetailHeading}>
          LA CLASSMENT DES PLUMES D'OR
        </h2>
        <h1 className={styles.resourceDetailHeading2}>
          Top 20 des plus belles Plumes
        </h1>
      </div>

      {users?.map((user, index) => (
        <div className={styles.userCard}>
          <div className={styles.top}>
            <div className={styles.left}>
              <h6>
                {index + 1}. {user?.writter?.fullname}
                <span>
                  <img src={goodWriterGold} />
                </span>
              </h6>
            </div>
            <div className={styles.right}>
              <button>Voir son profil</button>
            </div>
          </div>

          <div className={styles.bottom}>
            <p className={styles.totalFeathers}>{user?.count} de plumes d'or</p>
            <p className={styles.totalSubs}>
              {user?.writter?.subscribedCount} d'abonnes
            </p>

            <div className={styles.details}>
              <div className={styles.profilepicWrapper}>
                <div className={`${styles.profilepic}`}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={
                      user?.writter?.profilePicture?.url
                        ? user?.writter?.profilePicture?.url
                        : userAvatar
                    }
                  />
                </div>
              </div>
              <div className={styles.themesWrapper}>
                {user?.writter?.selectedThemes?.map((theme) => (
                  <p className={styles.theme}>{theme?.name}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatherUsers;
