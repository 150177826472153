import React, { useState, useEffect } from "react";
import CustomInput from "../../CustomInput";
import CustomTextArea from "../../CustomTetxArea";
import { Select, Spin } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import axios from "axios";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";
const Serie = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
}) => {
  const [seriesList, setseriesList] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.betaseries.com/movies/search",
        {
          params: {
            title: e.trim(),
          },
          headers: {
            "X-BetaSeries-Key": process.env.REACT_APP_BETASERIES_API_KEY,
            Authorization: `Bearer ${process.env.REACT_APP_BETASERIES_OAUTH_KEY}`,
          },
        }
      );

      setseriesList(response.data.movies);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  let allMovies = seriesList?.map((movie) => ({
    value: movie.title,
    label: movie.title,
    director: movie.director,
  }));

  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre de la série<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allMovies}
        onSearch={(e) => fetchData(e)}
        placeholder="Titre de la série ..."
        selectedValue={resourceInputState?.serietitle}
        handleOptionSelect={(value) => {
          const director = allMovies?.filter((el) => el.value === value)[0]
            ?.director
            ? allMovies?.filter((el) => el.value === value)[0]?.director
            : "";
          handleResourceInputChange({ target: { name: "serietitle", value } });
          handleResourceInputChange({
            target: { name: "acteur1", value: director },
          });
        }}
        isLoading={loading}
      />
      {/* <Select
        // showSearch
        // placeholder="Titre de la série ..."
        // optionFilterProp="children"
        // onChange={(value) => {
        //   handleResourceInputChange({ target: { name: "serietitle", value } });
        // }}
        // onSearch={(e) => fetchData(e)}
        // notFoundContent={
        //   loading ? (
        //     <Spin
        //       size="small"
        //       style={{ display: "flex", justifyContent: "center" }}
        //       className="py-3"
        //     />
        //   ) : null
        // }
        // options={allMovies}
        // filterOption={(input, option) =>
        //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        // }
      ></Select> */}
      {errorMessage == "Veuillez sélectionner le titre de la série." && (
        <div className="errorMessage">{errorMessage}</div>
      )}
      {/* <CustomInput
        label="Titre de la série"
        type="text"
        name="serietitle"
        mendatory="*"
        placeHolder="Titre de la série ..."
        handleOnChange={handleResourceInputChange}
      /> */}
      <CustomInput
        label="Acteur principal"
        type="text"
        name="acteur1"
        emogie={false}
        value={resourceInputState.acteur1}
        placeHolder="Acteur principal ..."
        handleOnChange={handleResourceInputChange}
      />
      <CustomInput
        label="Acteur secondaire"
        type="text"
        name="acteur2"
        emogie={false}
        value={resourceInputState.acteur2}
        placeHolder="Acteur secondaire ..."
        handleOnChange={handleResourceInputChange}
      />
      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Serie;
