import { Dropdown, Menu, Spin } from "antd";
import styles from "./BestAppsModal.module.scss";
import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";

import { useAuth } from "../../../context/AuthContext";
import { burgerCross } from "../../../assets";

const BestAppsModal = ({ toggleState, handleToggle, image }) => {
  const { token, userId } = useAuth();

  //   useEffect(() => {
  //     if (token) {
  //       getStoryDetails();
  //     }
  //   }, [token, storyId]);

  //   const getStoryDetails = async () => {
  //     setloading(true);
  //     try {
  //       const response = await ApiCall({
  //         params: {},
  //         route: `story/detailStory/${storyId}`,
  //         verb: "get",
  //         baseurl: true,
  //         token: token,
  //       });

  //       if (response.status == 200) {
  //         setsingleStory(response.response);
  //       }

  //       console.log("SingleStory", response);
  //     } catch (error) {
  //       setloading(false);
  //     } finally {
  //       setloading(false);
  //     }
  //   };

  return (
    <div>
      <Modal
        isOpen={toggleState}
        centered
        style={{ maxWidth: "1400px", width: "100%" }}
        modalClassName="mySubscriptionsStories"
        toggle={handleToggle}
      >
        <ModalBody>
          <div className={styles.mainContainer}>
            <div className={styles.imagesContainer}>
              <button
                aria-label="Close"
                className={styles.close}
                type="button"
                onClick={handleToggle}
              >
                <img
                  src={burgerCross}
                  onContextMenu={(e) => e.preventDefault()}
                ></img>
              </button>
              <div className={styles.imagesWrapper}>
                <img src={image} />
              </div>
            </div>

            <div className={styles.content}>
              <h5 className={styles.downloadButton}>Télécharger cette appli</h5>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BestAppsModal;
