import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/DisplayStories.module.scss";
import styles2 from "../../assets/styles/resources/Resources.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Menu, Select } from "antd";
import { Modal, ModalBody } from "reactstrap";
import {
  expand,
  relax,
  movieIconWhite,
  movieIconBlack,
  arrowBackGray,
  like,
  favorite,
  commentsIcon,
  love,
  check,
  check2,
  share,
  burgerCrossBlack,
  heartBlack,
  onlineCources,
  domairIcon,
  respirerIcon,
  cosmaticBioIcon,
} from "../../assets";
import RecommendedResourceModal from "./RecommendedResourceModal";

import { resources } from "./resourcesData";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../context/AuthContext";
import SearchableSelect from "../../components/SearchableDropdown/SearchableDropdown";
import StoryLoader from "../../components/UI/StoryLoader";
import { getImageByValue } from "../../components/stories/GetResourceIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { siteUrl } from "../../environment";
import { ApiCall } from "../../config/apiCall";

const MAX_LENGTH = 100;
const currentDate = new Date();
const monthNames = [
  "jan",
  "fév",
  "mar",
  "avril",
  "mai",
  "juin",
  "juil",
  "août",
  "sept",
  "oct",
  "nov",
  "déc",
];
const reactionsArray = [
  {
    emogi: heartBlack,
    value: "love",
    selected: false,
    id: 1,
    height: 25,
    width: 25,
    title: "J’aime !",
    length: 8,
  },
];

const ResourceDetails2 = () => {
  const { token, userId } = useAuth();
  let { typeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  localStorage.setItem("resourceId", resourceId);

  const [allComments, setallComments] = useState("");
  const [loadingComments, setloadingComments] = useState(false);
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [courcesModalOpen, setCourcesModalOpen] = useState(false);
  const [reactions, setReactions] = useState({});
  const [isCommentsOpen, setisCommentsOpen] = useState([]);

  const [showCommentsIndex, setShowCommentsIndex] = useState(-1);
  // const [selectedResource, setSelectedResource] = useState(null);

  let selectedResource = resources
    .find((resource) => resource.id === typeId)
    ?.resourceList?.find((resource) => resource.id === resourceId);

  // useEffect(() => {
  //   if (resourceId === "36") {
  //     getData();
  //   } else {
  //     setSelectedResource(
  //       resources
  //         .find((resource) => resource.id === typeId)
  //         ?.resourceList?.find((resource) => resource.id === resourceId)
  //     );
  //   }
  // }, []);

  // const getData = async () => {
  //   let url;

  //   if (token) {
  //     url = `other-resources/otherResourcesPoint/${resourceId}`;
  //   } else {
  //     url = `other-resources/otherResourcesPoint-without-auth/${resourceId}`;
  //   }

  //   try {
  //     const response = await ApiCall({
  //       params: { themeid: "" },
  //       route: url,
  //       verb: "get",
  //       baseurl: true,
  //       token: token ? token : "",
  //     });

  //     if (response.status === 200) {
  //       setSelectedResource({ stories: response?.response?.data?.data });
  //     }
  //     console.log("resp", response?.response?.data);
  //   } catch (error) {
  //   } finally {
  //   }
  // };

  const toggleCourcesModal = () => {
    console.log(courcesModalOpen);
    setCourcesModalOpen(!courcesModalOpen);
  };

  let selectedIndex = -1;

  const items = [
    {
      label: "Copy link",
      key: "0",
      // onClick: (index) => {
      //   const selectedStory = selectedResource?.stories[index];
      //   const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
      //   navigator.clipboard?.writeText(storyLink);
      //   message.success("Story link copied to clipboard!");
      // },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: (index) => {
        const selectedStory = selectedResource?.stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <FacebookShareButton
            url={storyLink}
            quote={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
              </svg>
            </a>
          </FacebookShareButton>
        );
      },
    },
    {
      label: "WhatsApp",
      key: "2",
      render: (index) => {
        const selectedStory = selectedResource?.stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <WhatsappShareButton
            url={storyLink}
            title={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
              </svg>
            </a>
          </WhatsappShareButton>
        );
      },
    },
    {
      label: "Twitter",
      key: "3",
      render: (index) => {
        const selectedStory = selectedResource?.stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <TwitterShareButton
            url={storyLink}
            title={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </TwitterShareButton>
        );
      },
    },
  ];

  return (
    <div className={styles2.resourcesContainer}>
      <div
        className={styles2.backButton}
        onClick={() => {
          navigate("/ressources");
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>
      <div className={styles2.topContent}>
        <h2 className={styles2.resourceDetailHeading}>Autres ressources</h2>
        <h1 className={styles2.resourceDetailHeading2}>
          {selectedResource?.titleInside}
        </h1>

        {resourceId === "11" && (
          <div className={styles2.topIconClass}>
            <div className={styles2.iconWrapp}>
              <ReactSVG src={onlineCources} />
            </div>
          </div>
        )}

        {resourceId === "16" && (
          <div className={styles2.topIconClass}>
            <div className={styles2.iconWrappPng}>
              <img src={domairIcon} />
            </div>
          </div>
        )}

        {resourceId === "17" && (
          <div className={styles2.topIconClass}>
            <div className={styles2.iconWrappPng}>
              <img src={selectedResource?.topIcon} />
            </div>
          </div>
        )}

        {resourceId === "19" && (
          <div className={styles2.topIconClass}>
            <div className={styles2.iconWrappPng}>
              <img src={respirerIcon} />
            </div>
          </div>
        )}

        {resourceId === "20" && (
          <div className={styles2.topIconClass}>
            <div className={styles2.iconWrappPng}>
              <img src={cosmaticBioIcon} />
            </div>
          </div>
        )}
      </div>
      <>
        {!selectedResource?.stories ? (
          <div>
            <StoryLoader />
            <StoryLoader />
          </div>
        ) : (
          <>
            {/* <p className="pb-5"></p> */}
            {selectedResource?.stories ? (
              <div>
                {selectedResource?.stories?.map((story, storyIndex) => {
                  selectedIndex = reactions[story._id];

                  return (
                    <>
                      <div key={storyIndex}>
                        <div className={styles.storyOuterContainer}>
                          <div className={styles.DisplayStoriesContainer}>
                            <div className={styles.storyHeader}>
                              <div className={styles.storyHeaderRight}>
                                <h2 className={styles.title}>{story.title}</h2>
                              </div>
                            </div>

                            <div className={styles.storyBody}>
                              <div
                                className={`${styles.storyContent} ${styles2.storyContent}`}
                              >
                                <div className={styles2.imgParagraphWrapper}>
                                  {story?.img && (
                                    <div className={styles2.imgWrapp}>
                                      <img
                                        className={styles2.storyImage}
                                        src={story?.img}
                                      />
                                    </div>
                                  )}

                                  {story?.paragraphs && (
                                    <div className={styles2.paragraphWrapper}>
                                      <ul>
                                        {story?.paragraphs?.map(
                                          (paragraph, index) => {
                                            const [firstPart, secondPart] =
                                              paragraph.split("(");
                                            return (
                                              <li key={index}>
                                                {secondPart ? (
                                                  <>
                                                    <strong>{firstPart}</strong>
                                                    <span>({secondPart}</span>
                                                  </>
                                                ) : (
                                                  paragraph
                                                )}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </div>

                                {story?.activitiesList && (
                                  <ul>
                                    {story?.activitiesList?.map((activity) => (
                                      <div className={styles2.listWrapper}>
                                        <img src={check2} />
                                        <li>{activity}</li>
                                      </div>
                                    ))}
                                  </ul>
                                )}

                                {story?.paragraphsWithoutList?.length !== 0 && (
                                  <div className={styles2.paragraphWrapper}>
                                    {story?.paragraphsWithoutList?.map(
                                      (paragraph) => (
                                        <p className={styles2.paragraph}>
                                          {paragraph}
                                        </p>
                                      )
                                    )}
                                  </div>
                                )}

                                {story?.links && (
                                  <>
                                    {story?.links?.map((link) => (
                                      <div className={styles2.linkswrapper}>
                                        {selectedResource?.id == "11" ? (
                                          <p onClick={toggleCourcesModal}>
                                            {link.name}
                                          </p>
                                        ) : (
                                          <>
                                            <Link to={link.url}>
                                              {link.name}
                                            </Link>
                                            <div className="pb-2" />
                                          </>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <p className="pt-5 text-center">
                It seems you are not connected with internet
              </p>
            )}
          </>
        )}
      </>
      {["11"].includes(resourceId) && (
        <div
          className={styles2.nextButton}
          onClick={() => {
            navigate(
              `/ressources/details4/2?resourceId=${JSON.parse(resourceId) + 2}`
            );
          }}
        >
          <p>SUIVANT</p>
          <ReactSVG src={arrowBackGray} />
        </div>
      )}

      <div>
        <Modal
          size="sm"
          // style={{ maxWidth: "450px", width: "100%" }}
          centered
          modalClassName="loginModal"
          toggle={toggleCourcesModal}
          isOpen={courcesModalOpen}
        >
          <ModalBody>
            <div className={styles2.popupListWrapper}>
              {selectedResource?.internalList?.map((resource) => {
                return (
                  <a href={resource?.link} className={styles2.courcesButton}>
                    {resource?.name}
                  </a>
                );
              })}
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default ResourceDetails2;
