import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../config/apiCall";
import styles from "../../assets/styles/profile/profileDetails.module.scss";
import ModifyProfileInfo from "./modifyProfileInfo";
import ChangePassword from "../../components/auth/ChangePassword";

import { goodWriterGold, userAvatar } from "../../assets/index";
import StoryLoader from "../../components/UI/StoryLoader";
import { Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import MySubscriptionsStoriesModal from "../subscriptions/MySubscriptionsStoriesModal";

const ProfileDetails = ({
  userDetails,
  setUserDetails,
  selectedTheme,
  setSelectedTheme,
  userId,
  setToken,
  setUserId,
  setUserInfo,
}) => {
  const navigate = useNavigate();
  const { token, singleStoriesLoading, getProfileData } = useAuth();
  const [modifyProfileModal, setModifyProfileModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  console.log(userDetails);

  const [selectedSubscriptionIndex, setselectedSubscriptionIndex] =
    useState(null);
  const [selectedSubscriptionCircle, setselectedSubscriptionCircle] =
    useState(null);
  const [subscriptionsStoriesModalOpen, setSubscriptionsStoriesModalOpen] =
    useState(false);

  const toggleModifyProfileModal = () => {
    setModifyProfileModal(!modifyProfileModal);
  };

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  const handlesubscriptionsStoriesModal = (item) => {
    setselectedSubscriptionIndex(item?._id);
    setselectedSubscriptionCircle(item?.circle);
    setSubscriptionsStoriesModalOpen(!subscriptionsStoriesModalOpen);
  };

  const handleThemeChange = (themeid) => {
    selectedTheme === themeid
      ? setSelectedTheme(null)
      : setSelectedTheme(themeid);
  };

  const items2 = [
    {
      label: "Modifier mon mot de passe",
      key: "0",
      onClick: () => {
        toggleChangePassword();
      },
    },
    {
      label: "Supprimer mon compte",
      key: "1",
      onClick: (userId) => {
        if (!userDetails?.isAdmin) {
          deleteUserProfile(userId);
        }
      },
    },
  ];

  // Delete User profile

  const deleteUserProfile = async (id) => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "user/deleteUser/" + id,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("username");
        localStorage.removeItem("userpic");
        setToken("");
        setUserId("");
        setUserInfo(null);

        toast.success(response.response.message);
      }
      console.log("deleteUserResp", response);
    } catch (error) {
      toast.success(error.response.message);
    } finally {
    }
  };

  return (
    <>
      {singleStoriesLoading ? (
        <div>
          <StoryLoader />
        </div>
      ) : (
        <div className={styles.profileDetailswrapper}>
          {!userDetails?.isAdmin && (
            <div className={styles.EditProfileDots}>
              <Dropdown
                overlay={
                  <Menu>
                    {items2.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={() =>
                          item.onClick && item.onClick(userDetails._id)
                        }
                      >
                        {item.render ? item.render() : item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    style={{ color: "#5c5c5c", cursor: "pointer" }}
                  />
                </a>
              </Dropdown>
            </div>
          )}

          <div className={styles.bio}>
            <div className={styles.profilepicWrapper}>
              <div
                className={`${styles.profilepic} ${
                  userDetails?.circle ? styles.userWithNewStory : ""
                }`}
                onClick={() => {
                  if (userDetails?.statusExist) {
                    handlesubscriptionsStoriesModal(userDetails);
                  }
                  // setTimeout(() => {
                  //   getProfileData(userDetails._id);
                  // }, 1000);
                }}
              >
                <img
                  src={
                    userDetails?.profilePicture?.url
                      ? userDetails?.profilePicture?.url
                      : userAvatar
                  }
                />
              </div>
            </div>
            <div className={styles.userInfo}>
              <h3
                className={`${styles.name} ${
                  userDetails?.isRated ? styles.nameRated : ""
                } `}
              >
                {userDetails?.fullname}
                {userDetails?.isRated && (
                  <img
                    src={goodWriterGold}
                    alt="profilepic"
                    height={25}
                    width={25}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
              </h3>
              <p
                className={` ${
                  userDetails?.isRated ? styles.userNameRated : ""
                } `}
              >
                @{userDetails?.username}{" "}
              </p>

              <div className={styles.details}>
                <p>{userDetails?.biography}</p>
              </div>

              <div className={styles.links}>
                <div
                  className={styles.modifyInfoLink}
                  onClick={toggleModifyProfileModal}
                >
                  <p>Modifier mes informations</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.socailCounts}>
            <div className={styles.countsWrapper}>
              <div className={styles.counts}>
                <h3>{userDetails?.storyCount}</h3>
                <p>Histoires postées</p>
              </div>
              <div className={styles.Subscribers}>
                <div className={styles.counts}>
                  <h3>{userDetails?.subscriber_count}</h3>
                  <p>Abonnés</p>
                </div>
                <div className={styles.counts}>
                  <h3>{userDetails?.subscribed_count}</h3>
                  <p>Abonnements</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.themes}>
            {userDetails?.selectedThemes?.map((theme, index) => (
              <div className={styles.themeFiltratin} key={theme._id}>
                <div className={styles.selector}>
                  <div className={styles.selectorItem}>
                    <input
                      type="radio"
                      id={theme._id}
                      name="resource"
                      className={styles.selectorItemRadio}
                      value={theme._id}
                      checked={theme._id === selectedTheme}
                      onClick={(e) => handleThemeChange(e.target.value)}
                    />
                    <label
                      htmlFor={theme._id}
                      className={styles.selectorItemLabel}
                    >
                      {theme.name}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <ModifyProfileInfo
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            toggleState={modifyProfileModal}
            handleToggle={toggleModifyProfileModal}
            userId={userId}
          />

          <ChangePassword
            toggleState={changePassword}
            handleToggle={toggleChangePassword}
          />

          <MySubscriptionsStoriesModal
            toggleState={subscriptionsStoriesModalOpen}
            handleToggle={handlesubscriptionsStoriesModal}
            selectedSubscriptionIndex={selectedSubscriptionIndex}
            selectedSubscriptionCircle={selectedSubscriptionCircle}
          />
        </div>
      )}
    </>
  );
};

export default ProfileDetails;
