import React, { useState } from "react";
import styles from "../../../assets/styles/comments/DisplayComments.module.scss";
import StoryLoader from "../../../components/UI/StoryLoader";
import { report } from "../../../assets";
import { useAuth } from "../../../context/AuthContext";
import { faTrashCan, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall } from "../../../config/apiCall";
import { message } from "antd";

const ReportedStories = ({
  reportedStories,
  setReportedStories,
  isStoryloading,
}) => {
  const { token } = useAuth();
  const [deleteStoryLoading, setdeleteStoryLoading] = useState(false);

  // For less/more story description
  const [showMoreDescription, setShowMoreDescription] = useState(
    Array(reportedStories?.length).fill(false)
  );

  // show more/less story content
  const toggleShowMore = (index) => {
    const newShowMoreDescription = [...showMoreDescription];
    newShowMoreDescription[index] = !newShowMoreDescription[index];
    setShowMoreDescription(newShowMoreDescription);
  };

  // Delete story
  const deleteStory = async (id) => {
    setdeleteStoryLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "story/deleteStory/" + id,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setReportedStories(reportedStories.filter((story) => story._id !== id));
        message.success(response.response);
      }
      console.log("deleteStoryResp", response);
    } catch (error) {
      setdeleteStoryLoading(false);
    } finally {
      setdeleteStoryLoading(false);
    }
  };

  return (
    <div>
      {isStoryloading ? (
        <StoryLoader oneLine={true} />
      ) : (
        <>
          {reportedStories.length == 0 && <p>Aucune histoire trouvée.</p>}
          {reportedStories?.map((story, indx) => {
            const date = new Date(story?.createdAt);
            const showMore = showMoreDescription[indx];
            {
              /* const showComments = showCommentsIndex === indx; */
            }

            const DateFormate = `le ${date.toLocaleDateString()} à ${date.toLocaleTimeString(
              [],
              { hour: "numeric", minute: "2-digit" }
            )}`;
            return (
              <>
                <div className={styles.DisplayStoriesContainer}>
                  <div className={styles.storyHeader}>
                    <div className={styles.storyHeaderLeft}>
                      <p className={styles.theme}>{story.theme?.name}</p>
                      <div className={styles.date}>
                        {/* {story.storyId.isAnonym ? (
                          <p>
                            Par,{" "}
                            {story.storyId.writter?.fullname?.charAt(0)}
                            ****
                          </p>
                        ) : ( */}
                        <p>Par {story.writter?.fullname},</p>
                        {/* )} */}

                        <p>{DateFormate}</p>
                      </div>
                    </div>
                    <div className={styles.storyHeaderRight}>
                      <h2 className={styles.heading}>{story.title}</h2>
                    </div>
                  </div>
                  <div className={styles.storyBody}>
                    {showMore ? (
                      <div className={styles.storyContent}>
                        <p>
                          {story.content}
                          {story.content.length > 100 && (
                            <span
                              className={styles.contentBtn}
                              onClick={() => toggleShowMore(indx)}
                            >
                              Replier ▲
                            </span>
                          )}{" "}
                        </p>
                      </div>
                    ) : (
                      <div className={styles.storyContent}>
                        <p>
                          {story.content.slice(0, 100)}
                          {story.content.length > 100 && "..."}
                          {story.content.length > 100 && (
                            <span
                              className={styles.contentBtn}
                              onClick={() => toggleShowMore(indx)}
                            >
                              Lire la suite
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                    <div className={styles.details}>
                      <div className={styles.icons}>
                        <div className={styles.commentsWrapper}>
                          <img
                            src={report}
                            alt="logo"
                            className={styles.icon}
                            width={20}
                            height={20}
                            onContextMenu={e => e.preventDefault()}
                          />
                          <p>({story.reports_count})</p>
                        </div>

                        <>
                          <div
                            onClick={() =>
                              !deleteStoryLoading && deleteStory(story._id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{
                                color: "gray",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div
                            onClick={() =>
                              !deleteStoryLoading && deleteStory(story._id)
                            }
                          >
                            <FontAwesomeIcon
                              className={styles.icon}
                              icon={faSquareCheck}
                              style={{ color: "gray" }}
                            />
                          </div>
                        </>
                      </div>
                      {/* <p>
                        Professionnel :{" "}
                        <span className="font-bold text-black">
                          {story.professionalConsulted?.fullname}
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReportedStories;
