import React, { useState } from "react";
import Rating from "react-rating";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../assets/styles/professionals/Professionnels.module.scss";
import {
  burgerCrossBlack,
  call,
  location,
  locationChoose,
  nextArrow,
  nextPage,
  prevPage,
  search,
  starRated,
  starUnrated,
  website,
} from "../../assets";
import professionalPic from "../../assets/images/professional.png";
import { getCurrentLocationAndFetchAddress } from "../../utils/helper/GetLocation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "./InfoModal";
import PermissionsModal from "./PermissionsModal";
import ProfessionalSpaceModal from "./ProfessionalSpaceModal";
import { Spin, message } from "antd";
import AutocompleteInput from "../../utils/helper/GoogleMapSearch";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";
import SkeltonList from "../../components/UI/SkeltonList";

const professionals = [
  {
    id: 1,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "44",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    reviews: [
      {
        name: "Akbar",
        desc: "dfkdkjfhsjdfskjhfksdhfkldjdlaksuaoi",
      },
      {
        name: "Asim",
        desc: "dfkdkjfhsjdfskjhfksdhfkldjdlaksuaoi",
      },
      {
        name: "Johar",
        desc: "dfkdkjfhsjdfskjhfksdhfkldjdlaksuaoi",
      },
    ],
    subTypes: [
      "Psychologue diplômé",
      "thérapie brève",
      "thérapie conjuguale",
      "consultation",
      "Formation",
      "Atelier",
      "Coach",
    ],
  },
  {
    id: 2,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 3,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 4,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
  {
    id: 5,
    firstname: "Ali",
    lastname: "Raza",
    pic: "",
    type: "Psychologue",
    starsCount: "10",
    location: "13 rue Régis, Bordeaux, Gironde",
    website: "www.nom-prenom.fr",
    phone: "06 24 67 85 64",
    subTypes: ["Psychologue diplômé", "thérapie brève", "thérapie conjuguale"],
  },
];

const initialState = {
  name: "",
  location: "",
  longitude: "",
  latitude: "",
};

const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

const Professionnels = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [professionalResults, setProfessionalResults] = useState(null);
  const [resultsLoading, setResultsLoading] = useState(false);

  const [activeSubTypeBtns, setActiveSubTypeBtns] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [professionalSpaceModal, setProfessionalSpaceModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  //   Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const USERS_PER_PAGE = 5;
  const totalPages = Math.ceil(professionalResults?.professionals?.length / USERS_PER_PAGE);
  const startIndex = (currentPage - 1) * USERS_PER_PAGE;
  const endIndex = startIndex + USERS_PER_PAGE;
  const displayedProfessionals = professionalResults?.professionals?.slice(startIndex, endIndex);

  // locations states
  const [locationPermissionStatus, setLocationPermissionStatus] =
    useState("prompt");
  const [locationLoading, setLocationLoading] = useState(false);

  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;

    if (totalPages <= maxDisplayedPages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const middleIndex = Math.floor(maxDisplayedPages / 2);
      let startPage = currentPage - middleIndex;
      let endPage = currentPage + middleIndex;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (endPage > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };
  //   Pagination end

  const handleSubTypeClick = (profIndex, activityIndex) => {
    setActiveSubTypeBtns((prevActiveSubTypeBtns) => {
      const newActiveSubTypeBtns = [...prevActiveSubTypeBtns];
      newActiveSubTypeBtns[profIndex] = activityIndex;
      return newActiveSubTypeBtns;
    });
  };

  const professionalSpaceModalToggle = () => {
    setProfessionalSpaceModal(!professionalSpaceModal);
  };

  const goToReviewsPage = (professional) => {
    navigate(`/professionals/reviews/${professional?.professional?._id}`, {
      state: {
        professional,
      },
    });
  };

  // Input managment

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: "" }));
  };

  // getting current location

  const handleGetLocation = async () => {
    setLocationLoading(true);
    try {
      if (locationPermissionStatus === "granted") {
        // If permission is granted, proceed to fetch the location
        const address = await getCurrentLocationAndFetchAddress();

        setFormData((prevState) => ({
          ...prevState,
          location: address,
        }));
        setFormErrors(initialState);
        setShowContent(false);
        setLocationLoading(false);
      } else if (locationPermissionStatus === "prompt") {
        // If permission is not determined yet, show the permission modal
        setShowPermissionsModal(true);
      } else {
        console.log("Location permission is denied.");
        setLocationLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLocationLoading(false);
    } finally {
      setLocationLoading(false);
    }
  };

  const handleAllow = async () => {
    setShowPermissionsModal(false);
    try {
      if (navigator.permissions && navigator.permissions.request) {
        // Use the newer navigator.permissions API if available
        await navigator.permissions.request({ name: "geolocation" });
      } else if (
        navigator.geolocation &&
        navigator.geolocation.getCurrentPosition
      ) {
        // Fallback to the older navigator.geolocation API
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      }

      // Update the permission status after the user responds
      setLocationPermissionStatus("granted");

      // Get the current location and update the location field in the form data
      const address = await getCurrentLocationAndFetchAddress();
      setFormData((prevState) => ({
        ...prevState,
        location: address,
      }));
      setFormErrors(initialState);
      setShowContent(false);
    } catch (error) {
      console.error("Error requesting location permission:", error);
    }
  };

  const handleDeny = () => {
    setShowPermissionsModal(false);
    setLocationPermissionStatus("denied");
  };

  const showLocationButon = () => {
    setShowContent(true);
  };

  const handleClose = () => {
    setShowContent(false);
  };

  const toggleReportUserModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const togglePermissionsUserModal = () => {
    setShowPermissionsModal(!showPermissionsModal);
  };

  const getSuggestion = async (val) => {
    setFormData((prev) => ({
      ...prev,
      location: val,
      longitude: "",
      latitude: "",
    }));
    fetch(
      ` https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=${access_token} `
    )
      .then((response) => response.json())
      .then((data) => {
        data?.features == undefined ? setshown(false) : setshown(true);
        setSuggetions(data?.features);
      });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    let hasErrors = false;

    // Check for empty fields
    // Object.entries(formData).forEach(([key, value]) => {
    //   if (!value) {
    //     errors[key] = "This field is required.";
    //     hasErrors = true;
    //   }
    // });

    if (formData.name.trim() === "") {
      errors.name = "Veuillez renseigner ce champ.";
      hasErrors = true;
    }

    if (hasErrors) {
      setFormErrors(errors);
    } else {
      try {
        setResultsLoading(true);
        const response = await ApiCall({
          params: {
            name: formData.name,
            longitude: formData.longitude,
            latitude: formData.latitude,
            search: formData.location,
          },
          route: "professional/search-professional",
          verb: "get",
          token,
          baseurl: true,
        });

        if (response.status === 200) {
          setProfessionalResults(response.response);
        }

        // console.log("response", response);
        return response;
      } catch (error) {
        setResultsLoading(false);
        console.error(error);
      } finally {
        setResultsLoading(false);
      }
    }
  };

  console.log("professionalResults", professionalResults);

  return (
    <div className={styles.professionalsContainer}>
      <h2 className={styles.heading}>
        Trouver un professionnel <span>(ou une association)</span>
      </h2>
      <h2 className={styles.headingMobileView}>
        <span>(ou une association)</span>
      </h2>

      <div className={styles.searchWrapper}>
        <div className={styles.searchBox}>
          <div
            className={`${styles.inputDiv} ${styles.inputDiv1} ${
              formErrors.name ? styles.borderRed : ""
            }`}
          >
            <img
              src={search}
              alt="filter"
              onContextMenu={(e) => e.preventDefault()}
            />
            <input
              type="text"
              placeholder="Qui ? Quoi ? …"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          {formErrors.name && (
            <p
              className={`errorMessage ${
                formErrors.name ? styles.hideDesktopError : ""
              }`}
            >
              {formErrors.name}
            </p>
          )}

          <div
            className={`${styles.inputDivWrapper} ${
              formErrors.location ? styles.borderRedDesktop : ""
            }`}
          >
            <div
              className={`${styles.inputDiv} ${
                formErrors.location ? styles.borderRedMobileView : ""
              }`}
            >
              <img
                src={location}
                alt="location"
                onContextMenu={(e) => e.preventDefault()}
              />
              <input
                type="text"
                placeholder="Où ?"
                id="location"
                name="location"
                value={formData.location}
                onChange={(e) => {
                  getSuggestion(e.target.value);
                }}
              />
              <div className={styles.customeLocationWrapper}>
                <img
                  src={locationChoose}
                  alt="locationChoose"
                  onClick={showLocationButon}
                  title="Select current location"
                />
                {showContent && (
                  <div className={`${styles.slideContent} ${styles.slideIn}`}>
                    <Spin spinning={locationLoading}>
                      <div className={styles.left}>
                        <img
                          src={locationChoose}
                          alt="locationChoose"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        <p onClick={handleGetLocation}>AUTOUR DE MOI</p>
                      </div>
                    </Spin>
                    <img
                      src={burgerCrossBlack}
                      height={15}
                      alt="close"
                      onClick={handleClose}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={styles.searchButtonMobileView}
              onClick={handelSubmit}
            >
              <img
                src={nextArrow}
                alt="next"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
          {formErrors.location && (
            <p
              className={`errorMessage ${
                formErrors.location ? styles.hideDesktopError : ""
              }`}
            >
              {formErrors.location}
            </p>
          )}
        </div>
        <div className={styles.searchButton} onClick={handelSubmit}>
          <img
            src={nextArrow}
            alt="next"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      </div>

      {/* show the list of searching */}
      {shown ? (
        <>
          {" "}
          {suggetions?.length > 0 && (
            <ul
              className={`z-40 bg-white ${
                suggetions?.length > 0 ? "h-[100px]" : "h-[0px]"
              } absolute w-[22%] overflow-y-scroll px-0 `}
            >
              {suggetions.map((item) => (
                <li
                  onClick={() => {
                    setshown(false);
                    setFormData((prev) => ({
                      ...prev,
                      location: item?.place_name,
                      longitude: item?.center[0],
                      latitude: item?.center[1],
                    }));
                  }}
                  className="hover:bg-blue-300 cursor-pointer"
                >
                  {item?.place_name}
                </li>
              ))}
            </ul>
          )}{" "}
        </>
      ) : null}

      {/* <AutocompleteInput
        onPlaceSelect={(v) => console.log("Place Selected", v)}
        name="Mukhtar"
        placeholder=""
        id="1"
        onBlur={() => console.log("onblure ")}
        onChange={(e) => {
          setFormData((prev) => ({
            ...prev,
            location: e.target.value,
          }));
        }}
        value={formData.location}
      /> */}

      <div className={styles.desktopFormErrorsWrapper}>
        {formErrors.name && (
          <p className={`errorMessage ${styles.hideMobileError}`}>
            {formErrors.name}
          </p>
        )}
        {formErrors.location && (
          <p className={`errorMessage ${styles.hideMobileError2}`}>
            {formErrors.location}
          </p>
        )}
      </div>

      {displayedProfessionals?.length > 0 && (
        <div className={styles.resultsHeader}>
          <div className={styles.leftSide}>
            <h5 className={styles.resultsHeading} title="information">
              {displayedProfessionals?.length > 0
                ? displayedProfessionals?.length
                : 0}{" "}
              résultats{" "}

              {/* description info */}
              {displayedProfessionals?.description && (
                <span
                  onClick={() => {
                    setShowInfoModal(!showInfoModal);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              )}
            </h5>

            <div className={styles.searchDetails}>
              <h5 className={styles.profType}>
                {displayedProfessionals && displayedProfessionals[0]?.name}
              </h5>
              {formData.location && (
                <>
                  {displayedProfessionals?.length > 0 && (
                    <div className={styles.dot}></div>
                  )}

                  <p className={styles.locationName}>
                    {displayedProfessionals && formData.location}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className={styles.rightSide}>
            <div
              onClick={professionalSpaceModalToggle}
              className={styles.findeProfButton}
            >
              Espace professionnel
            </div>
          </div>
        </div>
      )}

      {resultsLoading ? (
        <SkeltonList />
      ) : (
        <>
          {displayedProfessionals?.map(
            (professional, profIndex) => {
              return (
                <>
                  {professional?.professional && (
                    <div className={styles.professionalWrapper}>
                      <>
                        {/* professional top */}
                        <div className={styles.top}>
                          {/* professional profile pic */}
                          <img
                            className={styles.profPic}
                            src={
                              professional?.professional?.user?.profilePicture
                                ?.url
                                ? professional?.professional?.user
                                    ?.profilePicture?.url
                                : professionalPic
                            }
                            alt="profile"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                          {/* Professional content */}
                          <div className={styles.rightSide}>
                            <div className={styles.nameReviews}>
                              <div className={styles.info}>
                                {professional?.professional
                                  ?.profileDataVisibility ===
                                "firstname and lastname" ? (
                                  <h5
                                    className={styles.professionalName}
                                    onClick={() => {
                                      navigate(
                                        `/professional-profile?professionalId=${professional?.professional?.user?._id}`
                                      );
                                    }}
                                  >
                                    {professional?.professional?.firstName}{" "}
                                    {professional?.professional?.lastName}
                                  </h5>
                                ) : professional?.professional
                                    ?.profileDataVisibility ===
                                  "firmName" ? (
                                  <p className={styles.firmName}>
                                    {professional?.professional?.firmName}
                                  </p>
                                ) : (
                                  <>
                                    <h5
                                      className={styles.professionalName}
                                      onClick={() => {
                                        navigate(
                                          `/professional-profile?professionalId=${professional?.professional?.user?._id}`
                                        );
                                      }}
                                    >
                                      {professional?.professional?.firstName}{" "}
                                      {professional?.professional?.lastName}
                                    </h5>
                                    <p className={styles.firmName}>
                                      {professional?.professional?.firmName}
                                    </p>
                                  </>
                                )}

                                <p className={styles.profType}>
                                  {professional?.professional?.professionaltype}
                                </p>
                              </div>

                              <div className={styles.rating}>
                                <Rating
                                  initialRating={
                                    professional?.reviews?.averageRating
                                  }
                                  readonly
                                  emptySymbol={
                                    <img
                                      src={starUnrated}
                                      style={{ marginRight: "5px" }}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  }
                                  fullSymbol={
                                    <img
                                      src={starRated}
                                      style={{ marginRight: "5px" }}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  }
                                />

                                <p
                                  className={styles.reviewCount}
                                  onClick={() => goToReviewsPage(professional)}
                                >
                                  {professional?.reviews?.reviewsCount} avis
                                </p>
                              </div>
                            </div>

                            <div className={styles.address}>
                              <div className={styles.locationWrapper}>
                                <div className={styles.location}>
                                  <img
                                    src={location}
                                    alt="location"
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <p>
                                    {
                                      professional?.professional?.location
                                        ?.address
                                    }
                                  </p>
                                </div>
                                <div className={styles.website}>
                                  <img
                                    src={website}
                                    alt="website"
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <a
                                    target="_blank"
                                    href={professional?.professional?.website}
                                  >
                                    {professional?.professional?.website}
                                  </a>
                                </div>
                              </div>
                              <div className={styles.phone}>
                                <img
                                  src={call}
                                  alt="call"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                                <p>{professional?.professional?.telephone}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* professional contact on mobile view */}
                        <div className={styles.addressMobileView}>
                          <div className={styles.locationWrapper}>
                            {professional?.professional?.location?.address && (
                              <div className={styles.location}>
                                <img
                                  src={location}
                                  alt="location"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                                <p>
                                  {
                                    professional?.professional?.location
                                      ?.address
                                  }
                                </p>
                              </div>
                            )}

                            {professional?.professional?.website && (
                              <div className={styles.website}>
                                <img
                                  src={website}
                                  alt="website"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                                <a
                                  target="_blank"
                                  href={professional?.professional?.website}
                                >
                                  {professional?.professional?.website}
                                </a>
                              </div>
                            )}
                          </div>

                          {professional?.professional?.telephone && (
                            <div className={styles.phone}>
                              <img
                                src={call}
                                alt="call"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                              <p>{professional?.professional?.telephone}</p>
                            </div>
                          )}

                          {/* Availibility */}
                          <div className={styles.availibilityWrapper}>
                            <h6 className={styles.heading}>
                              Prochaines disponibilités
                            </h6>

                            {professional?.professional?.operationalHours && (
                              <div className={styles.appointmentsWrapp}>
                                {/* Matin */}
                                <div className={styles.appointmentDetail}>
                                  <p>Matin</p>
                                  <div className={styles.dates}>
                                    {professional?.professional
                                      ?.operationalHours &&
                                      Object?.entries(
                                        professional?.professional
                                          ?.operationalHours
                                      )?.map(([day, values]) => {
                                        const morning = values?.morning || {};
                                        if (morning.start && morning.end) {
                                          return (
                                            <div
                                              className={styles.gray}
                                              key={day}
                                            >
                                              {day.slice(0, 3)}, 24
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>

                                {/* Après-midi */}
                                <div className={styles.appointmentDetail}>
                                  <p>Après-midi</p>
                                  <div className={styles.dates}>
                                    {professional?.professional
                                      ?.operationalHours &&
                                      Object.entries(
                                        professional?.professional
                                          ?.operationalHours
                                      )?.map(([day, values]) => {
                                        const afterNoon =
                                          values?.afterNoon || {};
                                        if (afterNoon.start && afterNoon.end) {
                                          return (
                                            <div
                                              className={styles.gray}
                                              key={day}
                                            >
                                              {day.slice(0, 3)}, 24
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* professional keywords */}
                        {professional?.professional?.activities && (
                          <div className={styles.bottom}>
                            <div className={styles.subTypesContainer}>
                              {professional?.professional?.activities?.map(
                                (activity, activityIndex) => {
                                  return (
                                    <div key={activityIndex}>
                                      <button
                                        onClick={() =>
                                          handleSubTypeClick(
                                            profIndex,
                                            activityIndex
                                          )
                                        }
                                        className={`${
                                          activeSubTypeBtns[profIndex] ===
                                          activityIndex
                                            ? styles.activeSubType
                                            : ""
                                        } ${styles.subtype}`}
                                      >
                                        {activity}
                                      </button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </>
              );
            }
          )}
        </>
      )}

      {/* Pagination controls */}
      <div className={styles.paginationWrapper}>
        {/* Previous page button */}
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          <img
            src={prevPage}
            alt="prevPage"
            onContextMenu={(e) => e.preventDefault()}
          />
        </button>

        {/* Page numbers */}
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={
              currentPage === pageNumber
                ? styles.activePageNumber
                : styles.pageNumber
            }
            // disabled={currentPage === pageNumber}
          >
            {pageNumber}
          </button>
        ))}

        {/* Next page button */}
        <button
          onClick={handleNextPage}
          disabled={endIndex >= professionals.length}
        >
          <img
            src={nextPage}
            alt="nextPage"
            onContextMenu={(e) => e.preventDefault()}
          />
        </button>
      </div>

      <ProfessionalSpaceModal
        toggleState={professionalSpaceModal}
        handleToggle={professionalSpaceModalToggle}
      />

      <InfoModal
        toggleState={showInfoModal}
        handleToggle={toggleReportUserModal}
        info={professionalResults?.description}
      />

      <PermissionsModal
        toggleState={showPermissionsModal}
        handleToggle={togglePermissionsUserModal}
        handleDeny={handleDeny}
        handleAllow={handleAllow}
      />
    </div>
  );
};

export default Professionnels;
