import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/userLogin.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { message } from "antd";

import ButtonFilled from "../buttonFilled";
import { ApiCall } from "../../config/apiCall";
import { burgerCrossBlack, eyeIcon, eyeSlash } from "../../assets";

const ChangePassword = ({ toggleState, handleToggle }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [token, setToken] = useState("");

  //   get token
  useEffect(() => {
    if (typeof window !== "undefined") {
      const getToken = localStorage.getItem("token");
      setToken(getToken);
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const oldPasswordIsValid = oldPassword.trim() !== "";
    const newPasswordIsValid = password.trim() !== "";

    if (!(oldPasswordIsValid && newPasswordIsValid)) {
      message.error("Veuillez remplir toutes les entrées");
    } else {
      // appi call
      const response = await ApiCall({
        params: { oldPassword, password },
        route: "auth/change-password",
        verb: "post",
        token: token,
        baseurl: true,
      });
      if (response.status === 200) {
        message.success(`${response.response.success}`);
        handleToggle();
      } else {
        message.error(`${response.response.msg}`);
      }
      console.log(response);
    }
  };
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.loginModalBodyHeader}>
            <h5 id="signUpModalLabel">Modifier mon mot de passe</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              <img className="py-2" src={burgerCrossBlack} alt="close"  onContextMenu={e => e.preventDefault()}/>
            </button>
          </div>
          <div className={styles.loginForm}>
            <form>
              <div className={styles.inputContainer}>
                <label>Ancien mot de passe</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={handleToggleShowOldPassword}
                  >
                    <>
                      <img
                        className={styles.eyeClose}
                        src={showOldPassword ? eyeIcon : eyeSlash}
                        alt="eyeClose"
                        onContextMenu={e => e.preventDefault()}
                      />
                      {/* <div
                        className={showOldPassword ? styles.passwordSlash : ""}
                      ></div> */}
                    </>
                  </button>
                </div>
              </div>
              <div className={styles.inputContainer}>
                <label>Nouveau mot de passe</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={handleToggleShowPassword}
                  >
                    <>
                      <img
                        className={styles.eyeClose}
                        src={showOldPassword ? eyeIcon : eyeSlash}
                        alt="eyeClose"
                        onContextMenu={e => e.preventDefault()}
                      />
                      {/* <div
                        className={showPassword ? styles.passwordSlash : ""}
                      ></div> */}
                    </>
                  </button>
                </div>
              </div>
              <div className={styles.loginButton}>
                <ButtonFilled
                  title="Modifier"
                  textColr="white"
                  handleClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePassword;
