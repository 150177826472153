import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/profile/modifyProfileInfo.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { ApiCall } from "../../config/apiCall";
import ButtonFilled from "../../components/buttonFilled";
import Themes from "../../components/themes";
import { Spinner } from "reactstrap";
import { useAuth } from "../../context/AuthContext";
import { burgerCrossBlack, smilyIcon, upload, userAvatar } from "../../assets";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Select, message } from "antd";

let profileVisibilty = [
  {
    label: "Privé",
    value: "true",
  },
  {
    label: "Public",
    value: "false",
  },
];

const themeVisibilty = [
  { value: "true", label: "Afficher sur le profil" },
  { value: "false", label: "Ne pas afficher" },
];

const ModifyProfileInfo = ({
  toggleState,
  handleToggle,
  userDetails,
  setUserDetails,
  userId,
}) => {
  const { token, getProfileData } = useAuth();
  const [prevSelectedThemes, setPrevSelectedThemes] = useState([]);
  const [prevUnselectedThemes, setPrevUnselectedThemes] = useState([]);
  const [allThemes, setAllThemes] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [imageState, setImageState] = useState(null);
  const [imageUpState, setImageUpState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [maxCharacterError, setMaxCharacterError] = useState(false);

  //   get selected, unselected, all themes
  useEffect(() => {
    if (
      Array.isArray(userDetails?.selectedThemes) &&
      Array.isArray(userDetails?.unselectedThemes)
    ) {
      const themesWithSelectedFlag = userDetails.selectedThemes.map(
        (theme) => ({
          ...theme,
          isSelected: true,
        })
      );

      const themesWithUnselectedFlag = userDetails.unselectedThemes.map(
        (theme) => ({
          ...theme,
          isSelected: false,
        })
      );

      setPrevSelectedThemes(themesWithSelectedFlag);
      setPrevUnselectedThemes(themesWithUnselectedFlag);
    }
    setAllThemes([...prevSelectedThemes, ...prevUnselectedThemes]);
  }, [userDetails]);

  // update themes
  function handleCardClick(id) {
    const selectedThemesFilter = allThemes.filter((theme) => theme.isSelected);
    const clickedTheme = allThemes.find((theme) => theme._id === id);
    if (!clickedTheme.isSelected && selectedThemesFilter.length < 3) {
      setAllThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: true } : theme
        )
      );
    } else {
      setAllThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: false } : theme
        )
      );
    }
  }

  //   set updated themes
  useEffect(() => {
    const selectedThemesAfterUpdate = allThemes.filter(
      (theme) => theme.isSelected
    );
    const getSelectedThemes = selectedThemesAfterUpdate.map(
      (theme) => theme._id
    );
    setSelectedThemes(getSelectedThemes);
  }, [allThemes]);

  const MAX_CHARACTERS = 150;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= MAX_CHARACTERS) {
      setUserDetails((prevState) => ({ ...prevState, [name]: value }));
      setMaxCharacterError(false);
    } else {
      setMaxCharacterError(true);
    }
  };

  const handleEmojiSelect = (emoji) => {
    setUserDetails((prevState) => ({
      ...prevState,
      biography: userDetails.biography + emoji.native,
    }));
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleProfileVisibilityChange = async (value) => {
    setUserDetails((prevState) => ({ ...prevState, isPrivate: value }));
    const response = await ApiCall({
      params: {},
      route: "profile/changeProfileStatus/" + value,
      verb: "put",
      baseurl: true,
      token: token,
    });

    if (response.status === 200) {
      // setUserInfo(response.response);
    }
    console.log("profileResp", response);
  };

  const handleThemeVisibilityChange = async (value) => {
    setUserDetails((prevState) => ({
      ...prevState,
      theme_visibility_status: value,
    }));

    const response = await ApiCall({
      params: {},
      route: "profile/update_theme_visibility_status/" + value,
      verb: "put",
      baseurl: true,
      token: token,
    });

    if (response.status === 200) {
      // setUserInfo(response.response);
    }
    console.log("themeVisibiltyResp", response);
  };

  //   submit updated profile to backend
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const params = new FormData();
      params.append("biography", userDetails.biography || "");
      selectedThemes.forEach((val) => {
        console.log("v", val);
        params.append("selectedThemes", val);
      });
      if (imageUpState) {
        params.append("profilePicture", imageUpState);
      }

      const response = await ApiCall({
        params,
        route: "profile/update",
        verb: "put",
        baseurl: true,
        token: token,
      });
      console.log(response, "update profile");
      if (response.status === 200) {
        message.success(response.response.message);
        handleToggle();
        getProfileData(userId);
      } else {
        // message.error(response.response.message);
        message.error("Veuillez sélectionner 3 thèmes !");
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  //   Upload image

  const handleImageUpload = (event) => {
    const file = event.target;

    if (file) {
      setImageState(URL.createObjectURL(file.files[0]));
      const blob = new Blob([file.files[0]], { type: file.files[0].type });
      setImageUpState(blob);
    }
  };

  const handleRemoveImage = () => {
    setImageState(null);
  };

  return (
    <div>
      <Modal
        size="lg"
        className={styles.bodyWrapper}
        style={{ maxWidth: "700px", width: "100%" }}
        centered
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody>
          <div className={styles.bodyHeader}>
            <h5 id="signUpModalLabel">Modifier mes informations</h5>
            <button
              aria-label="Close"
              className="burgerCrossBlack"
              type="button"
              onClick={handleToggle}
            >
              <img
                src={burgerCrossBlack}
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.content}>
            <div className={styles.profileVisibilty}>
              <label className={styles.title} htmlFor="profileVisibility">
                Profil visible par :
              </label>
              <div className={styles.profileVisibiltyDropdown}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  value={userDetails?.isPrivate + ""}
                  onChange={handleProfileVisibilityChange}
                  options={profileVisibilty}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                ></Select>
              </div>
            </div>
            <div className={styles.picBioWrapper}>
              <div className={styles.profilepicWrapper}>
                <div className={styles["form-left"]}>
                  {imageState ? (
                    <>
                      <img
                        src={imageState}
                        alt="uploaded"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <label htmlFor="image-upload" onClick={handleRemoveImage}>
                        Remove
                      </label>
                    </>
                  ) : (
                    <>
                      <label
                        className={styles["image-upload-button"]}
                        htmlFor="image-upload"
                      >
                        <img
                          src={
                            userDetails?.profilePicture?.url
                              ? userDetails?.profilePicture?.url
                              : userAvatar
                          }
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        <div className={styles.overlay}>
                          <img
                            src={upload}
                            className={styles.icon}
                            style={{ width: "40px", height: "40px" }}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>
                      </label>
                    </>
                  )}
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
              <div className={styles.bio}>
                <p className={styles.title}>Biographie</p>
                <textarea
                  className={styles.details}
                  id="biography"
                  name="biography"
                  rows="3"
                  cols="50"
                  required
                  value={userDetails?.biography}
                  onChange={handleInputChange}
                />
                <span className={styles.emogie} onClick={toggleEmojiPicker}>
                  <img
                    src={smilyIcon}
                    alt="smily"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </span>
                {showEmojiPicker && (
                  <div className={styles.emogiePicker} ref={emojiPickerRef}>
                    <Picker
                      data={data}
                      previewPosition="none"
                      theme="light"
                      onEmojiSelect={handleEmojiSelect}
                    />
                  </div>
                )}
                {maxCharacterError && (
                  <div className={`${styles.errorMessage} errorMessage pe-1`}>
                    Vous avez atteint la limite de 150 caractères.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.themesHeader}>
              <p className={styles.title}>Thème favoris:</p>
              <div className={styles.themeVisibilty}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  value={userDetails?.theme_visibility_status + ""}
                  onChange={handleThemeVisibilityChange}
                  options={themeVisibilty}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                ></Select>
              </div>
            </div>
            <Themes themes={allThemes} handleCardClick={handleCardClick} />
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Spinner color="danger">Loading...</Spinner>
            </div>
          ) : (
            <div className={styles.submitButton}>
              <ButtonFilled
                title="Modifier"
                textColr="white"
                handleClick={handleSubmit}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModifyProfileInfo;
