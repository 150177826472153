import React, { useEffect } from "react";
import styles from "./Appointment.module.scss";
import { ReactSVG } from "react-svg";
import { arrowBackGray, userAvatar } from "../../../assets";
import { Collapse } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const dateValues = ["09:30", "10:15", "11:00", "11:45", "12:30", "13:15"];
const date = [
  {
    key: "1",
    label: (
      <div className={styles.detailTop}>
        <h6 className={styles.addressHeading}>Day XX month</h6>
      </div>
    ),
    children: (
      <div className={styles.detailBottom}>
        <div className={styles.dateWrapper}>
          {dateValues?.map((date, i) => (
            <p key={i} className={styles.date}>
              {date}
            </p>
          ))}
        </div>
        <br />
        <hr />
        <br />
        <div className={styles.moreButton}>VOIR PLUS</div>
      </div>
    ),
  },
];

const Appointment = () => {
  const { getSearchedUserProfile, searchedUserInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const getProfessionalId = searchParams.get("professionalId");

  useEffect(() => {
    getSearchedUserProfile(getProfessionalId);
  }, [getProfessionalId]);

  return (
    <div className={styles.appointmentContainer}>
      <div className={styles.header}>
        <div
          className={styles.back}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ReactSVG src={arrowBackGray} />
        </div>

        <div className={styles.profilepic}>
          <img
            src={
              searchedUserInfo?.profilePicture?.url
                ? searchedUserInfo?.profilePicture?.url
                : userAvatar
            }
          />
        </div>

        <div className={styles.content}>
          <h6 className={styles.name}>Prendre rendez-vous</h6>
          <p className={styles.desc}>
            {searchedUserInfo?.professional?.fullName}
          </p>
        </div>
      </div>

      <div className={styles.body}>
        <p className={styles.title}>Choisissez la date de consultation</p>

        {/* date */}
        <Collapse defaultActiveKey={["1"]} items={date} />
        <Collapse items={date} />
        <Collapse items={date} />

        <div className={styles.showMoreAvailability}>
          AFFICHER PLUS DE DISPONIBILITÉS
        </div>
      </div>
    </div>
  );
};

export default Appointment;
