import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/comments/DisplayComments.module.scss";
import { DownOutlined } from "@ant-design/icons";
import { MentionsInput, Mention } from "react-mentions";
import { Dropdown, Menu, Space, message } from "antd";
import { useAuth } from "../../context/AuthContext";
import {
  add,
  dislike,
  like,
  minus,
  report,
  sendGreenIcon,
  sendIcon,
  smilyIcon,
  userAvatar,
} from "../../assets/index";

import { ApiCall } from "../../config/apiCall";
import StoryLoader from "../UI/StoryLoader";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayCommentsReplys from "./DisplayCommentsReplys";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useNavigate } from "react-router-dom";

const DisplayComments = ({ storyId, setCommentCounts }) => {
  const {
    token,
    userInfo,
    userId,
    loginModal,
    setLoginModal,
    getSearchedUserProfile,
    getUserStories,
    singleUserStoryCount,
  } = useAuth();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [commentsList, setCommentsList] = useState("");
  const [loadingComments, setloadingComments] = useState(false);
  const [plainComment, setPlainComment] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentsType, setCommentsType] = useState("Les plus pertinents");
  const [selectedCommentId, setSelectedCommentId] = useState("-1");

  const [commentActions, setCommentActions] = useState([]);
  const [isRepliesOpen, setIsRepliesOpen] = useState([]);
  const [isRepliesInputOpen, setIsRepliesInputOpen] = useState([]);
  const [activeReaction, setActiveReaction] = useState(null);
  const [isLiked, setIsLiked] = useState(-1);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const commentInputRef = useRef(null);
  const [maxCharacterError, setMaxCharacterError] = useState(false);
  const [editableComment, setEditableComment] = useState(null);
  const [matchingUsers, setMatchingUsers] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [showReplyInput, setShowReplyInput] = useState(false);

  const commentsTypes = [
    {
      label: "Les plus pertinents",
      key: "1",
      onClick: (id) => {
        getStoryMostreleventComments(id);
        setCommentsType("Les plus pertinents");
      },
    },
    {
      label: "Les plus récents",
      key: "2",
      onClick: (id) => {
        getStoryComments(id);
        setCommentsType("Les plus récents");
      },
    },
  ];

  let allMentionsUsers = matchingUsers?.map((user) => ({
    id: user?._id,
    display: user?.username,
  }));

  // active input
  const activateInputField = () => {
    console.log("object", commentInputRef);
    // Check if the ref is available before attempting to focus
    if (commentInputRef.current) {
      commentInputRef.current.shiftFocus();
    }
  };

  const handleChange = (e, newValue, newPlainTextValue, mentions) => {
    const mentionedUserIds = mentions.map((mention) => mention.id);
    setTaggedUsers(mentionedUserIds);
    setComment(e.target.value);
    // setPlainComment(newPlainTextValue)
    // console.log(newPlainTextValue, 'pleain text value')
  };

  const commentOptions = [
    {
      label: "Modifier mon commentaire",
      key: "0",
      onClick: (comment, storyId) => {
        console.log(comment);
        setEditableComment({ storyId, comment });
      },
    },
    {
      label: "Supprimer mon commentaire",
      key: "1",
      onClick: (comment, storyId) => {
        deleteComment(comment._id, storyId);
      },
    },
  ];

  useEffect(() => {
    // Fetch the initial ten users from the API when the component loads
    async function fetchInitialUsers() {
      try {
        const response = await ApiCall({
          params: {},
          route: "user/get-tagged-users",
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setMatchingUsers(response.response);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchInitialUsers();
  }, []);

  // useEffect(() => {
  //   if (comment?.includes("@")) {
  //     // Fetch users from the API based on the search term
  //     async function searchUsers() {
  //       try {
  //         const search = comment?.split("@")[1].toLowerCase();
  //         const response = await ApiCall({
  //           params: { search },
  //           route: "user/get-tagged-searched-user", // Replace with your API route for searching users
  //           verb: "get",
  //           baseurl: true,
  //           token: token,
  //         });

  //         if (response.status === 200) {
  //           setMatchingUsers(response.response); // Store the matching users
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     searchUsers();
  //   } else {
  //     setMatchingUsers([]); // Clear the list when "@" is not present
  //   }
  // }, [comment]);

  // get story comments
  const getUpdatedStoryComments = async (storyId) => {
    try {
      const response = await ApiCall({
        params: {},
        route: "comment/get-comment-list/" + storyId,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setCommentsList(response.response);
      }
      // console.log("commentsResp", response);
    } catch (error) {
      // Handle error if necessary
    } finally {
    }
  };

  // get story comments
  const getStoryComments = async (storyId) => {
    if (token) {
      setloadingComments(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "comment/get-comment-list/" + storyId,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setCommentsList(response.response);
        }
        // console.log("commentsResp", response);
      } catch (error) {
        setloadingComments(false);
        // Handle error if necessary
      } finally {
        setloadingComments(false);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  // get story comments
  const getStoryMostreleventComments = async (storyId) => {
    if (token) {
      setloadingComments(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "comment/get-comment-relevent-list/" + storyId,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setCommentsList(response.response);
        }
        // console.log("releventCommentsResp", response);
      } catch (error) {
        setloadingComments(false);
        // Handle error if necessary
      } finally {
        setloadingComments(false);
      }
    } else {
      setLoginModal(!loginModal);
    }
  };

  useEffect(() => {
    getStoryMostreleventComments(storyId);
  }, []);

  const handleLikeClick = async (CommentIndex) => {
    const commentId = commentsList?.data?.list[CommentIndex]._id;

    try {
      const response = await ApiCall({
        params: {},
        route: `comment/comment-reaction/${commentId}&like`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        console.log("response.response.comment", response.response.comment);
        getUpdatedStoryComments(storyId);
      } else {
        message.error(response.response.error[0]?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleDislikeClick = async (CommentIndex) => {
    const commentId = commentsList?.data?.list[CommentIndex]._id;

    try {
      const response = await ApiCall({
        params: {},
        route: `comment/comment-reaction/${commentId}&dislike`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        getUpdatedStoryComments(storyId);
      } else {
        message.error(response.response.error[0]?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const MAX_CHARACTERS = 2200;
  const handleCommentInput = (event) => {
    const { value, scrollHeight, clientHeight } = event.target;
    event.target.style.height = "auto";
    const newHeight = Math.max(scrollHeight, clientHeight);
    event.target.style.height = newHeight + "px";
    if (value.length <= MAX_CHARACTERS) {
      setComment(value);

      setMaxCharacterError(false);
    } else {
      setMaxCharacterError(true);
    }

    if (value.trim() === "") {
      event.target.style.height = "41px";
    }
  };

  const handleEmojiSelect = (emoji) => {
    setComment(comment + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setIsLiked(-1);
  }, [commentsList]);

  const submitComment = async (e) => {
    e.preventDefault();

    if (comment !== "") {
      setCommentLoading(true);
      try {
        const response = await ApiCall({
          params: {
            storyId: storyId,
            comment: comment,
            taggedUserIds: taggedUsers,
          },
          route: "comment/add-comment",
          verb: "post",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setCommentsList({
            ...commentsList,
            data: {
              list: [response.response.data, ...commentsList.data.list],
              comments_count: ++commentsList.data.comments_count,
            },
          });
          setCommentCounts(storyId, response?.response?.comments_count);
          setComment("");
          document.getElementById("CommentTextarea").style.height = "44px";
        }
      } catch (error) {
        setCommentLoading(false);
      } finally {
        setCommentLoading(false);
      }
    }
  };

  const deleteComment = async (commentId, storyId) => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `comment/delete-comment/${storyId}&${commentId}`,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setCommentsList({
          ...commentsList,
          data: {
            list: commentsList.data.list.filter(
              (comment) => comment._id !== commentId
            ),
            comments_count: commentsList.data.comments_count,
          },
        });
        setCommentCounts(storyId, response?.response?.comments_count);
      }
      console.log("deleteCommentResp", response);
    } catch (error) {
      // setStoriesLoading(false);
      // Handle error if necessary
    } finally {
      // setStoriesLoading(false);
    }
  };

  const updateComment = async () => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {
          comment: editableComment?.comment?.comment,
          taggedUserIds: taggedUsers,
        },
        route: `comment/update-comment/${editableComment?.storyId}&${editableComment?.comment?._id}`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setCommentsList({
          ...commentsList,
          data: {
            list: commentsList.data.list.map((comment) => {
              if (comment._id === editableComment?.comment?._id) {
                return {
                  ...comment,
                  comment: editableComment?.comment?.comment,
                };
              } else {
                return comment;
              }
            }),
            comments_count: commentsList.data.comments_count,
          },
        });
        setEditableComment(null);
        // message.success(response.response);
      }
    } catch (error) {
      // setStoriesLoading(false);
      // Handle error if necessary
    } finally {
      // setStoriesLoading(false);
    }
  };

  const reportComment = async (commentId, storyId) => {
    // setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `comment/reportComment/${storyId}&${commentId}`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // setCommentsList({
        //   ...commentsList,
        //   data: {
        //     list: commentsList.data.list.filter(
        //       (comment) => comment._id !== commentId
        //     ),
        //     comments_count: commentsList.data.comments_count,
        //   },
        // });
        message.success(response.response);
      }
      console.log("reportCommentResp", response);
    } catch (error) {
      // setStoriesLoading(false);
      // Handle error if necessary
    } finally {
      // setStoriesLoading(false);
    }
  };

  const calculateTimeElapsed = (date) => {
    const now = new Date();
    const elapsedTime = now - date;

    const seconds = Math.floor(elapsedTime / 1000) % 60;
    const minutes = Math.floor(elapsedTime / (1000 * 60)) % 60;
    const hours = Math.floor(elapsedTime / (1000 * 60 * 60)) % 24;
    const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24)) % 7;
    const weeks = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 7));

    return {
      weeks: weeks,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  };

  const TimeElapsed = (time) => {
    if (time.weeks > 0) {
      return `${time.weeks} sem`;
    } else if (time.days > 0) {
      return `${time.days} j`;
    } else if (time.hours > 0) {
      return `${time.hours} h`;
    } else {
      return `${time.minutes} min`;
    }
  };

  const renderUserSuggestion = (suggestion, search, highlightedDisplay) => (
    <div>@ {highlightedDisplay}</div>
  );

  return (
    <div className="py-2">
      <form className={styles.writeComment}>
        <img
          src={
            userInfo?.profilePicture?.url
              ? userInfo?.profilePicture?.url
              : userAvatar
          }
          alt="userpic"
          width={30}
          height={30}
        />
        <div className={styles.textareaWrapper}>
          <MentionsInput
            value={comment}
            onChange={handleChange}
            placeholder="Ajouter un commentaire …"
            className="mentions"
            classNames={styles}
            ref={commentInputRef}
          >
            <Mention
              data={allMentionsUsers}
              markup="@[__display__]{__id__}"
              renderSuggestion={renderUserSuggestion}
              className={styles.mentions__mention}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
        </div>

        <span className={styles.emogie} onClick={toggleEmojiPicker}>
          <img
            src={smilyIcon}
            alt="smily"
            onContextMenu={(e) => e.preventDefault()}
          />
        </span>

        {showEmojiPicker && (
          <div className={styles.emogiePicker} ref={emojiPickerRef}>
            <Picker
              data={data}
              previewPosition="none"
              theme="light"
              onEmojiSelect={handleEmojiSelect}
            />
          </div>
        )}

        {comment === "" ? (
          <button disabled className={styles.sendCommntBtn}>
            <img
              src={sendIcon}
              alt="send"
              onContextMenu={(e) => e.preventDefault()}
            />
          </button>
        ) : (
          <button onClick={submitComment} className={styles.sendCommntBtn}>
            <img
              src={sendGreenIcon}
              alt="send"
              onContextMenu={(e) => e.preventDefault()}
            />
          </button>
        )}

        {maxCharacterError && (
          <div className={`errorMessage ${styles.limitErrorMsg}`}>
            Vous avez atteint la limite de 2200 caractères.
          </div>
        )}
      </form>

      {commentsList?.data?.list?.length > 0 && (
        <div className={styles.commentsSortWrapper}>
          <Dropdown
            overlay={
              <Menu className={styles.dropdownMenu}>
                {commentsTypes.map((item) => (
                  <Menu.Item
                    key={item.key}
                    onClick={() => item.onClick && item.onClick(storyId)}
                  >
                    {item.render ? item.render(storyId) : item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <a
              onClick={(e) => e.preventDefault()}
              style={{
                color: "#191919",
                // backgroundColor: "#e5e7e8",
                padding: "0.5rem",
                fontSize: "1rem",
              }}
            >
              <Space>
                {commentsType}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}

      {loadingComments ? (
        <div className={styles.CommentloaderContainer}>
          <StoryLoader oneLine={true} />
        </div>
      ) : (
        <>
          {commentsList?.data?.list?.length > 0 && (
            <div className={styles.commentWrapper}>
              {commentsList?.data?.list?.map((myComment, CommentIndex) => {
                const date = new Date(myComment?.createdAt);
                const showComments = selectedCommentId === myComment?._id;

                const timeElapsed = calculateTimeElapsed(date);
                const DateFormate = TimeElapsed(timeElapsed);

                const transformedComment = myComment?.comment.replace(
                  /\@\[([^\]]+)]\{([^\}]+)\}/g,
                  (match, displayName, userId) => {
                    return `<a href="/userProfile?userId=${userId}" style="color: #01989f; cursor: pointer;" data-userid="${userId}"">${displayName}</a>`;
                  }
                );

                return (
                  <>
                    <div key={CommentIndex}>
                      <div className={styles.top}>
                        <div className={styles.leftSide}>
                          <img
                            src={
                              myComment?.author?.profilePicture?.url
                                ? myComment.author?.profilePicture.url
                                : userAvatar
                            }
                            alt="userAvatar"
                            width={30}
                            height={30}
                          />
                          <div className={styles.nameDate}>
                            <h2>{myComment?.author?.fullname}</h2>
                            <p className="">{DateFormate}</p>
                          </div>
                        </div>
                      </div>
                      {editableComment &&
                      editableComment?.comment?._id === myComment?._id ? (
                        <div
                          className={`${styles.editableContent} ${styles.writeComment}`}
                        >
                          <div className={styles.textareaWrapper}>
                            <textarea
                              value={editableComment?.comment?.comment}
                              onChange={(e) =>
                                setEditableComment((prev) => ({
                                  ...prev,
                                  comment: {
                                    ...prev?.comment,
                                    comment: e.target.value,
                                  },
                                }))
                              }
                            />
                          </div>
                          {editableComment?.comment?.comment === "" ? (
                            <button disabled className={styles.sendCommntBtn}>
                              <img
                                src={sendIcon}
                                alt="send"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </button>
                          ) : (
                            <button
                              onClick={updateComment}
                              className={styles.sendCommntBtn}
                            >
                              <img
                                src={sendGreenIcon}
                                alt="send"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </button>
                          )}
                        </div>
                      ) : (
                        <>
                          {/* <p className={styles.content}>{myComment.comment}</p> */}
                          <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{
                              __html: transformedComment,
                            }}
                          />
                        </>
                      )}
                      <div className={styles.bottom}>
                        <div className={styles.bottomLeft}>
                          <div className={styles.reactionsWrapper}>
                            <div>
                              <button
                                onClick={() => handleLikeClick(CommentIndex)}
                                className={`
                                ${
                                  myComment.isReacted === 0
                                    ? styles.activeComment
                                    : ""
                                }
                                ${styles.comment}`}
                              >
                                <img
                                  src={like}
                                  alt="like"
                                  width={20}
                                  height={18}
                                  className={styles.like}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                                <span>{myComment.likes_count}</span>
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() => handleDislikeClick(CommentIndex)}
                                className={`
                                ${
                                  myComment?.isReacted === 1
                                    ? styles.activeComment
                                    : ""
                                }
                                ${styles.comment}`}
                              >
                                <img
                                  src={dislike}
                                  alt="dislike"
                                  width={20}
                                  height={18}
                                  className={styles.dislike}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                                <span>{myComment?.dislikes_count}</span>
                              </button>
                            </div>
                          </div>

                          <h4
                            className={styles.respond}
                            onClick={() => {
                              if (
                                commentActions.find(
                                  (el) => myComment?._id === el?.id
                                )
                              ) {
                                // setSelectedCommentId("-1");
                                setCommentActions((prev) =>
                                  prev.map((el) => {
                                    if (el?.id === myComment?._id) {
                                      return { ...el, input: !el?.input };
                                    } else {
                                      return el;
                                    }
                                  })
                                );
                                activateInputField();
                              } else {
                                // setSelectedCommentId(myComment?._id);
                                setCommentActions((prev) => [
                                  ...prev,
                                  {
                                    id: myComment?._id,
                                    input: true,
                                    comments: false,
                                  },
                                ]);
                              }
                            }}
                          >
                            Répondre
                          </h4>

                          {!userInfo?.isAdmin &&
                            myComment?.author?._id !== userId && (
                              <div
                                onClick={() =>
                                  reportComment(myComment._id, storyId)
                                }
                                style={{ cursor: "pointer", marginTop: "-2px" }}
                              >
                                <img
                                  src={report}
                                  alt="logo"
                                  className={styles.icon}
                                  width={17}
                                  height={17}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </div>
                            )}

                          <div
                            onClick={() =>
                              deleteComment(myComment._id, storyId)
                            }
                          >
                            {userInfo?.isAdmin && (
                              <FontAwesomeIcon
                                className={styles.icon}
                                icon={faTrashCan}
                                style={{
                                  color: "gray",
                                  cursor: "pointer",
                                  paddingTop: "5px",
                                }}
                              />
                            )}
                          </div>

                          {myComment?.author?._id === userId && (
                            <Dropdown
                              overlay={
                                <Menu>
                                  {commentOptions.map((item) => (
                                    <Menu.Item
                                      key={item.key}
                                      onClick={() =>
                                        item.onClick &&
                                        item.onClick(myComment, storyId)
                                      }
                                    >
                                      <p style={{ width: "100px" }}>
                                        {item.render
                                          ? item.render(CommentIndex)
                                          : item.label}
                                      </p>
                                    </Menu.Item>
                                  ))}
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  style={{
                                    color: "#5c5c5c",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            </Dropdown>
                          )}
                        </div>

                        <div>
                          {commentActions.find(
                            (el) => el?.id === myComment?._id
                          )?.comments ? (
                            <div
                              className={styles.bottomRight}
                              onClick={() => {
                                setSelectedCommentId(myComment?._id);

                                if (
                                  commentActions.find(
                                    (el) => myComment?._id === el?.id
                                  )
                                ) {
                                  setCommentActions((prev) =>
                                    prev.map((el) => {
                                      if (el?.id === myComment?._id) {
                                        return {
                                          ...el,
                                          comments: !el?.comments,
                                        };
                                      } else {
                                        return el;
                                      }
                                    })
                                  );
                                } else {
                                  setCommentActions((prev) => [
                                    ...prev,
                                    {
                                      id: myComment?._id,
                                      comments: false,
                                      input: false,
                                    },
                                  ]);
                                }
                              }}
                            >
                              <img
                                src={minus}
                                alt="hide"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                              {myComment.replies_count > 0 && (
                                <>
                                  {myComment.replies_count === 1 ? (
                                    <p className={styles.longRep}>
                                      {myComment.replies_count} réponse
                                    </p>
                                  ) : (
                                    <p className={styles.longRep}>
                                      {myComment.replies_count} réponses
                                    </p>
                                  )}
                                  <p className={styles.shortRep}>
                                    {myComment.replies_count} rép
                                  </p>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className={styles.bottomRight}
                              onClick={() => {
                                setSelectedCommentId(myComment?._id);

                                if (
                                  commentActions.find(
                                    (el) => myComment?._id === el?.id
                                  )
                                ) {
                                  setCommentActions((prev) =>
                                    prev.map((el) => {
                                      if (el?.id === myComment?._id) {
                                        return {
                                          ...el,
                                          comments: !el?.comments,
                                        };
                                      } else {
                                        return el;
                                      }
                                    })
                                  );
                                } else {
                                  setCommentActions((prev) => [
                                    ...prev,
                                    {
                                      id: myComment?._id,
                                      comments: true,
                                      input: false,
                                    },
                                  ]);
                                }
                              }}
                            >
                              {myComment.replies_count > 0 && (
                                <>
                                  {myComment.replies_count === 1 ? (
                                    <>
                                      <img
                                        src={add}
                                        alt="add"
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                      <p className={styles.longRep}>
                                        {myComment.replies_count} réponse
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={add}
                                        alt="add"
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                      <p className={styles.longRep}>
                                        {myComment.replies_count} réponses
                                      </p>
                                    </>
                                  )}
                                  <p className={styles.shortRep}>
                                    {myComment.replies_count} rép
                                  </p>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {token && (
                      <DisplayCommentsReplys
                        storyId={storyId}
                        commentId={myComment?._id}
                        showReplyInput={showReplyInput}
                        authorName={myComment?.author?.fullname}
                        authorId={myComment?.author?._id}
                        commentActions={commentActions}
                        setCommentActions={setCommentActions}
                      />
                    )}
                  </>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DisplayComments;
