import React from "react";
import { about } from "../assets";
const AboutUs = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: " 47px",
      }}
    >
      <img
        style={{
          maxWidth: "100%",
        }}
        src={about}
        alt="aboutUs"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default AboutUs;
