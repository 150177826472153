import React, { useState } from "react";
import styles from "../../assets/styles/userLogin.module.scss";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { ApiCall } from "../../config/apiCall";
import { toast } from "react-toastify";
import { burgerCrossBlack } from "../../assets";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const ForgotPassword = ({ toggleState, handleToggle }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === "") {
      message.error("Veuillez saisir votre email");
    } else {
      try {
        setLoading(true);
        // appi call
        const response = await ApiCall({
          params: { email },
          route: "auth/reset-password",
          verb: "post",
          token: "jwt_token",
          baseurl: true,
        });
        if (response.status === 200) {
          message.success(`${response.response.success}`);
          setEmail("");
          handleToggle();
        } else {
          message.error(`${response.response.message}`);
        }
        console.log(response);
      } catch (error) {
        // Handle error if necessary
      } finally {
        setLoading(false);
      }
    }
  };

  const handleForgotPasswordModalClose = () => {
    handleToggle();
    navigate("/");
  }

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody
          className={`${styles.loginModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.loginModalBodyHeader}>
            <h5 id="signUpModalLabel">Mot de passe oublié</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleForgotPasswordModalClose}
            >
              
              <img className="py-2" src={burgerCrossBlack} alt="close"  onContextMenu={e => e.preventDefault()}/>
            </button>
          </div>
          <p className="pb-2">Entrez l'adresse email pour réinitialiser le mot de passe.</p>
          <div className={styles.loginForm}>
            <form onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <label>Adresse email</label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type="email"
                    value={email}
                    placeholder="exemple@exemple.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Spinner color="danger" />
                </div>
              ) : (
                <div className={styles.loginButton}>
                  {/* <ButtonFilled
                    title="Envoyer"
                    textColr="white"
                    handleClick={handleSubmit}
                  /> */}
                  <input
                    type="submit"
                    value="Envoyer"
                    className="buttonFilled"
                  ></input>
                </div>
              )}
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
