import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./BestApps.module.scss";
import { useAuth } from "../../../context/AuthContext";
import { ApiCall } from "../../../config/apiCall";
import { message } from "antd";
import { siteUrl } from "../../../environment";
import { arrowBackGray } from "../../../assets";
import { ReactSVG } from "react-svg";
import BestAppsModal from "./BestAppsModal";
import { baseUrl } from "../../../environment";

const BestApps = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  const type = searchParams.get("type");

  const [resourceData, setResourceData] = useState(null);
  const [imageToModal, setImageToModal] = useState();
  const [loading, setLoading] = useState(null);

  // Modal
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModel = (image) => {
    setModalOpen(!modalOpen);
    setImageToModal(image);
  };

  useEffect(() => {
    if (token) {
      getResources();
    }
  }, [token]);

  const getResources = async () => {
    setLoading(true);

    let url;

    if (token) {
      url = `other-resources/otherResourcesPoint/${resourceId}`;
    } else {
      url = `other-resources/otherResourcesPoint-without-auth/${resourceId}`;
    }

    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setResourceData(response?.response?.data);
      }
      console.log("resp", response);
    } catch (error) {
      setLoading(false);
      message.error("Connection Error");
    } finally {
      setLoading(false);
    }
  };

  console.log("resourceData", resourceData);
  const topIcon = `${siteUrl}${resourceData?.topIcon}`;
  const topIcon2 = `${siteUrl}${resourceData?.topIcon2}`;
  const topIcon3 = `${siteUrl}${resourceData?.topIcon3}`;
  return (
    <div>
      <div
        className={styles.backButton}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>

      {type === "a" && resourceData?.description && (
        <p className="pb-3">{resourceData?.description}</p>
      )}

      {type === "b" && resourceData?.descriptionb && (
        <p className="pb-3">{resourceData?.descriptionb}</p>
      )}

      {type === "c" && resourceData?.descriptionc && (
        <p className="pb-3">{resourceData?.descriptionc}</p>
      )}

      {type === "d" && resourceData?.descriptiond && (
        <p className="pb-3">{resourceData?.descriptiond}</p>
      )}

      {type === "a" && resourceData?.topIcon && (
        <div className={styles.topIconClass}>
          <div className={styles.iconWrappPng}>
            {
              <img
                src={
                  resourceId === "17" || resourceId === "18"
                    ? topIcon2
                    : topIcon
                }
              />
            }
          </div>
        </div>
      )}

      {type === "b" &&
        resourceId !== "17" &&
        resourceId !== "18" &&
        resourceData?.topIcon2 && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrappPng}>{<img src={topIcon2} />}</div>
          </div>
        )}

      {type === "c" && resourceData?.topIcon3 && (
        <div className={styles.topIconClass}>
          <div className={styles.iconWrappPng}>{<img src={topIcon3} />}</div>
        </div>
      )}

      {["16"].includes(resourceId) &&
        type === "a" &&
        resourceData?.description2 && (
          <p className="pb-3">{resourceData?.description2}</p>
        )}

      {/* data mapping */}
      {resourceData?.data[0]?.[type]?.map((resourceData) => {
        return (
          <div
            className={styles.bestAppsContainer}
            onClick={() => toggleModel(`${siteUrl}${resourceData?.image}`)}
          >
            <div className={styles.top}>
              <div className={styles.content}>
                {resourceData?.heading && (
                  <div className={styles.headingWrapp}>
                    <h6 className={styles.heading}>{resourceData?.heading}</h6>
                    <h6 className={styles.subHeading}>
                      {resourceData?.downloaders}
                    </h6>
                  </div>
                )}

                <p>{resourceData?.description}</p>
              </div>
              {resourceData?.icon && (
                <div className={styles.iconContainer}>
                  <div className={styles.iconWrapper}>
                    <img alt="icon" src={`${siteUrl}${resourceData?.icon}`} />
                  </div>
                </div>
              )}
            </div>
            {resourceData?.description1 && (
              <div
                className={styles.bottom}
                style={{ paddingTop: resourceData?.description ? "20px" : "" }}
              >
                <p>{resourceData?.description1}</p>

                {resourceData?.description2 && (
                  <p>{resourceData?.description2}</p>
                )}
                {resourceData?.description3 && (
                  <p>{resourceData?.description3}</p>
                )}
              </div>
            )}
          </div>
        );
      })}
      {/* data mapping end */}

      {["16"].includes(resourceId) &&
        type === "a" &&
        resourceData?.description3 && (
          <p className="pb-3">{resourceData?.description3}</p>
        )}

      {["17", "18", "19", "20"].includes(resourceId) &&
        type === "a" &&
        resourceData?.description2 && (
          <p
            dangerouslySetInnerHTML={{ __html: resourceData?.description2 }}
            className="pb-3"
          ></p>
        )}

      {["18"].includes(resourceId) &&
        type === "c" &&
        resourceData?.descriptionc2 && (
          <p className="pb-3">{resourceData?.descriptionc2}</p>
        )}

      {/* BestAppsModal */}
      <BestAppsModal
        toggleState={modalOpen}
        handleToggle={toggleModel}
        image={imageToModal}
      />
    </div>
  );
};

export default BestApps;
