import React from "react";
import styles from "../../assets/styles/VerifyEmail.module.scss";
import { ApiCall } from "../../config/apiCall";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonFilled from "../../components/buttonFilled";
import { Link } from "react-router-dom";

const VerifyEmail = () => {
  let { userId } = useParams();
  const navigate = useNavigate();

  const verifyEmail = async event => {
    event.preventDefault();

    const response = await ApiCall({
      params: {},
      route: `auth/verify-email/${userId}`,
      verb: "put",
      token: "",
      baseurl: true
    });
    if (response.status === 200) {
      toast.success(`${response.response.success}`);
      navigate("/");
    } else {
      toast.error(`${response.response.msg}`);
    }
    console.log(response);
  };

  return (
    <div className={styles.VerifyEmailConntainer}>
      <h5 className="pb-4">
        Welcome <span className="font-bold">Pseudo, </span>
      </h5>
      <p className={styles.heading}>
        Dernière étape de votre inscription : La confirmation de votre adresse
        email !
      </p>

      <div onClick={verifyEmail}>
        <div className={styles.buttonWrapper}>
          <ButtonFilled
            title="Je confirme, c’est bien mon adresse email"
            textColr="white"
          />
        </div>
      </div>
      <p className={styles.verify}>
        Ceci est un message automatique, merci de ne pas y répondre. Vous pouvez
        nous contacter à l’adresse{" "}
        <Link className={styles.link} to="/contact">
          contact@psyup.fr
        </Link>
      </p>

      <p className={styles.details}>
        Conformément à la loi « informatique et libertés » du 6 janvier 1978
        modifiée en 2004, vousbénéficiez d’un droit d’accès et de rectification
        aux informations qui vous concernent, que vous pouvez exercer en nous
        contactant à l’adresse{" "}
        <Link className={styles.link} to="/contact">
          contact@psyup.fr
        </Link>
      </p>

      <h3 className={styles.seeYou}>A tout de suite sur le site !</h3>
    </div>
  );
};

export default VerifyEmail;
