import React, { useState, useEffect, useCallback } from "react";
import styles from "../../assets/styles/messages/messages.module.scss";
import { search, userAvatar, burgerCrossBlack } from "../../assets";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";

import StoryLoader from "../../components/UI/StoryLoader";

import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const NewMessages = () => {
  const { token, loginModal, setLoginModal, userInfo } = useAuth();

  const [username, setUsername] = useState("");
  const [allUsers, serAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);
  const [usersLoading, setUsersLoading] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const [createChatloading, setCreateChatloading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await ApiCall({
          params: {},
          route: "user/allUsers",
          verb: "get",
          baseurl: true,
          token: token,
        });
        console.log("searched", response.response);
        if (response.status === 200) {
          setSearchedUsers(response.response);
        }
      } catch (error) {
        setloading(false);
      } finally {
        setloading(false);
      }
    };
    getUsers();
  }, []);

  const getAllSuggestedUsers = useCallback(async () => {
    setUsersLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "user/suggestedUsers",
        verb: "get",
        baseurl: true,
        token: token,
      });

      // console.log("reSS", response);

      if (response.status === 200) {
        serAllUsers(response.response);
        setFilteredUsers(response.response);
      }
    } catch (error) {
      setUsersLoading(false);
    } finally {
      setUsersLoading(false);
    }
  }, [token]);

  const handleSearch = (event) => {
    event.preventDefault();

    let searchTerm = event.target.value?.toLowerCase(); // Convert the search term to lowercase for case-insensitive matching

    if (searchTerm == undefined && username.length !== 0) {
      searchTerm = username;
    }

    if (searchTerm) {
      // Filter the conversations based on the search term
      const filtered = searchedUsers.filter((user) => {
        const userName = `${user?.fullname}`.toLowerCase();

        if (searchTerm.trim() == "") {
          return allUsers;
        } else {
          return userName.includes(searchTerm);
        }
      });
      console.log("fik", filtered);
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(allUsers);
    }
  };

  useEffect(() => {
    if (token) {
      getAllSuggestedUsers();
    }
  }, [token, getAllSuggestedUsers]);

  const handleConversationClick = async (userId) => {
    setCreateChatloading(true);
    try {
      const response = await ApiCall({
        params: { user_id1: userInfo?._id, user_id2: userId },
        route: "chat/create-chatRoom",
        verb: "post",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        console.log("chat created");
        navigate(`/messaging?chatroomId=${response.response.data._id}`);
      }
      console.log("create-chatRoomResp", response);
    } catch (error) {
      setCreateChatloading(false);
    } finally {
      setCreateChatloading(false);
    }
  };

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  return (
    <div className={styles.messagesWrapper}>
      {token && (
        <div className={styles.conversations}>
          <div className={styles.header}>
            <div className={styles.top}>
              <p className={styles.title}>Nouveau Message</p>
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onClick={() => navigate("/messaging")}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <form onSubmit={handleSearch}>
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    handleSearch(event); // Trigger search when the input value changes
                  }}
                  // onSubmit={(event) => {
                  //   handleSearch(event);
                  // }}
                  placeholder="Rechercher …"
                />
                <button type="submit">
                  <img
                    src={search}
                    alt="search"
                    width={20}
                    height={20}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </button>
                {/* <img src={search} alt="search" width={20} height={20}  onContextMenu={e => e.preventDefault()}/> */}
              </div>
            </form>
          </div>

          <div className={styles.body}>
            <b className={styles.title}>Suggéré</b>
            <Spin spinning={createChatloading}>
              <div className={styles.convoWrapper}>
                {usersLoading ? (
                  <StoryLoader />
                ) : (
                  <>
                    {filteredUsers.length === 0 && <h4>No Conversations</h4>}

                    {filteredUsers?.map((user) => {
                      return (
                        <div
                          className={`${styles.messages}`}
                          key={user?._id}
                          onClick={async () => {
                            handleConversationClick(user?._id);
                          }}
                        >
                          <div className={styles.messageLeft}>
                            <img
                              className={styles.userPic}
                              src={
                                user?.profilePicture?.url
                                  ? user?.profilePicture?.url
                                  : userAvatar
                              }
                              alt="user"
                            />
                            <div className={styles.userName}>
                              <h5>{user?.fullname}</h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </Spin>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewMessages;
