import {
  Film,
  Livre,
  Série,
  Influenceur,
  Vidéo,
  Podcast,
  Musique,
  Citation,
} from "../../assets";

export const getImageByValue = (value) => {
  switch (value) {
    case "Film":
      return Film;
    case "Livre":
      return Livre;
    case "Série":
      return Série;
    case "Influenceur":
      return Influenceur;
    case "Vidéo":
      return Vidéo;
    case "Podcast":
      return Podcast;
    case "Musique":
      return Musique;
    case "Citation":
      return Citation;
    default:
      return Citation;
  }
};
