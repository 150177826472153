import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/SearchUser.module.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import { Spinner } from "reactstrap";

import { userAvatar } from "../../assets";
import StoryLoader from "../../components/UI/StoryLoader";
import { Spin, Tabs } from "antd";
import StoryModal from "../posts/StoryModal";
const { TabPane } = Tabs;

const SearchUser = () => {
  let { userName } = useParams();
  const navigate = useNavigate();
  const { token, loginModal, setLoginModal } = useAuth();
  const [searchType, setSearchType] = useState("users");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [storyId, setStoryId] = useState(null);
  const [storyModalOpen, setStoryModalOpen] = useState(false);
  const [loading, setloading] = useState(false);

  console.log("searchType", searchType);

  const toggleStoryModal = () => {
    setStoryModalOpen(!storyModalOpen);
  };

  useEffect(() => {
    if (token && userName) {
      setloading(true);
      const getUsers = async () => {
        try {
          const response = await ApiCall({
            params: {},
            route: `user/userSearch/${searchType}/${userName}`,
            verb: "get",
            baseurl: true,
            token: token,
          });

          if (response.status === 200) {
            setSearchedUsers(response.response);
          }
        } catch (error) {
          setloading(false);
        } finally {
          setloading(false);
        }
      };

      getUsers();
    }
  }, [token, userName, searchType]);

  useEffect(() => {
    const USER_ID = localStorage.getItem("userId");
    if (!USER_ID) {
      setLoginModal(!loginModal);
    }
  }, []);

  const handleTabChange = (key) => {
    // Map the tab key to the corresponding search type
    const tabKeyToSearchTypeMap = {
      1: "users",
      2: "hashtags",
      3: "professionals",
    };

    setSearchType(tabKeyToSearchTypeMap[key]);
  };

  const renderTabContent = (searchType) => {
    switch (searchType) {
      case "users":
        return (
          <div>
            {searchedUsers &&
              searchedUsers?.map((user) => (
                <Link
                  to={`/userProfile?userId=${user._id}`}
                  className={styles.usersWrapper}
                  key={user._id}
                >
                  <img
                    className={styles.profilepic}
                    src={user.profilePicture?.url || userAvatar}
                    alt="User Profile"
                  />
                  <div className={styles.content}>
                    <h5 className={styles.name}>{user?.username}</h5>
                    <p className={styles.fullname}>{user?.fullname}</p>
                  </div>
                </Link>
              ))}
          </div>
        );
      case "hashtags":
        return (
          <div>
            {searchedUsers &&
              searchedUsers?.map((hashtag) => (
                <Link
                  to={`/stories/${hashtag._id}`}
                  className={styles.usersWrapper}
                  key={hashtag._id}
                >
                  <h4 className={styles.hashtag}>#</h4>
                  <div className={styles.content}>
                    <h5 className={styles.name}>{hashtag?.title}</h5>
                  </div>
                </Link>
              ))}
          </div>
        );
      case "professionals":
        // Render content for professionnels
        return (
          <div>
            {searchedUsers &&
              searchedUsers?.map((professional) => (
                <Link
                  to={`/professional-profile?professionalId=${professional?.user?._id}`}
                  className={styles.usersWrapper}
                  key={professional._id}
                >
                  <img
                    className={styles.profilepic}
                    src={professional?.user?.profilePicture?.url || userAvatar}
                    alt="User Profile"
                  />
                  <div className={styles.content}>
                    <h5 className={styles.name}>{professional?.fullName}</h5>
                  </div>
                </Link>
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {token && (
        <Spin spinning={loading}>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="Comptes" key="1">
              {renderTabContent("users")}
            </TabPane>
            <TabPane tab="Hashtags" key="2">
              {renderTabContent("hashtags")}
            </TabPane>
            <TabPane tab="Professionnels" key="3">
              {renderTabContent("professionals")}
            </TabPane>
          </Tabs>
        </Spin>
      )}

      {/* Modal for single story show */}

      <StoryModal
        toggleState={storyModalOpen}
        handleToggle={toggleStoryModal}
        storyId={storyId}
      />
    </>
  );
};

export default SearchUser;
