import React from "react";
import styles from "../../assets/styles/resources/Resources.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  arrowBackGray,
  check2,
} from "../../assets";

import { resources } from "./resourcesData";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../context/AuthContext";

const ResourceDetails3 = () => {
  const { allThemes, activeTheme } = useAuth();
  let { typeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  const applicationsList = searchParams.get("applications");
  const reasonsList = searchParams.get("reasons");
  const stretchApplicationsList = searchParams.get("stretchApplications");
  const stretchReasonsList = searchParams.get("stretchReasons");
  localStorage.setItem("resourceId", resourceId);

  console.log("applicationsList", applicationsList);
  console.log("reasonsList", reasonsList);

  const selectedResource = resources
    .find((resource) => resource.id === typeId)
    ?.resourceList?.find((resource) => resource.id === resourceId);

  console.log("selectedResource3", selectedResource);

  return (
    <div className={styles.resourcesContainer}>
      <div
        className={styles.backButton}
        onClick={() => {
          navigate("/ressources");
        }}
      >
        <ReactSVG src={arrowBackGray} />
        <p>retour</p>
      </div>
      <div className={styles.topContent}>
        <h2 className={styles.resourceDetailHeading}>Autres ressources</h2>
        {/* <h1 className={styles.resourceDetailHeading2}>
          {selectedResource?.titleInside}
        </h1> */}

        {resourceId === "17" && reasonsList && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrapp}>
              <ReactSVG src={selectedResource?.topIcon1} />
            </div>
          </div>
        )}

        {resourceId === "18"  && reasonsList && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrappPng}>
              <img src={selectedResource?.topIcon} />
            </div>
          </div>
        )}

        {resourceId === "18"  && stretchReasonsList && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrappPng}>
              <img src={selectedResource?.topIcon1} />
            </div>
          </div>
        )}

        {resourceId === "20" && (
          <div className={styles.topIconClass}>
            <div className={styles.iconWrappPng}>
              <img src={selectedResource?.topIcon} />
            </div>
          </div>
        )}

        {selectedResource?.description !== "Lire la suite" && (
          <p>{selectedResource?.description}</p>
        )}

        {selectedResource?.stretchReasonsListDescription &&
          stretchReasonsList && (
            <p>{selectedResource?.stretchReasonsListDescription}</p>
          )}

        {selectedResource?.reasonsListDescription && reasonsList && (
          <p>{selectedResource?.reasonsListDescription}</p>
        )}
      </div>

      <p className="pt-5"></p>

      {/* Resource list */}

      <div className={styles.resourceListWrapper}>
        {selectedResource?.internalList?.map((listItem) => {
          console.log("listItem", listItem);
          return (
            <div className={styles.resourceDetail} key={listItem.id}>
              <div className={styles.resource3listHeadingWrapper}>
                <div className={styles.top}>
                  <h4 className={styles.resourceDetailHeading}>
                    {listItem.name}
                  </h4>
                </div>

                <div className={styles.bottom}>
                  {listItem.subscription && (
                    <p className={styles.description}>
                      <span>
                        <img src={check2} />
                      </span>
                      {listItem.subscription}
                    </p>
                  )}

                  {listItem.description && (
                    <p className={styles.description}>
                      <span>
                        <img src={check2} />
                      </span>
                      {listItem.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Reasons list and applications list for point 16 */}

      {reasonsList && (
        <div className={styles.resourceListWrapper}>
          {selectedResource?.reasonsList?.map((listItem) => {
            return (
              <div className={styles.resourceDetail} key={listItem.id}>
                <div className={styles.resource3listHeadingWrapper}>
                  <div className={styles.top}>
                    <h4 className={styles.resourceDetailHeading}>
                      {listItem.name}
                    </h4>
                  </div>
                  <div className={styles.bottom}>
                    {listItem.description && (
                      <p className={styles.description}>
                        {listItem.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {applicationsList && (
        <div className={styles.resourceListWrapper}>
          {selectedResource?.applicationsList?.map((listItem) => {
            return (
              <div className={styles.resourceDetail} key={listItem.id}>
                <div className={styles.resource3listHeadingWrapper}>
                  <div className={styles.top}>
                    <h4 className={styles.resourceDetailHeading}>
                      {listItem.name}
                    </h4>
                  </div>
                  <div className={styles.bottom}>
                    {listItem.description && (
                      <p className={styles.description}>
                        {listItem.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Stretch Reasons list and applications list for point 16 */}

      {stretchApplicationsList && (
        <div className={styles.resourceListWrapper}>
          {selectedResource?.stretchApplicationsList?.map((listItem) => {
            return (
              <div className={styles.resourceDetail} key={listItem.id}>
                <div className={styles.resource3listHeadingWrapper}>
                  <div className={styles.top}>
                    <h4 className={styles.resourceDetailHeading}>
                      {listItem.name}
                    </h4>
                  </div>
                  <div className={styles.bottom}>
                    {listItem.description && (
                      <p className={styles.description}>
                        {listItem.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {stretchReasonsList && (
        <div className={styles.resourceListWrapper}>
          {selectedResource?.stretchReasonsList?.map((listItem) => {
            return (
              <div className={styles.resourceDetail} key={listItem.id}>
                <div className={styles.resource3listHeadingWrapper}>
                  <div className={styles.top}>
                    <h4 className={styles.resourceDetailHeading}>
                      {listItem.name}
                    </h4>
                  </div>
                  <div className={styles.bottom}>
                    {listItem.description && (
                      <p className={styles.description}>
                        {listItem.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {reasonsList && <p>{selectedResource?.reasonsConsclusions}</p>}
      {stretchReasonsList && (
        <p>{selectedResource?.stretchReasonsConsclusions}</p>
      )}
    </div>
  );
};

export default ResourceDetails3;
