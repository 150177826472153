import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../../assets/styles/DisplayStories.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu, Spin } from "antd";
import Slider from "react-slick";
import {
  arrowDownBlack,
  beStrongHug,
  beforeAfter,
  burgerCross,
  commentsIcon,
  favorite,
  goodWriterGold,
  like,
  meToo,
  report,
  share,
  wow,
} from "../../assets";
import {
  faCaretRight,
  faEllipsisVertical,
  faStar,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/AuthContext";
import { getImageByValue } from "../../components/stories/GetResourceIcons";
import { ApiCall } from "../../config/apiCall";
import StoryLoader from "../../components/UI/StoryLoader";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

const currentDate = new Date();
const monthNames = [
  "jan",
  "fév",
  "mar",
  "avril",
  "mai",
  "juin",
  "juil",
  "août",
  "sept",
  "oct",
  "nov",
  "déc",
];
const reactionsArray = [
  {
    emogi: like,
    value: "like",
    selected: false,
    id: 1,
    height: 25,
    width: 25,
    title: "J’aime !",
    length: 8,
  },
  {
    emogi: meToo,
    value: "meToo",
    selected: false,
    id: 2,
    height: 25,
    width: 25,
    title: "J’ai vécu la même (ou presque) !",
    length: 32,
  },
  {
    emogi: beStrongHug,
    value: "beStrongHug",
    selected: false,
    id: 3,
    height: 27,
    width: 25,
    title: "Ensemble !",
    length: 10,
  },
  {
    emogi: wow,
    value: "wow",
    selected: false,
    id: 4,
    height: 30,
    width: 25,
    title: "Wow !",
    length: 5,
  },
  {
    emogi: goodWriterGold,
    value: "goodWriterGold",
    selected: false,
    id: 5,
    height: 25,
    width: 25,
    title: "Belle plume !",
    length: 13,
  },
];

const MySubscriptionsStoriesModal = ({
  toggleState,
  handleToggle,
  selectedSubscriptionIndex,
  selectedSubscriptionCircle,
  getSubscriptionsStatus,
}) => {
  const { token, userId, storyCount, userInfo } = useAuth();
  const sliderRef = useRef(null);
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [isCommentsOpen, setisCommentsOpen] = useState([]);
  const [allComments, setallComments] = useState("");
  const [showCommentsIndex, setShowCommentsIndex] = useState(-1);
  const [reactions, setReactions] = useState({});
  const [stories, setstories] = useState([]);
  const [subscriptionsStoriesLoading, setSubscriptionsStoriesLoading] =
    useState(false);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [watchedStoriesId, setWatchedStoriesId] = useState([]);

  useEffect(() => {
    const currentId = stories?.[activeSlideIndex]?._id;
    const currentWatchedStoriesIds = [
      ...new Set(
        currentId ? [...watchedStoriesId, currentId] : watchedStoriesId
      ),
    ];

    console.log("currentWatchedStoriesIds", currentWatchedStoriesIds);
    setWatchedStoriesId(currentWatchedStoriesIds);
    if (currentWatchedStoriesIds?.length === stories?.length) {
      console.log(
        "currentWatchedStoriesIds?.length",
        currentWatchedStoriesIds?.length
      );
      console.log("stories?.length", stories?.length);
      // handle submit all
      // submitWatchedStories();
    }
  }, [activeSlideIndex]);

  const submitWatchedStories = async () => {
    try {
      const response = await ApiCall({
        params: { watchedStoriesId },
        route: `story/watched-status-update/${selectedSubscriptionIndex}`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
      }
      console.log("SubscriptionUserStories", response);
    } catch (error) {
    } finally {
    }
  };

  console.log("watchedStoriesId", watchedStoriesId);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesToShow: 3,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    width: 20,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, infinite: false },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, infinite: false },
      },
    ],
    beforeChange: (current, next) => setActiveSlideIndex(next),
  };

  useEffect(() => {
    if (
      token &&
      selectedSubscriptionIndex !== null &&
      selectedSubscriptionIndex !== undefined
    ) {
      getStories();
    }
  }, [selectedSubscriptionIndex]);

  const getStories = async () => {
    setSubscriptionsStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: { seen: selectedSubscriptionCircle },
        route: `story/story-status/${selectedSubscriptionIndex}&${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setstories(response.response);
        setWatchedStoriesId([response.response?.[0]?._id]);
      }
      console.log("SubscriptionUserStories", response);
    } catch (error) {
      setSubscriptionsStoriesLoading(false);
    } finally {
      setSubscriptionsStoriesLoading(false);
    }
  };

  const items = [
    // {
    //   label: "Copy link",
    //   key: "0",
    //   onClick: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     navigator.clipboard?.writeText(storyLink);
    //     message.success("Story link copied to clipboard!");
    //   },
    //   render: (index) => (
    //     <>
    //       <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
    //     </>
    //   ),
    // },
    // {
    //   label: "Facebook",
    //   key: "1",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <FacebookShareButton
    //         url={storyLink}
    //         quote={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
    //           </svg>
    //         </a>
    //       </FacebookShareButton>
    //     );
    //   },
    // },
    // {
    //   label: "WhatsApp",
    //   key: "2",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <WhatsappShareButton
    //         url={storyLink}
    //         title={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 448 512"
    //           >
    //             <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
    //           </svg>
    //         </a>
    //       </WhatsappShareButton>
    //     );
    //   },
    // },
    // {
    //   label: "Twitter",
    //   key: "3",
    //   render: (index) => {
    //     const selectedStory = stories[index];
    //     const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
    //     return (
    //       <TwitterShareButton
    //         url={storyLink}
    //         title={stories[index]?.title}
    //       >
    //         <a className={styles.svgLink}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="1em"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
    //           </svg>
    //         </a>
    //       </TwitterShareButton>
    //     );
    //   },
    // },
  ];

  const storyOptions = [
    {
      label: "Modifier ma publication",
      key: "0",
      onClick: (story) => {
        // setStoryDataForUpdate(story);
        // toggleSubmitStoryModal();
        // message.success("Modifier Ma Publication");
      },
    },
    {
      label: "Supprimer ma publication",
      key: "1",
      onClick: (story) => {
        // handleDeleteStory(story._id);
        // message.success("Publication deleted successfully");
      },
    },
  ];

  const totalReactionsOnPost = [
    {
      key: "1",
      render: (story) => {
        return (
          <>
            <img src={like} onContextMenu={(e) => e.preventDefault()} />
            <p>{story?.smiley1_count}</p>
          </>
        );
      },
    },
    {
      key: "2",
      render: (story) => (
        <>
          <img src={meToo} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley2_count}</p>
        </>
      ),
    },
    {
      key: "3",
      render: (story) => (
        <>
          <img src={beStrongHug} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley3_count}</p>
        </>
      ),
    },
    {
      key: "4",
      render: (story) => (
        <>
          <img src={wow} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley4_count}</p>
        </>
      ),
    },
    {
      key: "5",
      render: (story) => (
        <>
          <img src={goodWriterGold} onContextMenu={(e) => e.preventDefault()} />
          <p>{story?.smiley5_count}</p>
        </>
      ),
    },
  ];

  const conditionallyRenderedItems = (story) => {
    const filteredItems = totalReactionsOnPost.filter(
      (item) => story[`smiley${item.key}_count`] > 0
    );

    return filteredItems.map((item) => (
      <React.Fragment key={item.key}>{item.render(story)}</React.Fragment>
    ));
  };

  // For less/more story description
  const [showMoreDescription, setShowMoreDescription] = useState(
    Array(stories?.length).fill(false)
  );

  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Go to the next slide
    }
  };

  const handlePrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Go to the previous slide
    }
  };

  const showMore = true;
  const MAX_LENGTH = 100;
  let selectedIndex = -1;

  return (
    <div>
      <Modal
        isOpen={toggleState}
        centered
        style={{ maxWidth: "1400px", width: "100%" }}
        modalClassName="mySubscriptionsStories"
        toggle={handleToggle}
        backdrop="static"
      >
        <ModalBody>
          <div className={styles.sliderWrapper}>
            <Spin spinning={subscriptionsStoriesLoading}>
              <div className={styles.arrowsWrapper}>
                <div className={styles.prevButton} onClick={handlePrevSlide}>
                  <ReactSVG src={arrowDownBlack} />
                </div>
                <div className={styles.nextButton} onClick={handleNextSlide}>
                  <ReactSVG src={arrowDownBlack} />
                </div>
              </div>

              <div className={styles.statusCircles}>
                {stories.map((story, index) => (
                  <div
                    key={index}
                    className={`${watchedStoriesId?.includes(story?._id) ||
                        story?.statusSeen
                        ? styles.circleFilled
                        : styles.circleBordered
                      }`}
                  />
                ))}
              </div>

              {!subscriptionsStoriesLoading && (
                <Slider {...sliderSettings} ref={sliderRef}>
                  {stories?.map((story, storyIndex) => {
                    const showMore = showMoreDescription[storyIndex];
                    const showComments = showCommentsIndex === storyIndex;

                    selectedIndex = reactions[story._id];

                    const imageUrl = getImageByValue(
                      story?.ressource?.complete?.resourcetype
                    );

                    let DateFormate = "";
                    const date = new Date(story?.createdAt);
                    const currentDate = new Date();
                    if (
                      date.getFullYear() === currentDate.getFullYear() &&
                      date.getMonth() === currentDate.getMonth() &&
                      date.getDate() === currentDate.getDate()
                    ) {
                      const timeDifferenceInMilliseconds = currentDate - date;
                      const timeDifferenceInMinutes = Math.floor(
                        timeDifferenceInMilliseconds / (1000 * 60)
                      );
                      if (timeDifferenceInMinutes < 60) {
                        DateFormate = `il y a ${timeDifferenceInMinutes} min`;
                      } else {
                        const hours = Math.floor(timeDifferenceInMinutes / 60);
                        DateFormate = `il y a ${hours} h`;
                      }
                    } else if (
                      date.getFullYear() === currentDate.getFullYear()
                    ) {
                      DateFormate = `${date.getDate()} ${monthNames[date.getMonth() - 1]
                        }`;
                    } else {
                      DateFormate = `${date.getDate()} ${monthNames[date.getMonth() - 1]
                        } ${date.getFullYear()} `;
                    }

                    const userProfileLink =
                      story.writter?._id === userId
                        ? `/profile?userId=${story.writter?._id}`
                        : `/userProfile?userId=${story.writter?._id}`;

                    return (
                      <>
                        <div
                          className={`${styles.storiesSliderContainer} ${activeSlideIndex == storyIndex - 1 ||
                              stories?.length == 1
                              ? styles.activeSlide
                              : ""
                            } `}
                        >
                          <div key={storyIndex}>
                            <div className={styles.subscriptionStoryHeader}>
                              <button
                                aria-label="Close"
                                className=" close"
                                type="button"
                                onClick={() => {
                                  handleToggle();
                                  submitWatchedStories();
                                  getSubscriptionsStatus();
                                }}
                              >
                                <img
                                  src={burgerCross}
                                  onContextMenu={(e) => e.preventDefault()}
                                ></img>
                              </button>
                            </div>

                            <div className={`${styles.storyOuterContainer}`}>
                              <div className={styles.DisplayStoriesContainer}>
                                <div className={styles.storyHeader}>
                                  <div className={styles.storyHeaderLeft}>
                                    <p className={styles.theme}>
                                      {story?.theme?.name}
                                    </p>
                                    <div className={styles.date}>
                                      {story?.isAnonym ? (
                                        <p className={`${styles.userAnonym}`}>
                                          Par **** ,
                                        </p>
                                      ) : (
                                        <p
                                          className={
                                            !story?.writter?.isAdmin
                                              ? styles.userName
                                              : styles.userAnonym
                                          }
                                        // onClick={() => {
                                        //   if (!story?.writter?.isAdmin)
                                        //     handleUserClick(story?.writter?._id);
                                        // }}
                                        >
                                          Par{" "}
                                          <Link
                                            to={userProfileLink}
                                            className={styles.userNameLink}
                                          >
                                            {story.writter?.username}
                                          </Link>
                                        </p>
                                      )}

                                      <p className={`${styles.dateP} ps-1`}>
                                        {" "}
                                        12/23/23
                                      </p>
                                    </div>
                                  </div>
                                  <div className={styles.storyHeaderRight}>
                                    <h2 className={styles.heading}>
                                      {story?.title}
                                    </h2>
                                  </div>
                                </div>
                                <div className={`${styles.storyBody} `}>
                                  {showMore ? (
                                    <div className={styles.storyContent}>
                                      <p>
                                        {story?.content}
                                        {story?.content.length > MAX_LENGTH && (
                                          <span
                                            className={styles.contentBtn}
                                          // onClick={() => toggleShowMore(selectedSubscriptionIndex)}
                                          >
                                            Replier ▲
                                          </span>
                                        )}{" "}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className={styles.storyContent}>
                                      <p>
                                        {story?.content.slice(0, MAX_LENGTH)}
                                        {story?.content.length > MAX_LENGTH &&
                                          "..."}
                                        {story?.content.length > MAX_LENGTH && (
                                          <span
                                            className={styles.contentBtn}
                                          // onClick={() => toggleShowMore(selectedSubscriptionIndex)}
                                          >
                                            Lire la suite
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  )}

                                  {/* first storyline  */}
                                  {!story?.professionalConsulted?.fullName &&
                                    !story?.ressource?.complete?.resourcetype &&
                                    !(
                                      story?.physicalTransformation
                                        ?.beforePictureUrl ||
                                      story?.physicalTransformation
                                        ?.afterPictureUrl
                                    ) ? (
                                    <div className="py-0"></div>
                                  ) : (
                                    <hr />
                                  )}

                                  <div
                                    className={
                                      styles.prefessionalResourcesWrapper
                                    }
                                  >
                                    {story?.professionalConsulted?.fullName && (
                                      <div>
                                        <p>Professionnel</p>
                                        <h6>
                                          <span className="font-bold text-black">
                                            {
                                              story?.professionalConsulted
                                                ?.fullName
                                            }
                                          </span>
                                        </h6>
                                      </div>
                                    )}

                                    {story?.ressource?.complete
                                      ?.resourcetype && (
                                        <div
                                        // onClick={() => handleResourceModal(selectedSubscriptionIndex)}
                                        >
                                          <p className={styles.ressource}>
                                            Ressource
                                          </p>
                                          <img
                                            src={imageUrl}
                                            alt="img"
                                            className={styles.icon}
                                            width={40}
                                            height={40}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </div>
                                      )}
                                    {(story?.physicalTransformation
                                      ?.beforePictureUrl ||
                                      story?.physicalTransformation
                                        ?.afterPictureUrl) && (
                                        <div
                                        // onClick={() => handleBeforeAfterModal(selectedSubscriptionIndex)}
                                        >
                                          <p>Avant /Après</p>
                                          <img
                                            src={beforeAfter}
                                            alt="logo"
                                            className={styles.icon}
                                            width={35}
                                            height={40}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </div>
                                      )}
                                  </div>

                                  {/* second storyline */}
                                  {!story?.professionalConsulted?.fullName &&
                                    !story?.ressource?.complete?.resourcetype &&
                                    !(
                                      story?.physicalTransformation
                                        ?.beforePictureUrl ||
                                      story?.physicalTransformation
                                        ?.afterPictureUrl
                                    ) ? (
                                    <div className="py-0"></div>
                                  ) : (
                                    <hr />
                                  )}

                                  <div className={styles.details}>
                                    <div className={styles.icons}>
                                      <div
                                        className={styles.favStarIcon}
                                      // onClick={() => handleFavoriteClick(story?._id)}
                                      >
                                        {favoriteStatus[story?._id] ||
                                          story?.isFavourite ? (
                                          <div className={styles.icon}>
                                            <FontAwesomeIcon
                                              icon={faStar}
                                              style={{ color: "#F5D003" }}
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src={favorite}
                                            alt="logo"
                                            className={styles.icon}
                                            width={22}
                                            height={20}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        )}
                                      </div>

                                      {isCommentsOpen?.includes(
                                        selectedSubscriptionIndex
                                      ) ? (
                                        <div
                                          className={styles.commentsWrapper}
                                        // onClick={() => {
                                        //   if (token) {
                                        //     setShowCommentsIndex("abc");
                                        //     setisCommentsOpen((prevState) =>
                                        //       prevState.filter((item) => item !== selectedSubscriptionIndex)
                                        //     );
                                        //   }
                                        // }}
                                        >
                                          <img
                                            src={commentsIcon}
                                            className={styles.icon}
                                            alt="comments"
                                            width={20}
                                            height={20}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                          <p>
                                            (
                                            {allComments?.data?.list[0]
                                              ?.storyId === story?._id
                                              ? allComments?.data?.list.length
                                              : story?.comments_count}
                                            )
                                          </p>
                                        </div>
                                      ) : (
                                        <div
                                          className={styles.commentsWrapper}
                                        // onClick={() => {
                                        //   getStoryMostreleventComments(story?._id);
                                        //   if (token) {
                                        //     setShowCommentsIndex(selectedSubscriptionIndex);
                                        //     setisCommentsOpen((prevState) => [selectedSubscriptionIndex]);
                                        //   }
                                        // }}
                                        >
                                          <img
                                            src={commentsIcon}
                                            className={styles.icon}
                                            alt="comments"
                                            width={20}
                                            height={20}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                          <p>
                                            (
                                            {allComments?.data?.list[0]
                                              ?.storyId === story?._id
                                              ? allComments?.data?.list.length
                                              : story?.comments_count}
                                            )
                                          </p>
                                        </div>
                                      )}

                                      <Dropdown
                                        overlay={
                                          <Menu className={styles.dropdownMenu}>
                                            {items.map((item) => (
                                              <Menu.Item
                                                key={item.key}
                                                onClick={() =>
                                                  item.onClick &&
                                                  item.onClick(
                                                    selectedSubscriptionIndex
                                                  )
                                                }
                                              >
                                                {item.render
                                                  ? item.render(
                                                    selectedSubscriptionIndex
                                                  )
                                                  : item.label}
                                              </Menu.Item>
                                            ))}
                                          </Menu>
                                        }
                                        trigger={["click"]}
                                      >
                                        <a onClick={(e) => e.preventDefault()}>
                                          <img
                                            src={share}
                                            alt="logo"
                                            className={styles.icon}
                                            width={22}
                                            height={22}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </a>
                                      </Dropdown>

                                      {story?.writter?._id !== userId && (
                                        <div
                                        // onClick={() => handleReportStory(story?._id)}
                                        >
                                          <img
                                            src={report}
                                            alt="logo"
                                            className={styles.icon}
                                            width={20}
                                            height={20}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        </div>
                                      )}

                                      {userInfo?.isAdmin &&
                                        story?.writter?._id !== userId && (
                                          <>
                                            <div
                                            // onClick={() => handleDeleteStory(story?._id)}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrashCan}
                                                style={{
                                                  color: "gray",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}

                                      {story?.writter?._id === userId && (
                                        <Dropdown
                                          overlay={
                                            <Menu>
                                              {storyOptions.map((item) => (
                                                <Menu.Item
                                                  key={item.key}
                                                // onClick={() =>
                                                //   item.onClick && item.onClick(story)
                                                // }
                                                >
                                                  {item.render
                                                    ? item.render(
                                                      selectedSubscriptionIndex
                                                    )
                                                    : item.label}
                                                </Menu.Item>
                                              ))}
                                            </Menu>
                                          }
                                          trigger={["click"]}
                                        >
                                          <a
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <FontAwesomeIcon
                                              icon={faEllipsisVertical}
                                              style={{
                                                color: "#5c5c5c",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </a>
                                        </Dropdown>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.reactionsWrapper}>
                                  {reactionsArray.map((reaction, index) => {
                                    return (
                                      <>
                                        <div
                                          className={styles.tooltipContainer}
                                        >
                                          <button
                                            // onClick={() => {
                                            //   handleReactionClick(
                                            //     story?._id,
                                            //     index,
                                            //     selectedSubscriptionIndex,
                                            //     story,
                                            //     selectedIndex === index ||
                                            //       (!selectedIndex &&
                                            //         story?.reactionId - 1 === index)
                                            //   );
                                            // }}
                                            className={` ${story?.reactionId - 1 === index
                                                ? styles.activeReaction
                                                : ""
                                              } ${styles.reaction}`}
                                            data-tooltip={reaction.title}
                                          >
                                            <img
                                              src={reaction.emogi}
                                              alt="like"
                                              width={reaction.width}
                                              height={reaction.height}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          </button>
                                          <span className={styles.tooltipText}>
                                            {reaction.title}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })}

                                  {story?.reaction?.length > 0 && (
                                    <>
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            {conditionallyRenderedItems(
                                              story
                                            ).map((item) => (
                                              <Menu.Item key={item.key}>
                                                <div
                                                  className={
                                                    styles.totalReactionsWrapper
                                                  }
                                                >
                                                  {item}
                                                </div>
                                              </Menu.Item>
                                            ))}
                                          </Menu>
                                        }
                                        trigger={["click"]}
                                        placement="bottomLeft"
                                      >
                                        <button>
                                          <span>
                                            {story?.reaction?.length}
                                            <FontAwesomeIcon
                                              icon={faCaretRight}
                                              className={styles.fontawesome}
                                            />
                                          </span>
                                        </button>
                                      </Dropdown>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Slider>
              )}
            </Spin>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MySubscriptionsStoriesModal;
