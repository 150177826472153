import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import styles from "./PreviewImage.module.scss";

import ButtonFilled from "../../components/buttonFilled";
import { arrowDownBlack, burgerCross } from "../../assets";
import { ReactSVG } from "react-svg";

const PreviewImage = ({
  toggleState,
  handleToggle,
  data,
  currentImageIndex,
}) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  // Find the object in the array that matches the currentImageIndex
  const matchingData = data?.transformationData?.find(
    (item, index) => index === currentImageIndex
  );

  console.log("matchingData", matchingData);
  const date = new Date(matchingData?.createdAt);

  const now = new Date(); // Current date/time

  // Set the time to 12 AM for both dates
  date.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);

  const timeDiff = now.getTime() - date.getTime(); // Time difference in milliseconds

  // Calculate the difference in days, weeks, and months
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const weeksDiff = Math.floor(daysDiff / 7);
  const monthsDiff = Math.floor(daysDiff / 30);

  let formattedTime;

  if (monthsDiff >= 1) {
    formattedTime = `il y a ${monthsDiff} mois${monthsDiff > 1 ? "s" : ""}`;
  } else if (weeksDiff >= 1) {
    formattedTime = `il y a ${weeksDiff} semaine${weeksDiff > 1 ? "s" : ""}`;
  } else if (daysDiff >= 1) {
    formattedTime = `il y a ${daysDiff} jour${daysDiff > 1 ? "s" : ""}`;
  } else {
    formattedTime = "Aujourd'hui";
  }

  const handleNextSlide = () => {};

  const handlePrevSlide = () => {};

  const formatPrice = (value) => {
    // Remove any non-numeric characters
    const numericValue = value?.replace(/[^0-9]/g, "");

    // Format with spacing
    const formattedValue = numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return formattedValue;
  };

  return (
    <div>
      <Modal
        isOpen={toggleState}
        centered
        style={{ maxWidth: "1400px", width: "100%" }}
        modalClassName="beforeAfterModal"
        toggle={handleToggle}
      >
        <ModalBody>
          <div className={styles.header}>
            <div>
              <h2 className={styles.heading}>{matchingData?.treatment}</h2>
              <p className={styles.details}>
                {formatPrice(matchingData?.price)} €
              </p>
            </div>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              {/* <span className={styles.close} aria-hidden={true}>
                  ×
                </span> */}
              <img
                src={burgerCross}
                onContextMenu={(e) => e.preventDefault()}
              ></img>
            </button>
          </div>

          <div className={styles.imagesWrapper}>
            <div className={styles.leftSide}>
              {matchingData?.beforePictureUrl ? (
                <img
                  src={matchingData?.beforePictureUrl}
                  onContextMenu={(e) => e.preventDefault()}
                ></img>
              ) : (
                <p className="text-black">No Image</p>
              )}
            </div>
            <div className={styles.rightSide}>
              {matchingData?.afterPictureUrl ? (
                <img
                  src={matchingData?.afterPictureUrl}
                  onContextMenu={(e) => e.preventDefault()}
                ></img>
              ) : (
                <p className="text-black">No Image</p>
              )}
            </div>

            <div className={styles.content}>
              <div className={styles.contentLeft}>
                <div className={styles.userInfo}>
                  {matchingData?.isAnonym ? (
                    <h6>
                      Par {matchingData?.user?.fullname?.charAt(0)}
                      ****.
                    </h6>
                  ) : (
                    <h6>Par {matchingData?.user?.fullname}.</h6>
                  )}

                  <p>{formattedTime}</p>
                </div>

                <div>
                  {matchingData?.isSatisfied == true ? (
                    <div className={styles.satisfaitBtn}>Satisfait</div>
                  ) : (
                    <div className={styles.pasSatisfaitBtn}>InSatisfait</div>
                  )}
                </div>
              </div>
              <div
                className={styles.contentRight}
                onClick={() => {
                  navigate(`/stories/${matchingData?.story}`);
                }}
              >
                <ButtonFilled title="Voir son histoire" textColr="white" />
              </div>
            </div>
          </div>

          <div className={styles.mobileViewBtn}>
            <div className={styles.prevButton} onClick={handlePrevSlide}>
              <ReactSVG src={arrowDownBlack} />
            </div>

            <ButtonFilled
              handleClick={() => {
                navigate(`/stories/${matchingData?.story}`);
              }}
              title="Voir son histoire"
              textColr="white"
            />

            <div className={styles.nextButton} onClick={handleNextSlide}>
              <ReactSVG src={arrowDownBlack} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PreviewImage;
