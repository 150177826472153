import React, { useState, useEffect } from "react";
import styles from "./Subscription.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import DotsLoader from "../../components/UI/DotsLoader";
import { message } from "antd";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";

const Subscription = () => {
  const { token } = useAuth();
  const stripe = useStripe();
  const elements = useElements();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaymentSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setLoading(false);
      console.log("error: ", error);
    } else {
      // Payment method created successfully
      sendPaymentDetails(paymentMethod?.id);
    }
  };

  const sendPaymentDetails = async (paymentMethodId) => {
    try {
      const response = await ApiCall({
        params: {},
        route: `planProfessional/confirm-professional-payment?paymentMethodId=${paymentMethodId}`,
        verb: "post",
        token: token,
        baseurl: true,
      });

      if (response.status === 200) {
        setLoading(false);
        setModalOpen(false);
      }
      console.error(response.response.message);
      return response;
    } catch (error) {
      console.error(error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if(cookies == null) {
    setModalOpen(true);
    // }
  }, []);

  const updatingSubscriptioninDB = (details) => {
    console.log(details);
  };
  return (
    <div>
      <Modal
        centered
        keyboard={false}
        backdrop="static"
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
      >
        <ModalBody className="Dashboard-policies-popup-body">
          <h5
            style={{
              textTransform: "uppercase",
              fontWeight: "500",
              paddingBottom: "20px",
              color: "#00565b",
            }}
          >
            Please Pay first
          </h5>

          <form onSubmit={handlePaymentSubmit}>
            <CardElement className={styles["card-input"]} />
            <br />

            {loading ? (
              <div className={styles.loaderWrapper}>
                <DotsLoader color="white" />
              </div>
            ) : (
              <button
                type="submit"
                disabled={!stripe}
                className={styles["pay-button"]}
              >
                Pay Now
              </button>
            )}
          </form>
          {/* <PayPalScriptProvider
            options={{
              "client-id":
                "ASuzX8fAuHKJpMA52fPGIqLTGa_ZRAWszSXHUqD_lvI90OahaI9nJBb7MvPHz-rG8TUfKJcZF_OB33Co",
              vault: true,
              intent: "capture",
            }}
          >
            <PayPalButtons
              style={{ layout: "vertical", label: "subscribe" }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: "P-5J809714700857845MOCKV4Q",
                });
              }}
              onApprove={(data, actions) => {
                return actions.subscription.get().then((details) => {
                  setShowpayment(false);
                  updatingSubscriptioninDB(details);
                });
              }}
            />
          </PayPalScriptProvider> */}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Subscription;
