import React, { useState, useRef } from "react";
import styles from "../../../assets/styles/signUp/step1.module.scss";
import { Link } from "react-router-dom";
import { calenderIcon, eyeIcon, eyeSlash } from "../../../assets";
import { ApiCall } from "../../../config/apiCall";
import { toast } from "react-toastify";
import { message } from "antd";

const today = new Date().toISOString().split("T")[0];

const Step1 = ({ userDataFunc, userData, initialState, setUserData }) => {
  const inputRef = useRef(null);
  const [formErrors, setFormErrors] = useState(initialState);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isEmailEmpty, setisEmailEmpty] = useState(false);
  const [isEmailLoading, setisEmailLoading] = useState(false);
  const [maxCharacterError, setMaxCharacterError] = useState(false);
  const [FullNamemaxCharacterError, setFullNameMaxCharacterError] =
    useState(false);

  const MAX_CHARACTERS = 30;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      if (value.length <= MAX_CHARACTERS) {
        setUserData((prevState) => ({
          ...prevState,
          [name]: value?.toLowerCase(),
        }));
        setMaxCharacterError(false);
      } else {
        setMaxCharacterError(true);
      }
    } else if (name === "fullname") {
      if (value.length <= MAX_CHARACTERS) {
        setUserData((prevState) => ({ ...prevState, [name]: value }));
        setFullNameMaxCharacterError(false);
      } else {
        setFullNameMaxCharacterError(true);
      }
    } else {
      setUserData((prevState) => ({ ...prevState, [name]: value }));
    }
    if (name === "email") {
      setisEmailLoading(true);
    }
  };

  const renderError = (fieldName) => {
    if (formErrors[fieldName]) {
      return <p className={` ${styles.errorMsg} `}>{formErrors[fieldName]}</p>;
    }
    return null;
  };

  const checkEmail = async () => {
    setisEmailLoading(true);
    if (isEmailEmpty) {
      return false;
    }
    try {
      const response = await ApiCall({
        params: {
          email: userData?.email,
        },
        route: `auth/email-verification`,
        verb: "get",
        baseurl: true,
      });
      if (response.status === 200) {
        setIsEmailExist(false);
        setisEmailLoading(false);
        return true;
      } else {
        setisEmailLoading(false);
        setIsEmailExist(true);
        message.error(response.response.message);
        message.error(response.response.error?.message);
        return false;
      }
    } catch (error) {
      setisEmailLoading(false);
      message.error(error);
      return false;
    } finally {
      setisEmailLoading(false);
      // return false;
    }
  };

  const handleSubmitStep1 = async (event) => {
    event.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernamePattern = /^[a-z_][a-z0-9_.]*$/;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    let errors = {};
    let hasErrors = false;

    const emailResponse = await checkEmail();
    const userAge = checkUserAge(userData?.dateOfBirth);

    if (emailResponse) {
      if (userData.fullname.trim() === "") {
        errors.fullname = "Merci de renseigner votre Nom complet.";
        hasErrors = true;
      } else if (FullNamemaxCharacterError) {
        errors.fullname =
          "Le nom d'utilisateur ne peut pas dépasser 30 caractères.";
        hasErrors = true;
      }

      // if (userData.firstname.trim() === "") {
      //   errors.firstname = "Merci de renseigner votre Prénom.";
      //   hasErrors = true;
      // }

      // if (userData.lastname.trim() === "") {
      //   errors.lastname = "Merci de renseigner votre Nom.";
      //   hasErrors = true;
      // }

      if (userData.username.trim() === "") {
        errors.username = "Choisissez un nom d'utilisateur.";
        hasErrors = true;
      } else if (!usernamePattern.test(userData.username)) {
        errors.username =
          "Votre nom d’utilisateur ne peut contenir que des : lettres, chiffres, points et tirets bas.";
        hasErrors = true;
      } else if (maxCharacterError) {
        errors.username =
          "Le nom d'utilisateur ne peut pas dépasser 30 caractères.";
        hasErrors = true;
      }

      if (userData.email.trim() === "") {
        errors.email = "Merci de saisir votre adresse mail.";
        hasErrors = true;
      } else if (!emailPattern.test(userData.email)) {
        errors.email = "Merci de saisir une adresse mail valide.";
        hasErrors = true;
      }

      if (
        userData.password.trim() === "" ||
        !passwordPattern.test(userData.password)
      ) {
        errors.password =
          "Le mot de passe doit contenir au moins 6 caractères, dont au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial (@, $, !, %, *, ?, &).";
        hasErrors = true;
      }

      if (userData.dateOfBirth.trim() === "") {
        errors.dateOfBirth = "Merci de renseigner votre Date de Naissance.";
        hasErrors = true;
      }

      if (userAge <= 13) {
        errors.dateOfBirth =
          "Vous devez avoir au moins 13 ans pour utiliser PsyUP.";
        hasErrors = true;
      }

      if (hasErrors) {
        setFormErrors(errors);
      } else {
        setFormErrors(initialState);
        userDataFunc(userData);
      }
    }
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleToggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const checkUserAge = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);
    let ageInYears = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      ageInYears--;
    }

    return ageInYears;
  };

  const openDatePicker = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Trigger the input click event
    }
  };

  return (
    <>
      <div className={styles.signUpForm}>
        <form onSubmit={handleSubmitStep1}>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Nom complet <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Nom complet"
                name="fullname"
                value={userData?.fullname}
                onChange={handleInputChange}
              />
              {/* {renderError("fullname")} */}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Nom d’utilisateur <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="nom d'utilisateur"
                value={userData?.username}
                name="username"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={() => {
                  setIsEmailExist(false);
                }}
              />

              {renderError("username")}
            </div>
          </div>
          {/* <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Prénom <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Prénom"
                name="firstname"
                value={userData?.firstname}
                onChange={handleInputChange}
              />
              {renderError("firstname")}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Nom de famille <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Nom de famille"
                value={userData?.lastname}
                name="lastname"
                onChange={handleInputChange}
              />

              {renderError("lastname")}
            </div>
          </div> */}
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Adresse Mail <span className="mendatory">*</span>
              </label>
              <input
                type="email"
                placeholder="exemple@exemple.com"
                value={userData?.email}
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                onBlur={checkEmail}
              />

              {renderError("email")}
              {isEmailLoading ? (
                <p className={styles.errorMsg}></p>
              ) : (
                isEmailExist && (
                  <p className={styles.errorMsg}>L'email existe déjà.</p>
                )
              )}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Mot de passe <span className="mendatory">*</span>
              </label>
              <div className={styles.inputEyeWrapper}>
                <input
                  type={showOldPassword ? "text" : "password"}
                  value={userData?.password}
                  name="password"
                  placeholder="Mot de passe"
                  autoComplete="off"
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />

                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={handleToggleShowOldPassword}
                >
                  <>
                    <img
                      className={styles.eyeClose}
                      src={showOldPassword ? eyeIcon : eyeSlash}
                      alt="eyeClose"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    {/* <div className={showOldPassword ? styles.slash : ""}></div> */}
                  </>
                </button>
              </div>
              {renderError("password")}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Date de naissance <span className="mendatory">*</span>
              </label>
              <div className={styles.inputEyeWrapper}>
                <input
                  type="date"
                  max={today}
                  placeholder="DD/MM/YYYY"
                  value={userData?.dateOfBirth}
                  name="dateOfBirth"
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  ref={inputRef}
                />

                <img
                  className={styles.calenderIcon}
                  src={calenderIcon}
                  alt="Calendar"
                  onClick={openDatePicker}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              {renderError("dateOfBirth")}
            </div>
          </div>
          <p className={styles.conditionsText}>
            En vous inscrivant, vous acceptez nos{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/termsAndConditions"
            >
              Conditions générales
            </Link>
            . Découvrez comment nous recueillons, utilisons et partageons vos
            données en consultant notre{" "}
            <Link target="_blank" rel="noopener noreferrer" to="/privacy">
              Politique d’utilisation des données
            </Link>{" "}
            et comment nous utilisons les cookies et autres technologies
            similaires en lisant notre{" "}
            <Link target="_blank" rel="noopener noreferrer" to="/cookies">
              Politique d’utilisation des cookies.
            </Link>
          </p>
          <div className={styles.NextButtonWrapper}>
            <input
              type="submit"
              value="Étape suivante"
              className={styles.NextButton}
            ></input>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
