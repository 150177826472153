import React from "react";
import styles from "../assets/styles/buttonFilled.module.scss";
import { ReactSVG } from "react-svg";

const buttonFilled = ({
  title,
  text,
  img,
  textColr,
  handleClick,
  isConditionsChecked,
}) => {
  return (
    <button
      className={styles.buttonFilled}
      style={{ color: textColr }}
      onClick={handleClick}
      disabled={isConditionsChecked}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>{title}</h3>
        {img && <ReactSVG className="ps-2" src={img} />}
      </div>
      {text && <p>{text}</p>}
    </button>
  );
};

export default buttonFilled;
