const monthNames = [
  "jan",
  "fév",
  "mar",
  "avril",
  "mai",
  "juin",
  "juil",
  "août",
  "sept",
  "oct",
  "nov",
  "déc",
];
const formatDate = (createdAt) => {
  const currentDate = new Date();
  const date = new Date(createdAt);
  let formattedDate = "";

  if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  ) {
    const timeDifferenceInMilliseconds = currentDate - date;
    const timeDifferenceInMinutes = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMinutes < 60) {
      formattedDate = `il y a ${timeDifferenceInMinutes} min`;
    } else {
      const hours = Math.floor(timeDifferenceInMinutes / 60);
      formattedDate = `il y a ${hours} h`;
    }
  } else if (date.getFullYear() === currentDate.getFullYear()) {
    formattedDate = `${date.getDate()} ${monthNames[date.getMonth() - 1]}`;
  } else {
    formattedDate = `${date.getDate()} ${
      monthNames[date.getMonth() - 1]
    } ${date.getFullYear()} `;
  }

  return formattedDate;
};

export default formatDate;
