import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import styles from "../../../assets/styles/signUp/step3.module.scss";

import { validate } from "../../../assets/index";
import { ApiCall } from "../../../config/apiCall";
import { message } from "antd";

const Step3 = ({ handleToggle, userData, setCurrentStep, setIsVerified }) => {
  const updatingSubscriptioninDB = (details) => {
    console.log(details);
  };

  const resendEmail = async () => {
    try {
      // setloading(true);
      // Submit sign up details to backend
      const response = await ApiCall({
        params: { email: userData?.email },
        route: "auth/resend-verification-email",
        verb: "put",
        token: "jwt_token",
        baseurl: true,
      });
      if (response.status === 200) {
        message.success(`${response.response.message}`);
        // userDataFunc();
      } else {
        message.error(response.response.message);
        // message.error(response.response.error?.message);
      }
      console.log("response resend", response);
    } catch (error) {
      // setloading(false);
      message.error(error);
    } finally {
      // setloading(false);
    }
  };

  return (
    <>
      <div className={styles.step3Wrapper}>
        <img src={validate} alt="logo" width={80} height={80}  onContextMenu={e => e.preventDefault()}/>
        <h3>Inscription terminée.</h3>
        <p>
          Confirmez votre adresse email via le lien envoyé à <br />
          <span className={styles.email}>{userData?.email}</span> <br />
          Merci et à bientôt !
        </p>

        {/* <div className={styles.paymentWrapper}>
          <PayPalScriptProvider
            options={{
              "client-id":
                "ASuzX8fAuHKJpMA52fPGIqLTGa_ZRAWszSXHUqD_lvI90OahaI9nJBb7MvPHz-rG8TUfKJcZF_OB33Co",
              vault: true,
              intent: "capture",
            }}
          >
            <PayPalButtons
              style={{ layout: "vertical", label: "subscribe" }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: "P-5J809714700857845MOCKV4Q",
                });
              }}
              onApprove={(data, actions) => {
                return actions.subscription.get().then((details) => {
                  updatingSubscriptioninDB(details);
                });
              }}
            />
          </PayPalScriptProvider>
        </div> */}

        <button
          onClick={() => {
            handleToggle();
            // setCurrentStep(0);
            setTimeout(() => {
              setIsVerified(true);
            }, 500);
          }}
          className={styles.BackButton}
        >
          retour à l’accueil
        </button>

        {/* <button
          onClick={() => {
            resendEmail();
          }}
          className={styles.resendButton}
        >
          reenviar correo electrónico de verificación
        </button> */}
      </div>
    </>
  );
};

export default Step3;
