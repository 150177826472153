import React, { useState, useEffect, useRef } from "react";
import styles from "./SearchableDropdown.module.scss";
import { Spin } from "antd";

const SearchableSelect = ({
  options,
  selectedValue,
  handleOptionSelect,
  placeholder,
  onSearch,
  isLoading,
  setNewOption,
  allowNewOption,
  newOptionTitle,
  onCrossClick,
  disabled,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const selectRef = useRef(null);

  // click outside of the picker to toggle the dropdown
  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    filterOptions();
  }, [searchValue]);

  // console.log("searchValue", searchValue);

  const filterOptions = () => {
    const filtered = options?.filter((option) =>
      option?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleSelectClick = () => {
    if (!disabled) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleOptionClick = (value) => {
    handleOptionSelect(value);
    setSearchValue(options?.find((option) => option.value === value)?.label);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.SearchableSelect} ref={selectRef}>
      {!isDropdownOpen ? (
        <div
          className={`${styles.SelectHeader} ${
            !selectedValue ? styles.placeholder : ""
          } ${placeholder || selectedValue ? styles.placeholderTrue : ""}`}
          onClick={handleSelectClick}
        >
          {options?.find((option) => {
            return option.value === selectedValue;
          })?.label || placeholder}
          <div className={styles.iconWrapper}>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="down"
              width="1.6em"
              height="1.3em"
              fill="#00565b"
              aria-hidden="true"
            >
              <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className={styles.inputWrapper}>
          <input
            type="text"
            className={styles.SearchInput}
            placeholder={placeholder}
            value={searchValue}
            autoFocus
            onChange={(e) => {
              if (searchValue !== "" && onCrossClick) {
                onCrossClick();
              }
              if (onSearch) {
                onSearch(e.target.value);
              }
              setSearchValue(e.target.value);
            }}
          />
          <div
            className={styles.iconWrapper}
            onClick={() => {
              if (onCrossClick) {
                onCrossClick();
              }
              setSearchValue("");
              setIsDropdownOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.6em"
              height="1.3em"
              fill="#00565b"
              viewBox="0 0 24 24"
              style={{ cursor: "pointer" }}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41l-1.41-1.41L12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </div>
        </div>
      )}

      {isDropdownOpen && (
        <div className={styles.Options}>
          <ul className={styles.OptionList}>
            {isLoading ? (
              <Spin
                size="small"
                style={{ display: "flex", justifyContent: "center" }}
                className="py-3"
              />
            ) : (
              <>
                {(filteredOptions?.length == undefined ||
                  filteredOptions?.length == 0) && (
                  <p
                    style={{ display: "flex", justifyContent: "center" }}
                    className="py-1"
                  ></p>
                )}
                {filteredOptions?.map((option) => (
                  <li
                    key={option.value}
                    className={`${styles.Option} ${
                      option.value === selectedValue
                        ? styles.SelectedOption
                        : ""
                    }`}
                    onClick={() => handleOptionClick(option.value)}
                  >
                    {option.label}
                  </li>
                ))}
                {allowNewOption && searchValue?.trim() !== "" && (
                  <li
                    className={`${styles.Option} `}
                    onClick={() => {
                      setIsDropdownOpen(false);
                      setNewOption(searchValue);
                    }}
                  >
                    <p>{newOptionTitle}</p>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
