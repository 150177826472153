import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/profile/ReportUserModal.module.scss";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { burgerCrossBlack } from "../../assets";
import Buttonfilled from "../../components/buttonFilled";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";

const initialState = {
  nuditie: false,
  actesSexuels: false,
  violences: false,
  harcèlement: false,
  suicide: false,
  automutilation: false,
  fausseInformation: false,
  contenuIndésirable: false,
  discoursHaineux: false,
  propriétéIntellectuelle: false,
  actesIllégaux: false,
};

const ReportUserModal = ({ toggleState, handleToggle, userId }) => {
  const { token } = useAuth();
  const [report, setReport] = useState(initialState);
  const [reportSuccessModal, setReportSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const reportReason = Object.keys(report).filter((field) => report[field]);

  // console.log(reportReason);

  // Report User profile
  const reportUserProfile = async () => {
    if (!Object.values(report).some((value) => value === true)) {
      toast.error("Veuillez sélectionner au moins une option");
      return;
    } else {
      try {
        setLoading(true);
        const response = await ApiCall({
          params: { reason: reportReason },
          route: "user/reportUser/" + userId,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          // toast.success(`${response.response.message}`);
          handleToggle();
          reportSuccessModalToggle();
          setReport(initialState);
        } else {
          toast.error(`${response.response.errorMessage}`);
        }
        console.log("reportUserResp", response);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const reportSuccessModalToggle = () => {
    setReportSuccessModal(!reportSuccessModal);
  };

  // console.log("report", report);
  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "350px", width: "100%" }}
        centered
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody>
          <div className={styles.header}>
            <h5 id="signUpModalLabel">Signaler pour :</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              <img className="py-2" src={burgerCrossBlack} alt="close"  onContextMenu={e => e.preventDefault()}/>
            </button>
          </div>

          <div className={styles.body}>
            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return { ...prevState, nuditie: !report.nuditie };
                });
              }}
            >
              {!report.nuditie ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.nuditie ? styles.boldText : ""}`}>
                  Nudité
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    actesSexuels: !report.actesSexuels,
                  };
                });
              }}
            >
              {!report.actesSexuels ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.actesSexuels ? styles.boldText : ""}`}>
                  Actes sexuels
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return { ...prevState, violences: !report.violences };
                });
              }}
            >
              {!report.violences ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.violences ? styles.boldText : ""}`}>
                  Violences
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return { ...prevState, harcèlement: !report.harcèlement };
                });
              }}
            >
              {!report.harcèlement ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.harcèlement ? styles.boldText : ""}`}>
                  Harcèlement
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return { ...prevState, suicide: !report.suicide };
                });
              }}
            >
              {!report.suicide ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.suicide ? styles.boldText : ""}`}>
                  Suicide
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    automutilation: !report.automutilation,
                  };
                });
              }}
            >
              {!report.automutilation ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p
                  className={`${report.automutilation ? styles.boldText : ""}`}
                >
                  Automutilation
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    fausseInformation: !report.fausseInformation,
                  };
                });
              }}
            >
              {!report.fausseInformation ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p
                  className={`${
                    report.fausseInformation ? styles.boldText : ""
                  }`}
                >
                  Fausse information
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    contenuIndésirable: !report.contenuIndésirable,
                  };
                });
              }}
            >
              {!report.contenuIndésirable ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p
                  className={`${
                    report.contenuIndésirable ? styles.boldText : ""
                  }`}
                >
                  Contenu indésirable
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    discoursHaineux: !report.discoursHaineux,
                  };
                });
              }}
            >
              {!report.discoursHaineux ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p
                  className={`${report.discoursHaineux ? styles.boldText : ""}`}
                >
                  Discours haineux
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    propriétéIntellectuelle: !report.propriétéIntellectuelle,
                  };
                });
              }}
            >
              {!report.propriétéIntellectuelle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p
                  className={`${
                    report.propriétéIntellectuelle ? styles.boldText : ""
                  }`}
                >
                  Propriété intellectuelle
                </p>
              </div>
            </div>

            <div
              className={` ${styles.Checkbox}`}
              onClick={() => {
                setReport((prevState) => {
                  return {
                    ...prevState,
                    actesIllégaux: !report.actesIllégaux,
                  };
                });
              }}
            >
              {!report.actesIllégaux ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  class="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p className={`${report.actesIllégaux ? styles.boldText : ""}`}>
                  Actes illégaux
                </p>
              </div>
            </div>

            {loading ? (
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner color="danger" />
              </div>
            ) : (
              <div className="pt-3">
                <Buttonfilled
                  title="Signaler"
                  textColr="white"
                  handleClick={reportUserProfile}
                />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="sm"
        style={{ maxWidth: "350px", width: "100%" }}
        centered
        toggle={reportSuccessModalToggle}
        isOpen={reportSuccessModal}
      >
        <ModalBody>
          <div className={styles.header}>
            <p>
              Nous vous remercions d’avoir signalé ce contenu pour{" "}
              <strong>Fausse information.</strong>
            </p>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={reportSuccessModalToggle}
            >
              <img className="py-2" src={burgerCrossBlack} alt="close"  onContextMenu={e => e.preventDefault()}/>
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ReportUserModal;
