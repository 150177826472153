import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { message } from "antd";
import { useAuth } from "../../context/AuthContext";
import StoryLoader from "../../components/UI/StoryLoader";
import Themes from "../../components/themes";
import { burgerCrossBlack } from "../../assets";
import { ApiCall } from "../../config/apiCall";
import styles from "../../assets/styles/signUp/step2.module.scss";
import DotsLoader from "../../components/UI/DotsLoader";

const VerifyUserByThirdParty = () => {
  const { getProfileData, setUserId, setToken, userInfo } = useAuth();
  const [themes, setThemes] = useState([]);
  const [allThemes, setAllThemes] = useState([]);
  const [prevSelectedThemes, setPrevSelectedThemes] = useState([]);
  const [prevUnselectedThemes, setPrevUnselectedThemes] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [loading, setloading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const USERID = searchParams.get("user");
  const NewUser = searchParams.get("new");
  setToken(searchParams.get("token"));
  setUserId(searchParams.get("user"));

  // modal
  const [modal, setModal] = useState(false);

  const modalToggle = () => {
    setModal(!modal);
  };

  if (USERID) {
    localStorage.setItem("token", token);
    localStorage.setItem("userId", USERID);
  }

  //   get selected, unselected, all themes
  useEffect(() => {
    if (
      Array.isArray(userInfo?.selectedThemes) &&
      Array.isArray(userInfo?.unselectedThemes)
    ) {
      const themesWithSelectedFlag = userInfo.selectedThemes.map((theme) => ({
        ...theme,
        isSelected: true,
      }));

      const themesWithUnselectedFlag = userInfo.unselectedThemes.map(
        (theme) => ({
          ...theme,
          isSelected: false,
        })
      );

      setPrevSelectedThemes(themesWithSelectedFlag);
      setPrevUnselectedThemes(themesWithUnselectedFlag);
    }
    setAllThemes([...prevSelectedThemes, ...prevUnselectedThemes]);
  }, [userInfo]);

  // update themes
  function handleCardClick(id) {
    const selectedThemesFilter = allThemes.filter((theme) => theme.isSelected);
    const clickedTheme = allThemes.find((theme) => theme._id === id);
    if (!clickedTheme.isSelected && selectedThemesFilter.length < 3) {
      setAllThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: true } : theme
        )
      );
    } else {
      setAllThemes((prevState) =>
        prevState.map((theme) =>
          theme._id === id ? { ...theme, isSelected: false } : theme
        )
      );
    }
  }

  //   set updated themes
  useEffect(() => {
    const selectedThemesAfterUpdate = allThemes.filter(
      (theme) => theme.isSelected
    );
    const getSelectedThemes = selectedThemesAfterUpdate.map(
      (theme) => theme._id
    );
    setSelectedThemes(getSelectedThemes);
  }, [allThemes]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setloading(true);
      const params = new FormData();
      selectedThemes.forEach((val) => {
        console.log("v", val);
        params.append("selectedThemes", val);
      });

      const response = await ApiCall({
        params,
        route: "profile/update",
        verb: "put",
        baseurl: true,
        token: token,
      });
      console.log(response, "update profile");
      if (response.status === 200) {
        message.success(response.response.message);
        setModal(false);
        navigate("/");
        getProfileData(USERID);
      } else {
        // message.error(response.response.message);
        message.error("Veuillez sélectionner 3 thèmes !");
      }
    } catch (error) {
      message.error(error);
    } finally {
      setloading(false);
    }
  };

  console.log("NewUser", NewUser);

  useEffect(() => {
    if (userInfo) {
      if (NewUser == "true") {
        setModal(true);
      } else {
        navigate("/");
        getProfileData(USERID);
      }
    }
  }, [userInfo, USERID, token]);
  return (
    <div>
      <StoryLoader />
      <StoryLoader />

      {/* themes modal */}

      <Modal
        size="sm"
        style={{ maxWidth: "740px", width: "100%" }}
        centered
        modalClassName="SignUpModal"
        toggle={modalToggle}
        isOpen={modal}
        backdrop="static"
        keyboard={false}
      >
        <ModalBody
          className={`${styles.signUpModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.signUpModalBodyHeader}>
            <h5 id="signUpModalLabel">Inscription</h5>
          </div>

          <div>
            <Themes themes={allThemes} handleCardClick={handleCardClick} />
            <div>
              {loading ? (
                <div className={styles.loaderWrapper}>
                  <DotsLoader color="white" />
                </div>
              ) : (
                <div className={styles.NextButtonWrapper}>
                  <button onClick={handleSubmit} className={styles.NextButton}>
                    Modifier
                  </button>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VerifyUserByThirdParty;
