import React, { useState } from "react";
import styles from "./LegalPages.module.scss";

const TermsAndConditions = () => {
  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionId) => {
    if (openSection === sectionId) {
      setOpenSection(null);
    } else {
      setOpenSection(sectionId);
    }
  };

  return (
    <div className={styles["super-container"]}>
      <div className={styles.container}>
        <h3 className={styles.heading}>
          Conditions générales de mise en ligne de contenu
        </h3>

        <div>
          <p className="pt-2">
            Les conditions générales de mise en ligne de contenu (ci-après « CGU
            DE MISE EN LIGNE ») sont conclues entre la société RIVELEZ SASU,
            société par actions simplifiée au capital de 1 euros, immatriculée
            au R.C.S. de Paris sous le numéro 888 155 348, dont le siège social
            est 6 rue dArmaillé 75017 Paris (ci après « RIVELEZ ») et
            l’internaute publiant un contenu en ligne via la plateforme de mise
            en ligne présente sur le site http://www.psyup.fr et son application
            mobile.
          </p>
          <p className={styles.paragraph}>
            Les présentes CGU DE MISE EN LIGNE ont pour objectif de définir les
            conditions de publication du contenu par l’internaute sur le site,
            les applications mobiles et l’ensemble des réseaux sociaux PsyUP
            (notamment les comptes Facebook, Instagram et Twitter et tout autre
            réseaux qui viendrait à être crées).
          </p>
          <p>
            Toute publication du contenu implique leur acceptation complète et
            sans réserve.
          </p>
          <ul className={styles.cookiesCollapse}>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(1)}
              >
                <h6>Article 1. Plateforme de mise en ligne</h6>
                <h5>{openSection === 1 ? "-" : "+"}</h5>
              </div>
              {openSection === 1 && (
                <p className="pb-3">
                  RIVELEZ a mis en place une plateforme de référencement et de
                  mise en ligne de contenu permettant à des internautes de
                  publier sur le site http://www.psyup.fr ainsi que sur les
                  réseaux sociaux des contenus qui leurs sont propres.
                </p>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(2)}
              >
                <h6>Article 2. Définitions</h6>
                <h5>{openSection === 2 ? "-" : "+"}</h5>
              </div>
              {openSection === 2 && (
                <ul className={styles.dashaedUL}>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Contenu :</span> tout
                    texte, son, image, photographie, vidéo, musique ou
                    combinaison audiovisuelle mise en ligne et partagé par
                    l’internaute via la Plateforme sur les Réseaux digitaux
                    PsyUP.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Internaute :</span>{" "}
                    personne physique majeure ou personne morale inscrite sur le
                    Site, détenant la capacité en son nom propre - ou en vertu
                    de tout mandat donnant pouvoir à tous tiers – de partager un
                    contenu qui lui est propre via la Plateforme et sur les
                    Réseaux digitaux PsyUP.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Plateforme :</span>{" "}
                    logiciel sous la forme d’un service hébergé (Software as a
                    Service - Saas) mis à disposition des Internautes par
                    RIVELEZ, accessible par le biais d’un navigateur Internet et
                    dont l’objet est de permettre à l’Internaute d’accéder à de
                    la documentation et de partager du contenu sur les Réseaux
                    digitaux PsyUP.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Réseaux digitaux PsyUP :
                    </span>{" "}
                    comprend le site internet accessible à l’adresse
                    http://www.psyup.fr, les applis mobiles correspondantes, et
                    les réseaux sociaux de PsyUP (notamment les comptes
                    Facebook, Instagram, Youtube et Twitter de PsyUP et tout
                    autres réseaux qui viendraient à être crées).
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Site :</span> le site
                    internet accessible à l’adresse{" "}
                    <a href="http://www.psyup.fr">http://www.psyup.fr</a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(3)}
              >
                <h6>Article 3. Garanties de l’Internaute</h6>
                <h5>{openSection === 3 ? "-" : "+"}</h5>
              </div>
              {openSection === 3 && (
                <>
                  <p className="pb-3">
                    L’Internaute s’engage à partager du Contenu de bonne foi, en
                    respectant les présentes CGU DE MISE EN LIGNE et :
                  </p>

                  <ul className={styles.dashaedUL}>
                    <li>
                      les droits des tiers et en particulier le droit à la vie
                      privée, les droits de propriété intellectuelle, etc. ;
                    </li>
                    <li>
                      les lois et réglementation en vigueur en France ou dans le
                      pays où le Contenu est destiné à être partagé et en
                      particulier les dispositions du Code de la propriété
                      Intellectuelle ainsi que les dispositions relatives à la
                      protection des droits de la personnalité et le respect des
                      dispositions de la loi sur la Presse.
                    </li>
                  </ul>
                  <div>
                    <p className="pb-3">
                      L’Internaute garantit à ce titre qu’il dispose de
                      l’ensemble des droits, licences et/ou autorisations
                      nécessaires à l’exploitation et à la publication du
                      Contenu tels que prévus aux présentes en vue de leur
                      exploitation sur l’ensemble des Réseaux digitaux PsyUP. Il
                      garantit notamment qu’il a obtenu l’autorisation de tout
                      tiers dont il utiliserait l’image ou les éléments (image,
                      nom, voix, produit, œuvre, logo, dessin, modèle, marque,
                      etc.) de quelque manière que ce soit dans le cadre leur
                      exploitation sur l’ensemble des Réseaux digitaux PsyUP.
                      L’internaute en garantit à RIVELEZ la jouissance paisible.
                    </p>
                    <p className="pb-3">
                      Le Contenu est récupéré par la Plateforme, de manière soit
                      synthétique soit identique, et pourra être publié sur
                      l’ensemble des Activités digitales de PsyUP à destinations
                      de tout internaute naviguant sur les Réseaux digitaux
                      PsyUP. La conformité du Contenu doit être vérifiée par
                      l’Internaute qui en est seul responsable et soustrait
                      RIVELEZ de toute action fondée directement ou
                      indirectement sur l’utilisation dudit Contenu ainsi que de
                      toute conséquence financière pouvant en résulter.
                    </p>
                    <p className="pb-3">
                      RIVELEZ se réserve le droit de publier ou non le Contenu
                      partagé par l’Internaute via la Plateforme sans avoir à
                      s’en justifier et sans recours possible ni indemnité.
                      RIVELEZ pourra supprimer à tout moment et sans préavis et
                      à son entière discrétion tout Contenu publié par
                      l’Internaute qui ne respecterait pas les conditions
                      ci-avant exposées et se réserve le droit d’interdire
                      l’accès d’un Internaute à la Plateforme en raison de la
                      soumission d’un tel Contenu dans les mêmes conditions.
                    </p>
                    <p className="pb-3">
                      La publication d’un Contenu faisant apparaitre une
                      opinion, une recommandation ou un avis exprimé par toute
                      personne physique ou morale n’emporte en aucun cas
                      approbation de la part de RIVELEZ, qui décline toute
                      responsabilité relative audit Contenu.
                    </p>
                    <p className="pb-3">
                      L’internaute reconnait que par le simple fait d’utiliser
                      les Réseaux digitaux PsyUP, il peut être exposé à du
                      Contenu inexacte, choquant, indécent ou autrement négatif.
                      RIVELEZ décline à ce titre toute responsabilité relative
                      audit Contenu précité.
                    </p>
                  </div>
                </>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(4)}
              >
                <h6>Article 4. Autorisations de l’Internaute</h6>
                <h5>{openSection === 4 ? "-" : "+"}</h5>
              </div>
              {openSection === 4 && (
                <div>
                  <p className="pb-3">
                    L’Internaute autorise toute modification, altération,
                    adaptation, ajout, reproduction totale ou partielle,
                    utilisation, exploitation, diffusion, publication,
                    distribution, promotion du Contenu par RIVELEZ sur les
                    Réseaux digitaux PsyUP, en toute norme et tout format, quels
                    qu’en soient les procédés et moyens, actuels et futurs, par
                    tout mode, à destination de tout terminal de réception fixe
                    et/ou mobile, présents ou à venir.
                  </p>
                </div>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(5)}
              >
                <h6>Article 5. Droits concédés</h6>
                <h5>{openSection === 5 ? "-" : "+"}</h5>
              </div>
              {openSection === 5 && (
                <div>
                  <p className="pb-3">
                    L’internaute conserve tous ses droits de propriété
                    intellectuelle sur son Contenu, mais il est tenu de concéder
                    des droits limités à RIVELEZ et aux internautes des Réseaux
                    digitaux PsyUP.
                  </p>
                  <ul className={styles.dashaedUL}>
                    Lorsque l’internaute soumet du Contenu sur la Plateforme, il
                    concéde :
                    <li className="pt-3">
                      à RIVELEZ, le droit non exclusif, cessible (y compris le
                      droit de sous-licencier), à titre gracieux, et pour le
                      monde entier d’héberger, d’utiliser, de reproduire, de
                      distribuer, de modifier, de traduire, de réaliser des
                      œuvres dérivées, de représenter et d’exécuter le Contenu
                      dans le cadre du Service ou de la mise à disposition sur
                      les Réseaux digitaux PsyUP, notamment, sans limitation,
                      pour la promotion et la redistribution de tout ou partie
                      des Réseaux digitaux PsyUP (et des œuvres dérivées qui en
                      résultent), en tout format, sur tout support et via tous
                      les canaux média ;
                    </li>
                    <li>
                      à chaque utilisateur des Réseaux digitaux PsyUP, le droit
                      non exclusif, à titre gracieux, et pour le monde entier
                      d’accéder à son Contenu via les Réseaux digitaux PsyUP et
                      d’utiliser, de reproduire, de distribuer, de représenter,
                      d’exécuter le Contenu dans la mesure autorisée par les
                      fonctionnalités des Réseaux digitaux PsyUP et par les
                      présentes CGU DE MISE EN LIGNE.
                    </li>
                  </ul>
                  <p className="pb-3">
                    Dans le cadre de la publication de Contenu, l’Internaute
                    décide s’il souhaite ou non que son pseudo apparaisse au
                    côté du contenu publié. En tout état de cause, l’Internaute
                    autorise RIVELEZ à disposer gracieusement de son nom,
                    pseudo, avatar, photo de profil, et des éléments relatifs à
                    son image, etc. - tels que dénomination sociale, produits
                    associés, marques, photos, vidéos, etc. - que l’Internaute
                    aura préalablement transmis à RIVELEZ à l’occasion de son
                    inscription sur le Site. Il lui en assure à ce titre la
                    jouissance paisible et RIVELEZ pourra ainsi les utiliser
                    dans le cadre de son activité. Lorsque l’Internaute
                    s’inscrit sur le Site, il s’engage notamment à ne
                    communiquer que des informations vérifiées, exactes,
                    véritables, précises, à jour et complètes.
                  </p>
                  <p className="pb-3">
                    Cette exploitation pourra être effectuée par tous procédés
                    techniques connus ou inconnus à ce jour, pour tous supports,
                    sur tous formats pour un nombre illimité d’utilisations, ou
                    non et en tout ou partie. L’Internaute reconnait par la
                    présente que ces autorisations constituent des obligations
                    essentielles et déterminantes de la validité des CGU DE MISE
                    EN LIGNE. L’Internaute reconnaît être informé et accepte que
                    RIVELEZ transmette lesdites autorisations à tout tiers de
                    son choix. Ces autorisations sont consenties à titre
                    gracieux sans restriction de temps et d’espace et est
                    accordées y compris pour l’archivage et la distribution.
                  </p>
                </div>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(6)}
              >
                <h6>Article 6. Droit de propriété intellectuelle de PsyUP</h6>
                <h5>{openSection === 6 ? "-" : "+"}</h5>
              </div>
              {openSection === 6 && (
                <div>
                  <ul className={styles.dashaedUL}>
                    Sont protégés par des droits de propriété intellectuelle les
                    éléments suivants :
                    <li className="pt-3">
                      la marque française semi-figurative « PsyUP » (n°…)
                      enregistrée le … par la société RIVELEZ et publiée au BOPI
                      par l’INPI ;
                    </li>
                    <li>
                      le site www.psyup.fr et la Plateforme ont été conçus et
                      réalisés exclusivement par RIVELEZ, qui en détient la
                      propriété exclusive, et cela comprend l’ensemble des
                      éléments le constituant (notamment les textes, logos et
                      dessins, vidéos, codes, chiffres et tout autre élément
                      visuel, textuel, graphique ou autre, …) ;
                    </li>
                    <li>
                      le nom de domaine www.psyup.fr du fait de son
                      enregistrement et de son exploitation commerciale.
                    </li>
                  </ul>
                  <p>
                    L’exploitation commerciale de noms et de signes distinctifs
                    desdites marques, du site et de la Plateforme ainsi que du
                    nom de domaine, portant préjudice à RIVELEZ par la création
                    d’une quelconque forme de confusion dans l’esprit du public,
                    est susceptible d’entraîner des poursuites en particulier
                    pour concurrence déloyale et/ou parasitisme selon les
                    procédures en vigueur.
                  </p>
                  <p className={styles.paragraph}>
                    Si l’Internaute utilise du Contenu couvert par des droits de
                    propriété intellectuelle que RIVELEZ possède et rend
                    disponibles sur les Réseaux digitaux PsyUP (par exemple, des
                    textes, des noms, des marques, des images, des dessins, des
                    vidéos ou des sons que RIVELEZ fournit et que l’Internaute
                    ajoute à du contenu qu’il crée ou partage sur Facebook,
                    Twitter, Instagram ou tout autre réseau social connu ou
                    inconnu à ce jour), RIVELEZ conserve tous les droits
                    relatifs à ce contenu (mais pas à celui de l’Internaute).
                  </p>
                  <p className="pb-3">
                    L’Internaute ne peut utiliser les droits de propriété
                    intellectuelle de RIVELEZ qu’après avoir reçu une
                    autorisation écrite de la part de RIVELEZ.
                  </p>
                </div>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(7)}
              >
                <h6>Article 7. Responsabilité</h6>
                <h5>{openSection === 7 ? "-" : "+"}</h5>
              </div>
              {openSection === 7 && (
                <div>
                  <p>
                    La publication de Contenu par l’Internaute se fait à ses
                    propres risques et sous sa seule responsabilité.
                  </p>
                  <p className={styles.paragraph}>
                    RIVELEZ ne garantit pas la confidentialité du Contenu, que
                    celui-ci soit ou non diffusé sur les Réseaux digitaux PsyUP.
                  </p>
                  <p>
                    L’Internaute soustrait RIVELEZ contre toute réclamation liée
                    à l’utilisation de la Plateforme, en ce compris, le Contenu,
                    ainsi que sa diffusion et publication et ce, en particulier,
                    si le Contenu et/ou sa diffusion et/ou sa publication
                    constitue des manquements aux conditions générales ou
                    spéciales applicables à des plateformes tierces (y compris
                    les réseaux sociaux), et/ou à l’ensemble du droit en vigueur
                    et/ou aux droits de tiers (droits de propriété
                    intellectuelle, droit à l’image, au nom etc.). Ainsi,
                    l’Internaute est seul responsable des dommages et
                    préjudices, directs ou indirects, matériels ou immatériels
                    qui résulteraient de tels manquements et il s’engage à
                    indemniser RIVELEZ de toute demande, réclamation et/ou
                    condamnation à des dommages et intérêts dont RIVELEZ
                    pourrait être l’objet, frais de contentieux inclus le cas
                    échéant, dès lors que celle-ci aurait pour cause, fondement
                    ou origine, de tels manquements.
                  </p>
                  <p className={styles.paragraph}>
                    RIVELEZ dégage totalement et absolument sa responsabilité
                    pour tous les dommages, directs et indirects, subis par
                    l’Internaute dans l’utilisation de la Plateforme, résultant
                    de l’existence et de l’application éventuelle de toutes
                    législations étrangères restrictives des accès et des
                    connexions au réseau et/ou des contenus numériques.
                  </p>
                </div>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(8)}
              >
                <h6>Article 8. Documents contractuels</h6>
                <h5>{openSection === 8 ? "-" : "+"}</h5>
              </div>
              {openSection === 8 && (
                <div>
                  <p>
                    La version des CGU DE MISE EN LIGNE applicable est celle en
                    vigueur à la date d’utilisation de la Plateforme.
                  </p>
                  <p className="pb-3">
                    RIVELEZ se réserve le droit exclusif et discrétionnaire de
                    modifier ou de suspendre les CGU DE MISE EN LIGNE notamment
                    si lesdites modifications ou suspensions s’avéraient
                    nécessaires à un objectif commercial ou légal.
                  </p>
                </div>
              )}
            </li>
            <li>
              <div
                className={styles.sectionHead}
                onClick={() => handleSectionClick(9)}
              >
                <h6>Article 9. Litige</h6>
                <h5>{openSection === 9 ? "-" : "+"}</h5>
              </div>
              {openSection === 9 && (
                <div>
                  <p>
                    Les présentes CGU DE MISE EN LIGNE et tous documents
                    contractuels y afférents sont soumis et régis par le droit
                    français, et doivent être interprétés au regard du droit
                    français. Aucune dérogation à cette disposition ne pourra
                    être invoquée. Il est expressément indiqué que toute version
                    traduite n’est faite qu’à titre informatif. Tout désaccord
                    lié aux CGU DE MISE EN LIGNE sera, à défaut d’accord amiable
                    entre les parties, soumis à la compétence exclusive des
                    tribunaux compétents de Paris.
                  </p>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
