import React, { useEffect, useState } from "react";
import styles from "./PricingPlans.module.scss";
import { Modal, ModalBody } from "reactstrap";
import {
  burgerCrossBlack,
  calenderIcon,
  eyeIcon,
  eyeSlash,
  location,
  nextArrow,
  validate,
} from "../../assets";
import { ApiCall } from "../../config/apiCall";
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import ProfessionalSignupModal from "./ProfessionalSignup/ProfessionalSignupModal";
import SkeltonList from "../../components/UI/SkeltonList";

const professionalsList = [
  {
    id: 1,
    value: "professionnel",
    name: "Un professionnel de santé*",
  },
  {
    id: 2,
    value: "psychologue",
    name: "Un psychologue",
  },
  {
    id: 3,
    value: "avocat",
    name: "Un avocat",
  },
  {
    id: 4,
    value: "coach",
    name: "Un coach",
  },
  {
    id: 5,
    value: "thérapeute",
    name: "Un thérapeute",
  },
  {
    id: 6,
    value: "expert-comptable",
    name: "Un expert-comptable",
  },
  {
    id: 7,
    value: "association",
    name: "Une association",
  },
  {
    id: 8,
    value: "autre",
    name: "Autre",
  },
];

const initialState = {
  planId: "",
  professionaltype: "",
  professionalSubType: "",
  firstName: "",
  lastName: "",
  username: "",
  firmName: "",
  email: "",
  password: "",
  dateOfBirth: "",
  siren: "",
  location: {
    address: "",
    coordinates: [],
  },
  postalCode: "",
  selectedThemes: [],
};

const featuresList = [
  "Votre nom et/ou celui de votre entreprise",
  "Votre adresse",
  "Le prix minimum d'une consultation",
  "Votre numéro de téléphone",
  "Votre photo ou logo",
  "10 mots-clés décrivant votre activité",
  "Votre description complète",
  "Votre site Web et/ou vos réseaux sociaux",
  "La réservation de rendez-vous en ligne",
  "La réservation de consultations en visio",
  "L'affichage prioritaire de votre profil",
];

const PricingPlans = () => {
  const [plansData, setplansData] = useState(null);
  const [plansDataLoading, setplansDataLoading] = useState(false);
  const [professionalData, setprofessionalData] = useState(initialState);
  const [selectedCard, setSelectedCard] = useState(null);

  // Modals
  const [professionalTypes, setProfessionalTypes] = useState(false);
  const [professionalJob, setProfessionalJob] = useState(false);
  const [professionalSignup, setProfessionalSignup] = useState(false);
  const [professionalIdentity, setProfessionalIdentity] = useState(false);
  const [professionalOnSuccess, setProfessionalOnSuccess] = useState(false);
  const [professionalOnCancel, setProfessionalOnCancel] = useState(false);

  const toggleProfessionalTypes = () => {
    setProfessionalTypes(!professionalTypes);
  };

  const toggleProfessionalJob = () => {
    setProfessionalJob(!professionalJob);
  };

  const toggleProfessionalSignup = () => {
    setProfessionalSignup(!professionalSignup);
  };

  const toggleProfessionalIdentity = () => {
    setProfessionalIdentity(!professionalIdentity);
  };

  const createProfessional = async () => {
    try {
      const response = await ApiCall({
        params: professionalData,
        route: `professional/create`,
        verb: "post",
        baseurl: true,
      });
      if (response.status === 200) {
        toggleOnSuccess();
        toggleProfessionalIdentity();
      } else {
      }
    } catch (error) {
      message.error(error);
    } finally {
    }
  };

  const toggleOnSuccess = () => {
    setProfessionalOnSuccess(!professionalOnSuccess);
  };

  const toggleOnCancel = () => {
    setProfessionalOnCancel(!professionalOnCancel);
  };

  // get plans data

  const getPlansData = async () => {
    setplansDataLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `planProfessional/plan-listing`,
        verb: "get",
        baseurl: true,
      });
      if (response.status === 200) {
        console.log("response.response", response.response);
        setplansData(response?.response?.data);
      } else {
      }
    } catch (error) {
      setplansDataLoading(false);
      message.error(error);
    } finally {
      setplansDataLoading(false);
    }
  };

  useEffect(() => {
    getPlansData();
  }, []);

  const onPlanClick = (plan) => {
    setprofessionalData((prevState) => ({
      ...prevState,
      planId: plan,
    }));
    toggleProfessionalTypes();
  };

  const handleCardClick = (professional) => {
    setprofessionalData((prevState) => ({
      ...prevState,
      professionaltype: professional?.value,
    }));
    setSelectedCard(professional?.id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setprofessionalData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const professionDetail = (type) => {
    if (type === "professionnel") {
      return "Afin d'assurer la sécurité de nos utilisateurs, toutes les données d'identification des professionnels de santé mentionnés dans notre Annuaire proviennent directement du répertoire officiel gouvernemental RPPS (Répertoire partagé des professionnels de santé).Nous allons donc procéder à la vérification de votre identité.";
    } else if (type === "psychologue") {
      return "Afin d'assurer la sécurité de nos utilisateurs, toutes les données d'identification des psychologues mentionnés dans notre Annuaire proviennent directement du répertoire officiel gouvernemental RPPS (Répertoire partagé des professionnels de santé).Nous allons donc procéder à la vérification de votre identité.";
    } else if (type === "avocat") {
      return "Afin d'assurer la sécurité de nos utilisateurs, toutes les données d'identification des avocats mentionnés dans notre Annuaire proviennent directement du répertoire officiel gouvernemental ; ce dernier étant régulièrement mis à jour. Nous allons donc procéder à la vérification de votre identité.";
    } else if (type === "coach") {
      return "";
    } else if (type === "thérapeute") {
      return "";
    } else if (type === "expert-comptable") {
      return "Afin d'assurer la sécurité de nos utilisateurs, toutes les données d'identification des experts-comptables mentionnés dans notre Annuaire proviennent directement de l'Ordre des experts-comptables, et sont régulièrement mises à jour. Nous allons donc procéder à la vérification de votre identité.";
    } else if (type === "association") {
      return "Afin d'assurer la sécurité de nos utilisateurs, toutes les données d'identification des associations mentionnées dans notre Annuaire proviennent directement du répertoire officiel gouvernemental ; ce dernier étant régulièrement mis à jour. Nous allons donc procéder à la vérification de votre identité.";
    } else {
      return "";
    }
  };

  const handleSubmitProType = (proType) => {
    toggleProfessionalTypes();
    if (proType === "autre") {
      toggleProfessionalJob();
    } else {
      toggleProfessionalSignup();
    }
  };
  return (
    <div className={styles.pricingPlansContainer}>
      {plansDataLoading ? (
        <SkeltonList />
      ) : (
        <div className={styles.planCardsContainer}>
          {plansData?.map((plan, i) => {
            const amount = (plan?.amount / 100).toString(); // Divide by 10 and convert to string
            const formattedAmount = amount.replace(/\.0+$/, ""); // Remove trailing zeros
            return (
              <div className={styles.cardWrapp}>
                {plan?.metadata?.popular && (
                  <div className={styles.popular}>
                    {plan?.metadata?.popular}
                  </div>
                )}

                <div className={styles.planCard}>
                  <h6 className={styles.planName}>{plan?.nickname}</h6>

                  <div className={styles.line} />

                  <div className={styles.priceWrapp}>
                    <h6 className={styles.heading}>
                      {formattedAmount == "0" ? "GRATUIT" : formattedAmount}
                      {formattedAmount != "0" && (
                        <>
                          €<span>/month</span>
                        </>
                      )}
                    </h6>

                    <div className={styles.detail}>
                      {plan?.metadata?.priceDetail}
                    </div>
                  </div>

                  <div className={styles.description}>
                    {plan?.metadata?.description}
                  </div>

                  <ul>
                    {featuresList?.map((feature, index) => (
                      <li
                        className={
                          plan?.metadata?.[index] == "true"
                            ? styles.availableFeature
                            : styles.unavailableFeature
                        }
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={
                              plan?.metadata?.[index] == "true"
                                ? faCheck
                                : faXmark
                            }
                          />
                        </span>{" "}
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <div
                    className={styles.button}
                    onClick={() => onPlanClick(plan?.id)}
                  >
                    CHOISIR
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Choose professional */}
      <Modal
        size="sm"
        style={{ maxWidth: "640px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={toggleProfessionalTypes}
        isOpen={professionalTypes}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <h5>Vous-êtes ?</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={toggleProfessionalTypes}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.professionalTypesBody}>
            <h5>
              <div className={styles.cardsWrapper}>
                {professionalsList?.map((professional) => (
                  <div
                    key={professional?.id}
                    className={`${styles.card} ${
                      selectedCard === professional?.id ? styles.activeCard : ""
                    }`}
                    onClick={() => handleCardClick(professional)}
                  >
                    <p>{professional?.name}</p>
                  </div>
                ))}
              </div>
              <div className={`${styles.buttonWrapper}`}>
                <button
                  onClick={() =>
                    handleSubmitProType(professionalData?.professionaltype)
                  }
                  className={styles.buttonSuccess}
                >
                  ÉTAPE SUIVANTE
                </button>
              </div>
              <p className={styles.professionalsDescription}>
                *Au sens des articles L4111-1 à L4163-10.
              </p>
            </h5>
          </div>
        </ModalBody>
      </Modal>

      {/* Who are you */}
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={toggleProfessionalJob}
        isOpen={professionalJob}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <h5>Quelle est votre profession ?</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={toggleProfessionalJob}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.professionalJobBody}>
            <div className={`${styles.inputDiv}`}>
              <input
                type="text"
                placeholder=""
                id="professionalSubType"
                name="professionalSubType"
                value={professionalData.professionalSubType}
                onChange={handleInputChange}
              />
            </div>

            <div
              className={styles.searchButtonMobileView}
              onClick={() => {
                toggleProfessionalJob();
                toggleProfessionalSignup();
              }}
            >
              <img
                src={nextArrow}
                alt="next"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Signup modal */}

      <ProfessionalSignupModal
        toggleState={professionalSignup}
        handleToggle={toggleProfessionalSignup}
        professionalData={professionalData}
        setprofessionalData={setprofessionalData}
        initialState={initialState}
        toggleProfessionalIdentity={toggleProfessionalIdentity}
      />

      {/* Verify identity modal */}

      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={toggleProfessionalIdentity}
        isOpen={professionalIdentity}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <h5>Vérification de votre identité</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={toggleProfessionalIdentity}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.professionalIdentityBody}>
            <p className={styles.professionalDetail}>
              {professionDetail(professionalData?.professionaltype)}
            </p>

            <h6 className={styles.professionalIdentityheading}>
              C'est bien vous
            </h6>

            <div className={styles.nameCard}>
              <h6>
                {professionalData?.firstName} {professionalData?.lastName}
              </h6>
              <p>
                <span>
                  <img src={location} />
                </span>{" "}
                {professionalData?.email}
              </p>
            </div>

            <div className={`${styles.buttonWrapper}`}>
              <button
                onClick={() => {
                  createProfessional();
                }}
                className={styles.buttonRed}
              >
                OUI
              </button>
            </div>
            <div className={`${styles.buttonWrapper}`}>
              <button
                onClick={() => {
                  toggleProfessionalIdentity();
                  toggleOnCancel();
                }}
                className={styles.buttonSuccess}
              >
                NON
              </button>
            </div>

            <h6
              className={styles.return}
              onClick={() => {
                toggleProfessionalIdentity();
                toggleProfessionalSignup();
              }}
            >
              RETOUR
            </h6>
          </div>
        </ModalBody>
      </Modal>

      {/* cancle signup modal */}

      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={toggleOnCancel}
        isOpen={professionalOnCancel}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <div />
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={toggleOnCancel}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.professionalIdentityCancleBody}>
            <p className={styles.professionalDetail}>
              Nous n'avons pas reussi a vous identifier.
            </p>

            <div className={`${styles.buttonWrapper}`}>
              <button
                onClick={toggleOnSuccess}
                className={styles.buttonOutline}
              >
                RETOUR A L'ACCUEIL
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* success signup modal */}

      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={toggleOnSuccess}
        isOpen={professionalOnSuccess}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <h5>Vérification de votre identité</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={toggleOnSuccess}
            >
              <img
                className="py-2"
                src={burgerCrossBlack}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.professionalOnSuccessBody}>
            <img
              src={validate}
              alt="logo"
              width={80}
              height={80}
              onContextMenu={(e) => e.preventDefault()}
            />
            <h3>Inscription terminée.</h3>
            <p>
              Confirmez votre adresse email via le lien envoyé à <br />
              <span className={styles.email}>
                {professionalData?.email}
              </span>{" "}
              <br />
              Merci et à bientôt !
            </p>

            <button
              onClick={() => {
                toggleOnSuccess();
              }}
              className={styles.BackButton}
            >
              retour à l’accueil
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PricingPlans;
