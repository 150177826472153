import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/home.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { filter, search } from "../../assets";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";

const OtherStoryFiltration = ({ setshowTimeFrameFilters }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    allUsersStories,
    setallUsersStories,
    token,
    setStoriesLoading,
    storyCount,
    storyFilter,
    setStoryFilter,
    setTimeFrameForStoryFiltration,
    activeResourceBtn,
    setactiveResourceBtn,
  } = useAuth();
  const [username, setUsername] = useState("");

  const items = [
    {
      label: "Les plus populaires",
      key: "2",
      onClick: async () => {
        setTimeFrameForStoryFiltration(null);
        setactiveResourceBtn("");
        setshowTimeFrameFilters(true);
        await setStoryFilter("reacted");
        // getAllUserStoriesByFilter(storyFilter);
      },
    },
    {
      label: "Les plus récentes",
      key: "1",
      onClick: async () => {
        setactiveResourceBtn("");
        setshowTimeFrameFilters(false);
        setTimeFrameForStoryFiltration(null);
        await setStoryFilter("recent");
        // getAllUserStoriesByFilter(storyFilter);
      },
    },

    {
      label: "Les plumes d'or",
      key: "3",
      onClick: async () => {
        setTimeFrameForStoryFiltration(null);
        setactiveResourceBtn("");
        setshowTimeFrameFilters(true);
        await setStoryFilter("feather");
        // getAllUserStoriesByFilter(storyFilter);
      },
    },
    // {
    //   label: "Les inoubliables",
    //   key: "4",
    //   onClick: () => {
    //     setshowTimeFrameFilters(false);
    //   },
    // },
  ];

  const handleSearch = (event) => {
    event.preventDefault();
    if (username.trim() !== "") {
      navigate(`/search/${username}`);
    }
  };

  const getAllUserStoriesByFilter = async (status) => {
    setallUsersStories("");
    setStoriesLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `story/filteredStories/${status}&${storyCount}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setallUsersStories(response.response);
      }
      // console.log("AllStoryResp", response.response);
    } catch (error) {
      setStoriesLoading(false);
    } finally {
      setStoriesLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (storyFilter) {
        await getAllUserStoriesByFilter(storyFilter);
      }
    };
    fetchData();
  }, [storyFilter]);

  return (
    <>
      {location.pathname === "/" ? (
        <form onSubmit={handleSearch}>
          <div className={styles.filters}>
            <div className={styles.searchContainer}>
              <img
                src={search}
                alt="search"
                width={20}
                height={20}
                onContextMenu={(e) => e.preventDefault()}
              />
              <input
                type="text"
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                  navigate(`/search/${event?.target?.value}`);
                }}
                onFocus={() => {
                  navigate(`/search/${username}`);
                }}
                placeholder="Rechercher …"
              />
            </div>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <div
                className={styles.filterIcon}
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={filter}
                  alt="filter"
                  width={20}
                  height={20}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </Dropdown>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSearch}>
          <div className={styles.filters}>
            <div className={styles.searchContainer2}>
              <img
                src={search}
                alt="search"
                width={20}
                height={20}
                onContextMenu={(e) => e.preventDefault()}
              />
              <input
                type="text"
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                  navigate(`/search/${event?.target?.value}`);
                }}
                onFocus={() => {
                  navigate(`/search/${username}`);
                }}
                placeholder="Rechercher …"
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default OtherStoryFiltration;
