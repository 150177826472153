import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useAuth } from "../context/AuthContext";
import styles from "./OtherResourcesPopup.module.scss";
import {
  PodcastWhite,
  animalBreeds,
  application_de_rencontre,
  bookOtherRescource,
  burgerCross,
  eventTickets,
  filmOtherResource,
  games,
  influencerOtherResource,
  musicOtherResource,
  onlineCources,
  quoteOtherResource,
  strongOtherResource,
  videoOtherResource,
} from "../assets/index";

import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "../config/apiCall";
import { message } from "antd";
import { siteUrl } from "../environment";
const OtherResourcesPopup = ({
  handleToggle,
  handleToggleMenubar,
  toggleState,
}) => {
  const {
    token,
    sidebarSelectedTheme,
    setSidebarSelectedTheme,
    activeTheme,
    setActiveTheme,
    getThemes,
    allThemes,
  } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  // get data

  const getData = async () => {
    setDataLoading(true);

    let url;
    if (token) {
      url = `other-resources/resources-name`;
    } else {
      url = `other-resources/resources-name-without-auth`;
    }

    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token ? token : "",
      });

      if (response.status === 200) {
        setData(response?.response?.data);
      }
      // console.log("resp", response?.response?.data);
    } catch (error) {
      setDataLoading(false);
      message.error("Connection Error");
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getThemes();
  }, []);

  useEffect(() => {
    getData();
  }, [token]);

  const handleThemeChange = (themeid, poster) => {
    handleToggle();
    handleToggleMenubar();
  };

  const setThemeName = (name) => {
    if (name.includes("|")) {
      var parts = name.split("|");
      var name = parts
        .map(function (part) {
          return "<nobr>" + part + "</nobr>";
        })
        .join(" | ");
    }
    return name;
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "390px", width: "100%" }}
        modalClassName="otherResourcesModal"
        backdropClassName={styles.customBackdrop}
        backdrop="static"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody className={`${styles.ModalBody}`}>
          <div className={styles.ModalBodyHeader}>
            <h5 id="signUpModalLabel"></h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              <img
                src={burgerCross}
                alt="close"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
          <div className={styles.themes}>
            {/* First static point */}

            <div className={styles.selector}>
              <Link
                to="/ressources/top40personalities"
                onClick={(e) => {
                  handleThemeChange();
                  // setActiveTheme(item?.point);
                  handleToggle();
                  handleToggleMenubar();
                }}
              >
                <div className={styles.selectorItem}>
                  <label
                    className={`${styles.selectorItemLabel} ${
                      activeTheme == "0" ? styles.activeConversation : ""
                    }`}
                  >
                    <img
                      src={strongOtherResource}
                      alt=""
                      width={40}
                      height={40}
                      style={{ color: "white" }}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: setThemeName("TOP Personnalités Warriors !"),
                      }}
                    />
                  </label>
                </div>
              </Link>
            </div>

            {/* Other resources */}
            {data?.map((item, index) => (
              <div className={styles.selector} key={item?.point}>
                <Link
                  to={
                    item.point == "11"
                      ? `/ressources/details2/2?resourceId=${item.point}`
                      : `/ressources/details/1?resourceId=${item.point}`
                  }
                  onClick={(e) => {
                    handleThemeChange();
                    // setActiveTheme(item?.point);
                    handleToggle();
                    handleToggleMenubar();
                  }}
                >
                  <div className={styles.selectorItem}>
                    <label
                      htmlFor={item?.point + index}
                      className={`${styles.selectorItemLabel} ${
                        activeTheme === item?.point
                          ? styles.activeConversation
                          : ""
                      }`}
                    >
                      <div className={styles.iconWrapper}>
                        {item?.dot && <div className={styles.dot}></div>}

                        <img
                          src={`${siteUrl}${item?.image}`}
                          alt=""
                          width={40}
                          height={40}
                          style={{ color: "white" }}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: setThemeName(item?.name),
                        }}
                      />
                    </label>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OtherResourcesPopup;
