import React, { useState } from "react";
import CustomTextArea from "../../CustomTetxArea";
import axios from "axios";
import { Select, Spin } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";
const Livre = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
}) => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_GOOGLE_BOOOKS_API_KEY;

  const handleSearch = (query) => {
    setLoading(true);
    const apiUrl = `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(
      query
    )}&key=${apiKey}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setBooks(data.items || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Anxious request failed:", error);
        setLoading(false);
      });
  };

  // console.log("books", books);

  // Create a Set to keep track of encountered book titles
  let encounteredTitles = new Set();

  // Filter out duplicate titles and map the books array
  let allBooks = books?.reduce((uniqueBooks, book) => {
    if (!encounteredTitles.has(book?.volumeInfo?.title)) {
      encounteredTitles.add(book?.volumeInfo?.title);
      uniqueBooks.push({
        value: book.id,
        label: book?.volumeInfo?.title,
        authers: book.volumeInfo.authors?.join(", "),
      });
    }
    return uniqueBooks;
  }, []);

  // console.log("booksList", allBooks);

  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre du livre<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allBooks}
        onSearch={(e) => handleSearch(e)}
        placeholder="Titre du livre ..."
        selectedValue={resourceInputState?.livreId}
        handleOptionSelect={(value) => {
          const bookTitle = allBooks?.filter((el) => el.value === value)[0]
            ?.label;
          const authers = allBooks?.filter((el) => el.value === value)[0]
            ?.authers
            ? allBooks?.filter((el) => el.value === value)[0]?.authers
            : "";
          handleResourceInputChange({
            target: { name: "livretitle", value: bookTitle },
          });
          handleResourceInputChange({
            target: { name: "livreId", value },
          });
          handleResourceInputChange({
            target: { name: "acteur1", value: authers },
          });
        }}
        isLoading={loading}
      />
      {/* <Select
        // showSearch
        // placeholder="Titre du livre ..."
        // optionFilterProp="children"
        // onChange={(value) => {
        //   const authers = allBooks?.filter((el) => el.value === value)[0]
        //     .authers;
        //   handleResourceInputChange({
        //     target: { name: "livretitle", value },
        //   });
        //   handleResourceInputChange({
        //     target: { name: "acteur1", value: authers },
        //   });
        // }}
        // onSearch={(value) => handleSearch(value)}
        // notFoundContent={
        //   loading ? (
        //     <Spin
        //       size="small"
        //       style={{ display: "flex", justifyContent: "center" }}
        //       className="py-3"
        //     />
        //   ) : null
        // }
        // options={allBooks}
        // filterOption={(input, option) =>
        //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        // }
      ></Select> */}
      {errorMessage == "Veuillez sélectionner le titre du livre." && (
        <div className="errorMessage">{errorMessage}</div>
      )}

      <div className={styles.CustomInput}>
        <label>
          Auteur du livre <span className="text-danger">*</span>{" "}
        </label>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Auteur du livre ..."
            id="acteur1"
            name="acteur1"
            value={resourceInputState?.acteur1 || ""}
            onChange={handleResourceInputChange}
          />
        </div>
        {errorMessage == "Veuillez renseigner l’auteur du livre" && (
          <div className="errorMessage">{errorMessage}</div>
        )}
      </div>

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Livre;
