import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../../assets/styles/professionals/InfoModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const InfoModal = ({ toggleState, handleToggle, info }) => {
  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "370px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody>
          <div className={styles.body}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </div>
            <p className={styles.text}>{info}</p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InfoModal;
