import React, { useState, useRef, useEffect } from "react";
import styles from "../../assets/styles/physicalTransformation/PhysicalTransformation.module.scss";
import { Spin, message } from "antd";
import { Modal, ModalBody } from "reactstrap";
import {
  backArrowWhite,
  eyeSlash,
  nextArrowWhite,
  nextPage,
  prevPage,
} from "../../assets";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";
import SearchableSelect from "../../components/SearchableDropdown/SearchableDropdown";
import formatDate from "../../utils/DateFormater/DateFormater";
import PreviewImage from "./PreviewImage";

const filters = [
  {
    label: "Popularité",
    value: "popular",
  },
  {
    label: "Récent",
    value: "recent",
  },
  {
    label: "Le - cher",
    value: "cheapest",
  },
  {
    label: "Le + cher",
    value: "expensive",
  },
];

const PhysicalTransformation = () => {
  const { token } = useAuth();
  const [physicalTransformationData, setPhysicalTransformationData] =
    useState(null);
  const [
    physicalTransformationDataLoading,
    setPhysicalTransformationDataLoading,
  ] = useState(false);
  const listRef = useRef(null);
  const [bodyParts, setBodyParts] = useState(null);
  const [bodyPartsLoading, setBodyPartsLoading] = useState(false);
  const [selectedBodyPart, setSelectedBodyPart] = useState("TOUT");
  const [selectedBodyPartIndex, setSelectedBodyPartIndex] = useState(0);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [treatments, setTreatments] = useState(null);
  const [treatmentsLoading, setTreatmentsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("popular");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollPosition, setMaxScrollPosition] = useState(0);
  const [hasPreviousData, setHasPreviousData] = useState(false);
  const [hasNextData, setHasNextData] = useState(false);
  const [previewImageModal, setPreviewImageModal] = useState(false);
  const [viewSensitiveImageModal, setViewSensitiveImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedPhysician, setSelectedPhysician] = useState(null);
  //   Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const USERS_PER_PAGE = 4;
  const totalPages = Math.ceil(
    physicalTransformationData?.transformationData?.length / USERS_PER_PAGE
  );
  const startIndex = (currentPage - 1) * USERS_PER_PAGE;
  const endIndex = startIndex + USERS_PER_PAGE;
  const displayedPhysicalTransformationData =
    physicalTransformationData?.transformationData?.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;

    if (totalPages <= maxDisplayedPages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const middleIndex = Math.floor(maxDisplayedPages / 2);
      let startPage = currentPage - middleIndex;
      let endPage = currentPage + middleIndex;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (endPage > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };
  //   Pagination end

  // get body parts data

  const getAllBodyParts = async () => {
    setBodyPartsLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `bodypart/bodyparts-name-listing`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setBodyParts(response?.response);
        setBodyPartsLoading(false);
      }
    } catch (error) {
      message.error("Connection Error");
      setBodyPartsLoading(false);
    } finally {
      setBodyPartsLoading(false);
    }
  };

  useEffect(() => {
    getAllBodyParts();
  }, []);

  const allBodyParts = bodyParts?.map((bodyPart) => ({
    label: bodyPart?.name,
    value: bodyPart?._id,
    image: bodyPart?.image,
  }));

  const ITEMS_PER_PAGE = 23;
  const [currentLayout, setCurrentLayout] = useState(1);
  const startListIndex = (currentLayout - 1) * ITEMS_PER_PAGE;
  // const endListIndex = startListIndex + ITEMS_PER_PAGE;

  const scrollList = (direction) => {
    const listElement = listRef.current;
    if (listElement) {
      const scrollAmount = direction * listElement.offsetWidth;
      listElement.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
      setScrollPosition(scrollPosition + scrollAmount);
    }
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      const maxScroll = listElement.scrollWidth - listElement.clientWidth;
      setMaxScrollPosition(maxScroll);
      setHasPreviousData(scrollPosition > 0);
      setHasNextData(scrollPosition < maxScroll);
    }
  }, [scrollPosition]);

  const handleItemSelection = async (index, value) => {
    setSelectedBodyPartIndex(index);

    const bodyPartName = allBodyParts?.filter((el) => el.value === value)[0]
      ?.label;

    setSelectedBodyPart(bodyPartName);
    setSelectedTreatment(null);
    // setSelectedFilter(null);
    setPhysicalTransformationData(null);
    setTreatmentsLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: `bodypart/bodyparts-treatment/${value}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setTreatments(response?.response);
        setTreatmentsLoading(false);
      }
    } catch (error) {
      setTreatmentsLoading(false);
      message.error("Connection Error");
    } finally {
      setTreatmentsLoading(false);
    }
  };

  const onTreatmentChange = (treatment) => {
    setSelectedTreatment(treatment);
  };

  const getPhysicalTransformationResult = async (value) => {
    setPhysicalTransformationDataLoading(true);
    try {
      const response = await ApiCall({
        params: { bodyPart: selectedBodyPart, treatment: selectedTreatment },
        route: `physical-trans/filter/${value}`,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        console.log("response?.response", response?.response);
        setPhysicalTransformationData(response?.response);
        setPhysicalTransformationDataLoading(false);
      }
    } catch (error) {
      setPhysicalTransformationDataLoading(false);
      message.error("Connection Error");
    } finally {
      setPhysicalTransformationDataLoading(false);
    }
  };

  useEffect(() => {
    getPhysicalTransformationResult(selectedFilter);
  }, [selectedBodyPart, selectedTreatment]);

  const onFilterChange = (value) => {
    setSelectedFilter(value);
    getPhysicalTransformationResult(value);
  };

  const previewImage = () => {
    setPreviewImageModal(!previewImageModal);
  };

  const viewSensitiveImageToggle = () => {
    setViewSensitiveImageModal(!viewSensitiveImageModal);
  };

  return (
    <div className={styles.physicalTransformationPage}>
      <h4 className={styles.heading}>Transformations physiques</h4>

      <div className={styles.bodyPartsWrapper}>
        <Spin spinning={bodyPartsLoading}>
          <div ref={listRef} className={styles.bodyPartsList}>
            {allBodyParts?.map((item, index) => {
              const isSelected =
                startListIndex + index === selectedBodyPartIndex;
              return (
                <div
                  className={`${styles.option} ${
                    isSelected ? styles.selectedOption : ""
                  }`}
                  key={startListIndex + index}
                  onClick={() =>
                    handleItemSelection(startListIndex + index, item.value)
                  }
                >
                  <img height={70} width={70} src={item?.image} alt="likes" />
                  <h4>{item.label}</h4>
                </div>
              );
            })}
          </div>
        </Spin>

        <>
          <div
            className={`${styles.backArrowWhiteActive} ${
              scrollPosition === 0 || !hasPreviousData ? styles.disabled : ""
            }`}
            onClick={() => {
              if (hasPreviousData) {
                scrollList(-1);
              }
            }}
          >
            <img
              src={backArrowWhite}
              alt="back"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>

          <div
            className={`${styles.nextArrowWhiteActive} ${
              scrollPosition === maxScrollPosition || !hasNextData
                ? styles.disabled
                : ""
            }`}
            onClick={() => {
              if (hasNextData) {
                scrollList(1);
              }
            }}
          >
            <img
              src={nextArrowWhite}
              alt="next"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </>
      </div>

      <div className={styles.filters}>
        {selectedBodyPart !== "TOUT" && (
          <div className={styles.filterItem}>
            <h3 className={`${styles.resourcesTitle}`}>
              Traitement / Opération / Programme
              <span className="text-danger"> *</span>
            </h3>
            <SearchableSelect
              options={treatments?.treatments?.map((treatment) => ({
                label: treatment,
                value: treatment,
              }))}
              placeholder="Nom de la solution choisie ..."
              selectedValue={selectedTreatment}
              handleOptionSelect={onTreatmentChange}
              isLoading={treatmentsLoading}
            />
          </div>
        )}

        <div
          className={`${styles.filterItem} ${
            selectedBodyPart === "TOUT" ? styles.filterItemSingle : ""
          } `}
        >
          <h3 className={`${styles.resourcesTitle}`}>
            Trier par :<span className="text-danger"> *</span>
          </h3>
          <SearchableSelect
            options={filters}
            placeholder="Trier par ..."
            selectedValue={selectedFilter}
            handleOptionSelect={onFilterChange}
            disabled={
              selectedBodyPart === "TOUT"
                ? false
                : selectedBodyPart === null || selectedTreatment === null
            }
          />
        </div>
      </div>

      <Spin spinning={physicalTransformationDataLoading}>
        {physicalTransformationData?.totalImages ? (
          <>
            <div className={styles.resultsHeader}>
              <div className={styles.leftSide}>
                <h5 className={styles.resultsHeading}>
                  {selectedBodyPart !== "TOUT"
                    ? selectedTreatment
                    : "TOUTES LES PHOTOS"}
                </h5>
                <div className={styles.searchDetails}>
                  {/* {selectedBodyPart !== "TOUT" && ( */}
                  <>
                    <h5 className={styles.profType}>
                      {parseFloat(
                        Math.round(
                          physicalTransformationData?.percentageSatisfied
                        )
                      )}
                      % sont satisfaits
                    </h5>
                    <div className={styles.dot}></div>
                    <p className={styles.locationName}>
                      {physicalTransformationData?.totalImages}{" "}
                      {physicalTransformationData?.totalImages > 1
                        ? "Photos"
                        : "Photo"}{" "}
                    </p>
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>

            <div className={styles.physiciansResults}>
              {displayedPhysicalTransformationData?.map((physician, index) => {
                const formattedDate = formatDate(physician?.createdAt);
                return (
                  <>
                    <div
                      className={`${styles.professionalWrapper} ${
                        physician?.Plus18
                          ? styles.professionalWrapperWithBluredImages
                          : ""
                      }`}
                      onClick={() => {
                        if (physician?.Plus18) {
                          viewSensitiveImageToggle();
                          setSelectedPhysician(physician._id);
                        } else {
                          setCurrentImageIndex(index);
                          previewImage();
                        }
                      }}
                    >
                      <div className={styles.leftSide}>
                        {physician?.beforePictureUrl ? (
                          <img
                            src={physician?.beforePictureUrl}
                            alt="physicianPic"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        ) : (
                          <p className="text-black">No Image</p>
                        )}

                        <div className={styles.eyeWrapper}>
                          <img src={eyeSlash} />
                        </div>
                      </div>
                      <div className={styles.rightSide}>
                        {physician?.afterPictureUrl ? (
                          <img
                            src={physician?.afterPictureUrl}
                            alt="physicianPic"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        ) : (
                          <p className="text-black">No Image</p>
                        )}
                        <div className={styles.eyeWrapper}>
                          <img src={eyeSlash} />
                        </div>
                      </div>

                      <div className={styles.content}>
                        <div className={styles.contentLeft}>
                          <div className={styles.userInfo}>
                            <h6>Par {physician?.user?.fullname}.</h6>

                            <p>{formattedDate}</p>
                          </div>
                        </div>
                        <div className={styles.contentRight}>
                          <div>
                            <div
                              className={`${
                                physician?.isSatisfied
                                  ? styles.satisfaitBtn
                                  : styles.pasSatisfaitBtn
                              }`}
                            >
                              {physician?.isSatisfied
                                ? "Satisfait"
                                : "InSatisfait"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            {/* Pagination controls */}
            <div className={styles.paginationWrapper}>
              {/* Previous page button */}
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <img
                  src={prevPage}
                  alt="prevPage"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </button>

              {/* Page numbers */}
              {getPageNumbers().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={
                    currentPage === pageNumber
                      ? styles.activePageNumber
                      : styles.pageNumber
                  }
                  // disabled={currentPage === pageNumber}
                >
                  {pageNumber}
                </button>
              ))}

              {/* Next page button */}
              <button
                onClick={handleNextPage}
                disabled={
                  endIndex >=
                  physicalTransformationData?.transformationData?.length
                }
              >
                <img
                  src={nextPage}
                  alt="nextPage"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </button>
            </div>
          </>
        ) : (
          <div className={styles.resultsHeader}>
            <div className={styles.leftSide}>
              <p>Data will display here</p>
            </div>
          </div>
        )}
      </Spin>

      {/* preview image */}
      <PreviewImage
        toggleState={previewImageModal}
        handleToggle={previewImage}
        data={physicalTransformationData}
        currentImageIndex={currentImageIndex}
      />

      {/* view sensitive image  */}

      <Modal
        isOpen={viewSensitiveImageModal}
        centered
        style={{ maxWidth: "500px", width: "100%" }}
        toggle={viewSensitiveImageToggle}
      >
        <ModalBody>
          <div className={styles.viewSensitiveImageModal}>
            <p>
              Cette partie de PsyUP inclut du contenu avec une restriction
              d'âge, dont de la nudité. En continuant, vous affirmez que vous
              avez au moins 18 ans ou l'âge de la majorité dans la juridiction
              de votre pays, et que vous consentez à regarder du contenu
              exposant de la nudité.
            </p>

            <div className={styles.buttonsWrapper}>
              <div
                className={styles.buttonOk}
                onClick={() => {
                  viewSensitiveImageToggle();
                  setPhysicalTransformationData((prev) => ({
                    ...prev,
                    transformationData: prev?.transformationData?.map(
                      (data) => {
                        if (data._id === selectedPhysician) {
                          return { ...data, Plus18: !data.Plus18 };
                        } else {
                          return data;
                        }
                      }
                    ),
                  }));
                }}
              >
                J'ai 18 ans ou + Regarder
              </div>
              <div
                className={styles.buttonClose}
                onClick={() => {
                  viewSensitiveImageToggle();
                }}
              >
                J'ai - de 18 ans Fermer
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PhysicalTransformation;
