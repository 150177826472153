import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ApiCall } from "../../../config/apiCall";
import { message } from "antd";
import {
  burgerCrossBlack,
  calenderIcon,
  eyeIcon,
  eyeSlash,
  location,
  locationChoose,
} from "../../../assets";
import styles from "./ProfessionalSignUpStep1.module.scss";
import { getCurrentLocationAndFetchAddress } from "../../../utils/helper/GetLocation";
import DotsLoader from "../../../components/UI/DotsLoader";

const today = new Date().toISOString().split("T")[0];
const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

const ProfessionalSignUpStep1 = ({
  professionalData,
  setprofessionalData,
  initialState,
  userDataFunc,
}) => {
  const inputRef = useRef(null);
  const [formErrors, setFormErrors] = useState(initialState);
  const [professionalSignup, setProfessionalSignup] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isEmailEmpty, setisEmailEmpty] = useState(false);
  const [isEmailLoading, setisEmailLoading] = useState(false);
  const [verifyProfessionalLoading, setVerifyProfessionalLoading] =
    useState(false);
  const [maxCharacterError, setMaxCharacterError] = useState(false);
  const [FullNamemaxCharacterError, setFullNameMaxCharacterError] =
    useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();
  const [locationLoading, setLocationLoading] = useState(false);

  const handleToggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleProfessionalSignup = () => {
    setProfessionalSignup(!professionalSignup);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setprofessionalData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const renderError = (fieldName) => {
    if (formErrors[fieldName]) {
      return <p className={` ${styles.errorMsg} `}>{formErrors[fieldName]}</p>;
    }
    return null;
  };

  const checkEmail = async () => {
    setisEmailLoading(true);
    if (isEmailEmpty) {
      return false;
    }
    try {
      const response = await ApiCall({
        params: {
          email: professionalData?.email,
        },
        route: `auth/email-verification`,
        verb: "get",
        baseurl: true,
      });
      if (response.status === 200) {
        setIsEmailExist(false);
        setisEmailLoading(false);
        return true;
      } else {
        setisEmailLoading(false);
        setIsEmailExist(true);
        message.error(response.response.error?.message);
        return false;
      }
    } catch (error) {
      setisEmailLoading(false);
      message.error(error);
      return false;
    } finally {
      setisEmailLoading(false);
      // return false;
    }
  };

  const checkUserAge = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);
    let ageInYears = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      ageInYears--;
    }

    return ageInYears;
  };

  const openDatePicker = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Trigger the input click event
    }
  };

  const getSuggestion = async (val) => {
    setprofessionalData((prev) => ({
      ...prev,
      location: { ...prev?.location, address: val },
    }));
    fetch(
      ` https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=${access_token} `
    )
      .then((response) => response.json())
      .then((data) => {
        data?.features == undefined ? setshown(false) : setshown(true);
        setSuggetions(data?.features);
      });
  };

  // getting current location
  const handleGetLocation = async () => {
    setLocationLoading(true);
    try {
      const address = await getCurrentLocationAndFetchAddress();

      setprofessionalData((prevState) => ({
        ...prevState,
        location: { ...prevState?.location, address },
      }));
    } catch (error) {
      console.error(error);
      setLocationLoading(false);
    } finally {
      setLocationLoading(false);
    }
  };

  const handleSubmitProfessionalData = async (event) => {
    event.preventDefault();
    toggleProfessionalSignup();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernamePattern = /^[a-z_][a-z0-9_.]*$/;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    let errors = {};
    let hasErrors = false;

    const emailResponse = await checkEmail();
    const userAge = checkUserAge(professionalData?.dateOfBirth);

    if (emailResponse) {
      if (professionalData.firstName.trim() === "") {
        errors.firstName = "Merci de renseigner votre Prénom.";
        hasErrors = true;
      }

      if (professionalData.lastName.trim() === "") {
        errors.lastName = "Merci de renseigner votre Nom.";
        hasErrors = true;
      }

      if (professionalData.username.trim() === "") {
        errors.username = "Choisissez un nom d'utilisateur.";
        hasErrors = true;
      } else if (!usernamePattern.test(professionalData.username)) {
        errors.username =
          "Votre nom d’utilisateur ne peut contenir que des : lettres, chiffres, points et tirets bas.";
        hasErrors = true;
      } else if (maxCharacterError) {
        errors.username =
          "Le nom d'utilisateur ne peut pas dépasser 30 caractères.";
        hasErrors = true;
      }

      if (professionalData.email.trim() === "") {
        errors.email = "Merci de saisir votre adresse mail.";
        hasErrors = true;
      } else if (!emailPattern.test(professionalData.email)) {
        errors.email = "Merci de saisir une adresse mail valide.";
        hasErrors = true;
      }

      if (
        professionalData.password.trim() === "" ||
        !passwordPattern.test(professionalData.password)
      ) {
        errors.password =
          "Le mot de passe doit contenir au moins 6 caractères, dont au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial (@, $, !, %, *, ?, &).";
        hasErrors = true;
      }

      if (professionalData.dateOfBirth.trim() === "") {
        errors.dateOfBirth = "Merci de renseigner votre Date de Naissance.";
        hasErrors = true;
      }

      if (userAge <= 13) {
        errors.dateOfBirth =
          "Vous devez avoir au moins 13 ans pour utiliser PsyUP.";
        hasErrors = true;
      }

      if (hasErrors) {
        setFormErrors(errors);
      } else {
        try {
          setVerifyProfessionalLoading(true);
          const response = await ApiCall({
            params: {
              firstName: professionalData?.firstName,
              lastName: professionalData?.lastName,
              postalCode: professionalData?.postalCode,
              professionaltype: professionalData?.professionaltype,
              siren: professionalData?.siren,
            },
            route: `professional/verify-professional`,
            verb: "get",
            baseurl: true,
          });
          if (response.status === 200) {
            setFormErrors(initialState);
            userDataFunc(professionalData);
          } else {
            console.log("error: " + response.status);
          }
        } catch (error) {
          setVerifyProfessionalLoading(false);
          message.error(error);
        } finally {
          setVerifyProfessionalLoading(false);
        }
      }
    }
  };

  return (
    <div>
      <div className={styles.signUpForm}>
        <form onSubmit={handleSubmitProfessionalData}>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Nom <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Nom"
                value={professionalData?.lastName}
                name="lastName"
                onChange={handleInputChange}
              />

              {renderError("lastName")}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Prénom <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Prénom"
                name="firstName"
                value={professionalData?.firstName}
                onChange={handleInputChange}
              />
              {renderError("firstName")}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Nom d’utilisateur <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Nom d'utilisateur"
                value={professionalData?.username}
                name="username"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={() => {
                  setIsEmailExist(false);
                }}
              />

              {renderError("username")}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Nom de votre entreprise ou association{" "}
                <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Nom de votre entreprise"
                name="firmName"
                value={professionalData?.firmName}
                onChange={handleInputChange}
              />
              {renderError("firmName")}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Adresse Mail <span className="mendatory">*</span>
              </label>
              <input
                type="email"
                placeholder="exemple@exemple.com"
                value={professionalData?.email}
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                onBlur={checkEmail}
              />

              {renderError("email")}
              {isEmailLoading ? (
                <p className={styles.errorMsg}></p>
              ) : (
                isEmailExist && (
                  <p className={styles.errorMsg}>L'email existe déjà.</p>
                )
              )}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Mot de passe <span className="mendatory">*</span>
              </label>
              <div className={styles.inputEyeWrapper}>
                <input
                  type={showOldPassword ? "text" : "password"}
                  value={professionalData?.password}
                  name="password"
                  placeholder="Mot de passe"
                  autoComplete="off"
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />

                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={handleToggleShowOldPassword}
                >
                  <>
                    <img
                      className={styles.eyeClose}
                      src={showOldPassword ? eyeIcon : eyeSlash}
                      alt="eyeClose"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    {/* <div className={showOldPassword ? styles.slash : ""}></div> */}
                  </>
                </button>
              </div>
              {renderError("password")}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Date de naissance <span className="mendatory">*</span>
              </label>
              <div className={styles.inputEyeWrapper}>
                <input
                  type="date"
                  max={today}
                  placeholder="DD/MM/YYYY"
                  value={professionalData?.dateOfBirth}
                  name="dateOfBirth"
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  ref={inputRef}
                />

                <img
                  className={styles.calenderIcon}
                  src={calenderIcon}
                  alt="Calendar"
                  onClick={openDatePicker}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              {renderError("dateOfBirth")}
            </div>
            <div className={styles.inputContainer}>
              <label>
                SIRET <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Siret"
                name="siren"
                value={professionalData?.siren}
                onChange={handleInputChange}
              />
              {renderError("siren")}
            </div>
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label>
                Adresse <span className="mendatory">*</span>
              </label>
              <div className={styles.inputEyeWrapper}>
                <input
                  type={"text"}
                  value={professionalData?.location.address}
                  name="location"
                  placeholder=""
                  autoComplete="off"
                  onChange={(e) => {
                    getSuggestion(e.target.value);
                  }}
                  f
                  style={{ width: "100%" }}
                />

                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={handleGetLocation}
                >
                  <>
                    <img
                      className={styles.eyeClose}
                      src={locationChoose}
                      alt="location"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </>
                </button>
              </div>
              {renderError("location.address")}
            </div>
            <div className={styles.inputContainer}>
              <label>
                Postal Code <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Postal Code"
                name="postalCode"
                value={professionalData?.postalCode}
                onChange={handleInputChange}
              />
              {renderError("postalCode")}
            </div>
          </div>

          {/* show the list of searching */}
          {shown ? (
            <>
              {" "}
              {suggetions?.length > 0 && (
                <ul
                  className={`z-40 bg-white ${
                    suggetions?.length > 0 ? "h-[100px]" : "h-[0px]"
                  } absolute w-[22%] overflow-y-scroll px-0 `}
                >
                  {suggetions.map((item) => (
                    <li
                      onClick={() => {
                        setshown(false);
                        setprofessionalData((prev) => ({
                          ...prev,
                          location: {
                            address: item?.place_name,
                            coordinates: [item?.center[1], item?.center[0]],
                          },
                        }));
                      }}
                      className="hover:bg-blue-300 cursor-pointer"
                    >
                      {item?.place_name}
                    </li>
                  ))}
                </ul>
              )}{" "}
            </>
          ) : null}

          <p className={styles.conditionsText}>
            En vous inscrivant, vous acceptez nos{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/termsAndConditions"
            >
              Conditions générales
            </Link>
            . Découvrez comment nous recueillons, utilisons et partageons vos
            données en consultant notre{" "}
            <Link target="_blank" rel="noopener noreferrer" to="/privacy">
              Politique d’utilisation des données
            </Link>{" "}
            et comment nous utilisons les cookies et autres technologies
            similaires en lisant notre{" "}
            <Link target="_blank" rel="noopener noreferrer" to="/cookies">
              Politique d’utilisation des cookies.
            </Link>
          </p>

          <div className={styles.NextButtonWrapper}>
            {verifyProfessionalLoading ? (
              <div
                className={styles.NextButton}
                style={{
                  padding: "15px",
                  marginInline: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DotsLoader color="white" />
              </div>
            ) : (
              <input
                type="submit"
                value="Étape suivante"
                className={styles.NextButton}
              ></input>
            )}
          </div>

          {/* <div className={`${styles.buttonWrapper}`}>
            <input
              type="submit"
              value="Étape suivante"
              className={styles.buttonSuccess}
            ></input>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default ProfessionalSignUpStep1;
