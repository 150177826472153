import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "../../../assets/styles/DisplayStories.module.scss";

const ResourcesModal = ({
  toggleState,
  handleToggle,
  selectedStoryIndex,
  filteredStories,
}) => {
  const result = filteredStories[selectedStoryIndex]?.ressource?.complete;

  return (
    <div>
      {selectedStoryIndex !== null && (
        <Modal
          isOpen={toggleState}
          centered
          modalClassName="resourceModal"
          toggle={handleToggle}
        >
          <ModalBody>
            <>
              {filteredStories[selectedStoryIndex]?.ressource?.complete && (
                <>
                  <div className={styles.ressourceWrapper}>
                    {/* For film */}
                    {result?.resourcetype === "Film" && (
                      <>
                        {result?.filmetitle && <h6>{result?.filmetitle}</h6>}

                        {result?.réalisateur && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>{result?.réalisateur}</h6>
                          </>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>Avec</p>
                            <h6>
                              {result?.acteur1}
                              <span className={styles.afterTitle}>et</span>
                              {result?.acteur2}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For music */}
                    {result?.resourcetype === "Musique" && (
                      <>
                        {result?.mosiquetitle && (
                          <h6>{result?.mosiquetitle}</h6>
                        )}

                        {result?.artistname && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>{result?.artistname}</h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For book */}
                    {result?.resourcetype === "Livre" && (
                      <>
                        {result?.livretitle && <h6>{result?.livretitle}</h6>}

                        {result?.réalisateur && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>{result?.réalisateur}</h6>
                          </>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>
                              {result?.acteur1}
                              {result?.acteur2 && (
                                <>
                                  {" "}
                                  <span className={styles.afterTitle}>et</span>
                                  result?.acteur2
                                </>
                              )}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For "Série" */}
                    {result?.resourcetype === "Série" && (
                      <>
                        {result?.serietitle && <h6>{result?.serietitle}</h6>}

                        {result?.réalisateur && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>{result?.réalisateur}</h6>
                          </>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>
                              {result?.acteur1}
                              {result?.acteur2 && (
                                <>
                                  {" "}
                                  <span className={styles.afterTitle}>et</span>
                                  result?.acteur2
                                </>
                              )}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For "Vidéo" */}
                    {result?.resourcetype === "Vidéo" && (
                      <>
                        {result?.videotitle && (
                          <a target="_blank" href={result?.videourl}>
                            {result?.videotitle}
                          </a>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>
                              {result?.acteur1}
                              {result?.acteur2 && (
                                <>
                                  {" "}
                                  <span className={styles.afterTitle}>et</span>
                                  result?.acteur2
                                </>
                              )}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For "Influenceur" */}
                    {result?.resourcetype === "Influenceur" && (
                      <>
                        {result?.influenceurtitle && (
                          <h6>{result?.influenceurtitle}</h6>
                        )}
                      </>
                    )}

                    {/* For "Podcast" */}
                    {result?.resourcetype === "Podcast" && (
                      <>
                        {result?.podcasttitle && (
                          <h6>{result?.podcasttitle}</h6>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>
                              {result?.acteur1}
                              {result?.acteur2 && (
                                <>
                                  {" "}
                                  <span className={styles.afterTitle}>et</span>
                                  result?.acteur2
                                </>
                              )}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {/* For "Citation" */}
                    {result?.resourcetype === "Citation" && (
                      <>
                        {result?.citationtitle && (
                          <h6>{result?.citationtitle}</h6>
                        )}

                        {result?.acteur1 && (
                          <>
                            <p className={styles.afterTitle}>de</p>
                            <h6>
                              {result?.acteur1}
                              {result?.acteur2 && (
                                <>
                                  {" "}
                                  <span className={styles.afterTitle}>et</span>
                                  result?.acteur2
                                </>
                              )}
                            </h6>
                          </>
                        )}
                      </>
                    )}

                    {result?.resourceReason && (
                      <p className={styles.para}>{result?.resourceReason}</p>
                    )}
                  </div>
                </>
              )}
            </>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default ResourcesModal;
