import React, { useState } from "react";
import styles from "../../assets/styles/profile/BlockUserModal.module.scss";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Buttonfilled from "../../components/buttonFilled";
import { ApiCall } from "../../config/apiCall";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const BlockUserModal = ({ toggleState, handleToggle, userId, userDetails }) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const blockUser = async () => {
    // console.log("blockUser");
    try {
      setLoading(true);

      const response = await ApiCall({
        params: {},
        route: "user/blockUser/" + userId,
        verb: "put",
        token: token,
        baseurl: true,
      });
      if (response.status === 200) {
        handleToggle();
        message.success(`${response.response.message}`);
        navigate("/");
      } else {
        message.error(`${response.response.errorMessage}`);
      }
      console.log("response", response);
    } catch (error) {
      // message.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "370px", width: "100%" }}
        centered
        modalClassName="blockUserModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody>
          <div className={styles.header}>
            <h5 id="signUpModalLabel">Bloquer {userDetails?.fullname} ?</h5>
          </div>

          <div className={styles.body}>
            <p className={styles.text}>
            {userDetails?.fullname} ne pourra ni voir vos activités, ni vous contacter.
            </p>
            {loading ? (
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner color="danger" />
              </div>
            ) : (
              <div className="pt-3 mx-3">
                <Buttonfilled
                  title="Bloquer"
                  textColr="white"
                  handleClick={blockUser}
                />
              </div>
            )}

            <div className={styles.annuler} onClick={handleToggle}>
              <p>Annuler</p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BlockUserModal;
