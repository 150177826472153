import React, { useState, useRef } from "react";
import axios from "axios";
import CustomTextArea from "../../CustomTetxArea";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import { useEffect } from "react";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";

// SPOTIFY KEYS
const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

// TEDDY_SECRET
const TEDDY_USER_ID = 480;
const TEDDY_SECRET_KEY =
  "2228769fccdd907087c5cfd1cf138a294ec4d3cbd4a5ab61c7072d4c057f29f799e55e574bb67d80ac639d4e089d634e5d";
const Podcast = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
  setErrorMessage,
}) => {
  const [podcastList, setPodcastList] = useState();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const offset = 0;
  const market = "FR";
  const limit = 50;
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
      // selectRef?.current?.setSelectionRange(selectRef.current?.value?.length, selectRef.current?.value?.length);
    }
  }, []);

  useEffect(() => {
    // Fetch the access token using the client credentials flow
    const fetchAccessToken = async () => {
      try {
        const response = await axios.post(
          "https://accounts.spotify.com/api/token",
          "grant_type=client_credentials",
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
            },
          }
        );

        setAccessToken(response.data.access_token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessToken();
  }, []);

  const handleSearch = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.spotify.com/v1/search?q=${e}&type=show&offset=${offset}&limit=${limit}&market=${market}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data?.shows || []);
        setPodcastList(response.data?.shows?.items);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching podcasts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      handleSearch();
    }
  }, [accessToken]);

  // Function to fetch a list of featured podcasts

  // async function fetchTopPodcasts() {
  //   const limit = 200;
  //   const countryCode = "FR";

  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `https://itunes.apple.com/${countryCode}/rss/toppodcasts/limit=${limit}/json`
  //     );
  //     setLoading(false);
  //     const data = await response.json();

  //     // The top podcasts are available in data.feed.entry array
  //     const topPodcasts = data.feed.entry;

  //     // Extract 'im:name' and 'im:artist' properties from all podcast entries
  //     const podcastsData = topPodcasts.map((podcast) => ({
  //       name: podcast["im:name"]?.label,
  //       artist: podcast["im:artist"]?.label,
  //       id: podcast["id"]?.attributes["im:id"],
  //     }));

  //     return podcastsData;
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error fetching top podcasts:", error);
  //     return [];
  //   }
  // }

  // useEffect(() => {
  //   fetchTopPodcasts()
  //     .then((topPodcasts) => {
  //       setPodcastList(topPodcasts);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);

  // const fetchData = async (title) => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get("https://api.taddy.org/graphql", {
  //       params: {
  //         query: `{
  //           getPodcastSeries(name: "${title}") {
  //             uuid
  //             name
  //             itunesId
  //             description
  //             imageUrl
  //             itunesInfo {
  //               uuid
  //               publisherName
  //               baseArtworkUrlOf(size: 640)
  //             }

  //           }
  //         }`,
  //       },
  //       headers: {
  //         "X-USER-ID": TEDDY_USER_ID,
  //         "X-API-KEY": TEDDY_SECRET_KEY,
  //       },
  //     });

  //     setLoading(false);
  //     setPodcastList(response.data?.data?.getPodcastSeries);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData(resourceInputState?.podcasttitle);
  // }, [resourceInputState?.podcasttitle]);

  let allPodcasts = podcastList?.map((podcast) => ({
    value: podcast?.id,
    label: podcast?.name,
    artist: podcast?.publisher,
  }));

  function handlePodacastTitleClearSelect() {
    handleResourceInputChange({
      target: { name: "podcasttitle", value: "" }, // Clear the value by setting it to undefined
    });
    handleResourceInputChange({
      target: { name: "podcastId", value: "" },
    });
    handleResourceInputChange({
      target: { name: "acteur1", value: "" },
    });
  }

  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Titre du podcast<span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allPodcasts}
        onSearch={(e) => handleSearch(e)}
        placeholder="Titre du podcast ..."
        selectedValue={resourceInputState["podcastId"]}
        handleOptionSelect={(value) => {
          const name = allPodcasts?.filter((el) => el.value === value)[0]
            ?.label;
          const artist = allPodcasts?.filter((el) => el.value === value)[0]
            ?.artist;
          setErrorMessage("");
          handleResourceInputChange({
            target: { name: "podcastId", value },
          });
          handleResourceInputChange({
            target: { name: "podcasttitle", value: name },
          });
          handleResourceInputChange({
            target: { name: "acteur1", value: artist },
          });
        }}
        isLoading={loading}
        onCrossClick={handlePodacastTitleClearSelect}
      />
      {/* <Select
        // showSearch
        // placeholder="Titre du podcast ..."
        // optionFilterProp="children"
        onChange={(value) => {
          const name = allPodcasts?.filter((el) => el.value === value)[0].name;
          const artist = allPodcasts?.filter((el) => el.value === value)[0]
            .artist;
          setErrorMessage("");
          handleResourceInputChange({
            target: { name: "podcasttitle", name },
          });
          handleResourceInputChange({
            target: { name: "acteur1", value: artist },
          });
        }}
        // value={resourceInputState["podcasttitle"]}
        // onSearch={(e) => fetchData(e)}
        // options={allPodcasts}
        // notFoundContent={
        //   loading ? (
        //     <Spin
        //       size="small"
        //       style={{ display: "flex", justifyContent: "center" }}
        //       className="py-3"
        //     />
        //   ) : null
        // }
        // filterOption={(input, option) =>
        //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        // }
        ref={selectRef}
        // showArrow={false}
        style={{ caretColor: "transparent" }}
      ></Select> */}
      {/* {resourceInputState["podcasttitle"] && (
        <button
          style={{
            zIndex: "99",
            position: "absolute",
            marginTop: "0.3rem",
            // top: "1%",
            right: "4rem",
            // transform: "translateY(-50%)",
            border: "none",
            background: "transparent",
            cursor: "pointer",
          }}
          onClick={handlePodacastTitleClearSelect}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 6.41l-1.41-1.41L12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </button>
      )} */}

      {errorMessage == "Veuillez sélectionner le titre du podcast." && (
        <div className="errorMessage">{errorMessage}</div>
      )}

      <div className={styles.CustomInput}>
        <label>
          Auteur du podcast <span className="text-danger">*</span>{" "}
        </label>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Auteur du podcast ..."
            id="acteur1"
            name="acteur1"
            value={resourceInputState?.acteur1 || ""}
            onChange={handleResourceInputChange}
          />
        </div>
        {errorMessage == "Veuillez renseigner l’auteur du podcast." && (
          <div className="errorMessage">{errorMessage}</div>
        )}
      </div>

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        value={resourceInputState.resourceReason}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Podcast;
