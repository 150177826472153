import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styles from "../../assets/styles/professionals/Reviews.module.scss";
import { burgerCrossBlack, starRated, starUnrated } from "../../assets";
import Rating from "react-rating";
import { message } from "antd";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import DotsLoader from "../../components/UI/DotsLoader";
import formatDate from "../../utils/DateFormater/DateFormater";
import SkeltonList from "../../components/UI/SkeltonList";

const initialState = {
  review: "",
  rating: 0,
  user_id: "",
  professional_id: "",
};

const Reviews = (props) => {
  const { state } = useLocation();
  const { token, userInfo } = useAuth();
  let { proId } = useParams();
  const navigate = useNavigate();
  localStorage.setItem("proId", proId);
  const [reviewsList, setReviewsList] = useState([]);

  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);

  const [showRatings, setShowRatings] = useState(false);
  const [addReviewLoading, setAddReviewLoading] = useState(false);
  const [ReviewsListLoading, setReviewsListLoading] = useState(false);

  // update formdata after loading user id and professional id
  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        user_id: userInfo?._id,
        professional_id: proId,
      };
    });
  }, [proId, userInfo]);

  // get reviews list

  const getReviews = async (proId) => {
    try {
      setReviewsListLoading(true);
      const response = await ApiCall({
        params: {},
        route: `professional/review-listing/${proId}`,
        verb: "get",
        token,
        baseurl: true,
      });
      if (response.status === 200) {
        setReviewsList(response?.response?.reviews);
      } else {
        message.error(`Something went wrong`);
      }
    } catch (error) {
    } finally {
      setReviewsListLoading(false);
    }
  };

  useEffect(() => {
    getReviews(proId);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: "" }));
  };

  const handleRatingChange = (value) => {
    setFormData((prevState) => ({ ...prevState, rating: value }));
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    let hasErrors = false;

    // Check for empty fields
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        errors[key] = "Can't send empty review.";
        hasErrors = true;
      } else {
        hasErrors = false;
      }
    });

    if (hasErrors) {
      setFormErrors(errors);
    } else {
      try {
        setAddReviewLoading(true);
        const response = await ApiCall({
          params: formData,
          route: "professional/review-add",
          verb: "post",
          token,
          baseurl: true,
        });
        if (response.status == 200) {
          message.success("Review submitted");
          setFormData((prevState) => {
            return {
              ...prevState,
              review: "",
              rating: 0,
            };
          });

          setReviewsList((prev) => [response.response.review, ...prev]);
        } else {
          message.error(`Something went wrong`);
        }
      } catch (error) {
      } finally {
        setAddReviewLoading(false);
      }
    }
  };

  const handleInputClick = () => {
    setShowRatings(true);
  };

  const goToBack = () => {
    navigate("/professionals");
  };

  console.log("state", state);

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.reviewsHeader}>
        <div className={styles.reviewsHeaderTop}>
          <div className={styles.reviewsHeaderTopLeft}>
            <h2>{state?.professional?.professional?.fullName}</h2>

            <div className={styles.totalReviews}>
              <div className={styles.rating}>
                <Rating
                  initialRating={
                    state?.professional?.reviews?.averageRating
                  }
                  readonly
                  emptySymbol={
                    <img
                      src={starUnrated}
                      style={{ marginRight: "5px" }}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  }
                  fullSymbol={
                    <img
                      src={starRated}
                      style={{ marginRight: "5px" }}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  }
                />
              </div>
              <p>
                {state?.professional?.reviews?.reviewsCount || "0"} avis
              </p>
            </div>
          </div>
          <button aria-label="Close" type="button" onClick={goToBack}>
            <img
              className="py-2"
              src={burgerCrossBlack}
              alt="close"
              onContextMenu={(e) => e.preventDefault()}
            />
          </button>
        </div>
        <p className={styles.profType}>
          {state?.professional?.professional?.professionaltype}
        </p>
        <div className={styles.totalReviewsMobileView}>
          <div className={styles.rating}>
            <Rating
              initialRating={state?.professional?.reviews?.averageRating}
              readonly
              emptySymbol={
                <img
                  src={starUnrated}
                  style={{ marginRight: "5px" }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              }
              fullSymbol={
                <img
                  src={starRated}
                  style={{ marginRight: "5px" }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              }
            />
          </div>
          <p>{state?.professional?.reviews?.reviewsCount || "0"} avis</p>
        </div>
      </div>

      <form className={styles.reviewInputWrapper} onSubmit={handelSubmit}>
        {/* Hidden by default, but displayed when input is clicked */}
        {showRatings && (
          <div className={styles.rating}>
            <Rating
              rating={formData.rating}
              initialRating={formData.rating}
              onChange={handleRatingChange}
              emptySymbol={
                <img
                  src={starUnrated}
                  style={{ marginRight: "5px" }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              }
              fullSymbol={
                <img
                  src={starRated}
                  style={{ marginRight: "5px" }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              }
            />
          </div>
        )}
        <input
          type="text"
          placeholder="Écrire un avis …"
          id="review"
          name="review"
          value={formData.review}
          onChange={handleInputChange}
          onClick={handleInputClick}
        />

        {formErrors.review && (
          <p
            className={`errorMessage ${
              formErrors.review ? styles.hideMobileError : ""
            }`}
          >
            {formErrors.review}
          </p>
        )}

        {/* Hidden by default, but displayed when input is clicked */}
        {showRatings && (
          <div className={styles.buttonWrapper}>
            <div className={styles.submitButton} onClick={handelSubmit}>
              {addReviewLoading ? (
                <div className={styles.loader}>
                  <DotsLoader color="white" />
                </div>
              ) : (
                <p>Poster l'avis</p>
              )}
            </div>
          </div>
        )}
      </form>

      {/* reviews list */}
      {ReviewsListLoading ? (
        <SkeltonList />
      ) : (
        <>
          {reviewsList &&
            reviewsList?.map((review, index) => {
              const formattedDate = formatDate(review?.updatedAt);
              return (
                <>
                  <div className={styles.review}>
                    <div className={styles.nameDate}>
                      <h2>{review?.user_id?.fullname}</h2>
                      <p className={styles.date}>{formattedDate}</p>
                    </div>
                    <div className={styles.rating}>
                      <Rating
                        initialRating={review?.rating}
                        readonly
                        emptySymbol={
                          <img
                            src={starUnrated}
                            style={{ marginRight: "5px" }}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        }
                        fullSymbol={
                          <img
                            src={starRated}
                            style={{ marginRight: "5px" }}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        }
                      />
                    </div>
                    <p>{review?.review}</p>
                  </div>
                </>
              );
            })}
        </>
      )}
    </div>
  );
};

export default Reviews;
