import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/chatBot/ChatBot.module.scss";
import {
  adminProfilePic,
  burgerCross,
  chatBotIcon,
  pointerDown,
  userAvatar,
} from "../../assets";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SubmitStory from "../../components/stories/SubmitStory";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";

const ChatBot = ({ toggleState, handleToggle }) => {
  const { token, loginModal, setLoginModal } = useAuth();
  const [myMessage, setMyMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [submitStoryModal, setSubmitStoryModal] = useState(false);
  const [askToChatUP, setAskToChatUP] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState("");

  const userpic = localStorage.getItem("userpic");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDay("Bonjour !");
    }
    // else if (currentHour >= 12 && currentHour < 17) {
    //   setTimeOfDay("Bon après-midi !");
    // }
    else {
      setTimeOfDay("Bonsoir !");
    }
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef?.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  // show submitStory modal
  const toggleSubmitStoryModal = () => {
    if (token) {
      setSubmitStoryModal(!submitStoryModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  // show ask question
  const askToChatUpHandeler = () => {
    setAskToChatUP(true);
  };

  useEffect(() => {
    const today = new Date();
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    const formattedDate = `${today.getDate()} ${months[today.getMonth()]}`;
    setCurrentDate(formattedDate);
  }, []);

  // submit query

  const submitQuery = async () => {
    if (myMessage != "") {
      try {
        setChat((prevState) => [
          ...prevState,
          {
            message: myMessage,
            sender: "user",
          },
        ]);
        setMessageLoading(true);
        const response = await ApiCall({
          params: { question: myMessage },
          route: "user/chatgpt",
          verb: "post",
          token: token,
          baseurl: true,
        });
        console.log("response: " + response.response.response);
        if (response.response.status == 200) {
          setMyMessage("");
          setChat((prevState) => [
            ...prevState,
            {
              message: response?.response?.response,
              sender: "gpt",
            },
          ]);
        }
      } catch (error) {
        // message.error(error);
      } finally {
        setMessageLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "400px", width: "100%" }}
        centered
        // backdropClassName="customBackdrop"
        modalClassName="chatUpModal"
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody>
          <div className={styles.chatBotContainer}>
            <div className={styles.chatBotHeader}>
              <div className={styles.logoContainer}>
                <img
                  src={chatBotIcon}
                  alt="chatbot"
                  onContextMenu={(e) => e.preventDefault()}
                />
                <h6>ChatUP</h6>
              </div>
              <div onClick={handleToggle} className={styles.closeIcon}>
                <img
                  src={burgerCross}
                  alt="chatbot"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </div>

            <div className={styles.chatBotBody}>
              <div className={styles.scrollableContent} ref={chatContainerRef}>
                <p className={styles.date}>{currentDate}</p>

                <div className={styles.chating}>
                  <div className={styles.answer}>
                    <img src={adminProfilePic} />
                    <p>
                      {timeOfDay} Vous avez une question sur un de vos citrons ?{" "}
                    </p>
                  </div>

                  <div className={styles.select}>
                    <p className={styles.heading}>
                      Sélectionnez une option <img src={pointerDown} />
                    </p>
                    <p onClick={askToChatUpHandeler} className={styles.option}>
                      Posez-là à ChatUP, notre IA !{" "}
                    </p>

                    <p
                      onClick={toggleSubmitStoryModal}
                      className={styles.option}
                    >
                      Posez-là à la communauté PsyUP !
                    </p>
                    <Link to="/professionals">
                      <p className={styles.option}>
                        Posez-là à un professionnel : prenez un rendez-vous !
                      </p>
                    </Link>
                  </div>

                  {askToChatUP && (
                    <div className={styles.answer}>
                      <img src={adminProfilePic} />
                      <p>Ok, quelle est votre question ? </p>
                    </div>
                  )}

                  {chat?.map((chat) => {
                    return (
                      <>
                        {chat?.sender === "user" ? (
                          <div className={styles.question}>
                            <p className={styles.option}>{chat.message}</p>
                            <img src={userpic ? userpic : userAvatar} />
                          </div>
                        ) : (
                          <div className={styles.answer}>
                            <img src={adminProfilePic} />
                            <p className={styles.option}>{chat.message}</p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>

              <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.messageInput}
              >
                <input
                  value={myMessage}
                  onChange={(e) => setMyMessage(e.target.value)}
                  placeholder="Écrire un message …"
                />
                {messageLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 25,
                            color: "#01989f",
                          }}
                          spin
                        />
                      }
                    />
                  </div>
                ) : (
                  <button
                    onClick={submitQuery}
                    className={styles.sendCommntBtn}
                  >
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ color: "gray" }}
                    />
                  </button>
                )}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <SubmitStory
        toggleState={submitStoryModal}
        handleToggle={toggleSubmitStoryModal}
      />
    </div>
  );
};

export default ChatBot;
