import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/ContactUs.module.scss";
import { useAuth } from "../../context/AuthContext";
import Spinner2 from "../../components/Spinner2";
import { toast } from "react-toastify";

const AdminLogin = () => {
  const { adminLogin, setToken, setUserInfo, setUserId } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // logout user on navgating to admin
  useEffect(() => {
    setToken("");
    setUserId("");
    setUserInfo(null);
    localStorage.setItem("token", "");
    localStorage.setItem("userId", "");
    // localStorage.setItem("username", "");
    localStorage.setItem("userFullName", "");
    localStorage.setItem("userpic", "");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordIsValid = password.trim() !== "";
    const emailIsValid = email.trim() !== "";

    if (!(passwordIsValid && emailIsValid)) {
      toast.error("Veuillez remplir toutes les entrées");
    } else {
      // api call
      await adminLogin(email, password, setEmail, setPassword, setLoading);
    }
  };

  return (
    <div className={styles.contactUsPage}>
      <div className={styles.contactUsBox}>
        <h2 className={styles.contactUsTitle}>Connexion Admin</h2>
        <form onSubmit={handleSubmit} className={styles.contactUsForm}>
          <div className={styles.contactDetails}>
            <label>Adresse Mail</label>
            <input
              className={styles.contactUsInput}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.contactDetails}>
            <label>Mot de passe</label>
            <input
              className={styles.contactUsInput}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Spinner2 />
            </div>
          ) : (
            <input
              type="submit"
              className={styles.contactUsFormButton}
              value="Connexion"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
