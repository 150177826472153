import React, { useEffect, useState } from "react";

import { useAuth } from "../context/AuthContext";
import DisplayStories from "../components/stories/DisplayStories";
import CookiesPage from "../components/cookies/CookiesPage";
import StoryFiltrationByTime from "../components/home/StoryFiltrationByTime";
import { useMediaQuery } from "react-responsive";
import StoryFiltrationByThemeMobile from "../components/home/StoryFiltrationByThemeMobile";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styles from "../assets/styles/home.module.scss";
import { assitentIcon, beforeAfter } from "../assets";
import ChatBot from "./chatBot/ChatBot";

const Home = ({ showTimeFrameFilters }) => {
  const {
    allUsersStories,
    setallUsersStories,
    storyCount,
    setStoryCount,
    sidebarSelectedTheme,
    getThemes,
    allThemes,
  } = useAuth();

  const [chatupModal, setChatupModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  useEffect(() => {
    if (!allThemes) {
      getThemes();
    }
  }, []);

  const toggleChatUpModal = () => {
    setChatupModal(!chatupModal);
  };

  return (
    <div>
      <StoryFiltrationByTime
        showTimeFrameFilters={showTimeFrameFilters}
        selectedTheme={sidebarSelectedTheme?.themeid}
      />
      {isTabletOrMobile && <StoryFiltrationByThemeMobile themes={allThemes} />}

      {(sidebarSelectedTheme?.themeid === "64ed88d445fa35a388aeef09" ||
        sidebarSelectedTheme?.themeid === "64ed817245fa35a388aeeee9") && (
        <Link to="/physical-transformation" className={styles.themeButton}>
          <h3>TRANSFORMATIONS PHYSIQUES</h3>
          <ReactSVG className="ps-2" src={beforeAfter} />
        </Link>
      )}
      <DisplayStories
        stories={allUsersStories}
        setStories={setallUsersStories}
        selectedTheme={sidebarSelectedTheme?.themeid}
        setStoryCount={setStoryCount}
        storyCount={storyCount}
      />
      <CookiesPage />
      <div onClick={toggleChatUpModal}>
        <img
          className="assitentIcon"
          src={assitentIcon}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <ChatBot toggleState={chatupModal} handleToggle={toggleChatUpModal} />
    </div>
  );
};

export default Home;
