import {
  faCopy,
  faDeleteLeft,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useRef } from "react";
import styles from "./AllPersonalities.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Menu, Modal, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// tiny
import { Editor } from "@tinymce/tinymce-react";
import {
  commentsIcon,
  favorite,
  love,
  nextPage,
  prevPage,
  share,
  storyEdit,
} from "../../../assets";
import { useAuth } from "../../../context/AuthContext";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { siteUrl } from "../../../environment";
import { ApiCall } from "../../../config/apiCall";
import StoryLoader from "../../../components/UI/StoryLoader";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

const AllPersonalities = () => {
  const { token, userInfo } = useAuth();
  let { typeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  localStorage.setItem("resourceId", resourceId);
  const [updatedPersonalityContent, setUpdatedPersonalityContent] =
    useState(null);
  const [personalityId, setPersonalityId] = useState(null);

  const [allPersonalities, setallPersonalities] = useState(null);
  const [loadingPersonalities, setloadingPersonalities] = useState(false);
  const [updatePersonalityLoading, setupdatePersonalityLoading] =
    useState(false);
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [courcesModalOpen, setCourcesModalOpen] = useState(false);
  const [reactions, setReactions] = useState({});
  const [isCommentsOpen, setisCommentsOpen] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (person) => {
    setUpdatedPersonalityContent(person.person);
    setPersonalityId(person._id);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleDragStart = (e, item) => {
  //   setallPersonalities({ ...allPersonalities, draggingItem: item });
  //   e.dataTransfer.setData("text/plain", "");
  // };

  // const handleDragEnd = () => {
  //   setallPersonalities({ ...allPersonalities, draggingItem: null });
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e, targetItem) => {
  //   const { draggingItem, data } = allPersonalities;
  //   console.log("draggingItem", draggingItem);
  //   console.log("data", data);
  //   if (!draggingItem) return;

  //   const currentIndex = data.indexOf(draggingItem);
  //   const targetIndex = data.indexOf(targetItem);

  //   if (currentIndex !== -1 && targetIndex !== -1) {
  //     data.splice(currentIndex, 1);
  //     data.splice(targetIndex, 0, draggingItem);
  //     setallPersonalities({ ...allPersonalities, data });
  //   }
  // };

  // console.log("personalityId", personalityId);
  // console.log("allPersonalities", allPersonalities);

  const editorRef = useRef(null);
  const hangleChangePersonalityContent = () => {
    if (editorRef.current) {
      setUpdatedPersonalityContent(editorRef.current.getContent());
    }
  };

  const updatePersonality = async () => {
    const formData = new FormData();
    formData.append("data", updatedPersonalityContent);

    if (updatedPersonalityContent != null) {
      setupdatePersonalityLoading(true);
      try {
        const res = await ApiCall({
          route: `other-resources/update-personality/${allPersonalities?._id}/${personalityId}`,
          token: token,
          verb: "put",
          params: formData,
        });

        if (res?.status === 200) {
          getPersonalities();
          setIsModalOpen(false);
          setUpdatedPersonalityContent(null);
          message.success("Added personality");
          setupdatePersonalityLoading(false);
        } else {
          console.log("error", res);
          setupdatePersonalityLoading(false);
        }
      } catch (e) {
        console.log("error", e.toString());
      }
    }
  };

  const deletePersonality = async (personId) => {
    try {
      const res = await ApiCall({
        route: `other-resources/delete-personality/${allPersonalities?._id}/${personId}`,
        token: token,
        verb: "delete",
        params: {},
      });

      if (res?.status === 200) {
        getPersonalities();

        message.success("Deleted personality");
      } else {
        console.log("error", res);
      }
    } catch (e) {
      console.log("error", e.toString());
    }
  };

  const [showCommentsIndex, setShowCommentsIndex] = useState(-1);
  //   Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const USERS_PER_PAGE = 5;
  const totalPages = Math.ceil(allPersonalities?.data?.length / USERS_PER_PAGE);
  const startIndex = (currentPage - 1) * USERS_PER_PAGE;
  const endIndex = startIndex + USERS_PER_PAGE;
  const displayedProfessionals = allPersonalities?.data?.slice(
    startIndex,
    endIndex
  );

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;

    if (totalPages <= maxDisplayedPages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const middleIndex = Math.floor(maxDisplayedPages / 2);
      let startPage = currentPage - middleIndex;
      let endPage = currentPage + middleIndex;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (endPage > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };
  //   Pagination end

  const reactionsArray = [
    {
      emogi: love,
      value: "love",
      selected: false,
      id: 1,
      height: 25,
      width: 25,
      title: "J’aime !",
      length: 8,
    },
  ];
  const items = [
    {
      label: "Copy link",
      key: "0",
      // onClick: (index) => {
      //   const selectedStory = selectedResource?.stories[index];
      //   const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
      //   navigator.clipboard?.writeText(storyLink);
      //   message.success("Story link copied to clipboard!");
      // },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: (index) => {
        // const selectedStory = selectedResource?.stories[index];
        // const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <FacebookShareButton
          // url={storyLink}
          // quote={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
              </svg>
            </a>
          </FacebookShareButton>
        );
      },
    },
    {
      label: "WhatsApp",
      key: "2",
      render: (index) => {
        // const selectedStory = selectedResource?.stories[index];
        // const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <WhatsappShareButton
          // url={storyLink}
          // title={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
              </svg>
            </a>
          </WhatsappShareButton>
        );
      },
    },
    {
      label: "Twitter",
      key: "3",
      render: (index) => {
        // const selectedStory = selectedResource?.stories[index];
        // const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
        return (
          <TwitterShareButton
          // url={storyLink}
          // title={selectedResource?.stories[index]?.title}
          >
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </TwitterShareButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (token) {
      getPersonalities();
    }
  }, [token]);

  const getPersonalities = async () => {
    setloadingPersonalities(true);
    try {
      const res = await ApiCall({
        route: `other-resources/otherResourcesPoint/51`,
        token: token,
        verb: "get",
        params: {},
      });

      if (res?.status === 200) {
        console.log("getPersonalities", res);
        setallPersonalities(res?.response?.data);
        setloadingPersonalities(false);
      } else {
        console.log("getPersonalities", res);
        setloadingPersonalities(false);
      }
    } catch (e) {
      setloadingPersonalities(false);
      console.log("saga doctor add error -- ", e.toString());
    }
  };

  return (
    <>
      {/* <div className="sortable-list">
        {allPersonalities?.data?.map((person, index) => (
          <div
            key={person?._id}
            className={`item ${
              person === allPersonalities?.draggingItem ? "dragging" : ""
            }`}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, person)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, person)}
          >
            <div className={styles.allPersonalitiesContainer}>
              <div className={styles.body}>
                <div className={styles.bodyContent}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: person.person,
                    }}
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.icons}>
                    <div className={styles.favStarIcon}>
                      {true ? (
                        <div className={styles.icon}>
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{ color: "#F5D003" }}
                          />
                        </div>
                      ) : (
                        <img
                          src={favorite}
                          alt="logo"
                          className={styles.icon}
                          width={22}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )}
                    </div>

                    <div className={styles.commentsWrapper}>
                      <img
                        src={commentsIcon}
                        className={styles.icon}
                        alt="comments"
                        width={20}
                        height={20}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <p>(0)</p>
                    </div>

                    <Dropdown
                      overlay={
                        <Menu className={styles.dropdownMenu}>
                          {items.map((item) => (
                            <Menu.Item
                              key={item.key}
                              onClick={() => item.onClick && item.onClick()}
                            >
                              {item.render ? item.render() : item.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={share}
                          alt="logo"
                          className={styles.icon}
                          width={22}
                          height={22}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </a>
                    </Dropdown>

                    {userInfo?.isAdmin && (
                      <>
                        <div
                          className={styles.favStarIcon}
                          onClick={() => showModal(person)}
                        >
                          <img
                            src={storyEdit}
                            alt="logo"
                            className={styles.icon}
                            width={22}
                            height={20}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>

                        <div onClick={() => deletePersonality(person?._id)}>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            style={{
                              color: "gray",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.reactionsWrapper}>
                {reactionsArray.map((reaction, index) => {
                  return (
                    <>
                      <div className={styles.tooltipContainer}>
                        <button
                          className={` 
                
                  ${styles.reaction}`}
                          data-tooltip={reaction.title}
                        >
                          <img
                            src={reaction.emogi}
                            alt="like"
                            width={reaction.width}
                            height={reaction.height}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </button>
                        <span className={styles.tooltipText}>
                          {reaction.title}
                        </span>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div> */}
      {loadingPersonalities ? (
        <StoryLoader />
      ) : (
        <>
          {displayedProfessionals?.map((person) => {
            return (
              <div className={styles.allPersonalitiesContainer}>
                <div className={styles.body}>
                  <div className={styles.bodyContent}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: person.person,
                      }}
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.icons}>
                      <div
                        className={styles.favStarIcon}
                        // onClick={() => handleFavoriteClick(story._id)}
                      >
                        {true ? (
                          <div className={styles.icon}>
                            <FontAwesomeIcon
                              icon={faStar}
                              style={{ color: "#F5D003" }}
                            />
                          </div>
                        ) : (
                          <img
                            src={favorite}
                            alt="logo"
                            className={styles.icon}
                            width={22}
                            height={20}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        )}
                      </div>

                      <div
                        className={styles.commentsWrapper}
                        // onClick={() => {
                        //   getStoryMostreleventComments(story._id);
                        //   if (token) {
                        //     setShowCommentsIndex(storyIndex);
                        //     setisCommentsOpen((prevState) => [
                        //       storyIndex,
                        //     ]);
                        //   }
                        // }}
                      >
                        <img
                          src={commentsIcon}
                          className={styles.icon}
                          alt="comments"
                          width={20}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        {/* <p>
                    (
                    {allComments?.data?.list[0]?.storyId ===
                    (story._id
                        ? allComments?.data?.list.length
                        : story.comments_count)}
                    )
                </p> */}
                        <p>(0)</p>
                      </div>

                      <Dropdown
                        overlay={
                          <Menu className={styles.dropdownMenu}>
                            {items.map((item) => (
                              <Menu.Item
                                key={item.key}
                                onClick={() => item.onClick && item.onClick()}
                              >
                                {item.render ? item.render() : item.label}
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <img
                            src={share}
                            alt="logo"
                            className={styles.icon}
                            width={22}
                            height={22}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </a>
                      </Dropdown>

                      {userInfo?.isAdmin && (
                        <>
                          <div
                            className={styles.favStarIcon}
                            onClick={() => showModal(person)}
                          >
                            <img
                              src={storyEdit}
                              alt="logo"
                              className={styles.icon}
                              width={22}
                              height={20}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </div>

                          <div onClick={() => deletePersonality(person?._id)}>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{
                                color: "gray",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.reactionsWrapper}>
                  {reactionsArray.map((reaction, index) => {
                    return (
                      <>
                        <div className={styles.tooltipContainer}>
                          <button
                            // onClick={() => {
                            //   handleReactionClick(
                            //     story._id,
                            //     index,
                            //     storyIndex,
                            //     story,
                            //     selectedIndex === index ||
                            //       (!selectedIndex &&
                            //         story.reactionId - 1 === index)
                            //   );
                            // }}
                            className={` 
                
                  ${styles.reaction}`}
                            data-tooltip={reaction.title}
                          >
                            <img
                              src={reaction.emogi}
                              alt="like"
                              width={reaction.width}
                              height={reaction.height}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </button>
                          <span className={styles.tooltipText}>
                            {reaction.title}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}

          <Modal
            title="Update Personality"
            open={isModalOpen}
            onOk={updatePersonality}
            okText="Update personality"
            confirmLoading={updatePersonalityLoading}
            onCancel={handleCancel}
          >
            <Editor
              apiKey="o26kfl6dfoxj3yv3uverykywo6grt4y0ml5pt8t0hwarjnyq"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                plugins:
                  "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
                editimage_cors_hosts: ["picsum.photos"],
                menubar: "file edit view insert format tools table help",
                toolbar:
                  "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                mergetags_list: [
                  { value: "First.Name", title: "First Name" },
                  { value: "Email", title: "Email" },
                ],
                ai_request: (request, respondWith) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
              }}
              onChange={hangleChangePersonalityContent}
              initialValue={updatedPersonalityContent}
            />
          </Modal>

          {/* Pagination controls */}
          <div className={styles.paginationWrapper}>
            {/* Previous page button */}
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              <img
                src={prevPage}
                alt="prevPage"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>

            {/* Page numbers */}
            {getPageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
                className={
                  currentPage === pageNumber
                    ? styles.activePageNumber
                    : styles.pageNumber
                }
                // disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </button>
            ))}

            {/* Next page button */}
            <button
              onClick={handleNextPage}
              disabled={endIndex >= allPersonalities?.data?.length}
            >
              <img
                src={nextPage}
                alt="nextPage"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AllPersonalities;
