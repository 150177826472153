import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
  burgerCrossBlack,
  crown,
  locationChoose,
  upload,
  userAvatar,
} from "../../assets";
import styles from "../../assets/styles/professionals/CreateProfessionalModal.module.scss";
import CustomInput from "../../components/CustomInput";
import { Radio, Select, Space, message, TimePicker } from "antd";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import { getCurrentLocationAndFetchAddress } from "../../utils/helper/GetLocation";
import SearchableSelect from "../../components/SearchableDropdown/SearchableDropdown";

const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

const makeObjects = (data) => {
  const arr = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const object = {};
  if (data) {
    arr.forEach((el) => {
      object[el] = {
        morning: {
          start: data[el]?.morning?.start || "",
          end: data[el]?.morning?.end || "",
        },
        afterNoon: {
          start: data[el]?.afterNoon?.start || "",
          end: data[el]?.afterNoon?.end || "",
        },
      };
    });
  } else {
    arr.forEach((el) => {
      object[el] = {
        morning: {
          start: "",
          end: "",
        },
        afterNoon: {
          start: "",
          end: "",
        },
      };
    });
  }
  return object;
};

const initialState = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  firmName: "",
  siren: "",
  address: "",
  coordinates: [],
  ville: "",
  postalCode: "",
  department: "",
  telephone: "",
  website: "",
  profile: null,
  profileDataVisibility: "",
  activities: "",
  averagePayment: "",
  thirdPartyPayment: "",
  minPrice: "",
  professionalSubType: "",
  professionalPlan: "",
  diplomaYear: "",
  diploma: "",
  experienceYear: "",
  experience: "",
  onlineAppointment: "",
  onlineAppointmentCheck: false,
  onlineConsultation: "",
  onlineConsultationCheck: false,
  operationalHours: makeObjects(),
};

const professionalActivities = [];

const CreateProfessional = ({ toggleState, handleToggle, toggleSideBar }) => {
  const { token, searchedUserInfo } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [userNameChecked, setUserNameChecked] = useState(false);
  const [userBusinessNameChecked, setUserBusinessNameChecked] = useState(false);
  const [bothChecked, setbothChecked] = useState(false);
  const [imageState, setImageState] = useState(null);
  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();
  const [locationLoading, setLocationLoading] = useState(false);

  console.log("formData", formData);
  // load professional data
  useEffect(() => {
    setFormData({
      firstName: searchedUserInfo?.professional?.firstName || "",
      lastName: searchedUserInfo?.professional?.lastName || "",
      dateOfBirth: searchedUserInfo?.dateOfBirth || "",
      firmName: searchedUserInfo?.professional?.firmname || "",
      siren: searchedUserInfo?.professional?.siren || "",
      address: searchedUserInfo?.professional?.location?.address || "",
      coordinates: searchedUserInfo?.professional?.location?.coordinates || [],
      ville: searchedUserInfo?.professional?.location?.ville || "",
      postalCode: searchedUserInfo?.professional?.location?.postalCode || "",
      department: searchedUserInfo?.professional?.department || "",
      telephone: searchedUserInfo?.professional?.telephone || "",
      website: searchedUserInfo?.professional?.website || "",
      profileDataVisibility:
        searchedUserInfo?.professional?.profileDataVisibility || "",
      activities: searchedUserInfo?.professional?.activities || "",
      averagePayment: searchedUserInfo?.professional?.averagePayment || "",
      thirdPartyPayment:
        searchedUserInfo?.professional?.thirdPartyPayment || "",
      minPrice: searchedUserInfo?.professional?.minPrice || "",
      professionalSubType:
        searchedUserInfo?.professional?.professionalSubType || "",
      professionalPlan:
        searchedUserInfo?.professional?.package?.planId?.nickname || "",

      diploma: searchedUserInfo?.professional?.formations?.diplomaYear || "",
      diplomaYear: searchedUserInfo?.professional?.formations?.diploma || "",

      experience: searchedUserInfo?.professional?.experiences?.experience || "",
      experienceYear:
        searchedUserInfo?.professional?.experiences?.experienceYear || "",

      onlineAppointment:
        searchedUserInfo?.professional?.appointments?.onlineAppointment || "",
      onlineAppointmentCheck:
        searchedUserInfo?.professional?.appointments?.onlineAppointmentCheck ||
        false,

      onlineConsultation:
        searchedUserInfo?.professional?.consultations?.onlineConsultation || "",
      onlineConsultationCheck:
        searchedUserInfo?.professional?.consultations
          ?.onlineConsultationCheck || false,

      operationalHours: makeObjects(
        searchedUserInfo?.professional?.operationalHours
      ),
    });
  }, [searchedUserInfo]);

  // get last hundred years
  const getLastHundredYears = () => {
    const currentYear = new Date().getFullYear();

    return Array.from({ length: 100 }, (_, index) => {
      const year = currentYear - index;
      return { label: `${year}`, value: year };
    });
  };

  // store the years in a variable
  const lastHundredYears = getLastHundredYears();

  const handleExperienceYearSelect = (value) => {
    setFormData((prevState) => {
      return { ...prevState, experienceYear: value };
    });
  };

  const handleDiplomaYearSelect = (value) => {
    setFormData((prevState) => {
      return { ...prevState, diplomaYear: value };
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onProfessionalSubTypeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleUserActivityChange = (selectedValues) => {
    // Check if the selected values exceed the limit of 10
    if (selectedValues.length <= 10) {
      setFormData((prevState) => {
        return { ...prevState, activities: selectedValues };
      });
    }
  };

  // handleTimeChange
  const handleTimeChange = (day, partOfDay, time) => {
    setFormData((prevState) => ({
      ...prevState,
      operationalHours: {
        ...prevState.operationalHours,
        [day]: {
          ...prevState.operationalHours[day],
          [partOfDay]: {
            ...prevState.operationalHours[day][partOfDay],
            start: time[0],
            end: time[1],
          },
        },
      },
    }));
  };

  // getting location suggestions
  const getSuggestion = async (val) => {
    setFormData((prevState) => {
      return { ...prevState, address: val };
    });
    fetch(
      ` https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=${access_token} `
    )
      .then((response) => response.json())
      .then((data) => {
        data?.features == undefined ? setshown(false) : setshown(true);
        setSuggetions(data?.features);
      });
  };

  // getting current location
  const handleGetLocation = async () => {
    setLocationLoading(true);
    try {
      const address = await getCurrentLocationAndFetchAddress();
      setFormData((prevState) => {
        return { ...prevState, address: address };
      });
    } catch (error) {
      console.error(error);
      setLocationLoading(false);
    } finally {
      setLocationLoading(false);
    }
  };

  const renderError = (fieldName) => {
    if (formErrors[fieldName]) {
      return <p className="text-danger">{formErrors[fieldName]}</p>;
    }
    return null;
  };

  //   Upload image

  const handleImageUpload = (event) => {
    const file = event.target;

    if (file) {
      setImageState(URL.createObjectURL(file.files[0]));
      const blob = new Blob([file.files[0]], { type: file.files[0].type });
      setFormData((prevState) => {
        return { ...prevState, profile: blob };
      });
    }
  };

  const handleRemoveImage = () => {
    setImageState(null);
  };

  const handleSubmitData = async () => {
    let errors = {};
    let hasErrors = false;

    if (formData.firstName?.trim() === "") {
      errors.firstName = "Veuillez renseigner votre Prénom";
      hasErrors = true;
    }

    if (formData.lastName?.trim() === "") {
      errors.lastName = "Veuillez renseigner votre Nom";
      hasErrors = true;
    }

    if (formData.dateOfBirth?.trim() === "") {
      errors.dateOfBirth = "Veuillez renseigner votre Date de naissance";
      hasErrors = true;
    }

    if (formData.address?.trim() === "") {
      errors.address = "Veuillez renseigner votre Adresse";
      hasErrors = true;
    }

    if (formData.ville?.trim() === "") {
      errors.ville = "Veuillez renseigner votre Ville";
      hasErrors = true;
    }

    if (formData.postalCode?.trim() === "") {
      errors.postalCode = "Veuillez renseigner votre Code postal";
      hasErrors = true;
    }

    if (formData.department?.trim() === "") {
      errors.department = "Veuillez renseigner votre Département";
      hasErrors = true;
    }

    if (formData.profileDataVisibility?.trim() === "") {
      errors.profileDataVisibility = "Veuillez renseigner ce champ :";
      hasErrors = true;
    }

    if (hasErrors) {
      setFormErrors(errors);
    } else {
      try {
        const params = {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          dateOfBirth: formData?.dateOfBirth,
          firmName: formData?.firmName,
          siren: formData?.siren,
          location: {
            address: formData?.address,
            coordinates: formData?.coordinates,
            ville: formData?.ville,
            postalCode: formData?.postalCode,
          },
          department: formData?.department,
          telephone: formData?.telephone,
          website: formData?.website,
          profileDataVisibility: formData?.profileDataVisibility,
          activities: formData?.activities,
          averagePayment: formData?.averagePayment,
          thirdPartyPayment: formData?.thirdPartyPayment,
          minPrice: formData?.minPrice,
          professionalSubType: formData?.professionalSubType,
          professionalPlan: formData?.professionalPlan,
          formations: {
            diploma: formData?.diplomaYear,
            diplomaYear: formData?.diploma,
          },
          experiences: {
            experience: formData?.experience,
            experienceYear: formData?.experienceYear,
          },
          appointments: {
            onlineAppointment: formData?.onlineAppointment,
            onlineAppointmentCheck: formData?.onlineAppointmentCheck,
          },
          consultations: {
            onlineConsultation: formData?.onlineConsultation,
            onlineConsultationCheck: formData?.onlineConsultationCheck,
          },

          operationalHours: formData?.operationalHours,
        };
        setLoading(true);
        const response = await ApiCall({
          params: params,
          route: `professional/update-professional/${searchedUserInfo?.professional?._id}`,
          verb: "put",
          token,
          baseurl: true,
        });
        if (response.status == 200) {
          message.success("Profile updated successfully");
          setFormErrors(initialState);
          // setFormData(initialState);
          handleToggle();
        } else {
          message.error(`Something went wrong`);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.modalContainer}>
      <Modal
        size="md"
        style={{ maxWidth: "670px", width: "100%" }}
        modalClassName="createProfessionalModal"
        toggle={handleToggle}
        isOpen={toggleState}
      >
        <ModalBody className={styles.StoryModalBody}>
          <div className={styles.header}>
            <h5 className={styles.headerTitle}>Espace professionnel</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleToggle}
            >
              <img
                src={burgerCrossBlack}
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>

          <div className={styles.content}>
            <p>
              Ici, vous pouvez ajouter ou modifier les informations de votre
              profil professionnel.
            </p>
            <p>
              Notez qu'en fonction de votre profession, certaines informations
              ne pourront pas être modifiées. En effet, pour des raisons de
              sécurité des utilisateurs, certaines données proviennent
              directement des différents annuaires gouvernementaux.
            </p>

            {/* INFORMATIONS PERSONNELLES */}
            {/* <div className={styles.title}>
              <h5>Informations personnelles</h5>
              <div className={styles.line}></div>
            </div> */}

            <div className={styles.profilepicWrapper}>
              <div className={styles["form-left"]}>
                {imageState ? (
                  <>
                    <img src={imageState} alt="uploaded" />
                    <label htmlFor="image-upload" onClick={handleRemoveImage}>
                      Remove
                    </label>
                  </>
                ) : (
                  <>
                    <label
                      className={styles["image-upload-button"]}
                      htmlFor="image-upload"
                    >
                      <img
                        src={
                          searchedUserInfo?.profilePicture?.url
                            ? searchedUserInfo?.profilePicture?.url
                            : userAvatar
                        }
                      />
                      <div className={styles.overlay}>
                        <img
                          src={upload}
                          className={styles.icon}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    </label>
                  </>
                )}
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
            </div>

            <div className={styles.inputsWrapper}>
              <div>
                <CustomInput
                  label="Prénom"
                  mendatory="*"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  emogie={false}
                  handleOnChange={handleInputChange}
                />
                {renderError("firstName")}
              </div>
              <div>
                <CustomInput
                  label="Nom"
                  mendatory="*"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  emogie={false}
                  handleOnChange={handleInputChange}
                />
                {renderError("lastName")}
              </div>
            </div>

            <div className={styles.inputsWrapper}>
              <div>
                <CustomInput
                  label="Date de naissance"
                  mendatory="*"
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  emogie={false}
                  handleOnChange={handleInputChange}
                />
                {renderError("dateOfBirth")}
              </div>

              <CustomInput
                label="Nom de votre enterprise"
                type="text"
                name="firmName"
                value={formData.firmName}
                emogie={false}
                handleOnChange={handleInputChange}
              />
            </div>

            <div className={styles.inputsWrapper}>
              <CustomInput
                label="SIRET"
                type="text"
                name="siren"
                value={formData.siren}
                emogie={false}
                handleOnChange={handleInputChange}
              />
            </div>

            {/* display on profile */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Dans votre profil, vous souhaitez afficher:
                <span className="text-danger"> *</span>
              </h3>

              <Radio.Group
                onChange={onProfessionalSubTypeChange}
                value={formData?.profileDataVisibility}
                name="profileDataVisibility"
              >
                <Space direction="vertical">
                  <Radio value="firstname and lastname">
                    votre nom et votre prenom
                  </Radio>
                  <Radio value="firmName">le nom de votre enterprise</Radio>
                  <Radio value="both">les deux</Radio>
                </Space>
              </Radio.Group>

              {renderError("profileDataVisibility")}
            </div>

            {/* Adresse */}
            <div className={styles.inputGroup}>
              <div className={styles.inputContainer}>
                <label>
                  Adresse <span className="mendatory">*</span>
                </label>
                <div className={styles.inputEyeWrapper}>
                  <input
                    type={"text"}
                    value={formData.address}
                    name="location"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      getSuggestion(e.target.value);
                    }}
                    f
                    style={{ width: "100%" }}
                  />

                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={handleGetLocation}
                  >
                    <>
                      <img
                        className={styles.eyeClose}
                        src={locationChoose}
                        alt="location"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </>
                  </button>
                </div>
                {renderError("address")}
              </div>
            </div>

            {/* show the list of searching */}
            {shown ? (
              <>
                {" "}
                {suggetions?.length > 0 && (
                  <ul
                    className={`z-40 bg-white ${
                      suggetions?.length > 0 ? "h-[100px]" : "h-[0px]"
                    } absolute w-[22%] overflow-y-scroll px-0 `}
                  >
                    {suggetions.map((item) => (
                      <li
                        onClick={() => {
                          setshown(false);
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              address: item?.place_name,
                              coordinates: [item?.center[1], item?.center[0]],
                            };
                          });
                        }}
                        className="hover:bg-blue-300 cursor-pointer"
                      >
                        {item?.place_name}
                      </li>
                    ))}
                  </ul>
                )}{" "}
              </>
            ) : null}

            {/* ville */}
            <div className={styles.inputsWrapper}>
              <div>
                <CustomInput
                  label="Ville"
                  mendatory="*"
                  type="text"
                  name="ville"
                  value={formData.ville}
                  emogie={false}
                  handleOnChange={handleInputChange}
                />
                {renderError("ville")}
              </div>
            </div>

            {/* postalCode */}
            <div className={styles.inputsWrapper}>
              <CustomInput
                label="Code postal"
                mendatory="*"
                type="text"
                name="postalCode"
                value={formData.postalCode}
                emogie={false}
                handleOnChange={handleInputChange}
              />
            </div>
            {renderError("postalCode")}

            {/* department */}
            <div className={styles.inputsWrapper}>
              <div>
                <CustomInput
                  label="Département"
                  type="text"
                  mendatory="*"
                  name="department"
                  value={formData.department}
                  emogie={false}
                  handleOnChange={handleInputChange}
                />
                {renderError("department")}
              </div>

              <CustomInput
                label="Téléphone"
                type="text"
                name="telephone"
                value={formData.telephone}
                emogie={false}
                handleOnChange={handleInputChange}
              />
            </div>

            {/* professional subtype */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Vous etes ?<span className="text-danger"> *</span>
              </h3>

              <Radio.Group
                onChange={onProfessionalSubTypeChange}
                value={formData?.professionalSubType}
                name="professionalSubType"
              >
                <Space direction="vertical">
                  <Radio value="sector1">
                    Conventionné secteur 1 (sans dépassement d'honoraires)
                  </Radio>
                  <Radio value="sector2">
                    Conventionné secteur 2 (dépassement d'honoraires possibles)
                  </Radio>
                  <Radio value="notApproved">
                    Non conventionné (honoraires libres)
                  </Radio>
                  <Radio value="notAplicable">
                    Non applicable à ma profession
                  </Radio>
                </Space>
              </Radio.Group>

              {renderError("professionalSubType")}
            </div>

            {/* minPrice */}
            <div className={styles.inputsWrapper}>
              <CustomInput
                label="Prix minimum d'une consultation"
                type="text"
                name="minPrice"
                value={formData?.minPrice}
                emogie={false}
                handleOnChange={handleInputChange}
              />
            </div>

            {/* select activity */}
            <div
              onClick={() => {
                if (formData?.professionalPlan === "PACK PRÉSENCE") {
                  navigate(`/professionals/sign-up/plans`);
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }
              }}
            >
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Indiquez les 10 mots-clés décrivant le mieux votre activité
                <span>
                  <img src={crown} />
                </span>
              </h3>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Select activity"
                onChange={handleUserActivityChange}
                optionLabelProp="label"
                options={professionalActivities}
                value={formData?.activities}
                disabled={
                  formData?.professionalPlan === "PACK PRÉSENCE" ? true : false
                }
              />
            </div>

            {/* website */}
            <div
              className={styles.inputsWrapper}
              onClick={() => {
                if (formData?.professionalPlan === "PACK PRÉSENCE") {
                  navigate(`/professionals/sign-up/plans`);
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }
              }}
            >
              <CustomInput
                label="Site Web"
                type="text"
                name="website"
                value={formData?.website}
                emogie={false}
                handleOnChange={handleInputChange}
                titleIcon={crown}
                readOnly={
                  formData?.professionalPlan === "PACK PRÉSENCE" ? true : false
                }
              />
            </div>

            {/* Average payment */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Moye en(s) de paiement
                <span>
                  <img src={crown} />
                </span>
              </h3>

              <div
                className={` ${styles.checkbox}`}
                onClick={() => {
                  if (formData?.professionalPlan === "PACK PRÉSENCE") {
                    navigate(`/professionals/sign-up/plans`);
                    handleToggle();
                    if (toggleSideBar) {
                      toggleSideBar();
                    }
                  } else {
                    setUserNameChecked(true);
                    setUserBusinessNameChecked(false);
                    setbothChecked(false);
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        averagePayment: "species",
                      };
                    });
                  }
                }}
              >
                {userNameChecked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                      fill="#01989F"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                      fill="#191919"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                )}

                <div>
                  <p>Espèces</p>
                </div>
              </div>

              <div
                className={` ${styles.checkbox}`}
                onClick={() => {
                  if (formData?.professionalPlan === "PACK PRÉSENCE") {
                    navigate(`/professionals/sign-up/plans`);
                    handleToggle();
                    if (toggleSideBar) {
                      toggleSideBar();
                    }
                  } else {
                    setUserNameChecked(false);
                    setUserBusinessNameChecked(true);
                    setbothChecked(false);
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        averagePayment: "bankCards",
                      };
                    });
                  }
                }}
              >
                {userBusinessNameChecked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                      fill="#01989F"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                      fill="#191919"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                )}

                <div>
                  <p>Cartes bancaires</p>
                </div>
              </div>

              <div
                className={` ${styles.checkbox}`}
                onClick={() => {
                  if (formData?.professionalPlan === "PACK PRÉSENCE") {
                    navigate(`/professionals/sign-up/plans`);
                    handleToggle();
                    if (toggleSideBar) {
                      toggleSideBar();
                    }
                  } else {
                    setUserNameChecked(false);
                    setUserBusinessNameChecked(false);
                    setbothChecked(true);
                    setFormData((prevState) => {
                      return { ...prevState, averagePayment: "check" };
                    });
                  }
                }}
              >
                {bothChecked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                      fill="#01989F"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    className="Fieldset_Ressource_checkbox__i3TPt"
                  >
                    <path
                      d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                      fill="#191919"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                )}

                <div>
                  <p>Chèques</p>
                </div>
              </div>

              {renderError("averagePayment")}
            </div>

            {/* third party payment */}
            <div
              onClick={() => {
                if (formData?.professionalPlan === "PACK PRÉSENCE") {
                  navigate(`/professionals/sign-up/plans`);
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }
              }}
            >
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Partiquez-vous le tiers-payant ?
                <span>
                  <img src={crown} />
                </span>
              </h3>

              <Radio.Group
                onChange={onProfessionalSubTypeChange}
                value={formData?.thirdPartyPayment}
                name="thirdPartyPayment"
                disabled={
                  formData?.professionalPlan === "PACK PRÉSENCE" ? true : false
                }
              >
                <Space direction="vertical">
                  <Radio value="yes">Oui</Radio>
                  <Radio value="no">Non</Radio>
                  <Radio value="n/a">N/A</Radio>
                </Space>
              </Radio.Group>

              {renderError("thirdPartyPayment")}
            </div>

            {/* Hours of operation */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Horaires d'ouverture
                <span>
                  <img src={crown} />
                </span>
              </h3>
              {Object.entries(formData?.operationalHours)?.map(
                ([key, value]) => (
                  <>
                    <h3 className={`${styles.resourcesTitle}`}>{key}</h3>
                    <div className={styles.timepickerWrapp}>
                      <div className={styles.selectWrapp}>
                        <h3 className={`${styles.title}`}>Matin</h3>
                        <TimePicker.RangePicker
                          format={"h:mm a"}
                          onChange={(time, timeString) =>
                            handleTimeChange(key, "morning", timeString)
                          }
                        />
                      </div>
                      <div className={styles.selectWrapp}>
                        <h3 className={`${styles.title}`}>Après-midi</h3>
                        <TimePicker.RangePicker
                          format={"h:mm a"}
                          onChange={(time, timeString) =>
                            handleTimeChange(key, "afterNoon", timeString)
                          }
                        />
                      </div>
                    </div>
                  </>
                )
              )}

              {renderError("professionalSubType")}
            </div>

            {/* formations */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Formations
                <span>
                  <img src={crown} />
                </span>
              </h3>
              <div className={styles.dropdownAndInputWrapper}>
                <div className={styles.selectWrapp}>
                  <h3 className={`pt-3 ${styles.resourcesTitle}`}>Année</h3>
                  <SearchableSelect
                    placeholder=""
                    options={lastHundredYears}
                    selectedValue={formData?.diplomaYear}
                    handleOptionSelect={handleDiplomaYearSelect}
                  />
                </div>
                <div className={styles.inputWrapp}>
                  <CustomInput
                    label="Diplôme"
                    type="text"
                    name="diploma"
                    value={formData?.diploma}
                    emogie={false}
                    handleOnChange={handleInputChange}
                    readOnly={
                      formData?.professionalPlan === "PACK PRÉSENCE"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              {renderError("professionalSubType")}
            </div>

            {/* Expériences */}
            <div>
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>Expériences</h3>
              <div className={styles.dropdownAndInputWrapper}>
                <div className={styles.selectWrapp}>
                  <h3 className={`pt-3 ${styles.resourcesTitle}`}>Année</h3>
                  <SearchableSelect
                    placeholder=""
                    options={lastHundredYears}
                    selectedValue={formData?.experienceYear}
                    handleOptionSelect={handleExperienceYearSelect}
                  />
                </div>
                <div className={styles.inputWrapp}>
                  <CustomInput
                    label="Expérience"
                    type="text"
                    name="experience"
                    value={formData?.experience}
                    emogie={false}
                    handleOnChange={handleInputChange}
                    readOnly={
                      formData?.professionalPlan === "PACK PRÉSENCE"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              {renderError("professionalSubType")}
            </div>

            {/* appointment booking services */}
            <div
              onClick={() => {
                if (formData?.professionalPlan === "PACK PRÉSENCE") {
                  navigate(`/professionals/sign-up/plans`);
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }
              }}
            >
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Souhaitez-vous utiliser nos services de réservation de
                rendez-vous en ligne ? Si oui, votre numéro de téléphone ne sera
                pas visible dans votre profil.
                <span>
                  <img src={crown} />
                </span>
              </h3>

              <Radio.Group
                onChange={onProfessionalSubTypeChange}
                value={formData?.onlineAppointment}
                name="onlineAppointment"
                disabled={
                  formData?.professionalPlan === "PACK PRÉSENCE" ? true : false
                }
              >
                <Space direction="vertical">
                  <Radio value="yes">Oui</Radio>
                  <Radio value="no">Non</Radio>
                </Space>
              </Radio.Group>

              {renderError("thirdPartyPayment")}
            </div>
            {/* appointment booking services check */}
            <br />
            <div
              className={` ${styles.isAnonymCheckbox}`}
              onClick={() => {
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    onlineAppointmentCheck: formData?.onlineAppointmentCheck
                      ? false
                      : true,
                  };
                });
              }}
            >
              {!formData?.onlineAppointmentCheck ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p>
                  Services de réservation de rendez-vous en ligne{" "}
                  <span>
                    <img
                      style={{ marginBottom: "5px" }}
                      height={20}
                      width={20}
                      src={crown}
                    />
                  </span>
                </p>
              </div>
            </div>

            {/* Consultation services */}
            <div
              onClick={() => {
                if (formData?.professionalPlan === "PACK PRÉSENCE") {
                  navigate(`/professionals/sign-up/plans`);
                  handleToggle();
                  if (toggleSideBar) {
                    toggleSideBar();
                  }
                }
              }}
            >
              <h3 className={`pt-3 ${styles.resourcesTitle}`}>
                Souhaitez-vous utiliser nos services de consultation en ligne,
                en visioconférence ?
                <span>
                  <img src={crown} />
                </span>
              </h3>

              <Radio.Group
                onChange={onProfessionalSubTypeChange}
                value={formData?.onlineConsultation}
                name="onlineConsultation"
                disabled={
                  formData?.professionalPlan === "PACK PRÉSENCE" ? true : false
                }
              >
                <Space direction="vertical">
                  <Radio value="yes">Oui</Radio>
                  <Radio value="no">Non</Radio>
                </Space>
              </Radio.Group>

              {renderError("thirdPartyPayment")}
            </div>
            {/* Consultation services check */}
            <br />
            <div
              className={` ${styles.isAnonymCheckbox}`}
              onClick={() => {
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    onlineConsultationCheck: formData?.onlineConsultationCheck
                      ? false
                      : true,
                  };
                });
              }}
            >
              {!formData?.onlineConsultationCheck ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429H3.036c-.888 0-1.607.72-1.607 1.607v13.928c0 .888.72 1.607 1.607 1.607h13.928c.888 0 1.607-.72 1.607-1.607V3.036c0-.888-.72-1.607-1.607-1.607zM3.036 2.5h13.928c.296 0 .536.24.536.536v13.928c0 .296-.24.536-.536.536H3.036a.536.536 0 0 1-.536-.536V3.036c0-.296.24-.536.536-.536z"
                    fill="#191919"
                    fillRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  className="Fieldset_Ressource_checkbox__i3TPt"
                >
                  <path
                    d="M16.964 1.429c.888 0 1.607.72 1.607 1.607v13.928c0 .888-.72 1.607-1.607 1.607H3.036c-.888 0-1.607-.72-1.607-1.607V3.036c0-.888.72-1.607 1.607-1.607zm0 1.071H3.036a.536.536 0 0 0-.536.536v13.928c0 .296.24.536.536.536h13.928c.296 0 .536-.24.536-.536V3.036a.536.536 0 0 0-.536-.536zm-2.346 3.386c.21.167.263.46.133.687l-.045.066-5.596 7.08c-.251.33-.646.519-1.061.506a1.28 1.28 0 0 1-.958-.47l-.065-.089-1.75-2.483a.537.537 0 0 1 .826-.679l.05.062L7.91 13.06a.22.22 0 0 0 .172.095.21.21 0 0 0 .143-.052l.04-.04 5.601-7.089a.537.537 0 0 1 .753-.088z"
                    fill="#01989F"
                    fillRule="evenodd"
                  ></path>
                </svg>
              )}

              <div>
                <p>
                  Services de consultation en ligne en visioconférence{" "}
                  <span>
                    <img
                      style={{ marginBottom: "5px" }}
                      height={20}
                      width={20}
                      src={crown}
                    />
                  </span>
                </p>
              </div>
            </div>

            {/* submit button */}
            <div className={styles.submitButtonWrapper}>
              <input
                onClick={handleSubmitData}
                type="submit"
                value={loading ? "Loading..." : "Étape suivante"}
                className={styles.submitButton}
              ></input>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateProfessional;
