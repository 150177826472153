import React, { useState, useEffect } from "react";
import CustomInput from "../../CustomInput";
import { Select, Spin, message } from "antd";
import styles from "../../../assets/styles/stories/SubmitStory.module.scss";
import { ApiCall } from "../../../config/apiCall";
import { useAuth } from "../../../context/AuthContext";
import CustomTextArea from "../../CustomTetxArea";
import SearchableSelect from "../../SearchableDropdown/SearchableDropdown";
const Influenceur = ({
  handleResourceInputChange,
  resourceInputState,
  characterCount,
  errorMessage,
  setErrorMessage,
}) => {
  const { token } = useAuth();
  const [influencersList, setInfluencersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowAddOptionButton, setIsShowAddOptionButton] = useState(true);
  const [searchedInfluencers, setSearchedInfluencers] = useState("");

  console.log("resourceInputState", resourceInputState);

  useEffect(() => {
    async function getAllInfluencers() {
      setLoading(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `influencer/allListing`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setInfluencersList(response?.response?.data);
          setLoading(false);
        }
        console.log("response", response);
      } catch (error) {
        message.error("Connection Error");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    getAllInfluencers();
  }, []);

  async function getInfluencers(e) {
    setSearchedInfluencers(e);
    setLoading(true);
    const url = e
      ? `influencer/filterInfluencer/${e}`
      : `influencer/allListing`;
    try {
      const response = await ApiCall({
        params: {},
        route: url,
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setInfluencersList(response?.response?.data);
        setLoading(false);
      }
    } catch (error) {
      message.error("Connection Error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  console.log("influencersList", influencersList);

  let allInfluencers = influencersList?.map((qoute) => ({
    value: qoute?._id,
    label: qoute?.influenceurtitle,
  }));

  const InfluencerChangeHandler = (value) => {
    const name = allInfluencers?.filter((el) => el.value === value)[0]?.label;

    setIsShowAddOptionButton(true);
    setErrorMessage("");
    handleResourceInputChange({
      target: { name: "influenceurId", value },
    });
    handleResourceInputChange({
      target: { name: "influenceurtitle", value: name },
    });
  };

  const setNewQoute = (value) => {
    setIsShowAddOptionButton(false);
    allInfluencers?.push({ label: value, value: value });
    setInfluencersList((prev) => [...prev, { _id: value, quote: value }]);
    handleResourceInputChange({
      target: { name: "influenceurtitle", value: value },
    });
    handleResourceInputChange({
      target: { name: "influenceurId", value: value },
    });
  };
  return (
    <div>
      <h3 className={`${styles.resourcesTitle}`}>
        Nom du compte Youtube / Instagram / TikTok
        <span className="text-danger">*</span>
      </h3>
      <SearchableSelect
        options={allInfluencers}
        onSearch={(e) => getInfluencers(e)}
        placeholder="@psyup ..."
        selectedValue={resourceInputState?.influenceurId}
        handleOptionSelect={(value) => {
          InfluencerChangeHandler(value);
        }}
        setNewOption={() => setNewQoute(searchedInfluencers)}
        allowNewOption={true}
        newOptionTitle="+ Ajouter cet influenceur"
        isLoading={loading}
      />
      {/* <CustomInput
        label="Nom du compte Youtube / Instagram / TikTok"
        type="text"
        name="influenceurtitle"
        emogie={false}
        value={resourceInputState.influenceurtitle}
        mendatory="*"
        placeHolder="@psyup ..."
        handleOnChange={handleResourceInputChange}
      /> */}
      {errorMessage == "Veuillez renseigner le nom de l’influenceur." && (
        <div className="errorMessage">{errorMessage}</div>
      )}

      <CustomTextArea
        label="Pourquoi cette ressource ?"
        placeHolder="Expliquez brièvement pourquoi vous recommandez cette ressource."
        value={resourceInputState.resourceReason}
        name="resourceReason"
        emogie={true}
        characterCount={characterCount}
        handleOnChange={handleResourceInputChange}
      />
    </div>
  );
};

export default Influenceur;
