import React from "react";
import styles from "../assets/styles/profile/themes.module.scss";

const ThemesCard = ({ title, selected, onClick, imageURL, imageBlack }) => {
  return (
    <div
      className={`${styles.card} ${selected ? styles.activeCard : ""}`}
      onClick={onClick}
    >
      {imageURL && (
        <img
          src={selected ? imageURL : imageBlack}
          alt=""
          width={50}
          height={50}
          onContextMenu={(e) => e.preventDefault()}
        />
      )}

      <p>{title}</p>
    </div>
  );
};

export default ThemesCard;
