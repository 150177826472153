import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/ContactUs.module.scss";
import passwordStyles from "../../assets/styles/signUp/step1.module.scss";

import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { message } from "antd";
import { ApiCall } from "../../config/apiCall";

import { burgerCrossBlack, eyeIcon, eyeSlash } from "../../assets";

const ResetPassword = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [sameError, setSameError] = useState(false);

  let { tokenId } = useParams();

  useEffect(() => {
    if (tokenId) {
      // api call for submiting token to verify
      const getToken = async () => {
        const response = await ApiCall({
          params: {},
          route: "auth/reset-password/" + tokenId,
          verb: "get",
          baseurl: true,
        });

        console.log(response);
      };

      getToken();
    }
  }, [tokenId]);

  const renderPasswordError = () => {
    if (formErrors.length != 0) {
      return <p className={` ${passwordStyles.errorMsg} `}>{formErrors}</p>;
    }
    return null;
  };

  const renderSamePasswordError = () => {
    if (sameError) {
      return (
        <p className={` ${passwordStyles.errorMsg} `}>
          Les mots de passe ne correspondent pas
        </p>
      );
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    let errors = "";
    let hasErrors = false;

    const passwordIsValid = password.trim() != "";
    const confirmPasswordIsValid = confirmPassword.trim() != "";
    const samePassword = password === confirmPassword ? true : false;

    console.log(
      !passwordIsValid &&
        !confirmPasswordIsValid &&
        !passwordPattern.test(password) &&
        password.length < 6
    );

    if (
      !passwordIsValid &&
      !passwordPattern.test(password) &&
      password.length < 6
    ) {
      // message.error("Veuillez remplir toutes les entrées");

      errors =
        "Le mot de passe doit contenir au moins 6 caractères, dont au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial (@, $, !, %, *, ?, &).";
      hasErrors = true;

      if (hasErrors) {
        setFormErrors(errors);
      } else {
        setFormErrors("");
      }
    } else {
      setFormErrors("");
      if (samePassword) {
        // appi call
        const response = await ApiCall({
          params: { password },
          route: "auth/set-password",
          verb: "post",
          token: tokenId,
          baseurl: true,
        });
        if (response.status === 200) {
          message.success(`${response.response.success}`);
          setSameError(false);
          setPassword("");
          setConfirmPassword("");
          navigate("/");
        } else {
          message.error(`${response.response.message}`);
        }
        console.log(response);
      } else {
        setSameError(true);
      }
    }
  };

  function handleToggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleToggleShowConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <div className={styles.contactUsPage}>
      <div className={styles.contactUsBox}>
        <h2 className={styles.contactUsTitle}>Reset Mot de passe</h2>
        <form onSubmit={handleSubmit} className={styles.contactUsForm}>
          <label>Mot de passe</label>
          <div className={styles.password}>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={handleToggleShowPassword}
            >
              <>
                <img
                  className={styles.eyeClose}
                  src={showPassword ? eyeIcon : eyeSlash}
                  alt="eyeClose"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </>
              {/* <div className={showPassword ? styles.passwordSlash : ""}></div> */}
            </button>
          </div>
          {renderPasswordError()}

          <label>Confirm Mot de passe</label>
          <div className={styles.password}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={handleToggleShowConfirmPassword}
            >
              <>
                <img
                  className={styles.eyeClose}
                  src={showConfirmPassword ? eyeIcon : eyeSlash}
                  alt="eyeClose"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </>
              {/* <div
                className={showConfirmPassword ? styles.passwordSlash : ""}
              ></div> */}
            </button>
          </div>
          {renderSamePasswordError()}

          <input
            style={{ marginTop: "1rem" }}
            type="submit"
            className={styles.contactUsFormButton}
            value="Connexion"
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
