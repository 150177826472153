import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import styles from "../../assets/styles/home.module.scss";
import { useMediaQuery } from "react-responsive";

const storyFilterByTimePeriod = [
  {
    name: "Ce jour",
    value: "This Day",
  },
  {
    name: "Cette semaine",
    value: "This Week",
  },
  {
    name: "Ce mois",
    value: "This Month",
  },
  {
    name: "Cette année",
    value: "This Year",
  },
];

const StoryFiltrationByTime = ({ showTimeFrameFilters }) => {
  const {
    sidebarSelectedTheme,
    setTimeFrameForStoryFiltration,
    activeResourceBtn,
    setactiveResourceBtn,
  } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handelThemeFilter = (value) => {
    setTimeFrameForStoryFiltration(value);
  };

  return (
    <div>
      {showTimeFrameFilters && (
        <div className={styles.themeFiltratin}>
          {storyFilterByTimePeriod.map((resource, index) => {
            return (
              <div>
                <button
                  key={index}
                  onClick={() => {
                    if (activeResourceBtn !== index) {
                      setactiveResourceBtn(index);
                      handelThemeFilter(resource.value);
                    } else {
                      setactiveResourceBtn("");
                      handelThemeFilter(null);
                    }
                  }}
                  className={`${
                    activeResourceBtn === index ? styles.activeResource : ""
                  } ${styles.resource} ${styles.filterTime}`}
                >
                  {resource.name}
                </button>
              </div>
            );
          })}
        </div>
      )}

      {!isTabletOrMobile && sidebarSelectedTheme && (
        <div className={styles.themeCover}>
          <img
            src={sidebarSelectedTheme?.poster}
            alt="themeCover"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}
    </div>
  );
};

export default StoryFiltrationByTime;
