import React from "react";
import styles from "../assets/styles/buttonOutline.module.scss";

const buttonOutline = ({ title, text, borderColr, textColr, handleClick }) => {
  return (
    <div
      className={styles.buttonOutline}
      style={{ borderColor: borderColr, color: textColr, borderWidth: "2px" }}
      onClick={handleClick}
    >
      <h3>{title}</h3>
      {text && <p>{text}</p>}
    </div>
  );
};

export default buttonOutline;
