import React from "react";
import { useAuth } from "../../context/AuthContext";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/styles/home.module.scss";

export default function StoryFiltrationByThemeMobile({ themes }) {
  const {
    setActiveTheme,
    activeTheme,
    setSidebarSelectedTheme,
    sidebarSelectedTheme,
  } = useAuth();

  // console.log("sidebarSelectedTheme", sidebarSelectedTheme);
  // console.log("activeTheme", activeTheme);
  const navigate = useNavigate();

  // Add an "ALL" option at the beginning of themesOptions
  const themesOptions = themes
    ? [
        {
          id: "TOUS",
          label: "TOUS",
          value: "TOUS",
          poster: null, // Set a default poster or null
        },
        ...themes.map((theme) => ({
          id: theme._id,
          label: theme.name,
          value: theme._id,
          poster: theme.poster,
        })),
      ]
    : [];

  const handleThemeChange = (themeid, poster) => {
    // console.log(themeid);
    // console.log(poster);

    // Check if the "ALL" option is selected
    if (themeid === "TOUS") {
      setActiveTheme(null); // Reset the active theme
      setSidebarSelectedTheme(null); // Reset the sidebar selected theme
    } else {
      setActiveTheme(themeid);
      setSidebarSelectedTheme({ themeid, poster });
    }
    navigate("/");
  };

  return (
    <>
      {sidebarSelectedTheme && (
        <div className={styles.themeCover}>
          <img
            src={sidebarSelectedTheme?.poster}
            alt="themeCover"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}
      <Select
        optionFilterProp="children"
        onChange={(option) => {
          const themePoster = themesOptions?.find(
            (el) => el.value === option
          )?.poster;
          handleThemeChange(option, themePoster);
        }}
        value={
          themesOptions.find((option) => option.value === activeTheme)?.value
        }
        options={themesOptions}
        className="mobileHomeThemeSelect"
        placeholder="TOUS"
        // placeholder="Mal‐être - Différences"
      />
    </>
  );
}
