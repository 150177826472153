import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
function Spinner2() {
  return (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
}

export default Spinner2;
