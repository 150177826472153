import React, { useState } from "react";
import styles from "./ProfessionalSignUp.module.scss";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { professionalSignupImage } from "../../assets";

const ProfessionalSignUp = () => {
  const navigate = useNavigate();
  const [infoModal, setInfoModal] = useState(false);

  const infoModalToggle = () => {
    setInfoModal(!infoModal);
  };
  return (
    <>
      <div className={styles.professionalSignUpContainer}>
        <div className={styles.imageWrapper}>
          <img src={professionalSignupImage} />
        </div>

        <p className={styles.description}>
          Cher Professionnel, vous offrez des services dans l’un des 14&nbsp;thèmes
          PsyUP ?
        </p>

        <span className={styles.info} onClick={infoModalToggle}>
          ¡
        </span>

        <p className={styles.boxDescription}>
          Alors, découvrez nos 4&nbsp;FORMULES afin de mettre en avant votre
          savoir-faire auprès de notre communauté et faciliter la gestion de vos
          rendez-vous !
        </p>

        <div
          className={styles.button}
          onClick={() => {
            navigate("/professionals/sign-up/plans");
          }}
        >
          DÉCOUVRIR
        </div>
      </div>

      {/* Info Modal */}
      <Modal
        size="md"
        style={{ maxWidth: "370px", width: "100%" }}
        centered
        modalClassName="professionalSignupInfoModal"
        toggle={infoModalToggle}
        isOpen={infoModal}
      >
        <ModalBody className={styles.infoModal}>
          <div>
            <h6 className={styles.heading}>Problèmes</h6>
            <p>
              <span>(1)</span> Familiaux <span>(2)</span> Sentimentaux
              <span>(3)</span> d’Amitié <span>(4)</span> de Santé
            </p>
            <p>
              <span>(5)</span> Souffrance au travail
            </p>
            <p>
              <span>(6)</span> Justice
            </p>
            <p>
              <span>(7)</span> Gestion du budget | Crédit | Difficultés
              financières
            </p>
            <p>
              <span>(8)</span> Psychologie
            </p>
            <p>
              <span>(9)</span> Trouver sa voie | Réaliser ses rêves | Changer de
              vie
            </p>
            <p>
              <span>(10)</span> Estime de soi | Différences | Mal-être |
              Complexes
            </p>
            <p>
              <span>(11)</span> Perte d’un être cher
            </p>
            <p>
              <span>(12)</span> Difficulté de conception
            </p>
            <p>
              <span>(13)</span> Éducation & Troubles d’apprentissage
            </p>
            <p>
              <span>(14)</span> Grossesse | Accouchement | Post-partum
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProfessionalSignUp;
