import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import styles from "../../assets/styles/featuredPosts.module.scss";
import DisplayComments from "../comments/DisplayComments";
import ButtonFilled from "../buttonFilled";
import { ApiCall } from "../../config/apiCall";
import { Dropdown, Menu, message } from "antd";
import { siteUrl } from "../../environment";
import {
  faCaretRight,
  faCopy,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  commentsIcon,
  favorite,
  share,
  like,
  meToo,
  storyEdit,
  favorite2,
} from "../../assets/index";
import StoryLoader from "../UI/StoryLoader";
import { ReactSVG } from "react-svg";
import FriendsListModal from "./storyModals/FriendsListModal";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
const MAX_LENGTH = 250;

const reactionsArray = [
  {
    emogi: like,
    value: "like",
    selected: false,
    id: 1,
  },
  {
    emogi: meToo,
    value: "meToo",
    selected: false,
    id: 2,
  },
];

const DisplayAdminStories = ({
  stories,
  setAdminStories,
  setStoryCount,
  loading,
  setPost,
  setPostUpdate,
  setModalOpen,
}) => {
  const {
    token,
    userInfo,
    adminStoriesLoading,
    getUserFavouriteStories,
    loginModal,
    setLoginModal,
    favstoryCount,
  } = useAuth();
  const [showCommentsIndex, setShowCommentsIndex] = useState(false);
  const [allComments, setallComments] = useState(null);
  const [loadingComments, setloadingComments] = useState(false);
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [isCommentsOpen, setIsCommentsOpen] = useState([]);
  const [friendsListModal, setFriendsListModal] = useState(false);
  const [storyId, setStoryId] = useState(null);

  useEffect(() => {
    setIsCommentsOpen([]);
    setShowCommentsIndex(-1);
    setallComments("");
  }, [stories]);

  const items = [
    {
      label: "Copy link",
      key: "0",
      onClick: (index) => {
        const selectedStory = stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
        navigator.clipboard?.writeText(storyLink);
        message.success("Story link copied to clipboard!");
      },
      render: (index) => (
        <>
          <FontAwesomeIcon icon={faCopy} style={{ color: "#fff" }} />
        </>
      ),
    },
    {
      label: "Facebook",
      key: "1",
      render: (index) => {
        const selectedStory = stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
        return (
          <FacebookShareButton url={storyLink} quote={stories[index]?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
              </svg>
            </a>
          </FacebookShareButton>
        );
      },
    },
    {
      label: "WhatsApp",
      key: "2",
      render: (index) => {
        const selectedStory = stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
        return (
          <WhatsappShareButton url={storyLink} title={stories[index]?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
              </svg>
            </a>
          </WhatsappShareButton>
        );
      },
    },
    {
      label: "Twitter",
      key: "3",
      render: (index) => {
        const selectedStory = stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}/${selectedStory.title}`;
        return (
          <TwitterShareButton url={storyLink} title={stories[index]?.title}>
            <a className={styles.svgLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </TwitterShareButton>
        );
      },
    },

    {
      label: "Share in private",
      key: "2",
      onClick: (index) => {
        const selectedStory = stories[index];
        const storyLink = `${siteUrl}/stories/${selectedStory._id}`;
      },
      render: (index) => {
        const selectedStory = stories[index];
        const storyId = `${selectedStory._id}`;
        return (
          <>
            <div
              className={styles.sharePrivate}
              onClick={() => sendInMessage(storyId)}
            >
              <ReactSVG src={storyEdit} />
            </div>
          </>
        );
      },
    },
  ];

  const toggleFriendsListModal = () => {
    if (token) {
      setFriendsListModal(!friendsListModal);
    } else {
      setLoginModal(!loginModal);
    }
  };

  const sendInMessage = (storyId) => {
    toggleFriendsListModal();
    setStoryId(storyId);
    // navigate(`/newMessage?storyId=${storyLink}`);
  };

  // State to store the selected reaction index for each story
  const [reactions, setReactions] = useState({});

  // Function to handle reaction click
  const handleReactionClick = async (storyId, selectedReactionIndex) => {
    try {
      const selectedReaction = reactionsArray[selectedReactionIndex].id;

      const response = await ApiCall({
        params: {},
        route: `story/add-story-reaction/${storyId}&${selectedReaction}`,
        verb: "put",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setReactions((prevReactions) => ({
          ...prevReactions,
          [storyId]: selectedReactionIndex,
        }));
      } else {
        console.log(response.response.error[0]?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // For less/more story description
  const [showMoreDescription, setShowMoreDescription] = useState(
    Array(stories?.length).fill(false)
  );

  const toggleShowMore = (index) => {
    const newShowMoreDescription = [...showMoreDescription];
    newShowMoreDescription[index] = !newShowMoreDescription[index];
    setShowMoreDescription(newShowMoreDescription);
  };

  const getStoryComments = async (storyId) => {
    if (token) {
      setloadingComments(true);
      try {
        const response = await ApiCall({
          params: {},
          route: "comment/get-comment-list/" + storyId,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setallComments(response.response);
        }
        console.log("commentsResp", response);
      } catch (error) {
        setloadingComments(false);
        // Handle error if necessary
      } finally {
        setloadingComments(false);
      }
    } else {
      console.error("Besoin de se connecter d'abord");
    }
  };

  // load more stories
  const loadMore = () => {
    setStoryCount((prevCount) => prevCount + 1);
  };

  const handleFavoriteClick = async (storyId) => {
    if (token) {
      try {
        const response = await ApiCall({
          params: {},
          route: `story/addToFavourities/` + storyId,
          verb: "put",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          getUserFavouriteStories(favstoryCount);
          // Update the favorite status for the clicked story
          setFavoriteStatus((prevStatus) => ({
            ...prevStatus,
            [storyId]: !prevStatus[storyId],
          }));
        }
        console.log("fav", response);
      } catch (error) { }
    } else {
      setLoginModal(!loginModal);
    }
  };

  // Delete story
  const handleDeleteStory = async (storyId) => {
    try {
      const response = await ApiCall({
        params: {},
        route: "story/deleteStory/" + storyId,
        verb: "delete",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        setAdminStories(stories.filter((story) => story._id !== storyId));
        message.success(response.response);
      }
      // console.log("deleteStoryResp", response);
    } catch (error) {
    } finally {
    }
    setShowCommentsIndex(-1);
  };

  console.log("stories", stories);

  return (
    <>
      <>
        {adminStoriesLoading && !stories ? (
          <div>
            <StoryLoader />
            <StoryLoader />
          </div>
        ) : (
          <>
            {stories?.length == 0 && (
              <h2 className="pt-5 text-center">Aucune histoire trouvée</h2>
            )}
            {stories && (
              <div>
                {stories?.map((story, index) => {
                  const showMore = showMoreDescription[index];
                  const showComments = showCommentsIndex === index;

                  const selectedReactionIndex = reactions[story._id];

                  const date = new Date(story?.createdAt);
                  const DateFormate = `${date.toLocaleDateString()}`;
                  return (
                    <div key={index}>
                      <div className={styles.featuredPostsContainer}>
                        <div className={styles.storyHeader}>
                          <div className={styles.storyHeaderLeft}>
                            <h2 className={styles.heading}>{story.title}</h2>
                            <div className={styles.date}>
                              <p>{DateFormate}</p>
                            </div>
                          </div>
                          <div className={styles.storyHeaderRight}></div>
                        </div>
                        <div className={styles.storyBody}>
                          {showMore ? (
                            <div className={styles.storyContent}>
                              <p>
                                {story.content}
                                {story.content.length > MAX_LENGTH && (
                                  <span
                                    className={styles.contentBtn}
                                    onClick={() => toggleShowMore(index)}
                                  >
                                    Replier ▲
                                  </span>
                                )}{" "}
                              </p>
                            </div>
                          ) : (
                            <div className={styles.storyContent}>
                              <p>
                                {story.content.slice(0, MAX_LENGTH)}
                                {story.content.length > MAX_LENGTH && "..."}
                                {story.content.length > MAX_LENGTH && (
                                  <span
                                    className={styles.contentBtn}
                                    onClick={() => toggleShowMore(index)}
                                  >
                                    Lire la suite
                                  </span>
                                )}
                              </p>
                            </div>
                          )}

                          <div className={styles.details}>
                            <div className={styles.icons}>
                              <div
                                className={styles.favStarIcon}
                                onClick={() => handleFavoriteClick(story._id)}
                              >
                                <img
                                  src={favoriteStatus[story._id] ||
                                    story?.isFavourite ? favorite2 : favorite}
                                  alt="logo"
                                  className={styles.icon}
                                  width={22}
                                  height={20}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </div>
                              {isCommentsOpen.includes(index) ? (
                                <div
                                  className={styles.commentsWrapper}
                                  onClick={() => {
                                    setShowCommentsIndex("abc");
                                    setIsCommentsOpen((prevState) =>
                                      prevState.filter((item) => item !== index)
                                    );
                                  }}
                                >
                                  <img
                                    src={commentsIcon}
                                    className={styles.icon}
                                    alt="comments"
                                    width={20}
                                    height={20}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <p>
                                    (
                                    {allComments?.data?.list[0]?.storyId ===
                                      story._id
                                      ? allComments?.data?.list.length
                                      : story.comments_count}
                                    )
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className={styles.commentsWrapper}
                                  onClick={() => {
                                    setShowCommentsIndex(index);
                                    getStoryComments(story._id);
                                    setIsCommentsOpen((prevState) => [index]);
                                  }}
                                >
                                  <img
                                    src={commentsIcon}
                                    className={styles.icon}
                                    alt="comments"
                                    width={20}
                                    height={20}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                  <p>
                                    (
                                    {allComments?.data?.list[0]?.storyId ===
                                      story._id
                                      ? allComments?.data?.list.length
                                      : story.comments_count}
                                    )
                                  </p>
                                </div>
                              )}

                              <Dropdown
                                overlay={
                                  <Menu className={styles.dropdownMenu}>
                                    {items.map((item) => (
                                      <Menu.Item
                                        key={item.key}
                                        onClick={() =>
                                          item.onClick && item.onClick(index)
                                        }
                                      >
                                        {item.render
                                          ? item.render(index)
                                          : item.label}
                                      </Menu.Item>
                                    ))}
                                  </Menu>
                                }
                                trigger={["click"]}
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <img
                                    src={share}
                                    alt="logo"
                                    className={styles.icon}
                                    width={20}
                                    height={20}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </a>
                              </Dropdown>

                              {userInfo?.isAdmin && (
                                <>
                                  <div
                                    onClick={() => handleDeleteStory(story._id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      style={{
                                        color: "gray",
                                        cursor: "pointer",
                                        paddingTop: "5px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    onClick={() => {
                                      setPostUpdate(true);
                                      setPost((prevState) => {
                                        return {
                                          ...prevState,
                                          title: story?.title,
                                          content: story?.content,
                                          _id: story?._id,
                                          theme: story?.theme?._id,
                                        };
                                      });
                                      setModalOpen(true);
                                    }}
                                  >
                                    <img
                                      src={storyEdit}
                                      alt="edit"
                                      style={{
                                        marginBottom: "4px",
                                        cursor: "pointer",
                                      }}
                                      width={20}
                                      height={20}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className={styles.reactionsContainer}>
                          <div className={styles.themeFiltratin}>
                            <div className={styles.selector}>
                              <div className={styles.selectorItem}>
                                <input
                                  type="radio"
                                  id={`like-${index}`}
                                  name={`reaction-${index}`}
                                  className={styles.selectorItemRadio}
                                  value={1}
                                  data-story-index={index}
                                  onChange={handleReactionChange}
                                />
                                <label
                                  htmlFor={`like-${index}`}
                                  className={styles.selectorItemLabel}
                                >
                                  <img
                                    src={like}
                                    alt="like"
                                    width={15}
                                    height={15}
                                     onContextMenu={e => e.preventDefault()}
                                  />
                                  <p>2</p>
                                </label>
                              </div>
                              <div className={styles.selectorItem}>
                                <input
                                  type="radio"
                                  id={`meToo-${index}`}
                                  name={`reaction-${index}`}
                                  className={styles.selectorItemRadio}
                                  value={2}
                                  data-story-index={index}
                                  onChange={handleReactionChange}
                                />
                                <label
                                  htmlFor={`meToo-${index}`}
                                  className={styles.selectorItemLabel}
                                >
                                  <img
                                    src={meToo}
                                    alt="meToo"
                                    width={15}
                                    height={15}
                                     onContextMenu={e => e.preventDefault()}
                                  />
                                  <p>2</p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className={styles.reactionsWrapper}>
                          {reactionsArray.map((resource, index) => {
                            return (
                              <div>
                                <button
                                  onClick={() => {
                                    handleReactionClick(story._id, index);
                                  }}
                                  className={`${selectedReactionIndex === index
                                      ? styles.activeResource
                                      : ""
                                    } ${!selectedReactionIndex &&
                                      story.reactionId - 1 === index
                                      ? styles.activeResource
                                      : ""
                                    } ${styles.resource}`}
                                >
                                  <img
                                    src={resource.emogi}
                                    alt="like"
                                    width={25}
                                    height={25}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </button>
                              </div>
                            );
                          })}

                          {story?.totalReactions > 0 && (
                            <button>
                              <span>
                                {story?.totalReactions}
                                <FontAwesomeIcon
                                  icon={faCaretRight}
                                  className={styles.fontawesome}
                                />
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      {showComments && (
                        <div className="pt-5">
                          <DisplayComments
                            storyId={story._id}
                            allComments={allComments}
                            setallComments={setallComments}
                            loadingComments={loadingComments}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
                {stories.length !== 0 && (
                  <>
                    {loading ? (
                      <div className="pt-5">
                        <ButtonFilled title="Loading..." textColr="white" />
                      </div>
                    ) : (
                      <div className="pt-5">
                        <ButtonFilled
                          title="VOIR PLUS"
                          textColr="white"
                          handleClick={loadMore}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {/* ) : (
              getToken && <p className="pt-5 text-center">No stories to show</p>
            )} */}
          </>
        )}

        <FriendsListModal
          toggleState={friendsListModal}
          handleToggle={toggleFriendsListModal}
          storyId={storyId}
        />
      </>
    </>
  );
};

export default DisplayAdminStories;
