import React from "react";
import styles from "../../assets/styles/UI/StoryLoader.module.scss";

const StoryLoader = ({ oneLine }) => {
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <h2></h2>
          {!oneLine && <p></p>}
        </div>
      </div>
    </div>
  );
};

export default StoryLoader;
