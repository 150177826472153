import React, { useState, useEffect, useRef } from "react";
import styles from "../assets/styles/CustomInput.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { smilyIcon } from "../assets";

const CustomInput = ({
  label,
  subtitle,
  placeHolder,
  type,
  icon,
  mendatory,
  value,
  handleOnChange,
  handleOnBlur,
  name,
  emogie,
  readOnly,
  titleIcon,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  const handleEmojiSelect = (emoji) => {
    handleOnChange({
      target: {
        name: name,
        value: value ? value + emoji.native : emoji.native,
      },
    });
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.CustomInput}>
      <label>
        {label} <span className="text-danger">{mendatory}</span>{" "}
        {titleIcon && <img src={titleIcon} />}
      </label>

      {!subtitle ? null : <small className={styles.subtitle}>{subtitle}</small>}
      <div className={styles.inputWrapper}>
        <input
          type={type}
          placeholder={placeHolder}
          id={name}
          name={name}
          spellCheck="true"
          accept={
            (name === "photoBefore" || name === "photoAfter") && "image/*"
          }
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          readOnly={readOnly}
        />

        {emogie && (
          <>
            {icon && (
              <FontAwesomeIcon icon={icon} className={styles.fontawesome} />
            )}
            <span className={styles.emogie} onClick={toggleEmojiPicker}>
              <img
                src={smilyIcon}
                alt="smily"
                onContextMenu={(e) => e.preventDefault()}
              />
            </span>
          </>
        )}
      </div>
      {showEmojiPicker && (
        <div className={styles.emogiePicker} ref={emojiPickerRef}>
          <Picker
            data={data}
            previewPosition="none"
            theme="light"
            onEmojiSelect={handleEmojiSelect}
          />
        </div>
      )}
    </div>
  );
};

export default CustomInput;
