import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "../../assets/styles/messages/messages.module.scss";
import {
  backArrow,
  search,
  smilyIcon,
  storyEdit,
  userAvatar,
  burgerCrossBlack,
} from "../../assets";
import { useAuth } from "../../context/AuthContext";
import { ApiCall } from "../../config/apiCall";
import { siteUrl } from "../../environment";
import { io } from "socket.io-client";
import StoryLoader from "../../components/UI/StoryLoader";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody } from "reactstrap";
import { message } from "antd";

const socket = io(siteUrl);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Messages = () => {
  const {
    token,
    loginModal,
    setLoginModal,
    getSearchedUserProfile,
    getUserStories,
    singleUserStoryCount,
    searchedUserId,
    setSearchedUserId,
  } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const chatRoomId = searchParams.get("chatroomId");
  const [username, setUsername] = useState("");
  const [showChat, setShowChat] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [otherUser, setOtherUser] = useState({});
  const [myMessage, setMyMessage] = useState(null);
  const [allConversations, setAllConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] =
    useState(allConversations);
  const [activeConversation, setActiveConversation] = useState(null);
  const [allChatsDetail, setAllChatsDetail] = useState();
  const [messages, setMessages] = useState([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [channelId, setChannelId] = useState();
  const [receiverId, setReceiverId] = useState();
  const chatContainerRef = useRef(null);
  const emojiPickerRef = useRef(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const navigate = useNavigate();

  const UserId = localStorage.getItem("userId");

  const dateArray = [];

  console.log("allChatsDetail", allChatsDetail)

  const toggleChatModal = () => {
    setShowModal(!showModal);
  };

  const sendChat = useCallback(
    async (event) => {
      event.preventDefault();
      if (myMessage !== "") {
        socket.emit("chat", {
          message: myMessage,
          receiver: receiverId,
          chatroomId: channelId,
          sender: UserId,
        });
        setMyMessage("");
      }
    },
    [myMessage, receiverId, channelId, UserId]
  );

  const fetchConversations = async () => {
    setConversationsLoading(true);
    try {
      const response = await ApiCall({
        params: {},
        route: "chat/get-chatRooms",
        verb: "get",
        baseurl: true,
        token: token,
      });

      if (response.status === 200) {
        // setAllConversations(response.response);
        const allConverstaionsArray = [];
        for (const conversation of response.response) {
          const other_user = [
            conversation.user_id1,
            conversation?.user_id2,
          ].filter((user) => user?._id !== UserId)[0];

          allConverstaionsArray.push({ ...conversation, other_user });
        }
        setAllConversations(allConverstaionsArray);
        setFilteredConversations(allConverstaionsArray);
      }
    } catch (error) {
      setConversationsLoading(false);
    } finally {
      setConversationsLoading(false);
    }
  };

  // Sort the conversations by time in descending order
  const sortedConversations = [...filteredConversations].sort((a, b) => {
    const timeA = new Date(a.updatedAt);
    const timeB = new Date(b.updatedAt);
    return timeB - timeA;
  });

  useEffect(() => {
    if (token) {
      // Fetch conversations immediately
      fetchConversations();

      // const interval = setInterval(fetchConversations, 10000);

      // return () => clearInterval(interval);
    }
  }, [token]);

  const handleSearch = (event) => {
    event.preventDefault();

    let searchTerm = event.target.value?.toLowerCase(); // Convert the search term to lowercase for case-insensitive matching

    if (searchTerm == undefined && username.length !== 0) {
      searchTerm = username;
    }

    if (searchTerm) {
      // Filter the conversations based on the search term
      const filtered = allConversations.filter((conversation) => {
        const userName = `${conversation.other_user?.fullname}`.toLowerCase();

        if (searchTerm.trim() == "") {
          return allConversations;
        } else {
          return userName.includes(searchTerm);
        }
      });
      console.log("fil", filtered);
      setFilteredConversations(filtered);
    } else {
      setFilteredConversations(allConversations);
    }
  };

  const handleConversationClick = () => {
    setShowChat(!showChat);
  };

  useEffect(() => {
    if (searchedUserId && allConversations) {
      const filteredConversationForRedirectedUser = allConversations?.find(
        (conversation) => conversation?.other_user?._id === searchedUserId
      );

      if (filteredConversationForRedirectedUser) {
        const conversationId = filteredConversationForRedirectedUser._id;
        console.log("conversationId", conversationId);
        getChats(conversationId);
      } else {
        console.log("No matching conversation found for searchedUserId.");
      }
    }
  }, [searchedUserId, allConversations]);

  useEffect(() => {
    if (chatRoomId) {
      getChats(chatRoomId);
    }
  }, [chatRoomId]);

  const getChats = useCallback(
    async (id) => {
      setChatsLoading(true);
      setShowChat(true);
      try {
        const response = await ApiCall({
          params: {},
          route: `chat/get-chatRoom-detail/${id}`,
          verb: "get",
          baseurl: true,
          token: token,
        });

        if (response.status === 200) {
          setAllChatsDetail(response.response);
          setMessages([...response.response.messages]);
          setChannelId(response.response._id);
          setSearchedUserId(null);
          // update all conversations
          // fetchConversations();

          setOtherUser(
            [response.response.user_id1, response.response.user_id2].filter(
              (user) => user._id !== UserId
            )[0]
          );

          setReceiverId(
            [response.response.user_id1, response.response.user_id2].filter(
              (user) => user._id !== UserId
            )[0]._id
          );
        }
        console.log("chatsResp", response);
      } catch (error) {
        setChatsLoading(false);
      } finally {
        setChatsLoading(false);
      }
    },
    [showChat, token]
  );

  useEffect(() => {
    socket.emit("join", {
      sender: UserId,
      chatroomId: channelId,
    });

    // return () => {
    //   socket.off("chat");
    // };
  }, [channelId]);

  useEffect(() => {
    socket.on("chat", (payload) => {
      console.log("payload chat", payload);
      setMessages((prevState) => [...prevState, payload]);
      scrollToBottom();
    });

    return () => {
      socket.off("chat");
    };
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef?.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleEmojiSelect = (emoji) => {
    setMyMessage(myMessage ? myMessage + emoji.native : emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  // click outside of the picker to toggle the emoji picker
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const getToken = localStorage.getItem("token");
  useEffect(() => {
    if (!getToken) {
      toggleLoginModal();
    }
  }, [token]);

  function getCurrentDate(messageDate) {
    const year = messageDate.getFullYear();
    const month = months[messageDate.getMonth()];
    const day = messageDate.getDate().toString().padStart(2, "0");

    return `${day} ${month} ${year}`;
  }

  function lastOnline(lastOnlineDate) {
    if (lastOnlineDate) {
      const now = new Date();
      const date = new Date(lastOnlineDate);
      const timeDifference = now - date;

      const minute = 60 * 1000;
      const hour = minute * 60;
      const day = hour * 24;
      const week = day * 7;
      const month = day * 30;
      const year = day * 365;

      if (timeDifference < minute) {
        return `last seen ${Math.floor(timeDifference / 1000)} sec`;
      } else if (timeDifference < hour) {
        return `last seen ${Math.floor(timeDifference / minute)} min`;
      } else if (timeDifference < day) {
        return `last seen ${Math.floor(timeDifference / hour)} h`;
      } else if (timeDifference < week) {
        return `last seen ${Math.floor(timeDifference / day)} j`;
      } else if (timeDifference < month) {
        return `last seen ${Math.floor(timeDifference / week)} sem`;
      } else if (timeDifference < year) {
        return `last seen ${Math.floor(timeDifference / month)} mois`;
      } else {
        return `last seen ${Math.floor(timeDifference / year)} ans`;
      }
    } else {
      return "";
    }
  }

  const handleUserClick = (id) => {
    getSearchedUserProfile(id);
    getUserStories(id, singleUserStoryCount);
    navigate("/userProfile");
  };

  const navigateToStory = (id) => {
    navigate(`/stories/${id}`);
  };

  return (
    <>
      <Modal
        size="sm"
        style={{ maxWidth: "560px", width: "100%" }}
        // centered
        backdropClassName="customBackdrop"
        modalClassName="messagingModal"
        toggle={toggleChatModal}
        isOpen={showModal}
        backdrop="static"
      >
        <ModalBody>
          <div className={styles.messagesWrapper}>
            {token && (
              <div
                className={styles.conversations}
                style={{
                  display: showChat ? "none" : "block",
                }}
              >
                <div className={styles.header}>
                  <div className={styles.top}>
                    <p className={styles.title}>Messagerie</p>
                    <div className={styles.rightIcons}>
                      <img
                        src={storyEdit}
                        alt="edit"
                        onClick={() => navigate("/newMessage")}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <img
                        className={styles.crossIcon}
                        src={burgerCrossBlack}
                        alt="close"
                        onClick={() => navigate("/")}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <form onSubmit={handleSearch}>
                    <div className={styles.inputWrapper}>
                      <input
                        type="text"
                        value={username}
                        onChange={(event) => {
                          setUsername(event.target.value);
                          handleSearch(event); // Trigger search when the input value changes
                        }}
                        // onSubmit={(event) => {
                        //   handleSearch(event);
                        // }}
                        placeholder="Rechercher …"
                      />
                      <button type="submit">
                        <img
                          src={search}
                          alt="search"
                          width={20}
                          height={20}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </button>
                    </div>
                  </form>
                </div>
                <div className={styles.body}>
                  <div className={styles.convoWrapper}>
                    {conversationsLoading ? (
                      <StoryLoader />
                    ) : (
                      <>
                        {sortedConversations.length === 0 && (
                          <h4>No Conversations</h4>
                        )}
                        {sortedConversations?.map((message) => {
                          return (
                            <div
                              className={`${styles.messages} ${activeConversation === message?._id
                                ? styles.activeConversation
                                : ""
                                }`}
                              key={message?._id}
                              onClick={() => {
                                setActiveConversation(message?._id);
                                getChats(message?._id);
                              }}
                            >
                              <div className={styles.messageLeft}>
                                <img
                                  className={styles.userPic}
                                  src={
                                    message.other_user?.profilePicture?.url
                                      ? message.other_user?.profilePicture?.url
                                      : userAvatar
                                  }
                                  alt="user"
                                />
                                <div
                                  className={
                                    message.other_user?.isOnline === true
                                      ? styles.greenDot
                                      : styles.whiteDot
                                  }
                                ></div>
                                <div className={styles.userName}>
                                  <h5>{message.other_user?.fullname}</h5>
                                  <p>{message?.lastMessage?.message}</p>
                                </div>
                              </div>
                              <div className={styles.messageRight}>
                                {message.unread_message_count !== 0 && (
                                  <p>{message.unread_message_count}</p>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {
              <div
                className={styles.chat}
                style={{
                  display: showChat ? "block" : "none",
                }}
              >
                {chatsLoading ? (
                  <>
                    <StoryLoader />
                  </>
                ) : (
                  <>
                    <div className={styles.chatHeader}>
                      <img
                        className={styles.back}
                        onClick={handleConversationClick}
                        src={backArrow}
                        alt="back"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <img
                        className={styles.userPic}
                        src={
                          otherUser?.profilePicture?.url
                            ? otherUser?.profilePicture?.url
                            : userAvatar
                        }
                        alt="user"
                      />
                      <div
                        className={
                          otherUser?.isOnline === true
                            ? styles.greenDot
                            : styles.whiteDot
                        }
                      ></div>

                      <div className={styles.userName}>
                        <h5
                          onClick={() => handleUserClick(otherUser?._id)}
                          style={{ cursor: "pointer" }}
                        >
                          {otherUser?.fullname}
                        </h5>
                        <p>
                          {otherUser?.isOnline === true
                            ? "En ligne"
                            : `${lastOnline(otherUser?.last_online)}`}
                        </p>
                      </div>
                    </div>

                    {/* <p className={styles.date}>20 mars 2020 à 13h40</p> */}

                    <div className={styles.chitchat} ref={chatContainerRef}>
                      {messages?.map((msg) => {
                        const messageDate = new Date(msg?.createdAt);
                        const messageHours = messageDate.getUTCHours();
                        const messageMinutes = messageDate.getUTCMinutes();
                        const date = getCurrentDate(messageDate);

                        let msgDate = false;

                        if (dateArray.includes(date)) {
                          msgDate = false;
                        } else {
                          dateArray.push(date);
                          msgDate = true;
                        }

                        return (
                          <div className={styles.messageBox}>
                            <p
                              className={styles.date}
                              style={msgDate ? { marginTop: "0.5rem" } : {}}
                            >
                              {msgDate ? dateArray[dateArray.length - 1] : ""}
                            </p>
                            {msg?.story ? (
                              <div
                                className={
                                  msg?.sender === UserId
                                    ? styles.senderStory
                                    : styles.receiverStory
                                }
                                onClick={() => navigateToStory(msg?.story?._id)}
                              >
                                <div className={styles.storyHeader}>
                                  <div className={styles.dpWrapper}>
                                    <img
                                      src={
                                        msg?.story?.writter?.profilePicture?.url
                                          ? msg?.story?.writter?.profilePicture
                                            ?.url
                                          : userAvatar
                                      }
                                    />
                                  </div>
                                  <div className={styles.contentWrapper}>
                                    <h6 className={styles.name}>
                                      {msg?.story?.writter?.fullname}
                                    </h6>
                                    <h6 className={styles.username}>
                                      {msg?.story?.writter?.username}
                                    </h6>
                                  </div>
                                </div>

                                <div className={styles.storyBody}>
                                  <div className={styles.bodyTop}>
                                    <h6 className={styles.theme}>
                                      {msg?.story?.theme?.name}
                                    </h6>
                                  </div>
                                  <div className={styles.bodyBottom}>
                                    <h6 className={styles.title}>
                                      {msg?.story?.title}
                                    </h6>
                                    <p className={styles.content}>
                                      {msg?.story?.content}
                                    </p>
                                  </div>
                                </div>
                                <span className={styles.msgTimeForStory}>
                                  {messageHours?.toString().padStart(2, "0")}:
                                  {messageMinutes?.toString().padStart(2, "0")}
                                </span>
                              </div>
                            ) : msg?.resource ? (
                              <p
                                className={
                                  msg?.sender === UserId
                                    ? styles.senderMsg
                                    : styles.receiverMsg
                                }
                              >
                                <a href={msg?.resource}>{msg?.resource}</a>
                                <span className={styles.msgTime}>
                                  {messageHours?.toString().padStart(2, "0")}:
                                  {messageMinutes?.toString().padStart(2, "0")}
                                </span>
                              </p>
                            ) : (
                              <p
                                className={
                                  msg?.sender === UserId
                                    ? styles.senderMsg
                                    : styles.receiverMsg
                                }
                              >
                                {msg?.message}
                                <span className={styles.msgTime}>
                                  {messageHours?.toString().padStart(2, "0")}:
                                  {messageMinutes?.toString().padStart(2, "0")}
                                </span>
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                {/* {allChatsDetail?.userBlockedYou &&
                  <p className={styles.blockedText}>This user blocked you</p>
                } */}

                {/* {!allChatsDetail?.userBlockedYou && */}
                  <>
                    <form onSubmit={!allChatsDetail?.userBlockedYou ? sendChat : null}
                      className={`${allChatsDetail?.userBlockedYou ? styles.messageInputDisabled : ""}`}>
                      <input
                        value={myMessage}
                        onChange={(e) => setMyMessage(e.target.value)}
                        placeholder="Écrire un message ..."
                        disabled={allChatsDetail?.userBlockedYou}
                      />
                    </form>

                    <span className={styles.emogie}>
                      <img
                        onClick={toggleEmojiPicker}
                        src={smilyIcon}
                        alt="smily"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <button onClick={!allChatsDetail?.userBlockedYou ? sendChat : null} className={styles.sendMessageBtn}>
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          style={{ color: "gray" }}
                        />
                      </button>
                    </span>
                    {showEmojiPicker && (
                      <div className={styles.emogiePicker} ref={emojiPickerRef}>
                        <Picker
                          data={data}
                          previewPosition="none"
                          theme="light"
                          onEmojiSelect={handleEmojiSelect}
                        />
                      </div>
                    )}
                  </>
                {/* } */}
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Messages;
