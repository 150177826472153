import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/signUp/userSignup.module.scss";
// reactstrap components
import { Modal, ModalBody } from "reactstrap";
import { Steps } from "antd";

import Step1 from "./SignUpStep1";
import Step2 from "./SignUpStep2";
import Step3 from "./SignUpStep3";
import { burgerCrossBlack } from "../../../assets";
import { useNavigate } from "react-router-dom";

const initialState = {
  fullname: "",
  username: "",
  email: "",
  password: "",
  dateOfBirth: "",
  selectedThemes: [],
};

const UserSignup = ({
  toggleState,
  handleToggle,
  isVerified,
  setIsVerified,
  email,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState(initialState);

  useEffect(() => {
    if (isVerified) {
      setCurrentStep(0);
      userData.email = "";
    } else {
      userData.email = email;
      setCurrentStep(2);
    }
  }, [isVerified, email]);

  const navigate = useNavigate();

  const userDataFunc = (data) => {
    setCurrentStep(currentStep + 1);
    // setUserData({ ...userData, ...data });
  };
  // console.log("userData", userData);

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: "Formulaire d’inscription",
      content: (
        <Step1
          userDataFunc={userDataFunc}
          userData={userData}
          setUserData={setUserData}
          initialState={initialState}
        />
      ),
    },
    {
      title: "Thèmes Favoris",
      content: (
        <>
          <Step2
            userDataFunc={userDataFunc}
            userData={userData}
            setUserData={setUserData}
            goBack={goBack}
          />
        </>
      ),
    },
    {
      title: "Validation",
      content: (
        <Step3
          userDataFunc={userDataFunc}
          userData={userData}
          setCurrentStep={setCurrentStep}
          handleToggle={handleToggle}
          setIsVerified={setIsVerified}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleSignUpModalClose = () => {
    handleToggle();
    navigate("/");
    setTimeout(()=>{setIsVerified(true)},500);
  };

  return (
    <div>
      <Modal
        size="sm"
        style={{ maxWidth: "740px", width: "100%" }}
        centered
        modalClassName="SignUpModal"
        toggle={handleToggle}
        isOpen={toggleState}
        backdrop="static"
      >
        <ModalBody
          className={`${styles.signUpModalBody}  Dashboard-policies-popup-body`}
        >
          <div className={styles.signUpModalBodyHeader}>
            <h5 id="signUpModalLabel">Inscription</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={handleSignUpModalClose}
            >
              {/* <span className={styles.close} aria-hidden={true}>
                ×
              </span> */}
              <img className="py-2" src={burgerCrossBlack} alt="close"  onContextMenu={e => e.preventDefault()}/>
            </button>
            <div
              className={`${currentStep === 0 && styles.customLineStart} 
              ${currentStep === 1 && styles.customLineStart1} 
              ${currentStep === 2 && styles.customLineStart2} `}
            ></div>
            <div
              className={`${currentStep === 0 && styles.customLineEnd} 
              ${currentStep === 1 && styles.customLineEnd1} 
              ${currentStep === 2 && styles.customLineEnd2}`}
              style={{
                background: currentStep === 2 ? "#00565b" : "#f5f7f8",
              }}
            ></div>
          </div>
          {currentStep == 0 && (
            <p className="pb-4">
              Rejoignez la communauté pour partager les citrons et les limonades
              !
            </p>
          )}
          {currentStep == 1 && (
            <p className="pb-4">Selectionnez jusqu’à 3 thèmes favoris.</p>
          )}
          {currentStep == 2 && <p className="pb-4">Activation du compte.</p>}

          <div>
            <Steps
              direction="horizontal"
              responsive={false}
              className="custom-steps"
              current={currentStep}
              labelPlacement="vertical"
              items={items}
            />
            <div>{steps[currentStep]?.content}</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserSignup;
