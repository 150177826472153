import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/resources/Resources.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { expand, nextarrowRed, point48, relax } from "../../assets";
import { ReactSVG } from "react-svg";
import { resources } from "./resourcesData";
import { Modal, ModalBody } from "reactstrap";


const Resources = () => {
  const [selectedResourceBox, setSelectedResourceBox] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const resourceId = searchParams.get("resourceId");
  const typeId = searchParams.get("typeId");
  const [professionalPopup, setProfessionalPopup] = useState(false);

  //   Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const USERS_PER_PAGE = 5;
  const totalPages = Math.ceil(resources.length / USERS_PER_PAGE);
  const startIndex = (currentPage - 1) * USERS_PER_PAGE;
  const endIndex = startIndex + USERS_PER_PAGE;
  const displayedProfessionals = resources.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;

    if (totalPages <= maxDisplayedPages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const middleIndex = Math.floor(maxDisplayedPages / 2);
      let startPage = currentPage - middleIndex;
      let endPage = currentPage + middleIndex;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (endPage > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };
  //   Pagination end

  // active the box using prams id
  useEffect(() => {
    if (typeId) {
      setSelectedResourceBox(typeId);
    }
  }, [typeId]);

  // scroll to the specific id

  useEffect(() => {
    const found = resources
      ?.find((el) => el.id == typeId)
      ?.resourceList?.find((el) => el.id == resourceId);
    console.log("useeffect", resourceId, found);
    const elementToScroll = document.getElementById(`${found?.id}`);
    console.log(elementToScroll, "scroll");
    if (elementToScroll) {
      // Scroll to the element
      elementToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [resourceId]);

  const goToResourceDetails = (id) => {
    if (id == 8 || id == 11) {
      navigate(`/ressources/details2/${selectedResourceBox}?resourceId=${id}`);
    } else if (
      id == 13 ||
      id == 16 ||
      id == 17 ||
      id == 18 ||
      id == 19 ||
      id == 20 ||
      id == 21 ||
      id == 22 ||
      id == 23 ||
      id == 24 ||
      id == 25 ||
      id == 26 ||
      id == 27 ||
      id == 28 ||
      id == 29 ||
      id == 30 ||
      id == 31 ||
      id == 32 ||
      id == 33 ||
      id == 34 ||
      id == 35 ||
      id == 36 ||
      id == 38 ||
      id == 39 ||
      id == 40 ||
      id == 41 ||
      id == 42 ||
      id == 43 ||
      id == 44 ||
      id == 45 ||
      id == 46 ||
      id == 47 ||
      id == 49 ||
      id == 50
    ) {
      navigate(`/ressources/details4/${selectedResourceBox}?resourceId=${id}`);
    } else if (id == 15) {
      toggleProfrssionalPopup();
    } else if (id === "37" || id === "48") {
      return;
    } else {
      navigate(`/ressources/details/${selectedResourceBox}?resourceId=${id}`);
    }
  };

  const toggleProfrssionalPopup = () => {
    setProfessionalPopup(!professionalPopup);
  };

  console.log("resources", resources);

  return (
    <div className={styles.resourcesContainer}>
      <div className={styles.topContent}>
        <h2 className={styles.heading}>Autres ressources</h2>
        <p className={styles.subHeading}>
          <strong>TOP 50</strong> des choses à faire afin de voir la vie en
          bonne Limonade !
        </p>
        <p className={styles.paragraph}>
          (pour surmonter les épreuves, avoir un impact positif sur soi-même et
          sur les autres, profiter de la vie et de ses petits plaisirs !)
        </p>
      </div>

      <div className={styles.resourceBoxes}>
        {resources.map((resource, index) => {
          return (
            <div
              className={`${styles.resourceBox} ${
                selectedResourceBox === resource.id
                  ? styles.selectedResourceBox
                  : ""
              }`}
              onClick={() => {
                setSelectedResourceBox(resource.id);
              }}
            >
              <div
                className={`${
                  selectedResourceBox === resource.id
                    ? styles.whiteColor
                    : styles.greenColor
                } ${styles.svgContainer} `}
              >
                <ReactSVG
                  src={
                    selectedResourceBox === resource.id
                      ? resource.iconWhite
                      : resource.iconGreen
                  }
                  alt="resourceIcon"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <h4>{resource.heading}</h4>
              <p>{resource.subHeading}</p>
              <div className={styles.icon}>
                <img
                  src={selectedResourceBox === resource.id ? relax : expand}
                  alt="resourceIcon"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </div>
          );
        })}
      </div>

      {resources.map((resource, index) => {
        return (
          <div className={styles.resourceListWrapper}>
            {resource.resourceList.map((resourcelist) => {
              if (resource.id === selectedResourceBox) {
                return (
                  <>
                    {/* {resourcelist.id == 12 ? (
                      <Link
                        to={"https://www.ticketmaster.com/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={styles.resourceList}>
                          <div className={styles.resourcelistHeadingWrapper}>
                            <div className={styles.top}>
                              <h4 className={styles.resourcelistHeading}>
                                {resourcelist.title}
                              </h4>
                              
                            </div>
                            {resourcelist.description != "" && (
                              <div className={styles.bottom}>
                                <div className={styles.details}>
                                  <p className={styles.description}>
                                    {resourcelist.description}
                                  </p>
                                </div>
                                <img
                                  src={nextarrowRed}
                                  alt="next"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    ) : ( */}
                    <div
                      className={styles.resourceList}
                      onClick={() => goToResourceDetails(resourcelist.id)}
                      id={resourcelist.id}
                    >
                      <div className={styles.resourcelistHeadingWrapper}>
                        <div className={styles.top}>
                          <h4 className={styles.resourcelistHeading}>
                            {resourcelist.title}
                            <br />
                            <span>{resourcelist.title2}</span>
                          </h4>

                          {resourcelist.id === "48" && (
                            <div
                              className={styles.iconWrapper}
                              style={{ width: "300px" }}
                            >
                              <img src={point48} />
                            </div>
                          )}

                          {/* <p className={styles.description}>
                          {resourcelist.description}
                        </p> */}
                        </div>
                        {resourcelist.description != "" && (
                          <div className={styles.bottom}>
                            <div className={styles.details}>
                              <p className={styles.description}>
                                {resourcelist.description}
                              </p>
                              {resourcelist.description2 && (
                                <p className={`pt-0 ${styles.description}`}>
                                  {resourcelist.description2}
                                </p>
                              )}
                            </div>

                            {resourcelist.id != "37" && (
                              <img
                                src={nextarrowRed}
                                alt="next"
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* )} */}
                  </>
                );
              }
              return null;
            })}
          </div>
        );
      })}

      {/* Pagination controls */}

      <div>
        <Modal
          // size="sm"
          // style={{ maxWidth: "450px", width: "100%" }}
          centered
          modalClassName="loginModal"
          toggle={toggleProfrssionalPopup}
          isOpen={professionalPopup}
        >
          <ModalBody>
            {resources.map((resource, index) => {
              return (
                <div>
                  {resource.resourceList.map((resourcelist) => {
                    if (resourcelist.id == 15) {
                      return (
                        <div className={styles.professionalPopupWrapper}>
                          <div>
                            <p className={styles.courcesButton}>
                              {resourcelist?.description}
                            </p>
                            <Link to={resourcelist?.link}>
                              {resourcelist?.buttonTitle}
                            </Link>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              );
            })}
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default Resources;
